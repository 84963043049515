import { useState } from 'react';
import { StagingFile } from '../types';

type StagingOptions = {
  append?: boolean;
};

const defaultOptions: StagingOptions = {
  append: true,
};

function useStagingFiles(customOptions = defaultOptions) {
  const [files, setFiles] = useState<StagingFile[]>([]);

  const options = { ...defaultOptions, ...customOptions };

  const handleChangeInputFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selected = e.currentTarget.files
      ? Array.from(e.currentTarget.files)
      : [];

    if (selected.length) {
      setFiles(current => {
        const adding = selected.map(file => ({
          id: btoa(`${file.name}#${Date.now()}`),
          file,
        }));

        return options.append ? [...current, ...adding] : adding;
      });
    }

    e.currentTarget.value = '';
  };

  const handleDeleteFile = (file: StagingFile) => {
    setFiles(current => current.filter(({ id }) => id !== file.id));
  };

  return {
    files,
    setFiles,
    handleDeleteFile,
    handleChangeInputFile,
  };
}

export default useStagingFiles;
