import React, { useState, useEffect, useMemo } from 'react';
import { Form, Field } from 'react-final-form';
import styled from 'styled-components/macro';
import {
  DropZone,
  Span,
  Button,
  SortableTable,
  P,
} from '@eriksdigital/atomic-ui/components';

import t from 'locales/translation';
import { notEmpty } from 'utils/validators';
import { getResourcesForEntity } from 'services/fileManagementServices';
import { Controls } from 'app/components/Controls';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import {
  FinalSelect,
  FinalTextField,
  FinalDatePicker,
} from 'app/components/FinalField';
import {
  CustomerObject,
  EntityType,
  ResourceType,
  Resource,
  ValueLabel,
} from 'types';

type LogoPreview = Pick<Resource, 'url' | 'description'>;

interface CustomerInfoFormProps {
  customer?: CustomerObject;
  onSubmit: Function;
  countriesLoading: boolean;
  contractPhasesLoading: boolean;
  technicalContactsLoading: boolean;
  countryOptions: ValueLabel[];
  contractPhaseOptions: ValueLabel[];
  technicalContactsOptions: ValueLabel[];
}

const InactivePhase = 'INACTIVE';

export const CustomerInfoForm = ({
  customer,
  onSubmit,
  countriesLoading,
  contractPhasesLoading,
  technicalContactsLoading,
  countryOptions,
  contractPhaseOptions,
  technicalContactsOptions,
}: CustomerInfoFormProps) => {
  const [stagingFile, setStagingFile] = useState<File>();

  const [logoLoading, setLogoLoading] = useState<boolean>(false);
  const [logoResource, setLogoResource] = useState<Resource>();
  const [logoPreview, setLogoPreview] = useState<LogoPreview>();

  const initialValues = useMemo(() => {
    let initialState = {
      ...customer,

      contractStartDate: customer?.contractStartDate
        ? new Date(customer.contractStartDate)
        : undefined,
      contractEndDate: customer?.contractEndDate
        ? new Date(customer.contractEndDate)
        : undefined,
      contractInactivationDate: customer?.contractInactivationDate
        ? new Date(customer.contractInactivationDate)
        : undefined,

      country: customer?.country
        ? countryOptions.find(opt => opt.value === customer.country)
        : undefined,
      contractPhase: customer?.contractPhase
        ? contractPhaseOptions.find(opt => opt.value === customer.contractPhase)
        : undefined,

      technicalContact: customer?.technicalContact
        ? technicalContactsOptions.find(
            opt => opt.value === customer.technicalContact,
          )
        : undefined,
    };
    return initialState;
  }, [
    contractPhaseOptions,
    countryOptions,
    customer,
    technicalContactsOptions,
  ]);

  const handleSubmit = payload => {
    const { id, technicalContact, ...formFields } = payload;
    formFields.technicalContact = technicalContact
      ? technicalContact.value
      : '';
    onSubmit({ id, formFields, logo: stagingFile });
  };

  const loadCustomerLogo = async (customerId: string) => {
    try {
      setLogoLoading(true);

      const {
        data: response,
      }: { data: Resource[] } = await getResourcesForEntity(customerId, {
        entityType: EntityType.CUSTOMER,
        resourceTypes: [ResourceType.LOGO],
      });

      setLogoResource(response[0]);
      setLogoPreview(response[0]);
      setLogoLoading(false);
    } catch (e) {
      setLogoLoading(false);
    }
  };

  // Sets the preview logoPreview if file staging
  useEffect(() => {
    if (stagingFile) {
      const reader = new FileReader();
      reader.onload = function (e) {
        if (e && e.target && e.target.result) {
          setLogoPreview({
            url: e.target.result.toString(),
            description: stagingFile.name,
          });
        }
      };
      reader.readAsDataURL(stagingFile);
    } else {
      setLogoPreview(undefined);
    }
  }, [stagingFile]);

  useEffect(() => {
    setStagingFile(undefined);
    setLogoPreview(undefined);
    customer && loadCustomerLogo(customer.id);
  }, [customer]);

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, form, submitting, pristine, values }) => {
        if (values?.contractPhase?.value !== InactivePhase) {
          form.change('contractInactivationDate', '');
        }

        return (
          <form onSubmit={handleSubmit}>
            <FieldsWrapper>
              <FormField>
                <Field
                  name="name"
                  label={t('Name')}
                  validate={value =>
                    notEmpty(value, {
                      errorMessage: t('ErrorMessage.FieldValidation.NotEmpty'),
                    })
                  }
                >
                  {props => (
                    <FinalTextField id="customer_name_text_input" {...props} />
                  )}
                </Field>
              </FormField>
              <FormField>
                <Field name="country" label={t('Country')}>
                  {props => (
                    <FinalSelect
                      {...props}
                      id="country_dropdown_select"
                      isLoading={countriesLoading}
                      isDisabled={countriesLoading}
                      renderOptions={(value: string) => {
                        const label = countryOptions.find(
                          e => e.value === value,
                        )?.label;

                        return { label, value };
                      }}
                      options={countryOptions.map(e => e.value)}
                      value={props.input.value || undefined}
                    />
                  )}
                </Field>
              </FormField>
              <FormField>
                <Field name="customerId" label={t('CustomerId')}>
                  {props => (
                    <FinalTextField id="customer_id_text_input" {...props} />
                  )}
                </Field>
              </FormField>
              <FormField>
                <Field name="technicalContact" label={t('TechnicalContact')}>
                  {props => (
                    <FinalSelect
                      {...props}
                      id="technical_contact_typeahead"
                      isLoading={technicalContactsLoading}
                      isDisabled={technicalContactsLoading}
                      renderOptions={(value: string) => {
                        const label = technicalContactsOptions.find(
                          e => e.value === value,
                        )?.label;

                        return { label, value };
                      }}
                      options={technicalContactsOptions.map(e => e.value)}
                      value={props.input.value || undefined}
                    />
                  )}
                </Field>
              </FormField>
              <FormField>
                <Field
                  label={t('ContractStartDate')}
                  name="contractStartDate"
                  maxDate={new Date()}
                  component={FinalDatePicker}
                />
              </FormField>
              <FormField>
                <Field
                  label={t('ContractEndDate')}
                  name="contractEndDate"
                  maxDate={new Date()}
                  component={FinalDatePicker}
                />
              </FormField>
              <FormField>
                <Field name="contractPhase" label={t('ContractPhase')}>
                  {props => (
                    <FinalSelect
                      {...props}
                      isLoading={contractPhasesLoading}
                      isDisabled={contractPhasesLoading}
                      id="customer_contract_date"
                      renderOptions={(value: string) => {
                        const label = contractPhaseOptions.find(
                          e => e.value === value,
                        )?.label;

                        return { label, value };
                      }}
                      options={contractPhaseOptions.map(e => e.value)}
                      value={props.input.value || undefined}
                    />
                  )}
                </Field>
              </FormField>
              <FormField>
                <Field
                  label={t('ContractInactiveDate')}
                  name="contractInactivationDate"
                  maxDate={new Date()}
                  component={FinalDatePicker}
                  disabled={values?.contractPhase?.value !== InactivePhase}
                />
              </FormField>
              <FormField>
                <PositionedP>{t('Customer.ChooseLogo')}</PositionedP>
                {logoLoading ? (
                  <LoadingIndicator />
                ) : (
                  <PositionedDropZone
                    id="customer_logo_dropzone"
                    onChange={fileList => setStagingFile(fileList[0])}
                    uploadInstructionText={t('Drag&Drop')}
                    linkText={t('ClickToUpload')}
                    accept="image/*"
                  >
                    {logoPreview && (
                      <StagingTable
                        header={[
                          {
                            header: 'Preview',
                          },
                          {
                            header: t('Attachments.StagingTable.Name'),
                          },
                        ]}
                        data={[
                          [
                            <img
                              alt="customer_logo"
                              height={40}
                              src={logoPreview.url}
                            />,
                            <FileName>
                              {stagingFile
                                ? stagingFile.name
                                : logoPreview && logoPreview.description}
                            </FileName>,
                          ],
                        ]}
                      />
                    )}
                  </PositionedDropZone>
                )}
              </FormField>
            </FieldsWrapper>
            <Controls>
              <Button
                id="open_modal_button"
                type="submit"
                stretch="auto"
                isLoading={submitting}
                disabled={submitting || (pristine && !stagingFile)}
              >
                {t('SaveChanges')}
              </Button>
              {(stagingFile || !pristine) && (
                <Button
                  id="cancel_button"
                  stretch="auto"
                  variant="secondary"
                  type="reset"
                  onClick={() => {
                    form.reset();
                    logoResource && setLogoPreview(logoResource); // Reset Logo Upload
                    setStagingFile(undefined);
                  }}
                >
                  {t('Cancel')}
                </Button>
              )}
            </Controls>
          </form>
        );
      }}
    />
  );
};

const FileName = styled(Span)`
  flex: 1;
`;

const PositionedDropZone = styled(DropZone)`
  margin: ${({ theme }) => `${theme.spacing.sp8} 0 ${theme.spacing.sp16}`};
`;

const StagingTable = styled(SortableTable)`
  margin: -${({ theme }) => theme.spacing.sp24} 0;
  tbody tr:last-child td {
    border-bottom: 0;
  }
  td {
    padding: ${({ theme }) => theme.spacing.sp8}
      ${({ theme }) => theme.spacing.sp16};
  }
`;

const FieldsWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

const FormField = styled.div`
  padding: ${({ theme }) => theme.spacing.sp12};
  flex: 1 1 50%;

  .react-datepicker-wrapper {
    width: 100%;
  }
`;

const PositionedP = styled(P)`
  font-weight: ${({ theme }) => theme.fonts.fontWeight.medium};
  margin-top: ${({ theme }) => theme.spacing.sp24};
`;
