import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
//import { HomeIcon, QuoteIcon } from '@eriksdigital/atomic-ui/components/Icons';
import { ReactComponent as DocumentsIcon } from 'assets/icons/documents.svg';
import { ReactComponent as WorkOrdersIcon } from 'assets/icons/work_orders.svg';
import { translationString } from 'locales/translation';
import { useLocation, withRouter } from 'react-router-dom';
import { useInjectReducer } from 'utils/redux-injectors';
import routeHelpers from 'utils/route-helpers';
import { matchMedia, mediaQuery } from 'utils/style-utils';
import MenuAssets from './components/MenuAssets';
import MenuEquipment from './components/MenuEquipment';
import MenuItem from './components/MenuItem';
import MenuSettings from './components/MenuSettings';
import { selectMenuIsOpen, selectSubMenuIsOpen } from './selectors';
import { actions, reducer, sliceKey } from './slice';
import CreateEquipmentTypeModal from './components/CreateEquipmentTypeModal';
import {
  selectSelectedCustomer,
  selectSelectedLocation,
} from '../CustomerLocation/selectors';

function SideMenu() {
  useInjectReducer({ key: sliceKey, reducer: reducer });

  const isMenuOpen = useSelector(selectMenuIsOpen);
  const isSubMenuOpen = useSelector(selectSubMenuIsOpen());
  const customerId = useSelector(selectSelectedCustomer);
  const locationId = useSelector(selectSelectedLocation);

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.close());
  }, [location, dispatch]);

  useEffect(() => {
    if (matchMedia.phoneLarge.matches) {
      return;
    }

    if (isMenuOpen) {
      document.documentElement.style.setProperty('overflow', 'hidden');
      document.body.style.setProperty('overflow', 'hidden');
    } else {
      document.body.style.removeProperty('overflow');
      document.documentElement.style.removeProperty('overflow');
    }
  }, [isMenuOpen]);

  const ABTestCustomers = [
    '576a83be-16f7-43e4-929c-6769501267aa', // BritishGypsum Dev
    'd4bcde24-bc04-494a-8fe4-a0f386f7566c', // ERIKS UK Demo Acc/Prod
    '85dc4f03-7cf3-44ef-a5a5-3852d912cd8e', // ED QA Automation
    '3d06f50a-4c4c-4ce1-a482-4db21c0452f5', // ERIKS UK Demo Dev
    'c03b1cb3-4afb-4fca-9efd-597febb5b618',
    'b3be2c9b-0caf-4b93-8b6c-c3ee31aa85d0', // Friesland Campina (Prod)
  ];

  return (
    <SideMenuContainer isMenuOpen={isMenuOpen} isSubMenuOpen={isSubMenuOpen}>
      <Menu>
        {/* <MenuItem
          to={routeHelpers.buildDashboardRoute(customerId, locationId)}
          icon={HomeIcon}
          label={translationString('Dashboard')}
        /> */}
        <MenuAssets />
        {ABTestCustomers.includes(customerId) && <MenuEquipment />}
        <MenuItem
          to={routeHelpers.buildDashboardTasksRoute(customerId, locationId)}
          icon={WorkOrdersIcon}
          label={translationString('Tasks')}
        />
        {/* <MenuItem to="/" icon={QuoteIcon} label={translationString('Quotes')} /> */}
        <MenuItem
          to={routeHelpers.buildDashboardAttachmentsRoute(
            customerId,
            locationId,
          )}
          icon={DocumentsIcon}
          label={translationString('Attachments.Attachments')}
        />
        <MenuSettings />
      </Menu>
      <CreateEquipmentTypeModal />
    </SideMenuContainer>
  );
}

export default withRouter(SideMenu);

const SideMenuContainer = styled.nav<{
  isMenuOpen: boolean;
  isSubMenuOpen: boolean;
}>`
  position: fixed;
  top: 40px;
  bottom: 0;
  left: 0;
  width: 100vw;
  max-width: 0;
  background-color: ${({ theme }) => theme.colors.default.blue1};
  z-index: 2;
  transition: max-width 300ms;

  ${({ isMenuOpen }) =>
    isMenuOpen &&
    css`
      max-width: 100vw;
    `}

  ${({ isSubMenuOpen }) =>
    isSubMenuOpen &&
    css`
      max-width: 0;
    `}

  ${mediaQuery.phoneLarge} {
    top: 80px;
    width: 96px;
    max-width: unset;
  }
`;

const Menu = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  overflow: hidden;
`;
