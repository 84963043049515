import { useDispatch, useSelector } from 'react-redux';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { OutputSelector } from 'reselect';
import { DataTableSort } from './types';
import { RootState, FieldType } from 'types';
import { useCallback } from 'react';

type Props<T> = {
  selector: OutputSelector<
    RootState,
    DataTableSort | undefined,
    (res: T) => DataTableSort | undefined
  >;
  action: ActionCreatorWithPayload<DataTableSort>;
};

function useSortRedux<T>({ selector, action }: Props<T>) {
  const dispatch = useDispatch();
  const sort = useSelector(selector);

  const handleSort = useCallback(
    (columnId: string, fieldType: FieldType) => {
      dispatch(
        action({
          columnId,
          fieldType,
          sortDirection: sort?.sortDirection === 'ASC' ? 'DESC' : 'ASC',
        }),
      );
    },
    [dispatch, action, sort],
  );

  return { sort, handleSort };
}

export default useSortRedux;
