import React from 'react';
import { TextField } from '@eriksdigital/atomic-ui/components';

import { WrappedComponentProps } from './types';

export const TextFieldAdapter = ({
  input,
  meta,
  ...otherProps
}: WrappedComponentProps) => {
  return (
    <TextField
      {...input}
      error={meta?.error && meta?.touched}
      errorMessage={meta?.error && meta?.touched ? meta.error : undefined}
      disabled={meta?.submitting}
      {...otherProps}
    />
  );
};
