import React from 'react';
import { H1, P, Link } from '@eriksdigital/atomic-ui/components';
import styled from 'styled-components/macro';

import t from 'locales/translation';
import { pxToRem } from 'utils/style-utils';

export function BoundaryDisplay() {
  return (
    <ErrorLayout>
      <ErrorHeader>{t('ErrorBoundary.title')}</ErrorHeader>
      <ErrorMessage>
        {t('ErrorBoundary.message')} <br />
        <br />
        <Link href="/">{t('ErrorBoundary.action')}</Link>
      </ErrorMessage>
    </ErrorLayout>
  );
}

const ErrorLayout = styled.div`
  padding: ${({ theme }) => theme.spacing.sp32};
`;

const ErrorHeader = styled(H1)`
  font-size: ${({ theme }) => theme.fonts.fontSize.ft24};
  margin: 0;
`;

const ErrorMessage = styled(P)`
  max-width: ${pxToRem(600)};
`;
