export enum ThresholdZone {
  NONE,
  GOOD,
  WARNING,
  DANGER,
}

const ThresholdColors = {
  [ThresholdZone.NONE]: 'rgb(255,255,255)',
  [ThresholdZone.GOOD]: 'rgb(189,229,195)',
  [ThresholdZone.WARNING]: 'rgb(253,226,130)',
  [ThresholdZone.DANGER]: 'rgb(238,143,143)',
};

const DataLineColors = [
  'rgb(26,140,255)',
  'rgb(153,63,0)',
  'rgb(0,63,92)',
  'rgb(255,178,25)',
  'rgb(255,102,25)',
  'rgb(153,63,0)',
  '#31B6E4',
  '#FF00E5',
  '#02803A',
  '#A22615',
  '#4719FF',
  '#C8CCD6',
  '#73E6FF',
];

const TempLineColors = ['rgb(200,204,214)'];

export function getThresholdBackground(zone: ThresholdZone): string {
  return ThresholdColors[zone];
}

export function getDataLineColor(index: number): string {
  return DataLineColors[index % DataLineColors.length];
}

export function getTempLineColor(index: number): string {
  return TempLineColors[index % TempLineColors.length];
}
