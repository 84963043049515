/**
 *
 * Asynchronously loads the component for UserSettings
 *
 */

import { lazyLoad } from 'utils/loadable';

export const UserSettings = lazyLoad(
  () => import('./index'),
  module => module.UserSettings,
);
