import React from 'react';

export const NotificationsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_2788_8173)">
        <path
          d="M14.3535 9.6465L13 8.29295V6.5C12.9984 5.26098 12.5374 4.06655 11.7062 3.1477C10.875 2.22884 9.73266 1.65082 8.5 1.5254V0.5H7.5V1.5254C6.26734 1.65082 5.12496 2.22884 4.29377 3.1477C3.46257 4.06655 3.0016 5.26098 3 6.5V8.29295L1.6465 9.6465C1.60005 9.69291 1.56321 9.74801 1.53807 9.80867C1.51293 9.86933 1.49999 9.93434 1.5 10V11.5C1.5 11.6326 1.55268 11.7598 1.64645 11.8536C1.74021 11.9473 1.86739 12 2 12H5.5V12.5C5.5 13.163 5.76339 13.7989 6.23223 14.2678C6.70107 14.7366 7.33696 15 8 15C8.66304 15 9.29893 14.7366 9.76777 14.2678C10.2366 13.7989 10.5 13.163 10.5 12.5V12H14C14.1326 12 14.2598 11.9473 14.3536 11.8536C14.4473 11.7598 14.5 11.6326 14.5 11.5V10C14.5 9.93434 14.4871 9.86933 14.4619 9.80867C14.4368 9.74801 14.3999 9.69291 14.3535 9.6465ZM9.5 12.5C9.5 12.8978 9.34196 13.2794 9.06066 13.5607C8.77936 13.842 8.39782 14 8 14C7.60218 14 7.22064 13.842 6.93934 13.5607C6.65804 13.2794 6.5 12.8978 6.5 12.5V12H9.5V12.5Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2788_8173">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
