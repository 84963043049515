/**
 *
 * AnnouncementsToast
 *
 */
import React from 'react';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import { Toast } from '@eriksdigital/atomic-ui/components';
import { stacking } from '@eriksdigital/atomic-ui/styles';

import { useInjectReducer } from 'utils/redux-injectors';
import { reducer, sliceKey, actions } from './slice';
import { selectAnnouncements } from './selectors';

export function AnnouncementsToast() {
  useInjectReducer({ key: sliceKey, reducer: reducer });

  const dispatch = useDispatch();
  const { announcements } = useSelector(selectAnnouncements);
  const dismiss = 5;

  // Remove Announcement
  const onExit = (toastId: string) => {
    setTimeout(() => {
      dispatch(actions.removeAnnouncement(toastId));
    }, dismiss * 1000);
  };

  return (
    <ToastContainer>
      {announcements?.map(toast => {
        return (
          <Toast
            id={toast.id}
            key={`toast_${toast.id}`}
            alert={toast.type}
            message={toast.message}
            dismiss={dismiss}
            dismissIcon
            onExit={onExit}
          />
        );
      })}
    </ToastContainer>
  );
}

export default AnnouncementsToast;

const ToastContainer = styled.div`
  position: fixed;
  top: ${({ theme }) => theme.spacing.sp16};
  z-index: ${() => stacking.popUp};
  width: 100%;

  ${({ theme }) => theme.breakpoints.tabletLandscape`
    right: ${({ theme }) => theme.spacing.sp16};
    width: auto;
  `};
`;
