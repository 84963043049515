import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import useEquipmentParams from 'app/containers/Equipments/hooks/useEquipmentParams';
import { equipmentDetailsSaga } from '../saga';
import { reducer, sliceKey, actions } from '../slice';
import {
  selectEquipment,
  selectEquipmentTypeId,
  selectGroupedAttributes,
  selectIsLoading,
} from '../selectors';

function useEquipment() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: equipmentDetailsSaga });

  const dispatch = useDispatch();

  const { miId } = useEquipmentParams();

  const equipment = useSelector(selectEquipment);
  const groupedAttributes = useSelector(selectGroupedAttributes);
  const miTypeId = useSelector(selectEquipmentTypeId);
  const isLoadingEquipment = useSelector(selectIsLoading);

  const fetchEquipment = useCallback(() => {
    if (miId) {
      dispatch(actions.getEquipment(miId));
    }
  }, [dispatch, miId]);

  return {
    equipment,
    fetchEquipment,
    groupedAttributes,
    miTypeId,
    isLoadingEquipment,
  };
}

export default useEquipment;
