import React, { memo, useState } from 'react';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';

import t, { translationString } from 'locales/translation';
import { Resource } from 'types';
import { useAuth } from 'app/providers/AuthProvider';
import { CAM, USER } from 'app/providers/AuthProvider/roles';
import { ResourceEditForm } from 'app/components/Resources/ResourceEditForm';
import { DownloadResourceButton } from 'app/components/Resources/Buttons/DownloadResourceButton';
import { UpdateResourceButton } from 'app/components/Resources/Buttons/UpdateResourceButton';
import { DeleteResourceButton } from 'app/components/Resources/Buttons/DeleteResourceButton';
import { ConfirmDialog } from 'app/components/ConfirmDialog';
import { actions } from '../slice';

const CertificatesTableActions = ({ resource }: { resource: Resource }) => {
  const { userHasRole } = useAuth();

  const [editingResource, setEditingResource] = useState<Resource>();
  const [deletingResource, setDeletingResource] = useState<Resource>();
  const dispatch = useDispatch();

  const handleResourceUpdate = (
    entityId: string,
    resource: Resource,
    updateObject: { description: string; reportDate: Date },
  ): void => {
    dispatch(
      actions.editDocumentsAndCertificates({
        entityId,
        updateObject,
        resourceId: resource.id,
      }),
    );
  };

  const handleResourceDelete = (entityId: string, resource: Resource): void => {
    dispatch(
      actions.deleteDocumentsAndCertificates({
        entityId,
        resourceId: resource.id,
      }),
    );
  };

  return (
    <>
      <ActionsWrapper>
        <DownloadResourceButton resource={resource} />
        {!userHasRole([CAM, USER]) && (
          <>
            <UpdateResourceButton
              resourceId={resource.id}
              onClick={() => {
                setEditingResource(resource);
              }}
            />
            <DeleteResourceButton
              resourceId={resource.id}
              onClick={() => {
                setDeletingResource(resource);
              }}
            />
          </>
        )}
      </ActionsWrapper>
      <ResourceEditForm
        open={!!editingResource}
        resource={editingResource}
        onCancel={() => setEditingResource(undefined)}
        onConfirm={(resource, newData) => {
          handleResourceUpdate(resource.entityId, resource, newData);
          setEditingResource(undefined);
        }}
      />
      <ConfirmDialog
        open={!!deletingResource}
        title={t('Resources.Actions.ConfirmDeleteTitle')}
        message={t('Resources.Actions.ConfirmDeleteMessage')}
        onConfirm={() => {
          deletingResource &&
            handleResourceDelete(resource.entityId, deletingResource);
          setDeletingResource(undefined);
        }}
        onCancel={() => setDeletingResource(undefined)}
        confirmText={translationString('Yes')}
        cancelText={translationString('No')}
      />
    </>
  );
};

export default memo(CertificatesTableActions);

const ActionsWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;
