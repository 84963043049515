import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { GridRow, GridColumn } from '@eriksdigital/atomic-ui/components';

import t from 'locales/translation';
import { TaskTypes } from 'types';
import { mediaQuery } from 'utils/style-utils';
import { Text } from 'app/components/Typography';
import useEquipmentParams from 'app/containers/Equipments/hooks/useEquipmentParams';
import useEquipment from 'app/containers/EquipmentDetail/hooks/useEquipment';
import { DataTableColumn } from 'app/components/DataTable/types';
import {
  selectPageInfo,
  selectSort,
  selectWorkOrders,
  selectWorkOrdersIsLoading,
} from '../selectors';
import { actions } from '../slice';
import { mapWorkOrdersToDataTableRow, workOrdersTableColumns } from '../utils';
import { WorkOrdersTable } from './WorkOrdersTable';
import TaskEditorModal from './TaskEditorModal';

export const WorkOrdersTab = ({ miId }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { customerId, locationId } = useEquipmentParams();

  const loadingPlannedWorkOrders = useSelector(
    selectWorkOrdersIsLoading('planned'),
  );
  const loadingPreviousWorkOrders = useSelector(
    selectWorkOrdersIsLoading('previous'),
  );
  const { equipment } = useEquipment();
  const plannedWorkOrders = useSelector(selectWorkOrders('planned'));
  const previousWorkOrders = useSelector(selectWorkOrders('previous'));
  const plannedData = plannedWorkOrders?.map(item =>
    mapWorkOrdersToDataTableRow(item, customerId, locationId, pathname),
  );
  const previousData = previousWorkOrders?.map(item =>
    mapWorkOrdersToDataTableRow(item, customerId, locationId, pathname),
  );

  const columns: Array<DataTableColumn> = workOrdersTableColumns();

  useEffect(() => {
    dispatch(
      actions.getPlannedWorkOrders({
        miId,
        taskTypes: TaskTypes.WORK_ORDER,
      }),
    );
    dispatch(
      actions.getPreviousWorkOrders({
        miId,
        taskTypes: TaskTypes.WORK_ORDER,
      }),
    );
  }, [dispatch, miId]);

  return (
    <Wrapper>
      <GridRow>
        <GridColumn desktop={12} mobile={12}>
          <TableHeader>
            <Text fontSize="ft14" fontWeight="medium">
              {t('WorkOrders.planned')}
            </Text>

            <TaskEditorModal miId={miId} name={equipment?.name || ''} />
          </TableHeader>
          <WorkOrdersTable
            columns={columns}
            data={plannedData}
            sortSelector={selectSort('planned')}
            setSortAction={actions.setPlannedSort}
            isLoading={loadingPlannedWorkOrders}
            pageSelector={selectPageInfo('planned')}
            pageAction={actions.setPlannedPageInfo}
          />
        </GridColumn>
      </GridRow>

      <GridRow>
        <GridColumn desktop={12} mobile={12}>
          <TableHeader>
            <Text fontSize="ft14" fontWeight="medium">
              {t('WorkOrders.previous')}
            </Text>
          </TableHeader>
          <WorkOrdersTable
            columns={columns}
            data={previousData}
            sortSelector={selectSort('previous')}
            setSortAction={actions.setPreviousSort}
            isLoading={loadingPreviousWorkOrders}
            pageSelector={selectPageInfo('previous')}
            pageAction={actions.setPreviousPageInfo}
          />
        </GridColumn>
      </GridRow>
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  padding: 12px;
  margin-top: 0;

  ${mediaQuery.phoneLarge} {
    padding: 0;
    margin-top: 25px;
  }
`;

export const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.sp24};
  margin-bottom: ${({ theme }) => theme.spacing.sp16};

  ${mediaQuery.phoneLarge} {
    padding: 0;
    margin-top: 25px;
    justify-content: flex-start;
  }
`;
