import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from './slice';
import {
  getVisibleFilters,
  hydrateEquipments,
  reduceEnumLabels,
} from './utils';
import { EquipmentFilters } from './types';

const selectDomain = (state: RootState) => state.equipments || initialState;

export const selectMiTypeId = createSelector(
  [selectDomain],
  ({ miTypeId }) => miTypeId,
);

export const selectMetadataDomain = createSelector(
  [selectDomain],
  ({ metadata }) => metadata,
);

export const selectMetadata = createSelector(
  [selectMetadataDomain],
  ({ data }) => data,
);

export const selectEquipmentTypeMetadata = createSelector(
  [selectMiTypeId, selectMetadata],
  (miTypeId, metadata) =>
    metadata?.maintainableItemTypes.find(
      et => et.maintainableItemTypeId === miTypeId,
    ),
);

export const selectEquipmentTypeAttributes = createSelector(
  [selectEquipmentTypeMetadata],
  metadata => metadata?.attributes,
);

export const selectEnumLabels = createSelector([selectMetadata], metadata =>
  metadata ? reduceEnumLabels(metadata) : {},
);

export const selectIsLoadingMetadata = createSelector(
  [selectMetadataDomain],
  ({ loading }) => loading,
);

export const selectAttributeGroups = createSelector(
  [selectMetadata],
  metadata => metadata?.attributeGroups,
);

export const selectAllEquipmentTypes = createSelector(
  [selectMetadata],
  metadata => metadata?.maintainableItemTypes,
);

export const selectEquipmentType = (miTypeId: string) =>
  createSelector([selectMetadata], metadata =>
    metadata?.maintainableItemTypes.find(
      et => et.maintainableItemTypeId === miTypeId,
    ),
  );

export const selectEquipmentTypesDomain = createSelector(
  [selectDomain],
  ({ equipmentTypes }) => equipmentTypes,
);

export const selectEquipmentTypes = createSelector(
  [selectEquipmentTypesDomain],
  ({ data }) => data,
);

export const selectIsLoadingEquipmentTypes = createSelector(
  [selectEquipmentTypesDomain],
  ({ loading }) => loading,
);

export const selectEquipmentsDomain = createSelector(
  [selectDomain],
  ({ equipments }) => equipments,
);

export const selectEquipmentsData = createSelector(
  [selectEquipmentsDomain],
  ({ data }) => data,
);

export const selectEquipments = createSelector(
  [selectEquipmentsData, selectEnumLabels],
  (equipments, enumLabels) =>
    equipments ? hydrateEquipments(equipments, enumLabels) : [],
);

export const selectEquipmentsAllMap = createSelector(
  [selectEquipmentsDomain],
  ({ allMap }) => allMap,
);

export const selectIsLoadingEquipments = createSelector(
  [selectEquipmentsDomain],
  ({ loading }) => loading,
);

export const selectPageInfo = createSelector(
  [selectEquipmentsDomain],
  ({ pageInfo }) => pageInfo,
);

export const selectSort = createSelector(
  [selectEquipmentsDomain],
  ({ sort }) => sort,
);

export const selectFilters = createSelector(
  [selectEquipmentsDomain],
  ({ filters }) => filters,
);

export const selectEquipmentsPayload = createSelector(
  [selectDomain],
  ({ miTypeId, locationId, equipments }) => {
    const { pageInfo, sort, searchText, filters } = equipments;
    let visibleFilters: EquipmentFilters = {};
    if (filters && miTypeId) {
      visibleFilters = getVisibleFilters(filters, miTypeId);
    }
    const fields = visibleFilters
      ? Object.entries(visibleFilters)
          .filter(([fieldName, { values: searchTerm }]) => searchTerm.length)
          .map(([fieldName, { type: fieldType, values: searchTerm }]) => ({
            fieldName,
            fieldType,
            searchTerm,
          }))
      : [];
    const search = { mainTerm: searchText, fields };

    return {
      miTypeId,
      locationId,
      pageIndex: pageInfo?.page,
      pageSize: pageInfo?.pageSize,
      sort: sort
        ? {
            field: sort.columnId,
            order: sort.sortDirection,
            fieldType: sort.fieldType,
          }
        : undefined,
      search,
    };
  },
);

export const selectHierarchyTree = createSelector(
  [selectDomain],
  ({ hierarchyTree }) => hierarchyTree,
);
