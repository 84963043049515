export function buildRedirectPath(
  path: string,
  customerId: string,
  locationId?: string,
) {
  return locationId
    ? path
        .replace(':customerId', customerId)
        .replace(':locationId', locationId)
        .split(locationId)
        .slice(0, 1)
        .concat([locationId])
        .join('')
    : path.replace(':customerId', customerId);
}
