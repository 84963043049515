/**
 *
 * SpacesTree
 *
 */
import React from 'react';
import styled from 'styled-components/macro';

import { HierarchyNode, EntityType } from 'types';
import { LoadingIndicator } from '../LoadingIndicator';
import { TreeNode } from '../TreeNode';

interface Props {
  tree: HierarchyNode | null;
  customerId: string;
  loading?: boolean;
  error?: string | null;
  withStatus?: boolean;
  activeItem?: string;
  buildOverflowMenu?: Function;
  nodeTypesOnClick?: {
    [key in EntityType]?: Function;
  };
  cutNode?: HierarchyNode | null;
  canDrag?: boolean;
}

export function SpacesTree({
  tree,
  customerId,
  loading = false,
  error = null,
  withStatus = false,
  canDrag = false,
  activeItem,
  buildOverflowMenu,
  nodeTypesOnClick,
  cutNode,
}: Props) {
  return (
    <TreeLayout>
      {loading && !tree && <LoadingIndicator />}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {tree !== null && (
        <TreeRoot data-testid="asset-tree">
          <TreeNode
            firstNode
            data={tree}
            defaultOpen
            activeItem={activeItem}
            withStatus={withStatus}
            rootNode={tree.id}
            customerId={customerId}
            buildOverflowMenu={buildOverflowMenu}
            nodeTypesOnClick={nodeTypesOnClick}
            cutNode={cutNode}
            canDrag={canDrag}
            isDragEnabled
          />
        </TreeRoot>
      )}
    </TreeLayout>
  );
}

const TreeLayout = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.sp12} 0;
  min-height: 0;
  min-width: 0;
`;

const TreeRoot = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.colors.text.alert};
`;

export const SpacesTreeStyles = { TreeLayout };
