/**
 *
 * LiveStatusBadge
 *
 */
import React from 'react';
import styled from 'styled-components/macro';
import { StatusBadge } from '@eriksdigital/atomic-ui/components';

interface Props {
  value: number | string;
  color: string;
  animated?: boolean;
}

export function LiveStatusBadge({ value, color, animated = false }: Props) {
  return (
    <StyledStatusBadge
      coloring={color}
      variant="circle"
      text={typeof value === 'number' ? value.toFixed(2) : value}
      animated={animated}
    />
  );
}

const StyledStatusBadge = styled(StatusBadge)<{ coloring: string }>`
  background: ${({ coloring }) => coloring}33;
  border-radius: 50px;
  padding: 6px 14px 6px 24px;

  font-weight: ${({ theme }) => theme.fonts.fontWeight.normal};
  font-size: ${({ theme }) => theme.fonts.fontSize.ft12};
  line-height: 14px;

  &::before {
    width: 8px;
    height: 8px;
    left: 8px;
    transform: translateY(-50%);
  }
`;
