import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';

type PropType<T> = {
  fetchPromise: () => Promise<AxiosResponse<T>>;
  lazy?: boolean;
};

export function useFetch<T>({ fetchPromise, lazy = false }: PropType<T>) {
  const [data, setData] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [isMounted, setIsMounted] = useState<boolean>(true);

  const doFetch = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchPromise();
      isMounted && setData(response.data);
      return response.data;
    } catch (err) {
      isMounted && setError(err as Error);
    } finally {
      isMounted && setIsLoading(false);
    }
  }, [fetchPromise, setData, setError, isMounted]);

  useEffect(() => {
    !lazy && doFetch();
  }, [doFetch, lazy]);

  useEffect(() => {
    return () => {
      setIsMounted(false);
    };
  }, []);

  return {
    isLoading,
    data,
    error,
    doFetch,
  };
}
