import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import {
  all,
  call,
  put,
  take,
  takeLatest,
  select,
  delay,
  race,
} from 'redux-saga/effects';

import { translationString } from 'locales/translation';
import { actions as announcementActions } from 'app/containers/AnnouncementsToast/slice';
import { getSurveys } from 'services/conditionMonitoringService';
import {
  getCustomerLocations as getCustomerLocationsApi,
  postLocationDetails as postLocationDetailsApi,
} from 'services/customersService';
import {
  deleteLocation as deleteLocationApi,
  getLocationOfflineStatus as getLocationOfflineStatusApi,
  postLocationOfflineCheckin as postLocationOfflineCheckinApi,
  postLocationOfflineCheckout as postLocationOfflineCheckoutApi,
  postLocationOfflineClear as postLocationOfflineClearApi,
  putLocationDetails as putLocationDetailsApi,
  getAllowedContractPhases,
  getAllowedCountriesForLocation,
} from 'services/hierarchyServices';
import {
  getSensorProjectLinkedToLocation as getSensorProjectLinkedToLocationApi,
  getUnlinkedSensorProjects as getUnlinkedSensorProjectsApi,
  linkSensorProjectToLocation,
  unlinkSensorProjectFromLocation,
} from 'services/sensorService';
import {
  createNewUser as createNewUserAPI,
  getUserById as getUserByIdAPI,
  getUsersForCustomer as getUsersForCustomerAPI,
  updateUserDetails as updateUserDetailsAPI,
  deleteUser as deleteUserAPI,
  getAllUsers as getAllAPI,
} from 'services/userManagementService';
import apiCall from 'services/utils/apiCall';
import {
  CustomerLocation,
  OfflinedLocationInfoObject,
  SensorType,
  User,
  NameId,
} from 'types';
import RouteHelpers from 'utils/route-helpers';
import { notifyAxiosError, parseAxiosError } from 'utils/error-helpers';
import { getSensorRealType } from 'utils/getSensorType';
import { selectCustomerLocations, selectUploadLogsPayload } from './selectors';
import { actions } from './slice';
import {
  LinkUnlinkSensorPayload,
  LocationVendor,
  LockStatus,
  LogsPayload,
  SensorProject,
} from './types';
import { uploadLogs } from 'services/fileManagementServices';

const CANCEL_STATUS_POLLING = 'CancelStatusPolling';
const POLLING_DELAY = 5000;

export function* getCustomerLocations(action: PayloadAction<string>) {
  const customerId: string = action.payload;

  try {
    const { data: customerLocations } = yield apiCall(
      getCustomerLocationsApi,
      customerId,
    );
    yield put(actions.getCustomerLocationsComplete(customerLocations));
  } catch (error) {
    if (error?.response?.status === 404) {
      yield put(actions.getCustomerLocationsComplete([]));
    } else {
      const effectError = parseAxiosError(
        error,
        translationString('ErrorMessage.LocationsRetrievingError'),
      );
      yield put(actions.getCustomerLocationsError(effectError));
      yield put(announcementActions.addError(effectError));
    }
  }
}

export function* watchCustomerLocationStatuses() {
  yield race({
    task: call(pollAdminCustomerLocationStatus),
    cancel: take(CANCEL_STATUS_POLLING),
  });
}

export function* pollAdminCustomerLocationStatus() {
  while (true) {
    try {
      yield delay(POLLING_DELAY);
      const customerLocations = yield select(selectCustomerLocations);
      const locationIsSyncing = customerLocations.reduce(
        (anySycning, { status }) => anySycning || status === LockStatus.SYNCING,
        false,
      );

      if (!locationIsSyncing) {
        yield put({ type: CANCEL_STATUS_POLLING });
      } else {
        const offlineStatus = yield all(
          customerLocations.map(({ id }) =>
            apiCall(getLocationOfflineStatusApi, id),
          ),
        );
        yield put(
          actions.getAdminCustomerLocationStatusesComplete(
            offlineStatus.map(response => response.data),
          ),
        );
      }
    } catch (error) {
      yield put({ type: CANCEL_STATUS_POLLING });
      const effectError = parseAxiosError(
        error,
        translationString('ErrorMessage.LocationStatusRetrievingError'),
      );
      yield put(announcementActions.addError(effectError));
    }
  }
}

export function* getAdminCustomerLocations(action: PayloadAction<string>) {
  const customerId: string = action.payload;

  try {
    const { data: customerLocations } = yield apiCall(
      getCustomerLocationsApi,
      customerId,
    );

    const offlineStatus = yield all(
      customerLocations.map(({ id }) =>
        apiCall(getLocationOfflineStatusApi, id),
      ),
    );

    const mendixAvailable = offlineStatus.reduce(
      (offline, result) => (result.status === 207 ? false : offline),
      true,
    );
    yield put(actions.putMendixSyncingAvailable(mendixAvailable));

    yield put(
      actions.getAdminCustomerLocationsComplete({
        locations: customerLocations,
        offlineStatus: offlineStatus.map(response => response.data),
      }),
    );
    yield call(watchCustomerLocationStatuses);
  } catch (error) {
    if (error?.response?.status === 404) {
      yield put(
        actions.getAdminCustomerLocationsComplete({
          locations: [],
          offlineStatus: [],
        }),
      );
    } else {
      const effectError = parseAxiosError(
        error,
        translationString('ErrorMessage.LocationsRetrievingError'),
      );
      yield put(actions.getAdminCustomerLocationsError(effectError));
      yield put(announcementActions.addError(effectError));
    }
  }
}

export function* refreshAdminCustomerLocationStatus() {
  try {
    const customerLocations = yield select(selectCustomerLocations);
    const offlineStatus = yield all(
      customerLocations.map(({ id }) =>
        apiCall(getLocationOfflineStatusApi, id),
      ),
    );
    yield put(
      actions.getAdminCustomerLocationStatusesComplete(
        offlineStatus.map(response => response.data),
      ),
    );
  } catch (error) {
    yield notifyAxiosError(
      error,
      translationString('ErrorMessage.LocationStatusRetrievingError'),
    );
  }
}

export function* postCheckoutCustomerLocation(action: PayloadAction<string>) {
  try {
    const {
      data: offlineStatus,
    }: { data: OfflinedLocationInfoObject } = yield apiCall(
      postLocationOfflineCheckoutApi,
      action.payload,
    );
    yield put(actions.putLockOperationSuccess(offlineStatus));
    yield call(watchCustomerLocationStatuses);
  } catch (error) {
    const effectError = parseAxiosError(
      error,
      translationString('ErrorMessage.LockLocationFailed'),
    );
    yield put(announcementActions.addError(effectError));
    yield put(
      actions.putOfflinedLocationOperationFailure({
        locationId: action.payload,
        status: LockStatus.UNLOCKED,
      }),
    );
    yield call(refreshAdminCustomerLocationStatus);
  }
}

export function* postClearLockLocation(action: PayloadAction<string>) {
  try {
    const {
      data: offlineStatus,
    }: { data: OfflinedLocationInfoObject } = yield apiCall(
      postLocationOfflineClearApi,
      action.payload,
    );

    yield put(actions.putLockOperationSuccess(offlineStatus));
    yield call(watchCustomerLocationStatuses);
  } catch (error) {
    const effectError = parseAxiosError(
      error,
      translationString('ErrorMessage.ClearLockLocationFailed'),
    );
    yield put(announcementActions.addError(effectError));
    yield put(
      actions.putOfflinedLocationOperationFailure({
        locationId: action.payload,
        status: LockStatus.LOCKED,
      }),
    );
    yield call(refreshAdminCustomerLocationStatus);
  }
}

export function* postCheckinCustomerLocation(action: PayloadAction<string>) {
  try {
    const {
      data: offlineStatus,
    }: { data: OfflinedLocationInfoObject } = yield apiCall(
      postLocationOfflineCheckinApi,
      action.payload,
    );

    yield put(actions.putLockOperationSuccess(offlineStatus));
    yield call(watchCustomerLocationStatuses);
  } catch (error) {
    const effectError = parseAxiosError(
      error,
      translationString('ErrorMessage.UnlockLocationFailed'),
    );
    yield put(announcementActions.addError(effectError));
    yield put(
      actions.putOfflinedLocationOperationFailure({
        locationId: action.payload,
        status: LockStatus.LOCKED,
      }),
    );
    yield call(refreshAdminCustomerLocationStatus);
  }
}

export function* unlinkSensorProject(
  action: PayloadAction<LinkUnlinkSensorPayload>,
) {
  const { locationId, projectId, vendor } = action.payload;
  try {
    yield apiCall(
      unlinkSensorProjectFromLocation,
      locationId,
      projectId,
      getSensorRealType(vendor),
    );
    yield put(actions.unlinkSensorProjectComplete());
    yield put(
      announcementActions.addSuccess(
        translationString('SuccessMessage.LocationSaved'),
      ),
    );
  } catch (error) {
    yield notifyAxiosError(
      error,
      translationString('ErrorMessage.UnlinkSensorProjectError'),
    );
  }
}

export function* linkSensorProject(
  action: PayloadAction<LinkUnlinkSensorPayload>,
) {
  const { locationId, projectId, vendor } = action.payload;
  try {
    const {
      data: linkedSensorProject,
    }: { data: SensorProject } = yield apiCall(
      linkSensorProjectToLocation,
      locationId,
      projectId,
      getSensorRealType(vendor),
    );
    yield put(actions.linkSensorProjectComplete(linkedSensorProject));
    yield put(
      announcementActions.addSuccess(
        translationString('SuccessMessage.LocationSaved'),
      ),
    );
  } catch (error) {
    yield notifyAxiosError(
      error,
      translationString('ErrorMessage.LinkSensorProjectError'),
    );
  }
}

// Not using the default apiCall helper function because i dont want to retry this call on 404 response
// Because 404 response is valid 'no linked sensor'.
export function* getLinkedSensorProject(action: PayloadAction<LocationVendor>) {
  const { locationId, vendor } = action.payload;
  try {
    const response = yield call(
      getSensorProjectLinkedToLocationApi,
      locationId,
      getSensorRealType(vendor),
    );
    const { data: linkedSensorProject } = response;
    yield put({
      type: `API_${getSensorProjectLinkedToLocationApi.name}_SUCCESS`,
      payload: JSON.stringify(response),
    });
    yield put(actions.getLinkedSensorProjectComplete(linkedSensorProject));
  } catch (error) {
    if (error?.response?.status === 404) {
      yield put(actions.getLinkedSensorProjectComplete(null));
    } else {
      const effectError = parseAxiosError(
        error,
        translationString('ErrorMessage.LinkedSensorProjectRetrievingError'),
      );
      yield put(actions.getLinkedSensorProjectError(effectError));
      yield put(announcementActions.addError(effectError));
    }
  }
}

export function* getUnlinkedSensorProjects(action: PayloadAction<SensorType>) {
  try {
    const {
      data: unlinkedSensorProjects,
    }: { data: SensorProject[] } = yield apiCall(
      getUnlinkedSensorProjectsApi,
      getSensorRealType(action.payload),
    );
    yield put(
      actions.getUnlinkedSensorProjectsComplete(unlinkedSensorProjects),
    );
  } catch (error) {
    yield notifyAxiosError(
      error,
      translationString('ErrorMessage.UnlinkedSensorProjectsRetrievingError'),
    );
  }
}

export function* getSurveyTypes() {
  try {
    const { data: surveyTypes } = yield apiCall(getSurveys);
    yield put(actions.getSurveyTypesComplete(surveyTypes));
  } catch (error) {
    const effectError = parseAxiosError(
      error,
      translationString('ErrorMessage.SurveyTypesRetrievingError'),
    );
    yield put(actions.getSurveyTypesError(effectError));
    yield put(announcementActions.addError(effectError));
  }
}

export function* deleteLocation(action: PayloadAction<string>) {
  try {
    yield apiCall(deleteLocationApi, action.payload);
    yield put(actions.deleteLocationComplete(action.payload));
    yield put(
      announcementActions.addSuccess(
        translationString('SuccessMessage.LocationDeleted'),
      ),
    );
  } catch (error) {
    const effectError = parseAxiosError(
      error,
      translationString('ErrorMessage.LocationsDeleteError'),
    );
    yield put(actions.deleteLocationError(effectError));
    yield put(announcementActions.addError(effectError));
  }
}

export function* postLocationDetails(action: PayloadAction<CustomerLocation>) {
  const { customerId } = action.payload;
  try {
    yield apiCall(postLocationDetailsApi, customerId, action.payload);
    yield put(actions.postLocationDetailsComplete(action.payload));
  } catch (error) {
    const effectError = parseAxiosError(
      error,
      translationString('ErrorMessage.LocationsAddError'),
    );
    yield put(actions.locationDetailsError(effectError));
    yield put(announcementActions.addError(effectError));
  }
}

export function* putLocationDetails(action: PayloadAction<CustomerLocation>) {
  const { id } = action.payload;
  try {
    yield apiCall(putLocationDetailsApi, id, action.payload);
    yield put(actions.putLocationDetailsComplete(action.payload));
  } catch (error) {
    const effectError = parseAxiosError(
      error,
      translationString('ErrorMessage.LocationsUpdateError'),
    );
    yield put(actions.locationDetailsError(effectError));
    yield put(announcementActions.addError(effectError));
  }
}

export function* getCustomerUser(action: PayloadAction<string>) {
  try {
    const { data: user }: { data: User } = yield apiCall(
      getUserByIdAPI,
      action.payload,
    );

    const mappedUserData: User = {
      ...user,
      customerId: user.customer,
    };

    yield put(actions.getAdminCustomerUsersComplete([mappedUserData]));
  } catch (error) {
    yield notifyAxiosError(
      error,
      translationString('ErrorMessage.UserManagement.UnfetchableUser'),
    );
  }
}

export function* getCustomerUsers(
  action: PayloadAction<{
    customerId: string;
    page: number;
    perPage: number;
  }>,
) {
  try {
    const { data: usersPage } = yield apiCall(
      getUsersForCustomerAPI,
      action.payload.customerId,
      action.payload.page,
      action.payload.perPage,
    );

    yield put(actions.getAdminCustomerUsersComplete(usersPage.content));
  } catch (error) {
    const effectError = parseAxiosError(
      error,
      translationString('ErrorMessage.UserManagement.UnfetchableUser'),
    );
    yield put(actions.getAdminCustomerUsersComplete([]));
    yield put(announcementActions.addError(effectError));
  }
}

export function* getCreateNewUser(action: PayloadAction<User>) {
  try {
    yield apiCall(createNewUserAPI, action.payload);
    yield put(actions.getAdminCustomerUsersComplete([]));
    yield put(
      push(
        RouteHelpers.buildAdminCustomerManageRoute(
          'users',
          action.payload.customerId,
        ),
      ),
    );
    yield put(
      announcementActions.addSuccess(
        translationString('SuccessMessage.UserManagement.UserCreated'),
      ),
    );
  } catch (error) {
    const effectError = parseAxiosError(
      error,
      translationString('ErrorMessage.UserManagement.CreateUserFailed'),
    );
    yield put(actions.getAdminCustomerUsersComplete([]));
    yield put(announcementActions.addError(effectError));
  }
}

export function* getUpdateUser(action: PayloadAction<User>) {
  try {
    const { data: user }: { data: User } = yield apiCall(
      updateUserDetailsAPI,
      action.payload,
    );
    yield put(actions.putUpdateUserComplete(user));
    yield put(
      announcementActions.addSuccess(
        translationString('SuccessMessage.UserManagement.UserUpdated'),
      ),
    );
  } catch (error) {
    const effectError = parseAxiosError(
      error,
      translationString('ErrorMessage.UserManagement.UpdateUserFailed'),
    );
    yield put(actions.putUpdateUserComplete());
    yield put(announcementActions.addError(effectError));
  }
}

export function* deleteUser(action: PayloadAction<string>) {
  try {
    yield apiCall(deleteUserAPI, action.payload);
    yield put(actions.deleteUserComplete(action.payload));
    yield put(
      announcementActions.addSuccess(
        translationString('SuccessMessage.UserManagement.UserDeleted'),
      ),
    );
  } catch (error) {
    const effectError = parseAxiosError(
      error,
      translationString('ErrorMessage.UserManagement.DeleteUserFailed'),
    );
    yield put(actions.deleteUserFailed);
    yield put(announcementActions.addError(effectError));
  }
}

export function* getUploadLogs() {
  try {
    const payload: LogsPayload = yield select(selectUploadLogsPayload);
    const { data: logs } = yield apiCall(uploadLogs, payload);
    yield put(actions.getLogsComplete(logs));
  } catch (error) {
    const effectError = parseAxiosError(
      error,
      translationString('ErrorMessage.LogsRetrievingError'),
    );
    yield put(actions.getLogsError(effectError));
    yield put(announcementActions.addError(effectError));
  }
}

export function* getCountries() {
  try {
    const { data: countries }: { data: NameId[] } = yield apiCall(
      getAllowedCountriesForLocation,
    );
    yield put(actions.getCountriesComplete(countries));
  } catch (error) {
    const effectError = parseAxiosError(
      error,
      translationString('ErrorMessage.FetchEntity.Countries'),
    );
    yield put(actions.getCountriesError(effectError));
  }
}

export function* getContractPhases() {
  try {
    const { data: contractPhases }: { data: NameId[] } = yield apiCall(
      getAllowedContractPhases,
    );
    yield put(actions.getContractPhasesComplete(contractPhases));
  } catch (error) {
    parseAxiosError(
      error,
      translationString('ErrorMessage.FetchEntity.ContractPhases'),
    );
  }
}

export function* getTechnicalUsers() {
  try {
    const { data: users } = yield apiCall(getAllAPI, 'FAM', '', 1, 1000);

    yield put(actions.getTechnicalUsersComplete(users.content));
  } catch (error) {
    parseAxiosError(
      error,
      translationString('ErrorMessage.FetchEntity.TechnicalContacts'),
    );
  }
}

export function* customerManagementSaga() {
  yield takeLatest(
    actions.getAdminCustomerLocations.type,
    getAdminCustomerLocations,
  );
  yield takeLatest(actions.getCustomerLocations.type, getCustomerLocations);
  yield takeLatest(actions.getSurveyTypes.type, getSurveyTypes);
  yield takeLatest(actions.unlinkSensorProject.type, unlinkSensorProject);
  yield takeLatest(actions.linkSensorProject.type, linkSensorProject);
  yield takeLatest(
    actions.getUnlinkedSensorProjects.type,
    getUnlinkedSensorProjects,
  );
  yield takeLatest(actions.getLinkedSensorProject.type, getLinkedSensorProject);
  yield takeLatest(actions.putLocationDetails.type, putLocationDetails);
  yield takeLatest(actions.postLocationDetails.type, postLocationDetails);
  yield takeLatest(actions.postLockLocation.type, postCheckoutCustomerLocation);
  yield takeLatest(actions.postClearLockLocation.type, postClearLockLocation);
  yield takeLatest(
    actions.postUnlockLocation.type,
    postCheckinCustomerLocation,
  );
  yield takeLatest(actions.getAdminCustomerUser.type, getCustomerUser);
  yield takeLatest(actions.getAdminCustomerUsers.type, getCustomerUsers);
  yield takeLatest(actions.getCreateNewUser.type, getCreateNewUser);
  yield takeLatest(actions.getUpdateUser.type, getUpdateUser);
  yield takeLatest(actions.deleteUser.type, deleteUser);
  yield takeLatest(actions.deleteLocation.type, deleteLocation);
  yield takeLatest(actions.getUploadLogs.type, getUploadLogs);
  yield takeLatest(actions.setUploadLogsSort.type, getUploadLogs);
  yield takeLatest(actions.setUploadLogsPageInfo.type, getUploadLogs);
  yield takeLatest(actions.getCountries.type, getCountries);
  yield takeLatest(actions.getContractPhases.type, getContractPhases);
  yield takeLatest(actions.getTechnicalUsers.type, getTechnicalUsers);
}
