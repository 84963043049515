import React, { useState } from 'react';

import { TaskTypeField } from 'types';
import { Datepicker } from 'app/components/DatePicker';

type Props = {
  field: TaskTypeField;
};

function InspectionFieldDate({ field }: Props) {
  const { id, fieldName, isMandatory } = field;
  const [value, setValue] = useState<Date | undefined>(undefined);

  return (
    <Datepicker
      id={id}
      name={fieldName}
      selected={value}
      required={isMandatory}
      onChange={date => {
        if (!Array.isArray(date) && date !== null) {
          setValue(date);
        }
      }}
      placeholderText="dd-mm-yyyy"
    />
  );
}

export default InspectionFieldDate;
