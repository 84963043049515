import { useMemo, useState } from 'react';

import { TaskTypeField } from 'types';

export function filterConditionalField(conditional: Map<string, string[]>) {
  return (field: TaskTypeField) =>
    !field.isConditional ||
    Array.from(conditional.values())
      .flatMap(item => item)
      .includes(field.id);
}

type Props = {
  fields: TaskTypeField[];
};

function useConditional({ fields }: Props) {
  const [conditional, setConditional] = useState<Map<string, string[]>>(
    new Map(),
  );

  const hasConditional = useMemo(
    () => fields.some(field => field.isConditional),
    [fields],
  );

  const filteredFields = useMemo(
    () =>
      hasConditional
        ? fields.filter(filterConditionalField(conditional))
        : fields,
    [fields, conditional, hasConditional],
  );

  const handleChange = (value: string | boolean, field?: TaskTypeField) => {
    if (!hasConditional || field?.fieldType !== 'enum') {
      return;
    }

    const selected = field.taskTypeFieldEnums.find(item => item.id === value);

    if (conditional.has(field.id)) {
      setConditional(current => {
        current.delete(field.id);
        return new Map(current);
      });
    }

    if (selected?.conditionalTaskTypeFieldList?.length) {
      setConditional(current => {
        current.set(field.id, selected.conditionalTaskTypeFieldList);
        return new Map(current);
      });
    }
  };

  return {
    filteredFields,
    handleChange,
  };
}

export default useConditional;
