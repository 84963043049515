import { ParentTreeNode } from 'types';
/**
 * returns
 * @param tree
 */
export function flattenParentTree(tree: ParentTreeNode): ParentTreeNode[] {
  let parentNodes: ParentTreeNode[] = [];

  parentNodes = parentNodes.concat(tree);

  if (tree.parent) {
    parentNodes = parentNodes.concat(flattenParentTree(tree.parent));
  }

  return parentNodes;
}
