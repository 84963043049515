/**
 *
 * Modal
 *
 */
import React, { ReactNode, useEffect } from 'react';
import ReactDOM from 'react-dom';
import {
  CrossIcon,
  BackArrowIcon,
} from '@eriksdigital/atomic-ui/components/Icons';

import t from 'locales/translation';
import { TextColor, BgColor } from 'types';
import { Text } from 'app/components/Typography';
import { Div } from 'app/components/Div';
import {
  ModalWrapper,
  Overlay,
  ContentWrapper,
  Content,
  Frame,
  StyledIcon,
} from './style';

const content = React.createRef<HTMLDivElement>();

interface Props {
  id?: string;
  children: ReactNode;
  withFrame?: boolean;
  maxWidth?: number;
  overflowY?: 'visible' | 'hidden' | 'scroll' | 'auto' | 'initial' | 'inherit';
  onClose: Function;
  showCloseIcon?: boolean;
  showCloseHeader?: boolean;
  withOutsideClose?: boolean;
  background?: BgColor;
}

export function Modal(props: Props) {
  const {
    id = 'unset',
    children,
    withFrame = true,
    maxWidth,
    overflowY = 'auto',
    onClose,
    showCloseIcon = true,
    showCloseHeader = false,
    withOutsideClose,
    background,
  } = props;

  const el = document.createElement('div');
  const contentContainsTarget = (target: HTMLElement) =>
    content.current && (content.current as HTMLElement).contains(target);

  useEffect(() => {
    const modalRoot = document.createElement('div');
    modalRoot.setAttribute('id', `${id}-modal-root`);
    document.body.appendChild(modalRoot);

    modalRoot.appendChild(el);

    return () => {
      document.body.removeChild(modalRoot);
    };
  }, [el, id]);

  useEffect(() => {
    const handleOutsideClick = e => {
      if (withOutsideClose && !contentContainsTarget(e.target)) {
        onClose();
      }
    };

    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('click', handleOutsideClick, true);
    document.addEventListener('keydown', handleKeyPress, true);

    return () => {
      document.removeEventListener('click', handleOutsideClick, true);
      document.removeEventListener('keydown', handleKeyPress, true);
    };
  }, [onClose, withOutsideClose]);

  return el
    ? ReactDOM.createPortal(
        <ModalWrapper>
          <Overlay />
          <ContentWrapper>
            <Content
              maxWidth={maxWidth}
              overflowY={overflowY}
              background={background}
              ref={content}
            >
              <Frame withFrame={withFrame}>
                {showCloseIcon && (
                  <Div display="flex" justifyContent="flex-end">
                    <StyledIcon as={CrossIcon} onClick={onClose} />
                  </Div>
                )}
                {showCloseHeader && (
                  <Div
                    display="flex"
                    align="center"
                    bg={BgColor.white}
                    color={TextColor.secondary}
                    padding="16px"
                    onClick={() => onClose()}
                  >
                    <StyledIcon as={BackArrowIcon} />
                    <Text fontSize="ft14">{t('Back')}</Text>
                  </Div>
                )}
                {children}
              </Frame>
            </Content>
          </ContentWrapper>
        </ModalWrapper>,
        el,
      )
    : null;
}
