export const initialCookie = (name: string, value: string, endDate: string) => {
  const existCookie = getCookieValue(name);
  if (existCookie) return;

  setCookie(name, value, endDate);
};

export const getCookieValue = (name: string): string | undefined => {
  const cookiesArray = document.cookie.split('; ');
  const cookie = cookiesArray.find(cookie => cookie.includes(name));
  const value = cookie?.replace(`${name}=`, '');

  return value;
};

export const setCookie = (name: string, value: string, endDate: string) => {
  const expires = new Date(endDate).toUTCString();

  document.cookie = `${name}=${value};expires=${expires};path=/`;
};

export const deleteCookie = (name: string) => {
  const expires = new Date();

  document.cookie = `${name}=;expires=${expires};path=/`;
};
