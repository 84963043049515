import { DataTableColumn, DataTableColumnPreference } from './types';

const DATA_TABLE_COLUMN_PREFERENCES = 'samDataTableColumnPreferences#';

export function getPreferencesLocalStorage(
  tableId: string,
): DataTableColumnPreference[] {
  const fromLocalStorage = localStorage.getItem(
    `${DATA_TABLE_COLUMN_PREFERENCES}${tableId}`,
  );
  return fromLocalStorage ? JSON.parse(fromLocalStorage) : [];
}

export function setPreferencesLocalStorage(
  tableId: string,
  preferences: DataTableColumnPreference[],
) {
  localStorage.setItem(
    `${DATA_TABLE_COLUMN_PREFERENCES}${tableId}`,
    JSON.stringify(preferences),
  );
}
export function changeColumnsOrder(
  columns: DataTableColumn[],
  columnId: string,
  indexFrom: number,
  indexTo: number,
): DataTableColumnPreference[] {
  const column = columns?.find(item => item.id === columnId);
  const copy = [...columns];

  if (column) {
    copy.splice(indexFrom, 1);
    copy.splice(indexTo, 0, column);
  }

  return copy.map((item, index) => ({
    columnId: item.id,
    visible: item.visible,
    order: index,
  }));
}

export function changeColumnVisibility(
  columns: DataTableColumnPreference[],
  column: DataTableColumn,
  visible: boolean,
) {
  const index = columns.findIndex(item => item.columnId === column.id);
  const copy = [...columns];

  if (index > -1) {
    copy.splice(index, 1, { ...columns[index], visible });
  } else {
    copy.push({ columnId: column.id, order: column.order, visible });
  }

  return copy;
}

export function mergeWithPreferences(
  columns: DataTableColumn[],
  preferences: DataTableColumnPreference[],
): DataTableColumn[] {
  return columns?.map(column => {
    const preference = preferences.find(item => item.columnId === column.id);

    return preference
      ? { ...column, visible: preference.visible, order: preference.order }
      : column;
  });
}

export function sortColumns(columns: DataTableColumn[]): DataTableColumn[] {
  return [...columns].sort((a, b) => a.order - b.order);
}
