import React from 'react';
import styled from 'styled-components/macro';
import { ProcessStatus } from './types';
import ProcessStatusIcon from './ProcessStatusIcon';

type Props = {
  status: ProcessStatus;
  children: React.ReactNode;
};

function ProcessStatusIndicator({ status, children }: Props) {
  return (
    <Wrapper>
      <ProcessStatusIcon status={status} />
      {children}
    </Wrapper>
  );
}

export default ProcessStatusIndicator;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  color: ${({ theme }) => theme.colors.default.blue2};
  font-size: ${({ theme }) => theme.fonts.fontSize.ft14};

  span {
    flex: 1;
  }
`;
