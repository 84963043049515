/**
 *
 * LocationSelector
 *
 */
import React, { memo, useState, useEffect } from 'react';
import styled, { css } from 'styled-components/macro';

import t from 'locales/translation';
import { CustomerLocation } from '../../../types';
import { DropdownSelector } from '../DropdownSelector';

interface Props {
  locations: CustomerLocation[];
  homeLocation?: string;
  selectedLocation?: string;
  loading?: boolean;
  onSelectLocation?: Function;
}

export const LocationSelector = memo((props: Props) => {
  const {
    locations,
    selectedLocation,
    loading = false,
    onSelectLocation = () => null,
  } = props;

  const [currentLocation, setCurrentLocation] = useState<
    CustomerLocation | undefined
  >(undefined);

  useEffect(() => {
    if (selectedLocation) {
      const matchingLocations = locations.filter(
        loc => loc.id === selectedLocation,
      );
      if (matchingLocations.length > 0) {
        setCurrentLocation(matchingLocations[0]);
      }
    } else if (locations.length) {
      setCurrentLocation(locations[0]);
    }
  }, [selectedLocation, locations]);

  const selectedItemDisplay = (
    <SelectedLocation data-testid="nav-selected-location">
      {currentLocation ? (
        <h4>{currentLocation.name}</h4>
      ) : (
        <h4>{t('Hierarchy.NoLocationSelected')}</h4>
      )}
      <span>{t('Location.Location')}</span>
    </SelectedLocation>
  );

  return (
    <DropdownSelector
      id="location-selector"
      selectedItemDisplay={selectedItemDisplay}
      listItems={locations.map(loc => ({
        id: loc.id,
        contents: (
          <LocationOption selected={loc.id === selectedLocation}>
            {loc.name}
          </LocationOption>
        ),
      }))}
      onSelect={onSelectLocation}
      loading={loading}
    />
  );
});

const SelectedLocation = styled.div`
  position: relative;
  width: 100%;
  text-align: left;
  line-height: 1;

  span {
    font-weight: ${({ theme }) => theme.fonts.fontWeight.normal};
    font-size: ${({ theme }) => theme.fonts.fontSize.ft10};
    color: ${({ theme }) => theme.colors.default.gray3};
    text-transform: uppercase;
  }

  h4 {
    font-weight: ${({ theme }) => theme.fonts.fontWeight.medium};
    font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
    margin: 0;
    color: inherit;
    white-space: nowrap;
  }
`;

const LocationOption = styled.div<{ selected: boolean }>`
  padding: ${({ theme }) => `${theme.spacing.sp12} 0`};
  font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
  color: ${({ theme }) => theme.colors.default.blue1};

  &:hover {
    text-decoration: underline;
  }

  ${({ selected }) =>
    selected &&
    css`
      font-weight: 600;
    `}
`;
