import React from 'react';
import styled from 'styled-components/macro';
import { Icon } from '@eriksdigital/atomic-ui/components';

const Button = styled.button`
  border: none;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.default.yellowB};
  color: ${({ theme }) => theme.colors.default.blue2};
  text-decoration: none;
  font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
  cursor: pointer;
  white-space: nowrap;

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const ButtonPrimary = styled(Button)`
  background-color: ${({ theme }) => theme.colors.default.yellowB};
  color: ${({ theme }) => theme.colors.default.blue2};
  padding: ${({ theme }) => `0 ${theme.spacing.sp12}`};

  :disabled {
    background-color: ${({ theme }) => theme.colors.default.gray2};
    color: ${({ theme }) => theme.colors.default.white};
  }
`;

export const ButtonSecondary = styled(Button)`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.default.blue1};
  padding: 0;
  height: unset;

  :disabled {
    color: ${({ theme }) => theme.colors.default.gray2};
  }

  :hover {
    text-decoration: underline;
  }
`;

const ButtonIconStyled = styled.button`
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
`;

type ButtonIconProps = {
  icon: React.ReactNode;
  size: 'sz16' | 'sz20' | 'sz24';
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const ButtonIcon = ({ icon: as, size, ...rest }: ButtonIconProps) => (
  <ButtonIconStyled {...rest}>
    <Icon as={as} size={size} />
  </ButtonIconStyled>
);

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  width: 100%;
`;
