import { PayloadAction } from '@reduxjs/toolkit';
import { Resource } from 'types';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState, EntityQuery } from './types';

// The initial state of the mediaGallery container
export const initialState: ContainerState = {
  entityItems: {},
};

const mediaGallerySlice = createSlice({
  name: 'mediaGallery',
  initialState,
  reducers: {
    getMediaItems(state, action: PayloadAction<EntityQuery>) {
      return {
        entityItems: {
          ...state.entityItems,
          [action.payload.entityId]: {
            loading: true,
            error: '',
            items: state.entityItems[action.payload.entityId]?.items || [],
          },
        },
      };
    },
    getMediaItemsSuccessful(
      state,
      action: PayloadAction<{ entityId: string; items: Resource[] }>,
    ) {
      return {
        entityItems: {
          ...state.entityItems,
          [action.payload.entityId]: {
            loading: false,
            error: '',
            items: action.payload.items,
          },
        },
      };
    },
    getMediaItemsError(
      state,
      action: PayloadAction<{ entityId: string; error: string }>,
    ) {
      return {
        entityItems: {
          ...state.entityItems,
          [action.payload.entityId]: {
            loading: false,
            error: action.payload.error,
            items: [],
          },
        },
      };
    },
    removeMediaItem(
      state,
      action: PayloadAction<{ entityId: string; resourceId: string }>,
    ) {
      return {
        entityItems: {
          ...state.entityItems,
          [action.payload.entityId]: {
            loading: true,
            error: '',
            items: state.entityItems[action.payload.entityId].items,
          },
        },
      };
    },
    removeMediaItemSuccess(
      state,
      action: PayloadAction<{ entityId: string; resourceId: string }>,
    ) {
      return {
        entityItems: {
          ...state.entityItems,
          [action.payload.entityId]: {
            loading: false,
            error: '',
            items: state.entityItems[action.payload.entityId].items.reduce(
              (items, currentItem) => {
                return currentItem.id === action.payload.resourceId
                  ? items
                  : [...items, currentItem];
              },
              [] as Resource[],
            ),
          },
        },
      };
    },
    removeMediaItemError(
      state,
      action: PayloadAction<{ entityId: string; error: string }>,
    ) {
      return {
        entityItems: {
          ...state.entityItems,
          [action.payload.entityId]: {
            loading: false,
            error: '',
            items: [],
          },
        },
      };
    },
  },
});

export const { actions, reducer, name: sliceKey } = mediaGallerySlice;
