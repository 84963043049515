/**
 *
 * Equipment Detail
 *
 */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { GridRow, GridColumn } from '@eriksdigital/atomic-ui/components';
import { breakpointObj } from '@eriksdigital/atomic-ui/styles';

import t, { translationString } from 'locales/translation';
import { AlertType, EntityType } from 'types';
import {
  buildEquipmentsRoute,
  buildEquipmentEditRoute,
} from 'utils/route-helpers';
import { useWindowSize } from 'utils/hooks/useWindowSize';
import { MEDIA_RESOURCE_TYPES } from 'utils/constants';
import { useAuth } from 'app/providers/AuthProvider';
import { CAM, FAM, TAM } from 'app/providers/AuthProvider/roles';
import { PageContent } from 'app/elements/Page';
import { H1 } from 'app/components/Typography';
import { Div } from 'app/components/Div';
import Tabs from 'app/components/Tabs';
import SensorAlert from 'app/components/SensorAlert';
import { BackNavigation } from 'app/components/BackNavigation';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { StatusLevel } from 'app/components/StatusLevel';
import { MediaGallery } from 'app/containers/MediaGallery';
import Badges from './components/Badges';
import Alerts from './components/Alerts';
import { AttributesTable } from './components/AttributesTable';
import { WorkOrdersTab } from './components/WorkOrdersTab';
import { AttributesDetails } from './components/AttributesDetails';
import { SlidingSideBar } from '../../components/SlidingSideBar';
import { useCustomerLocation } from '../CustomerLocation';
import { EquipmentInspection } from '../EquipmentInspection';
import { EquipmentAttachments } from '../EquipmentAttachments/index';
import useInspections from '../EquipmentInspection/hooks/useInspections';
import InspectionSideBar from '../EquipmentInspection/components/InspectionSideBar';
import useEquipmentParams from '../Equipments/hooks/useEquipmentParams';
import useEquipment from 'app/containers/EquipmentDetail/hooks/useEquipment';
import { actions } from './slice';
import { selectEquipmentStatus } from './selectors';
import useMetadata from '../Equipments/hooks/useMetadata';
import { EquipmentDetailTab } from './types';

export const EquipmentDetail = () => {
  useCustomerLocation({});

  const { width } = useWindowSize();
  const isMobile = width <= breakpointObj.tabletPortrait;

  const dispatch = useDispatch();
  const history = useHistory();
  const { customerId, locationId, miId } = useEquipmentParams();

  const { userHasRole } = useAuth();
  const { condition, status, isLoadingStatus } = useSelector(
    selectEquipmentStatus,
  );

  const [showAllSpecs, setShowAllSpecs] = useState(false);
  const {
    fetchMetadata: fetchInspectionsMetadata,
    isAddingInspection,
  } = useInspections();
  const { isLoadingMetadata, fetchMetadata } = useMetadata();

  const {
    fetchEquipment,
    equipment,
    groupedAttributes,
    miTypeId,
    isLoadingEquipment,
  } = useEquipment();

  useEffect(() => {
    fetchInspectionsMetadata();
  }, [fetchInspectionsMetadata]);

  useEffect(() => {
    fetchMetadata();
  }, [fetchMetadata]);

  useEffect(() => {
    if (!miId) return;
    fetchEquipment();
    dispatch(actions.getEquipmentStatus(miId));
  }, [dispatch, fetchEquipment, miId]);

  useEffect(() => {
    dispatch(actions.startPoolingEquipment());

    return () => {
      dispatch(actions.stopPoolingEquipment());
    };
  }, [dispatch]);

  const handleEditEquipment = () => {
    if (miTypeId) {
      history.push(
        buildEquipmentEditRoute(customerId, locationId, miTypeId, miId),
      );
    }
  };

  if (isLoadingEquipment || isLoadingMetadata || isLoadingStatus) {
    return <LoadingIndicator />;
  }

  if (!equipment || !groupedAttributes) {
    return null;
  }

  return (
    <>
      <PageContent>
        <Div display="flex" direction="column" gap="24px" mb="24px">
          <>
            {miTypeId && !isMobile && (
              <BackNavigation
                label="BackToOverview"
                name={translationString(`EquipmentTypes.${equipment.type}`)}
                link={buildEquipmentsRoute(customerId, locationId, miTypeId)}
              />
            )}
            <H1>{equipment.name}</H1>
          </>
          <Badges />
        </Div>
        <GridRow>
          <GridColumn desktop={4} mobile={12}>
            <StyledMediaGallery
              hasLightbox
              entityId={equipment.id || miId}
              entityName={equipment.name}
              entityType={EntityType.MAINTAINABLE_ITEM}
              canManage={userHasRole([TAM, FAM])}
              resourceTypes={MEDIA_RESOURCE_TYPES}
            />
          </GridColumn>
          <GridColumn desktop={8} mobile={12}>
            <AttributesTable
              groupedAttributes={groupedAttributes.filter(
                group => group.highlight,
              )}
              openDetails={setShowAllSpecs}
            />
          </GridColumn>
        </GridRow>
        <Tabs<EquipmentDetailTab>
          tabs={[
            {
              id: 'condition-alerts',
              label: (
                <Div
                  display="flex"
                  align="center"
                  justifyContent="space-between"
                  gap="8px"
                  width="100%"
                >
                  {t('ConditionAlerts')}
                  {condition?.conditionScore && (
                    <StatusLevel size="18px" score={condition.conditionScore} />
                  )}
                </Div>
              ),
              content: (
                <Alerts alertTypes={[AlertType.CONDITION, AlertType.SURVEY]} />
              ),
            },
            {
              id: 'threshold-alerts',
              label: (
                <Div
                  display="flex"
                  align="center"
                  justifyContent="space-between"
                  gap="8px"
                  width="100%"
                >
                  {t('ThresholdAlerts')}
                  {status?.severity && (
                    <SensorAlert severity={status.severity} />
                  )}
                </Div>
              ),
              content: <Alerts alertTypes={[AlertType.STATUS]} />,
            },
            {
              id: 'work-orders',
              label: t('WorkOrders'),
              content: <WorkOrdersTab miId={miId} />,
            },
            {
              id: 'inspections',
              label: t('Inspections'),
              content: <EquipmentInspection />,
            },
            {
              id: 'documents',
              label: t('DocumentsAndCertificates'),
              content: <EquipmentAttachments />,
            },
          ]}
        />
      </PageContent>
      {isAddingInspection && <InspectionSideBar />}
      {showAllSpecs && (
        <SlidingSideBar
          onClose={() => setShowAllSpecs(false)}
          onEdit={
            userHasRole([CAM, FAM, TAM]) && !isMobile
              ? handleEditEquipment
              : undefined
          }
          render={() => (
            <AttributesDetails
              groupedAttributes={groupedAttributes}
            ></AttributesDetails>
          )}
          title={t('Specifications')}
        />
      )}
    </>
  );
};

const StyledMediaGallery = styled(MediaGallery)`
  margin-bottom: ${({ theme }) => theme.spacing.sp24};
  position: relative;

  & div:nth-child(1) {
    background-color: ${({ theme }) => theme.colors.background.white};
  }

  & div:nth-child(2) {
    height: 38px;
    background: ${({ theme }) => theme.colors.background.white};
    justify-content: center;
  }
`;
