import { ReactElement } from 'react';
import { ThresholdZone } from 'utils/chartColorProvider';
import { RootState } from './RootState';

export type { RootState };

export const AlertSeverity = {
  SERIOUS: '#d61c1c', // red
  WARNING: '#d99300', // yellow
  INFORMATION: '#646972', // grey
  GOOD: '#33b91e', // green
};

export enum BgColor {
  white = 'white', // white
  gray = 'gray', // #C1C7CD
  lighterGray = 'lighterGray', //#f2f4f8
  lightGray = 'lightGray', // light gray
  darkGray = 'darkGray', // light gray
  lightBlue = 'lightBlue', // light blue
  darkBlue = 'darkBlue', // #005FBE
}

export enum TextColor {
  primary = 'primary', // black
  inverted = 'inverted', // white
  secondary = 'secondary', // light blue
  tertiary = 'tertiary', // dark blue
  muted = 'muted', // gray
  primaryNew = 'primaryNew', //#005FBE
  secondaryNew = 'secondaryNew', //#002c4e
  red = 'red', //#d61c1c
}

export enum IconColor {
  green = 'green', //#009f47
  red = 'red', //#d61c1c
  orange = 'orange', //#d99300
}

export enum AggregationsMode {
  RAW = 'RAW',
  MEAN = 'MEAN',
  MIN = 'MIN',
  MAX = 'MAX',
}

export enum AlertType {
  CONDITION = 'CONDITION',
  STATUS = 'STATUS',
  SURVEY = 'SURVEY',
}

export enum NotificationFilter {
  LOCATION = 'LOCATION',
  SPACE = 'SPACE',
  UNSUBSCRIBE = 'UNSUBSCRIBE',
}

export enum Severity {
  GOOD = 'good',
  CRITICAL = 'critical',
  WARNING = 'warning',
  INFO = 'info',
  UNKNOWN = 'unknown',
}

export enum TaskStatus {
  DRAFT = 'DRAFT',
  TODO = 'TODO',
  DONE = 'DONE',
}

export enum EventType {
  TASK_CREATED = 'TASK_CREATED',
  STATUS_CHANGED = 'STATUS_CHANGED',
  ASSIGNEE_CHANGED = 'ASSIGNEE_CHANGED',
}

export enum EntityType {
  CUSTOMER = 'CUSTOMER',
  LOCATION = 'LOCATION',
  SPACE = 'SPACE',
  ASSET = 'ASSET',
  SUB_UNIT = 'SUB_UNIT',
  MAINTAINABLE_ITEM = 'MAINTAINABLE_ITEM',
}

export enum ConfigurationType {
  CONSTANT = 'CONSTANT',
  SENSOR = 'SENSOR',
  MEASUREMENT = 'MEASUREMENT',
  MAINTAINABLE_ITEM = 'MAINTAINABLE_ITEM',
}

export enum ResourceType {
  ATTACHMENT = 'ATTACHMENT',
  IMAGE = 'IMAGE',
  LOGO = 'LOGO',
  LUBRICATION_REPORT = 'LUBRICATION_REPORT',
  OTHER = 'OTHER',
  QUOTE = 'QUOTE',
  REPAIR_REPORT = 'REPAIR_REPORT',
  TERMO_REPORT = 'TERMO_REPORT',
  VIDEO = 'VIDEO',
  NAME_PLATE_PHOTO = 'NAME_PLATE_PHOTO',
  VIBRATION_REPORT = 'VIBRATION_REPORT',
  THERMOGRAPHY_BASELINE = 'THERMOGRAPHY_BASELINE',
  CERTIFICATE = 'CERTIFICATE',
}

export enum ChartType {
  LINE = 'LINE',
  SCATTER = 'SCATTER',
}

export enum ChartGroupType {
  'ACCELERATION',
  'ACCELERATION_800',
  'VELOCITY_SINGLE_AXIS',
  'VELOCITY_VS_AXIS',
  'VELOCITY_BAND',
  'ACCELERATION_BAND',
}

export enum PlotLimitRatio {
  'ONE_ONE',
  'EIGHT_ONE',
}

export enum MeasurementType {
  ACCELERATION = 'Acceleration',
  VELOCITY = 'Velocity',
  TEMPERATURE = 'Temperature',
}

export enum OrientationVector {
  HORIZONTAL = 'HORIZONTAL',
  AXIAL = 'AXIAL',
  VERTICAL = 'VERTICAL',
}

export enum SensorType {
  BV = 'BV',
  IMX = 'SKF_OBSERVER_IMX',
  NETBITER = 'PROVIDER_DEVICE',
}

export enum ProviderType {
  BV = 'NONE', // Change when fixed on the backend
  IMX = 'NONE',
  NETBITER = 'NETBITER',
}

export enum VendorLink {
  BV = 'Bluzone',
  IMX = 'IMX',
  NETBITER = 'NETBITER',
}

export enum MaintainableItemAttributeType {
  ENUM = 'enum',
  STRING = 'string',
  INTEGER = 'integer',
  DECIMAL = 'decimal',
  LINK = 'link',
}

export enum TABS {
  LOGS = 'Logs',
  TASKS = 'Tasks',
  ATTACHMENTS = 'Attachments',
  GENERAL = 'General',
  VIBRATION = 'VIBRATION',
  FLUID = 'FLUID',
  STATUS = 'STATUS',
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
}

export enum URLParams {
  SU = 'su',
  MI = 'mi',
  TYPE = 'type',
  TAB = 'tab',
  SubunitId = 'subunitId',
  SensorIds = 'sensorIds',
  StartDate = 'startDate',
  EndDate = 'endDate',
  OnValues = 'onValues',
  AggregationMode = 'aggregationMode',
  FilterAssets = 'filterAssets',
}

export interface Name {
  id: number;
  name: string;
}

export type NameId = {
  id: string;
  name: string;
};

export interface Label {
  id: string;
  label: string;
}

export interface Type {
  id: string;
  type: string;
}

export interface Value {
  id: string;
  value: string;
  displayValue?: string;
}

export interface ValueLabel {
  value: string;
  label: string;
}

export interface WindowSize {
  width: number;
  height: number;
}

export interface FilterSelection {
  [key: string]: string[];
}

export type ChartDefinition = {
  type: ChartType;
  measurements: SensorPropertyQuery[];
};

export interface Asset {
  atexZone?: string;
  criticality?: string;
  description?: string;
  id: string;
  locked: boolean;
  name: string;
  positionNumber: string;
  spaceId: string;
  spaceName?: string;
}

export interface AssetCondition {
  aggregatedConditionScore: number;
  id: string;
}

export interface CustomerObject {
  id: string;
  name: string;
  country?: string;
  customerId?: string;
  technicalContact?: string;
  contractStartDate?: string;
  contractEndDate?: string;
  contractInactivationDate?: string;
  contractPhase?: string;
}

export interface CreateCustomerObject {
  name: string;
  country?: string;
  customerId?: string;
  technicalContact?: string;
  contractStartDate?: string;
  contractEndDate?: string;
  contractInactivationDate?: string;
  contractPhase?: string;
}

export interface UpdateCustomerObject {
  name: string;
  country?: string;
  customerId?: string;
  technicalContact?: string;
  contractStartDate?: string;
  contractEndDate?: string;
  contractInactivationDate?: string;
  contractPhase?: string;
}

export interface LocationDetails {
  address: string;
  businessCategory?: string;
  country: string;
  county?: string;
  customerId: string;
  id: string;
  industry?: string;
  installationType?: string;
  locked: boolean;
  name: string;
  postalCode: string;
  town: string;
}

export interface CustomerLocation {
  id: string;
  locked: boolean;
  customerId: string;
  name: string;
  country: string;
  county: string;
  town: string;
  postalCode: string;
  address: string;
}

export interface Space {
  id: string;
  label: Name;
  locked: boolean;
  name: string;
  parentId: string;
}

export type SpaceUpdateObject = {
  label: number;
  name: string;
  parentId?: string;
};

export type CreateAssetObject = {
  atexZone?: string;
  criticality: string;
  description?: string;
  name: string;
  positionNumber: string;
};

export type UpdateAssetObject = {
  atexZone?: string;
  criticality: string;
  description?: string;
  name: string;
  positionNumber: string;
  spaceId: string;
};

export interface HierarchyNode {
  id: string;
  name: string;
  children: HierarchyNode[];
  depth?: number;
  type: string;
  hasChildren: boolean;
  activePack?: ConfiguredPack;
  alertSeverity?: keyof typeof AlertSeverity;
  atexZone?: string;
  attributes?: Attribute[];
  conditionScore?: number;
  conditionScoreChangedSinceTimestamp?: string;
  criticality?: string;
  description?: string;
  firstLevelChildNode?: boolean;
  hasAlert?: boolean;
  locationId?: string;
  locked?: boolean;
  parentId?: string;
  positionNumber?: string;
  parentName?: string;
  sensors?: any[];
  severity?: Severity;
  severityChangedSinceTimestamp?: string;
  spaceId?: string;
  spaceName?: string;
  specificProperties?: {
    type?: string;
  };
  sequence?: number;
}

export interface ParentTreeNode {
  id: string;
  name: string;
  type: string;
  depth: number;
  parent?: ParentTreeNode;
}

export type CreateResourceFileObject = {
  customerId: string;
  description: string;
  entityType: EntityType;
  file: File;
  locationId?: string;
  reportDate?: string;
  resourceType: ResourceType;
};

export type Resource = {
  creationDate: Date | string;
  customerId: string;
  description: string;
  entityId: string;
  entityType: EntityType;
  id: string;
  locationId: string;
  reportDate: string;
  resourceType: ResourceType;
  specificProperties?: {
    large?: string;
    small?: string;
    isValid?: string;
    validUntil?: Date | string;
    certificateStatus?: CertificateStatus;
  };
  url: string;
  createdBy?: string;
};

export type EnrichedResourceFileObject = {
  assetName?: string;
  assetId?: string;
  creationDate: string;
  customerId: string;
  description: string;
  entityId: string;
  entityType: EntityType;
  id: string;
  locationId: string;
  locationName: string;
  maintainableItemName?: string;
  reportDate: string;
  resourceType: ResourceType;
  specificProperties?: {
    large: string;
    small: string;
  };
  url: string;
  createdBy?: string;
};

export type UpdateResourceObject = {
  description: string;
  reportDate: Date;
};

export type Sort = {
  empty: boolean;
  sorted: boolean;
  unsorted: boolean;
};

export interface Pageable {
  offset: number;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  sort: Sort;
  unpaged: boolean;
}

export interface Page {
  content: any[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: Pageable;
  size: number;
  sort: Sort;
  totalElements: number;
  totalPages: number;
}

export type User = {
  allLocationsAccess: boolean;
  customer: string;
  email: string;
  id: string;
  locationsIds: string[] | null;
  name: string;
  role: string;
  phone: string | null;
  isPhoneVerified: boolean;
  language: string;
  customerId?: string;
  locationNames?: string[];
};

export interface CreatedBy {
  id: string;
  name: string;
  email: string;
  active: boolean;
}

export type UserRole = {
  id: string;
  name: string;
};

export interface Sensor {
  name: string;
  orientationX: OrientationVector;
  orientationY: OrientationVector;
  orientationZ: OrientationVector;
  sensorId: string;
  sensorType: SensorType;
  provider?: ProviderType;
  maintainableItemId?: string;
  deviceId?: string;
  properties?: string[];
}

export interface EnrichedSensor extends Sensor {
  values: ValueTimestamp[];
  color: string;
  hideSensor: boolean;
  threshold?: ThresholdObject;
}

export interface SensorProperties {
  customerId: string;
  locationId: string;
  subUnitId: string;
  sensorId: string;
  maintainableItemId: string;
  properties: Property[];
}

export type MaintainableItemsSensors = {
  [key: string]: Sensor[];
};

export interface SensorScore {
  advice?: string;
  adviceCode?: string;
  conditionScore: number;
  date: string;
  targetDate?: string;
  diagnostic?: string;
  diagnosticCode?: string;
  isOnline?: boolean;
  isPack?: boolean;
  assetId?: string;
  maintainableItemId?: string;
  sensorId: string;
  subUnitId?: string;
}

export interface SensorDataPoint extends Sensor, SensorScore {}

export interface AggregatedConditionScore {
  conditionScore: number;
  originDate?: string;
}

export interface AggregatedSensorAlert {
  id?: string;
  assetId?: string;
  maintainableItemId?: string;
  sensorId?: string;
  severity?: keyof typeof AlertSeverity;
  timestamp?: string;
}

// TODO: This should come from AtomicUI
export interface ParentTreeNode {
  id: string;
  name: string;
  type: string;
  description?: string;
  positionNumber?: string;
  parent?: ParentTreeNode;
  criticality?: string;
  depth: number;
}

// couldn't find this type exported from withParentSize
export declare type WithParentSizeProps = {
  debounceTime?: number;
  enableDebounceLeadingCall?: boolean;
};

export interface SensorProperty {
  axis: string;
  conditionScore?: number;
  frequencyBand: string;
  measurement: string;
  measurementType: string;
  orientation: string;
  propertyGroup: string;
  propertyGroupId: string;
  propertyId: string;
  samplingMode: string;
  threshold: ThresholdObject;
  thresholdPropertyConditionScore?: number;
  unitOfMeasurement: string;
  values: ValueTimestamp[];
  color?: string;
  sensor?: Sensor;
}

export interface ChartMeasurement {
  name: string;
  color: string;
  unitOfMeasurement: string;
  values: ValueTimestamp[];
}

export type TooltipInterface = {
  name: string;
  color: string;
  date: Date;
  items: { value: number; unit: string }[];
} & ValueTimestamp;

export interface SensorPropertyScore {
  conditionScore: number;
  propertyId: string;
}

export interface EnrichedSensorProperty extends SensorProperty {
  sensors: EnrichedSensor[];
  selectedSensorId: string;
}

export interface ValueTimestamp {
  value: number;
  timestamp: string;
}

export interface Coordinate {
  axisX: number;
  axisY: number;
}

export interface CoordinateWithTimestamp extends Coordinate {
  timestamp: string;
}

export interface NameValues {
  name: string;
  values: ValueTimestamp[];
}

export interface Threshold {
  warningValue: number; // H1
  dangerValue: number; // H2
  lowWarningValue?: number; //L1
  lowDangerValue?: number; // L2
}
export interface ThresholdRequestObject {
  warningThreshold: number; // H1
  dangerThreshold: number; // H2
  lowWarningThreshold?: number; //L1
  lowDangerThreshold?: number; // L2
}

export interface ThresholdObject extends Threshold {
  thresholdId: string;
}

export interface SensorChartData {
  type: ChartType;
  sensorQueries: SensorQuery[];
  sensorData?: SensorProperty[];
}

export interface SensorQuery {
  property: SensorPropertyQuery;
  sensorId: string;
}

// Difference in naming of key measureType vs measurementType in API
export type SensorPropertyQuery = {
  measurementType?: string; // can't use the enum because of inconsistent naming in API
  axis?: string;
  measurement: string;
  orientation?: string;
  samplingMode?: string;
  frequencyBand?: string;
};

export interface Property {
  id: string;
  frequencyBand: string;
  measurement: string;
  measureType: string;
  samplingMode: string;
  orientation: string;
  propertyGroup: string;
  name?: string;
}

export interface Measurement extends Name {
  unit: string;
}

export interface Metadata {
  aggregationModes: any[];
  frequencyBands: Label[];
  measurements: Measurement[];
  orientations: Label[];
  measureTypes: Label[];
  samplingModes: Label[];
  propertyGroups: Label[];
  properties: Property[];
  packTypes: Type[];
}

export type SensorOrientation = {
  orientationX: OrientationVector;
  orientationY: OrientationVector;
  orientationZ: OrientationVector;
};

export interface Alert {
  conditionScore: number;
  isActive: boolean;
  endTime: string;
  startTime: string;
  type: AlertType;
  advice?: string;
  adviceCode?: string;
  description?: string;
  diagnostic?: string;
  diagnosticCode?: string;
  id: string;
  graphValues?: SensorProperty;
  name?: string;
  measurementUnit?: string;
  maintainableItemId?: string;
  propertyId?: string;
  packId?: string;
  value?: number;
  sensorId?: string;
  severity: keyof typeof AlertSeverity;
}

export interface ManualSurvey {
  advice?: string;
  diagnostic?: string;
  conditionScore?: number;
  creationTime?: string;
}

export interface SensorDevice {
  deviceId: string;
  deviceName: string;
}

export interface ReplaceSensorObject {
  orientationX: OrientationVector;
  orientationY: OrientationVector;
  orientationZ: OrientationVector;
  replacementDeviceId: string;
  replacementDeviceName: string;
  replacementTimestamp?: string;
}

export type NotificationType = {
  description: string;
  id: number;
  name: string;
  constraints?: Array<Record<string, string>>;
};

export type RetrievedHierarchyNotificationPreference = {
  hierarchyItemId: string;
  id: number;
  locationId?: string;
  notificationId: number;
  userId?: string;
};

export type SurveyResponse = {
  failedEntries: string[];
  importedEntries: string[];
  partiallyImportedEntries: string[];
};

export type OfflinedLocationChangeDataObject = {
  created: number;
  deleted: number;
  updated: number;
};

export type OfflinedLocationChangesObject = {
  assets: OfflinedLocationChangeDataObject;
  maintainableItems: OfflinedLocationChangeDataObject;
};

export type OfflinedLocationInfoObject = {
  changes: OfflinedLocationChangesObject;
  id: string;
  lockedBy: string;
  status: string;
  summary?: string;
};

type AssetBucketObject = {
  count: number;
  items: Asset[];
};

export type AssetSeveritiesObject = {
  critical: AssetBucketObject;
  good: AssetBucketObject;
  unknown: AssetBucketObject;
  warning: AssetBucketObject;
};

export type CreateSubUnitObject = {
  attributes: Value[];
  type: string;
};

export type UpdateSubUnitObject = {
  attributes: Value[];
  assetId?: string;
  type?: string;
};

export type SpaceNodeObject = {
  depthLevel: number;
  label: string;
  name: string;
};

export type SubUnit = {
  assetId: string;
  attributes?: Attribute[];
  criticality?: string;
  id: string;
  locked?: boolean;
  spacePath?: SpaceNodeObject[];
  type: string;
  displayName?: string;
};

export interface MaintainableItemLocation {
  assetId: string;
  locationId: string;
  maintainableItemId: string;
  subUnitId: string;
}

export type Attribute = {
  displayValue: string;
  id: string;
  label: string;
  order: number;
  type: string;
  unitOfMeasure: string;
  value: string;
  visible: boolean;
  attributeGroupName: string;
  attributeGroupSequence: number;
  guestVisibility: boolean;
  enumValues?: {
    id: string;
    label: string;
    order: number;
  }[];
};

export type LocationMaintainableItemObject = {
  assetId: string;
  locationId: string;
  maintainableItemId: string;
  subUnitId: string;
};

export type MaintainableItem = {
  id: string;
  name: string;
  locationId?: string;
  spaceId?: string;
  subUnitId?: string;
  type: string;
  alias?: string;
  attributes?: Attribute[];
  brand?: string;
  locked?: boolean;
  onOffThreshold?: number;
  manufacturerPartNumber?: string;
  qrCode?: string;
  typeLabel?: string;
  webshopUrl?: string;
};

export type CreateMaintainableItemObject = {
  attributes: Value[];
  type: string;
};

export type UpdateMaintainableItemObject = {
  attributes: Value[];
  locationId?: string;
  spaceId?: string;
  assetId?: string;
  subUnitId?: string;
  type?: string;
};

export type SubUnitMetadata = {
  defaultSubUnitType: string;
  subUnitTypes: SubUnitMetadataType[];
  version: number;
};

export type SubUnitMetadataType = {
  attributes: MetadataTypeAttributes[];
  label: string;
  subUnitType: string;
};

export type AttributeGroup = {
  attributeGroupId: string;
  name: string;
  sequence: number;
  showAll: boolean;
  attributes?: Attribute[];
  parentId?: string;
  attributeSubGroupType?: 'rows' | 'columns';
  highlight?: boolean;
};

export type MaintainableItemMetadata = {
  defaultMaintainableItemType: string;
  maintainableItemTypes: MaintainableItemMetadataType[];
  version: number;
  attributeGroups: AttributeGroup[];
};

export type MaintainableItemMetadataType = {
  attributes: MetadataTypeAttributes[];
  label: string;
  maintainableItemType: string;
  maintainableItemTypeId: string;
};

export type MetadataAttributeType =
  | 'string'
  | 'enum'
  | 'date'
  | 'datetime'
  | 'boolean'
  | 'decimal'
  | 'integer'
  | 'hierarchy'
  | 'text'
  | 'image'
  | 'file'
  | 'tag';

export type MetadataTypeAttributes = {
  defaultValue?: string;
  value?: string;
  displayValue?: string;
  enumValues: MetadataAttributeEnumValue[];
  id: string;
  label: string;
  mandatory: boolean;
  readOnly?: boolean;
  maxSize?: number;
  maximum?: number;
  minSize?: number;
  minimum?: number;
  multipleOf?: number;
  order: number;
  pattern?: string;
  type: MetadataAttributeType;
  unitOfMeasure: string;
  visible: boolean;
  listVisible?: boolean;
  attributeGroupName?: string;
  attributeGroupSequence?: number;
  group?: string;
  attributeGroupId: string;
  copyTo?: string;
  attributeId: string;
  isConditional?: boolean;
  groupType?: string;
  groupLabel?: string;
  highlight?: boolean;
};

export type MetadataAttributeEnumValue = {
  id: string;
  label: string;
  order: number;
  dependentAttributeIds?: string[];
};

export interface Pack {
  configuration: PackMainConfiguration;
  properties?: PackProperty;
}

export interface PackMainConfiguration {
  id: string;
  type: string;
  subType: string;
  confOrder: string;
  groups: PackConfigurationGroup[];
}

export interface PackProperty {
  id?: string;
  typeId: string;
  measurements: PackMeasurement[];
  constants: PackConstant[];
}
export interface ConfiguredPack extends PackProperty {
  id: string;
}

export interface PackConfigurationGroup {
  id: string;
  group: string;
  isRequired: boolean;
  priority: number;
  configurations: PackConfiguration[];
}

export interface PackConfiguration {
  id: string;
  type: ConfigurationType;
  parameter: string;
  measurement?: string;
  isRequired?: boolean;
  measurementId?: string;
  sensorId?: string;
  propertyId?: string;
  maintainableItemId?: string;
  constantId?: string;
  value?: number;
  priority: number;
}

export interface PackMeasurement {
  id?: string;
  typeId: string;
  sensorId?: string;
  propertyId?: string;
  maintainableItemId?: string;
}
export interface PackConstant {
  id?: string;
  typeId: string;
  value?: number;
}

export interface Language {
  id: string;
  displayName: string;
  code: string;
  flagIconPath: string;
}

export interface TaskReq {
  assetId?: string;
  assignee?: string;
  closeDate?: string;
  comment?: string;
  createdBy?: string;
  createdDate?: string;
  description?: string;
  id?: string;
  isActive?: boolean;
  locationId?: string;
  maintainableItemId?: string;
  notifyMe?: boolean;
  relatedAlertId?: string;
  startDate?: string;
  status?: string;
  subUnitId?: string;
  taskFieldValues?: TaskFieldValue[];
  taskTypeId?: string;
  title?: string;
}

export interface TaskItem {
  id: string;
  name: string;
  type: EntityType;
}
export enum TaskTypes {
  WORK_ORDER = 'WORK_ORDER',
  INSPECTION = 'INSPECTION',
  CERTIFICATES = 'CERTIFICATES',
  CONDITION_ALERTS = 'CONDITION_ALERTS',
  THRESHOLD_ALERTS = 'THRESHOLD_ALERTS',
}

export type TaskTypeFieldEnum = {
  id: string;
  name: string;
  key: string;
  sequence: number;
  conditionalTaskTypeFieldList: string[];
};

export interface TaskFieldValue {
  fieldName: string;
  taskTypeFieldId: string;
  value: string;
}

export type TaskTypeField = {
  id: string;
  fieldName: string;
  fieldLabel: string;
  fieldType: FieldType;
  unitOfMeasurement?: string;
  isMandatory: boolean;
  sequence: number;
  creationDate: string;
  taskTypeFieldEnums: TaskTypeFieldEnum[];
  isConditional: boolean;
  value?: string;
};

export type TaskTypeMetadata = {
  id: string;
  mainType: TaskTypes;
  sequence: number;
  subType?: string;
  taskTypeFields?: TaskTypeField[];
};

export interface Task {
  alertMeasurementUnit?: string;
  alertSeverity?: keyof typeof AlertSeverity;
  alertValue?: number;
  assetDescription?: string;
  assetId?: string;
  assetName?: string;
  assignee?: { id: string; name: string; active: boolean };
  conditionScore?: number;
  createdBy: CreatedBy;
  createdDate: string;
  closeDate?: string;
  comment?: string;
  customerId: string;
  description: string;
  id: string;
  isActive: boolean;
  locationId: string;
  maintainableItemId?: string;
  maintainableItemName?: string;
  maintainableItemType?: string;
  notifyMe: boolean;
  positionNumber: string;
  relatedAlertId?: string;
  status: TaskStatus;
  subUnitId?: string;
  subUnitName?: string;
  subUnitType?: string;
  taskFieldValues: TaskFieldValue[];
  taskType: TaskTypeMetadata;
  title: string;
}

export interface Worklog {
  id: string;
  taskId: string;
  eventType: EventType;
  status?: TaskStatus;
  createdBy: { id: string; name?: string; active?: boolean };
  assignee?: { id: string; name?: string; active?: boolean };
  comment?: string;
  createdDate: string;
}

export interface Tag {
  uuid: string;
  createdAt: string;
  customerId: string;
  externalUrl: string;
  isActive: boolean;
  linkedAt?: string;
  maintainableItemId?: string;
}

export interface LegendItem {
  name: string;
  status?: number;
  value?: number;
  color?: string;
  sensor?: Sensor;
  propertyId?: string;
  threshold?: ThresholdObject;
  conditionScore?: number;
}

export interface LegendConfiguration {
  title?: string;
  showThresholds: boolean;
  canManageThresholds: boolean;
  selectedSensors?: string[];
  focusedSensor?: string;
  setFocusedSensor?: (sensorId: string) => void;
  onChecked?: (checked: boolean, sensorId: string) => void;
  items: LegendItem[];
  header: {
    width: number;
    content?: string | ReactElement;
    position?: string;
  }[];
  otherColumns: {
    width: number;
    content: Function;
    position?: string;
  }[];
  thresholds?: {
    key: keyof Threshold;
    name: string | ReactElement;
    zone: ThresholdZone;
  }[];
}

export type WebsocketSubscriptionMessage = {
  sensorId: string;
  properties: string[];
};

export interface WebsocketMessage {
  type: 'VALUE' | 'ERROR';
  data: WebsocketSuccessMessage | WebsocketErrorMessage[];
}

export interface WebsocketErrorMessage {
  sensorId: string;
  message: string;
}

export interface WebsocketSuccessMessage {
  propertyId: string;
  sensorId: string;
  timestamp: string;
  value: number;
}

export type EquipmentType = {
  miTypeId: string;
  equipmentSubType: string;
  equipmentSubTypeLabel: string;
  miTypeCount: number;
};

export type PageInfo = {
  page?: number;
  pageSize?: number;
  pageCount?: number;
  totalRecords?: number;
};

export enum TemplateType {
  EQUIPMENT = 'EQUIPMENT',
  INSPECTION = 'INSPECTION',
  EQUIPMENT_INSPECTION = 'EQUIPMENT_INSPECTION',
  EQUIPMENT_HISTORY = 'EQUIPMENT_HISTORY',
}

export enum CertificateStatus {
  VALID = 'VALID',
  ALMOST_EXPIRED = 'ALMOST_EXPIRED',
  FAILED = 'FAILED',
  EXPIRED = 'EXPIRED',
}

export type Conditional = {
  dependentAttributeIds: string[];
  pending: boolean;
};

export interface ExportEquipment {
  locationId: string;
  customerId: string;
  equipmentTypeId: string;
  equipmentExportIds: string[];
}

export interface ExportHistory {
  locationId: string;
  customerId: string;
  templateType: TemplateType;
  maintainableItemIds: string[];
}

export interface ExportResponse {
  data: BlobPart;
  headers: {
    'content-disposition': string;
  };
}

export type EquipmentTag = {
  createdAt: string;
  customerId: string;
  isActive: boolean;
  keyword: string;
  linkedAt?: string;
  maintainableItemId?: string;
  uuid: string;
  isLinked: boolean;
};

export type TagSearchPayload = {
  customerId?: string;
  searchTerm: string;
  filter?: {
    createdAt?: string[];
    isLinked?: boolean;
    linkedAt?: string[];
  };
};

export interface Paged<T> extends Page {
  content: T[];
}

export interface LinkPath {
  pathname: string;
  state?: PreviousPath;
}

export interface PreviousPath {
  prevPath: string;
  prevName?: string;
  prevSearch?: string;
}

export type Translated<T> = T & {
  translation: string;
};

export type FieldType =
  | 'string'
  | 'decimal'
  | 'link'
  | 'date'
  | 'datetime'
  | 'enum'
  | 'hierarchy'
  | 'integer'
  | 'decimal';

export interface SearchField {
  fieldName: string;
  fieldType: FieldType;
  searchTerm: string[];
}
