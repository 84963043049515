import styled from 'styled-components/macro';
import { TextColor } from 'types';
import { getTextColor } from 'utils/getColor';

type SizeType =
  | 'ft10'
  | 'ft12'
  | 'ft14'
  | 'ft16'
  | 'ft20'
  | 'ft24'
  | 'ft32'
  | 'ft40'
  | 'ft72';

interface TextProps {
  fontSize?: SizeType;
  fontWeight?: 'normal' | 'medium';
  lh?: SizeType;
  text?: 'capitalize' | 'uppercase' | 'lowercase';
  color?: TextColor;
  cursor?: 'pointer';
}

export const Text = styled.span<TextProps>`
  font-weight: ${({ fontWeight = 'normal', theme }) =>
    theme.fonts.fontWeight[fontWeight]};
  font-size: ${({ fontSize = 'ft12', theme }) =>
    theme.fonts.fontSize[fontSize]};
  line-height: ${({ lh, theme }) => lh || theme.fonts.lineHeight.text};
  color: ${({ color, theme }) => getTextColor(theme, color)};
  text-transform: ${({ text = 'none' }) => text};
  cursor: ${({ cursor = 'inherit' }) => cursor};
`;
