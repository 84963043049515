/**
 *
 * ErrorBoundary
 *
 */
import React from 'react';
import { BoundaryDisplay } from './BoundaryDisplay';

type EBState = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<{}, EBState> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // TODO: You can also log the error to an error reporting service
    console.error(error.message);
  }

  render() {
    if (this.state.hasError) {
      return <BoundaryDisplay />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
