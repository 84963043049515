import styled from 'styled-components/macro';

export default styled.textarea`
  border: none;
  height: 64px;
  width: 100%;
  resize: none;
  background-color: ${({ theme }) => theme.colors.default.gray1};
  font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
  color: ${({ theme }) => theme.colors.default.gray3};
  padding: ${({ theme }) => theme.spacing.sp8};
  outline: none;
`;
