import { ReactElement } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { isWithinInterval } from 'date-fns';

import t from 'locales/translation';
import { ThresholdObject } from 'types';

export function combineValidations(value, validationsList) {
  let errorMessage;

  validationsList.some(element => {
    if (element && element.validation(value, element)) {
      errorMessage = element.errorMessage;
      return true;
    }
    return false;
  });
  return errorMessage;
}

export function notUndefined(value, { errorMessage }) {
  if (value === null || value === undefined) return errorMessage;
  return undefined;
}

export function notEmpty(value, { errorMessage }) {
  if (value === null || value === undefined || value === '')
    return errorMessage;
  return undefined;
}

export function beOneOf(value, { errorMessage, matchingValues }) {
  if (matchingValues.includes(value)) return undefined;
  return errorMessage;
}

export function minLength(value, { errorMessage, length }) {
  if (value.length < length) return errorMessage;
  return undefined;
}

export function shouldBeTrue(value, { errorMessage }) {
  if (!value) return errorMessage;
  return undefined;
}

export function validateEmail(value, { errorMessage }) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
  if (re.test(String(value))) return undefined;
  return errorMessage;
}

export function validateName(value, { errorMessage }) {
  const reg = /^[A-Za-zÀ-ÖØ-öø-ÿ ,.'-]+$/;
  if (reg.test(value)) return undefined;
  return errorMessage;
}

export function validatePhone(value, { errorMessage }) {
  if (typeof value === 'string') {
    if (isValidPhoneNumber(value)) return undefined;

    return errorMessage;
  } else {
    return undefined;
  }
}

export function validateCompanyName(value, { errorMessage }) {
  const reg = /^[0-9A-Za-zÀ-ÖØ-öø-ÿ ,.'-]+$/;
  if (reg.test(value)) return undefined;
  return errorMessage;
}

export function shouldBeValidConditionScore(value: number, { errorMessage }) {
  // Between 0 and 10, no decimals
  if (value < 0 || value > 10 || value % 1 !== 0) return errorMessage;
  return undefined;
}

export const validateAnnouncement = ():
  | {
      key: string;
      startDate: string;
      endDate: string;
    }
  | undefined => {
  const key = process.env.REACT_APP_ANNOUNCEMENT_KEY;
  const startDate = process.env.REACT_APP_ANNOUNCEMENT_START;
  const endDate = process.env.REACT_APP_ANNOUNCEMENT_END;

  if (!startDate || !endDate || !key) {
    return;
  }

  if (
    !isWithinInterval(new Date(), {
      start: new Date(startDate),
      end: new Date(endDate),
    })
  ) {
    return;
  }

  return { key, startDate, endDate };
};

const getNumber = (value?: string | number): number | undefined => {
  if (value == null) return;
  if (typeof value === 'string') return parseFloat(value);

  return value;
};

export function validateThreshold(
  values: ThresholdObject,
  allowNegative = false,
) {
  const errors: {
    warningValue?: string | ReactElement;
    dangerValue?: string | ReactElement;
    lowWarningValue?: string | ReactElement;
    lowDangerValue?: string | ReactElement;
  } = {};

  const warning = getNumber(values.warningValue);
  const danger = getNumber(values.dangerValue);
  const lowWarning = getNumber(values.lowWarningValue);
  const lowDanger = getNumber(values.lowDangerValue);

  // check if empty threshold, allow zero value
  if (warning == null) {
    errors.warningValue = t('ErrorMessage.FieldValidation.NotEmpty');
  }

  if (danger == null) {
    errors.dangerValue = t('ErrorMessage.FieldValidation.NotEmpty');
  }

  if (lowWarning && lowDanger == null) {
    errors.lowDangerValue = t('ErrorMessage.FieldValidation.NotEmpty');
  }

  if (lowDanger && lowWarning == null) {
    errors.lowWarningValue = t('ErrorMessage.FieldValidation.NotEmpty');
  }

  // check if lowDanger is greater then lowWarning
  if (lowDanger && lowWarning) {
    if (lowDanger === lowWarning || lowDanger > lowWarning)
      errors.lowWarningValue = t(
        'ErrorMessage.FieldValidation.ThresholdLowWarningBelowLowDanger',
      );
  }

  // check if lowWarning is greater then warning
  if (lowWarning && warning) {
    if (lowWarning === warning || lowWarning > warning)
      errors.warningValue = t(
        'ErrorMessage.FieldValidation.ThresholdWarningBelowLowWarning',
      );
  }

  // check if warning is greater then danger
  if (warning && danger) {
    if (warning === danger || warning > danger)
      errors.dangerValue = t(
        'ErrorMessage.FieldValidation.ThresholdDangerBelowWarning',
      );
  }

  if (!allowNegative) {
    // if it doesn't allow negative value, check if threshold < 0
    if (warning && warning < 0) {
      errors.warningValue = t('ErrorMessage.FieldValidation.InvalidValue');
    }

    if (danger && danger < 0) {
      errors.dangerValue = t('ErrorMessage.FieldValidation.InvalidValue');
    }

    if (lowWarning && lowWarning < 0) {
      errors.lowWarningValue = t('ErrorMessage.FieldValidation.InvalidValue');
    }

    if (lowDanger && lowDanger < 0) {
      errors.lowDangerValue = t('ErrorMessage.FieldValidation.InvalidValue');
    }
  }

  return errors;
}

export function validateMonthDuration(value: number, { errorMessage }) {
  // Between 0 and 99, no decimals
  if (value < 0 || value > 99 || value % 1 !== 0) return errorMessage;
  return undefined;
}
