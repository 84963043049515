/**
 *
 * UsernameDisplayMenu
 *
 */
import React, { ReactElement } from 'react';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { Avatar } from '@eriksdigital/atomic-ui/components';

import t from 'locales/translation';
import { useAuth, useUserDetails } from 'app/providers/AuthProvider';
import { DropdownSelector } from 'app/components/DropdownSelector';

type menuOption = {
  id: string;
  contents: ReactElement;
};

interface Props {
  onSelect?: Function;
  loading?: boolean;
}
export function UsernameDisplayMenu({
  onSelect = () => null,
  loading = false,
}: Props) {
  const { logout } = useAuth();
  const history = useHistory();
  const name = useUserDetails()?.name || 'ERIKS User';

  const Username = (
    <Display>
      <Avatar name={name} size={32} showName />
    </Display>
  );

  const menuOptions: menuOption[] = [
    {
      id: 'account-settings',
      contents: (
        <CustomerOption onClick={() => history.push('/admin')}>
          {t('PersonalSettings')}
        </CustomerOption>
      ),
    },
    {
      id: 'notification-management',
      contents: (
        <CustomerOption onClick={() => history.push('/admin')}>
          {t('User.NotificationPreferences')}
        </CustomerOption>
      ),
    },
    {
      id: 'logout-button',
      contents: <CustomerOption onClick={logout}>{t('Logout')}</CustomerOption>,
    },
  ];

  return (
    <Wrapper>
      <DropdownSelector
        id="user-menu"
        selectedItemDisplay={Username}
        listItems={menuOptions}
        onSelect={onSelect}
        loading={loading}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: auto;
  display: flex;
`;

const Display = styled.div`
  display: flex;

  // Overriding atomic-ui Avatar styling
  span {
    font-weight: 600;
    font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
    color: inherit;
  }

  // TODO Consider this variant on atomic-ui 20
  [shape='circle'] {
    background-color: currentColor;
    border-color: currentColor;

    span {
      color: ${({ theme }) => theme.colors.text.white};
    }
  }
`;

const CustomerOption = styled.div`
  padding: ${({ theme }) => `${theme.spacing.sp12} 0`};
  font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
  color: ${({ theme }) => theme.colors.default.blue1};

  &:hover {
    text-decoration: underline;
  }
`;
