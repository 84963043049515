import React from 'react';
import { Icon, Button } from '@eriksdigital/atomic-ui/components';
import { DownloadIcon } from '@eriksdigital/atomic-ui/components/Icons';

import { translationString } from 'locales/translation';
import { EnrichedResourceFileObject, Resource } from 'types';

interface Props {
  resource: EnrichedResourceFileObject | Resource;
}

export const DownloadResourceButton = ({ resource }: Props) => {
  const downloadInNewWindow = function (url: string): void {
    window.open(url, '_blank');
  };
  return (
    <Button
      id={`${resource.id}_download_button`}
      alt={`${resource.description} link`}
      onClick={() => downloadInNewWindow(resource.url)}
      size="regular"
      stretch="auto"
      variant="square"
      weight="medium"
      type="button"
      data-stringid="DownloadFile"
      aria-label={translationString('DownloadFile')}
    >
      <Icon as={DownloadIcon} size="sz32" />
    </Button>
  );
};
