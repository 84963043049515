import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  REMEMBER_LOCATION_KEY,
  REMEMBER_CUSTOMER_KEY,
  REMEMBER_REQUEST_PATH_KEY,
} from '../constants';

export default () => {
  let { customerId, locationId } = useParams() as {
    customerId: string;
    locationId: string;
  };

  useEffect(() => {
    customerId && localStorage.setItem(REMEMBER_CUSTOMER_KEY, customerId);
    locationId && localStorage.setItem(REMEMBER_LOCATION_KEY, locationId);
    sessionStorage.removeItem(REMEMBER_REQUEST_PATH_KEY);
  }, [customerId, locationId]);
};
