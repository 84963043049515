import React from 'react';

import { translationString } from 'locales/translation';
import { formatDate } from 'locales/date-format-i18n';
import {
  Attribute,
  MaintainableItem,
  MaintainableItemMetadata,
  MetadataTypeAttributes,
  FieldType,
} from 'types';
import {
  DataTableColumn,
  DataTableColumnPreference,
  DataTableRow,
} from 'app/components/DataTable/types';
import EquipmentLink from './components/EquipmentLink';
import { getPreferencesLocalStorage } from 'app/components/DataTable/utils';
import { EquipmentFilters } from './types';
import { hydrateEquipmentEnumLabels } from '../EquipmentDetail/utils';
import EquipmentLinkOut from './components/EquipmentLinkOut';

export const mapAttributeToDataTableColumn = (
  attr: MetadataTypeAttributes,
): DataTableColumn => ({
  order: attr.order,
  visible: attr.listVisible || false,
  type: attr.type as FieldType,
  id: attr.id,
  label: translationString(`Attributes.${attr.id}`),
  enumValues: attr.enumValues,
});

export const mapEquipmentToDataTableRow = (
  equipment: MaintainableItem,
): DataTableRow => ({
  id: equipment.id,
  values: equipment.attributes?.map(mapAttributeToDataTableValue(equipment)),
});

const mapAttributeToDataTableValue = (equipment: MaintainableItem) => (
  attr: Attribute,
) => {
  if (attr.id === 'name') {
    return {
      id: attr.id,
      value: <EquipmentLink miId={equipment.id} label={equipment.name} />,
    };
  }

  if (isEquipmentLinkOut(attr)) {
    return {
      id: attr.id,
      value: attr.value ? (
        <EquipmentLinkOut miId={attr.value} label={attr.displayValue} />
      ) : undefined,
    };
  }

  if (attr.type === 'date' || attr.type === 'datetime') {
    return {
      id: attr.id,
      value: formatDate(attr.value),
    };
  }

  return {
    id: attr.id,
    value: attr.displayValue,
  };
};

export const filterOutAttachmentAttributes = (attr: MetadataTypeAttributes) =>
  !['file', 'image'].includes(attr.type);

export function reduceEnumLabels(metadata: MaintainableItemMetadata) {
  return metadata?.maintainableItemTypes
    .flatMap(miType =>
      miType.attributes.flatMap(attr =>
        attr.enumValues.map(enumValue => ({
          miType: miType.maintainableItemType,
          attributeId: attr.id,
          enumId: enumValue.id,
          enumLabel: enumValue.label,
        })),
      ),
    )
    .reduce(
      (acc, { miType, attributeId, enumId, enumLabel }) => ({
        ...acc,
        [`${miType}_${attributeId}_${enumId}`]: enumLabel,
      }),
      {},
    );
}

export function hydrateEquipments(
  equipments: MaintainableItem[],
  enumLabels: { [key: string]: string },
) {
  return equipments?.map(hydrateEquipmentEnumLabels(enumLabels));
}

export const getVisibleFilters = (
  filters: EquipmentFilters,
  miTypeId: string,
) => {
  const pref = getPreferencesLocalStorage(miTypeId);
  const hiddenColumns: DataTableColumnPreference[] = pref.filter(f => {
    return !f.visible;
  });
  const hiddenColumnIds: Array<string> = hiddenColumns.map(h => h.columnId);
  const updatedFilters = { ...filters };
  hiddenColumnIds.forEach(e => delete updatedFilters[e]);
  return updatedFilters;
};

export const isEquipmentLinkOut = ({ id }: { id: string }) =>
  ['replacedBy', 'replacementFor'].includes(id);

export const parseSearchParams = (queryString: string): EquipmentFilters => {
  const filters: EquipmentFilters = {};
  const params = new URLSearchParams(queryString);

  params.forEach((value, key) => {
    if (!value) return;

    if (filters[key]) {
      filters[key].values.push(value);
    } else {
      filters[key] = {
        type: 'enum',
        values: [value],
      };
    }
  });

  return filters;
};
