/**
 *
 * DropdownSelector
 *
 */
import React, {
  ReactElement,
  ReactNode,
  useState,
  useContext,
  useRef,
  useMemo,
} from 'react';
import styled, { ThemeContext, keyframes } from 'styled-components/macro';
import { Button, Icon } from '@eriksdigital/atomic-ui/components';
import {
  DownArrowIcon,
  LoadingIcon,
} from '@eriksdigital/atomic-ui/components/Icons';
import { translationString } from 'locales/translation';
import { pxToRem } from '../../../utils/style-utils';
import { DropdownListItem } from './types';
import { filterListItems } from './utils';
import { useClickOutside } from 'utils/hooks/useClickOutside';

type DropdownPosition = 'left' | 'right';

interface Props {
  id?: string;
  selectedItemDisplay: ReactElement;
  listItems?: DropdownListItem[];
  onSelect?: Function;
  loading?: boolean;
  dropdownWidth?: number | undefined;
  fixedButtonLabel?: string | ReactElement;
  fixedButtonIcon?: ReactNode;
  onFixedButtonClick?: Function;
  searchable?: boolean;
  showCarret?: boolean;
  position?: DropdownPosition;
  closeOnSelect?: boolean;
  onClose?: () => void;
  itemsWrapper?: (children: ReactNode) => ReactNode;
  disabled?: boolean;
}

export function DropdownSelector(props: Props) {
  const themeContext = useContext(ThemeContext);
  const wrapperEl = useRef<HTMLInputElement>(null);

  const {
    id = '',
    selectedItemDisplay,
    listItems,
    onSelect,
    loading = false,
    dropdownWidth,
    onFixedButtonClick,
    fixedButtonLabel,
    fixedButtonIcon,
    searchable = false,
    showCarret = true,
    position = 'left',
    closeOnSelect = true,
    onClose,
    itemsWrapper,
    disabled = false,
  } = props;

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string | undefined>();

  const filteredListItems = useMemo(() => {
    if (listItems) {
      return searchTerm ? filterListItems(listItems, searchTerm) : listItems;
    }
  }, [searchTerm, listItems]);

  useClickOutside(wrapperEl, () => {
    setIsDropdownOpen(false);
    onClose?.();
  });

  const renderItems = () => {
    return (
      filteredListItems &&
      filteredListItems.map(item => (
        <DropdownItem
          key={item.id}
          data-dropdown-item
          onClick={() => {
            closeOnSelect && setIsDropdownOpen(!isDropdownOpen); // Todo: handle onClose
            onSelect?.(item.id);
          }}
          role="button"
          data-testid={`${id}-dropdown-option-${item.id}`}
        >
          {item.contents}
        </DropdownItem>
      ))
    );
  };

  return (
    <SelectorWrapper ref={wrapperEl} data-testid={`${id}-dropdown`}>
      {loading && (
        <LoadingWrapper data-testid={`${id}-dropdown-loading`}>
          <Icon
            as={LoadingIcon}
            size="sz32"
            color={themeContext.colors.text.secondary}
          />
        </LoadingWrapper>
      )}
      {!loading && (
        <DropdownButton
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          data-testid={`${id}-dropdown-btn-foldout`}
          disabled={disabled}
        >
          {selectedItemDisplay}
          {showCarret && <Icon as={DownArrowIcon} />}
        </DropdownButton>
      )}
      {isDropdownOpen && (
        <DropdownFoldout
          dropdownWidth={dropdownWidth}
          position={position}
          aria-expanded="true"
        >
          {searchable && (
            <InputSearch
              type="text"
              placeholder={`${translationString('Search')}...`}
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
            />
          )}
          <DropdownFoldoutInner>
            {itemsWrapper ? itemsWrapper(renderItems()) : renderItems()}
          </DropdownFoldoutInner>
          {onFixedButtonClick && fixedButtonLabel && (
            <DropDownListButton
              id="dropdownFixedButton"
              onClick={onFixedButtonClick}
              variant="ghost"
            >
              {fixedButtonIcon}
              {fixedButtonLabel}
            </DropDownListButton>
          )}
        </DropdownFoldout>
      )}
    </SelectorWrapper>
  );
}

const animation = keyframes`
  from {
    opacity: 0;
    max-height: 0;
  }
  to {
    opacity: 1;
    max-height: 400px;
  }
`;

const SelectorWrapper = styled.div<{ ref: any }>`
  position: relative;
  flex: 1 1 auto;
  width: auto;
`;

const DropdownButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.sp8};
  border: 0;
  background: 0;
  border-radius: 0;
  padding: 0;
  position: relative;
  width: 100%;
  height: auto;
  color: ${({ theme }) => theme.colors.default.blue1};
  transition: all 300ms;

  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.default.blue2};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.default.gray2};
  }

  svg {
    width: ${({ theme }) => theme.sizes.sz20};
    min-width: ${({ theme }) => theme.sizes.sz20};
    height: ${({ theme }) => theme.sizes.sz20};
    min-height: ${({ theme }) => theme.sizes.sz20};
  }
`;

const DropDownListButton = styled(Button)`
  line-height: ${({ theme }) => theme.sizes.sz40};
  margin-right: ${({ theme }) => theme.spacing.sp8};
  justify-content: flex-start;
  font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
  // !important here is necessary to override atomic-ui styling
  border-top: 1px solid ${({ theme }) => theme.colors.default.gray1} !important;
  color: ${({ theme }) => theme.colors.default.blue1} !important;
  background-color: ${({ theme }) => theme.colors.default.white} !important;
  text-decoration: none !important;
`;

const DropdownFoldout = styled.div<{
  dropdownWidth: number | undefined;
  position: DropdownPosition;
}>`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: calc(100% + 10px);
  ${({ dropdownWidth }) =>
    dropdownWidth && `min-width: ${pxToRem(dropdownWidth)}`};
  background-color: ${({ theme }) => theme.colors.background.white};
  left: ${({ position }) => (position === 'left' ? '0' : null)};
  right: ${({ position }) => (position === 'right' ? '0' : null)};
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  z-index: ${({ theme }) => theme.stacking.dropdown};
  max-height: 400px;
  min-width: 100%;
  width: max-content;
  max-width: 450px;
  padding: ${({ theme }) =>
    `${theme.spacing.sp8} ${theme.spacing.sp4} ${theme.spacing.sp8} ${theme.spacing.sp12}`};
  animation: ${animation} 400ms forwards;
`;

const DropdownFoldoutInner = styled.div`
  overflow-y: scroll;

  &::-webkit-scrollbar {
    appearance: none;
    width: ${({ theme }) => theme.sizes.sz8};
    height: ${({ theme }) => theme.sizes.sz8};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.default.gray2};
    border-radius: ${({ theme }) => theme.sizes.sz8};
  }
`;

const DropdownItem = styled.div`
  display: block;
  width: 100%;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.default.gray1};
  }

  &:hover {
    cursor: pointer;
  }
`;

const LoadingWrapper = styled.div`
  flex: 1 1 100%;
  padding: ${({ theme }) => theme.spacing.sp24};
  text-align: center;
`;

const InputSearch = styled.input`
  border-radius: 5px 5px 0 0;
  background-color: ${({ theme }) => theme.colors.default.gray1};
  min-height: ${({ theme }) => theme.sizes.sz40};
  width: auto;
  border: none;
  padding: ${({ theme }) => `0 ${theme.spacing.sp12}`};
  margin: ${({ theme }) => `0 ${theme.spacing.sp8} ${theme.spacing.sp8} 0`};
  outline: none;
  color: ${({ theme }) => theme.colors.text.darkGray};

  &::placeholder {
    color: ${({ theme }) => theme.colors.default.gray2};
  }
`;
