import {
  takeLatest,
  put,
  select,
  delay,
  race,
  call,
  take,
} from 'redux-saga/effects';
import { Action, PayloadAction } from '@reduxjs/toolkit';

import {
  getUserNotifications as getUserNotificationsApi,
  postMarkNotificationsRead as markAllNotificationsReadAPI,
} from 'services/messagingService';
import { parseAxiosError } from 'utils/error-helpers';
import { translationString } from 'locales/translation';

import apiCall from 'services/utils/apiCall';
import {
  selectCurrentNotifications,
  selectUnreadMessageIds,
} from './selectors';
import { actions } from './slice';
import {
  Notification,
  NotificationPanelItem,
  NotificationsPage,
} from './types';

import { mapNotificationsToPanelItems } from './utils';

const CANCEL_NOTIFICATIONS_POLLING = 'CancelNotificationsPolling';
const POLLING_DELAY = 60000; // Poll new notifications every 60 seconds

export function* watchNotifications(customerId: string) {
  yield race({
    task: call(pollNotifications, customerId),
    cancel: take(CANCEL_NOTIFICATIONS_POLLING),
  });
}

export function* pollNotifications(customerId: string) {
  while (true) {
    try {
      const {
        data: notifications,
      }: { data: NotificationsPage } = yield apiCall(
        getUserNotificationsApi,
        customerId,
      );

      const currentNotifications: NotificationPanelItem[] = yield select(
        selectCurrentNotifications,
      );

      /* We only process notifications the first time they are seen -
       * the parent tree is fetched and action routes are calculated and saved
       * with the notification state
       */
      const newNotifications: Notification[] = notifications.content.reduce(
        (newItems, currentItem) => {
          const existingNotification = currentNotifications.find(
            n => n.id === currentItem.id,
          );
          return existingNotification
            ? [...newItems]
            : [...newItems, currentItem];
        },
        [] as Notification[],
      );

      // Map the notifications to panel items with parsed date and calculated action route
      const panelItems: NotificationPanelItem[] = mapNotificationsToPanelItems(
        newNotifications,
      );

      yield put(
        actions.getNotificationsSuccess([
          ...currentNotifications,
          ...panelItems,
        ]),
      );
      yield delay(POLLING_DELAY);
    } catch (error) {
      yield put({ type: CANCEL_NOTIFICATIONS_POLLING });
      const effectError = parseAxiosError(
        error,
        translationString('ErrorMessage.FetchEntity.Notifications'),
      );
      yield put(actions.notificationsOperationFailure(effectError));
    }
  }
}

export function* getNotifications(action: PayloadAction<string>) {
  yield call(watchNotifications, action.payload);
}

export function* markNotificationRead(
  action: PayloadAction<{ noteId: string; closePanel: boolean }>,
) {
  try {
    yield apiCall(markAllNotificationsReadAPI, [action.payload.noteId]);
    yield put(actions.markNotificationsReadComplete([action.payload.noteId]));
  } catch (error) {
    const effectError = parseAxiosError(
      error,
      translationString('ErrorMessage.MarkNotificationRead'),
    );
    yield put(actions.notificationsOperationFailure(effectError));
  }
}

export function* markAllNotificationsRead(action: Action) {
  try {
    const allUnreadNotificationIds = yield select(selectUnreadMessageIds);
    yield apiCall(markAllNotificationsReadAPI, allUnreadNotificationIds);
    yield put(actions.markNotificationsReadComplete(allUnreadNotificationIds));
  } catch (error) {
    const effectError = parseAxiosError(
      error,
      translationString('ErrorMessage.MarkNotificationRead'),
    );
    yield put(actions.notificationsOperationFailure(effectError));
  }
}

export function* notificationsSaga() {
  yield takeLatest(actions.getNotifications.type, getNotifications);
  yield takeLatest(actions.markNotificationRead.type, markNotificationRead);
  yield takeLatest(
    actions.markAllNotificationsRead.type,
    markAllNotificationsRead,
  );
}
