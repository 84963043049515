/**
 *
 * Asynchronously loads the component for EquipmentForm
 *
 */

import { lazyLoad } from 'utils/loadable';

export const EquipmentFormPage = lazyLoad(
  () => import('./index'),
  module => module.EquipmentForm,
);
