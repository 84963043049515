import * as msal from '@azure/msal-browser';
const {
  REACT_APP_AZURE_B2C_CLIENT_ID = '',
  REACT_APP_AZURE_B2C_AUTHORITY = '',
  REACT_APP_AZURE_B2C_AUTH_POLICY = '',
  REACT_APP_AZURE_B2C_CUSTOM_SCOPES = '',
} = process.env;

const customScopes = REACT_APP_AZURE_B2C_CUSTOM_SCOPES.split(',').map(str =>
  str.trim(),
);

export const scopes = ['openid', 'offline_access', ...customScopes];
const FULL_AUTHORITY_URL = `${REACT_APP_AZURE_B2C_AUTHORITY}/${REACT_APP_AZURE_B2C_AUTH_POLICY}`;

const msalConfig = {
  auth: {
    clientId: REACT_APP_AZURE_B2C_CLIENT_ID,
    authority: FULL_AUTHORITY_URL,
    knownAuthorities: [FULL_AUTHORITY_URL],
    redirectUri: document.location.origin,
  },
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

export default msalInstance;
