import { ReactNode } from 'react';
import {
  Asset,
  Alert,
  AlertType,
  Task,
  TaskItem,
  TaskTypeMetadata,
  TaskFieldValue,
  TaskTypeField,
  TaskTypes,
  ValueLabel,
  EntityType,
} from 'types';
import { getConditionOrStatus } from 'utils/getConditionOrStatus';
import { formatDateTime, formatDate } from 'locales/date-format-i18n';
import { getValue } from 'utils/getDiagnosticAdviceValue';

export const getTaskItem = (task: Task): TaskItem => {
  const {
    maintainableItemId,
    maintainableItemName,
    subUnitId,
    subUnitName,
  } = task;

  if (maintainableItemId) {
    return {
      id: maintainableItemId,
      name: maintainableItemName || '',
      type: EntityType.MAINTAINABLE_ITEM,
    };
  }

  return {
    id: subUnitId || '',
    name: subUnitName || '',
    type: EntityType.SUB_UNIT,
  };
};

export const generateGeneralInfo = (
  taskItem: TaskItem,
  asset?: Asset,
): ValueLabel[] => [
  { label: 'TableColumnHeaders.AssetName', value: asset?.name || '' },
  { label: 'PositionNumber', value: asset?.positionNumber || '' },
  { label: taskItem.type, value: taskItem.name },
];

export const generateAlertInfo = (
  alert: Alert,
): { value: ReactNode; label: string }[] => {
  const {
    name,
    type,
    startTime,
    diagnostic,
    diagnosticCode,
    advice,
    adviceCode,
  } = alert;

  return [
    { label: 'Sensor', value: name || '' },
    {
      label: type === AlertType.STATUS ? 'Status' : 'ConditionScore',
      value: getConditionOrStatus(alert),
    },
    { label: 'TriggeredOn', value: formatDateTime(startTime) },
    {
      label: 'Diagnostic',
      value: getValue('Diagnostic', diagnostic, diagnosticCode),
    },
    {
      label: 'Advice',
      value: getValue('Advice', advice, adviceCode),
    },
  ];
};

export const generateTaskInfo = (
  task: Task,
  metadata: TaskTypeMetadata[],
): ValueLabel[] => {
  const {
    taskType,
    createdDate,
    closeDate,
    description,
    taskFieldValues,
  } = task;

  if (!taskType?.mainType || taskType.mainType === TaskTypes.WORK_ORDER) {
    return [
      {
        label: 'creationDate',
        value: createdDate ? formatDateTime(createdDate) : '',
      },
      { label: 'closeDate', value: closeDate ? formatDateTime(closeDate) : '' },
      { label: 'description', value: description },
    ];
  }

  const taskMetadataFields =
    metadata.find(m => m.mainType === taskType.mainType)?.taskTypeFields || [];
  return hydrateTaskFieldValues(taskFieldValues, taskMetadataFields);
};

export function hydrateTaskFieldValues(
  taskFieldValues: TaskFieldValue[],
  taskTypeFields: TaskTypeField[],
): ValueLabel[] {
  return taskTypeFields.reduce<ValueLabel[]>((accumulator, field) => {
    const matchingValue = taskFieldValues.find(
      value => value.taskTypeFieldId === field.id,
    );

    if (matchingValue) {
      let value = matchingValue.value;
      if (field.fieldType === 'date' && value) {
        value = formatDate(value);
      } else if (field.fieldType === 'enum') {
        value = field.taskTypeFieldEnums?.find(m => m.id === value)?.name || '';
      }

      accumulator.push({
        label: matchingValue.fieldName,
        value: value + (field.unitOfMeasurement || ''),
      });
    }

    return accumulator;
  }, []);
}
