/**
 *
 * Asynchronously loads the component for Equipments
 *
 */

import { lazyLoad } from 'utils/loadable';

export const EquipmentsPage = lazyLoad(
  () => import('./index'),
  module => module.Equipments,
);
