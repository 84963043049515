import { PayloadAction, Action } from '@reduxjs/toolkit';

import {
  User,
  CustomerLocation,
  OfflinedLocationInfoObject,
  SensorType,
  NameId,
} from 'types';
import { createSlice } from 'utils/@reduxjs/toolkit';

import {
  ContainerState,
  SensorProject,
  LinkUnlinkSensorPayload,
  SurveyType,
  LockStatus,
  LocationVendor,
  UploadLogsContent,
} from './types';
import { DataTableSort } from 'app/components/DataTable/types';

// The initial state of the CustomerManagement container
export const initialState: ContainerState = {
  customerId: null,
  locationId: null,
  sensorProjectLinkUnlinkLoading: false,
  linkedSensorProject: null,
  linkedSensorProjectLoading: false,
  linkedSensorProjectError: '',
  unlinkedSensorProjects: null,
  unlinkedSensorProjectsLoading: false,
  surveyTypes: null,
  surveyTypesLoading: false,
  surveyTypesError: '',
  locations: null,
  locationsOfflineStatus: null,
  locationsLoading: false,
  locationsError: '',
  locationUpdateError: '',
  locationUpdateLoading: false,
  locationDeleteError: '',
  locationDeleteLoading: false,
  users: [],
  usersLoading: false,
  mendixSyncingAvailable: true,
  uploadLogs: null,
  uploadLogsLoading: false,
  uploadLogsError: '',
  uploadLogsSort: undefined,
  uploadLogsPageInfo: {
    pageSize: 50,
    page: 1,
    pageCount: undefined,
    totalRecords: undefined,
  },

  countries: [],
  countriesLoading: false,
  countriesError: '',
  contractPhases: [],
  contractPhasesLoading: false,
  technicalUsers: [],
  technicalUsersLoading: false,
};

const customerManagementSlice = createSlice({
  name: 'customerManagement',
  initialState,
  reducers: {
    unlinkSensorProject(
      state: ContainerState,
      action: PayloadAction<LinkUnlinkSensorPayload>,
    ): ContainerState {
      return { ...state, sensorProjectLinkUnlinkLoading: true };
    },
    unlinkSensorProjectComplete(state) {
      return {
        ...state,
        linkedSensorProject: null,
        sensorProjectLinkUnlinkLoading:
          initialState.sensorProjectLinkUnlinkLoading,
      };
    },
    linkSensorProject(state, action: PayloadAction<LinkUnlinkSensorPayload>) {
      return { ...state, sensorProjectLinkUnlinkLoading: true };
    },
    linkSensorProjectComplete(state, action: PayloadAction<SensorProject>) {
      return {
        ...state,
        linkedSensorProject: action.payload,
        sensorProjectLinkUnlinkLoading:
          initialState.sensorProjectLinkUnlinkLoading,
      };
    },
    getLinkedSensorProject(state, action: PayloadAction<LocationVendor>) {
      return { ...state, linkedSensorProjectLoading: true };
    },
    getLinkedSensorProjectComplete(
      state,
      action: PayloadAction<SensorProject | null>,
    ) {
      return {
        ...state,
        linkedSensorProject: action.payload,
        linkedSensorProjectError: initialState.linkedSensorProjectError,
        linkedSensorProjectLoading: initialState.linkedSensorProjectLoading,
      };
    },
    getLinkedSensorProjectError(state, action: PayloadAction<string>) {
      return {
        ...state,
        linkedSensorProjectLoading: initialState.linkedSensorProjectLoading,
        linkedSensorProjectError: action.payload,
      };
    },
    getUnlinkedSensorProjects(state, action: PayloadAction<SensorType>) {
      return { ...state, unlinkedSensorProjectsLoading: true };
    },
    getUnlinkedSensorProjectsComplete(
      state,
      action: PayloadAction<SensorProject[]>,
    ) {
      return {
        ...state,
        unlinkedSensorProjects: action.payload,
        unlinkedSensorProjectsLoading:
          initialState.unlinkedSensorProjectsLoading,
      };
    },

    getSurveyTypes(state) {
      return { ...state, surveyTypesLoading: true };
    },
    getSurveyTypesComplete(state, action: PayloadAction<SurveyType[]>) {
      return {
        ...state,
        surveyTypes: action.payload,
        surveyTypesError: initialState.surveyTypesError,
        surveyTypesLoading: initialState.surveyTypesLoading,
      };
    },
    getSurveyTypesError(state, action: PayloadAction<string>) {
      return {
        ...state,
        surveyTypesLoading: initialState.surveyTypesLoading,
        surveyTypesError: action.payload,
      };
    },

    getCustomerLocations(state, action: PayloadAction<string>) {
      return {
        ...state,
        locationsLoading: true,
        locationsError: initialState.locationsError,
      };
    },
    getCustomerLocationsComplete(
      state,
      action: PayloadAction<CustomerLocation[]>,
    ) {
      return {
        ...state,
        locationsLoading: initialState.locationsLoading,
        locations: action.payload,
      };
    },
    getCustomerLocationsError(state, action: PayloadAction<string>) {
      return {
        ...state,
        locationsLoading: initialState.locationsLoading,
        locations: initialState.locations,
        locationsError: action.payload,
      };
    },

    getAdminCustomerLocations(state, action: PayloadAction<string>) {
      return { ...state, locationsLoading: true };
    },
    getAdminCustomerLocationsComplete(
      state,
      action: PayloadAction<
        | {
            locations: CustomerLocation[];
            offlineStatus: OfflinedLocationInfoObject[];
          }
        | undefined
      >,
    ) {
      return {
        ...state,
        locationsLoading: initialState.locationsLoading,
        locations: action.payload?.locations
          ? action.payload.locations
          : initialState.locations,
        locationsOfflineStatus: action.payload?.offlineStatus
          ? action.payload.offlineStatus
          : initialState.locationsOfflineStatus,
      };
    },
    getAdminCustomerLocationsError(state, action: PayloadAction<string>) {
      return {
        ...state,
        locationsLoading: initialState.locationsLoading,
        locations: initialState.locations,
        locationsError: action.payload,
      };
    },
    getAdminCustomerLocationStatusesComplete(
      state,
      action: PayloadAction<OfflinedLocationInfoObject[]>,
    ) {
      return {
        ...state,
        locationsOfflineStatus: action.payload,
      };
    },
    deleteLocation(state, action: PayloadAction<string>) {
      return {
        ...state,
        locationDeleteLoading: true,
      };
    },
    deleteLocationError(state, action: PayloadAction<string>) {
      return {
        ...state,
        locationDeleteError: action.payload,
        locationDeleteLoading: initialState.locationDeleteLoading,
      };
    },
    deleteLocationComplete(state, action: PayloadAction<string>) {
      return {
        ...state,
        locationDeleteLoading: initialState.locationDeleteLoading,
        locationDeleteError: initialState.locationDeleteError,
        locations: state.locations
          ? state.locations.filter(location => location.id !== action.payload)
          : null,
      };
    },
    postLocationDetails(state, action: PayloadAction<CustomerLocation>) {
      return {
        ...state,
        locationUpdateLoading: true,
      };
    },
    postLocationDetailsComplete(
      state,
      action: PayloadAction<CustomerLocation>,
    ) {
      return {
        ...state,
        locationUpdateError: initialState.locationUpdateError,
        locationUpdateLoading: initialState.locationUpdateLoading,
        locations: state.locations
          ? [...state.locations, action.payload]
          : [action.payload],
      };
    },
    putLocationDetails(state, action: PayloadAction<CustomerLocation>) {
      return {
        ...state,
        locationUpdateLoading: true,
      };
    },
    locationDetailsError(state, action: PayloadAction<string>) {
      return {
        ...state,
        locationUpdateError: action.payload,
        locationUpdateLoading: initialState.locationUpdateLoading,
      };
    },
    putLocationDetailsComplete(state, action: PayloadAction<CustomerLocation>) {
      return {
        ...state,
        locationUpdateError: initialState.locationUpdateError,
        locationUpdateLoading: initialState.locationUpdateLoading,
        locations: state.locations
          ? state.locations.map(location => {
              if (location.id === action.payload.id) return action.payload;
              return location;
            })
          : null,
      };
    },
    postLockLocation(state, action: PayloadAction<string>) {
      return {
        ...state,
        locationsOfflineStatus: state.locationsOfflineStatus
          ? state.locationsOfflineStatus?.map(locationStatus => {
              return locationStatus.id === action.payload
                ? { ...locationStatus, status: LockStatus.SYNCING }
                : locationStatus;
            })
          : null,
      };
    },
    postUnlockLocation(state, action: PayloadAction<string>) {
      return {
        ...state,
        locationsOfflineStatus: state.locationsOfflineStatus
          ? state.locationsOfflineStatus?.map(locationStatus => {
              return locationStatus.id === action.payload
                ? { ...locationStatus, status: LockStatus.SYNCING }
                : locationStatus;
            })
          : null,
      };
    },
    postClearLockLocation(state, action: PayloadAction<string>) {
      return {
        ...state,
        locationsOfflineStatus: state.locationsOfflineStatus
          ? state.locationsOfflineStatus?.map(locationStatus => {
              return locationStatus.id === action.payload
                ? { ...locationStatus, status: LockStatus.SYNCING }
                : locationStatus;
            })
          : null,
      };
    },
    putLockOperationSuccess(
      state,
      action: PayloadAction<OfflinedLocationInfoObject>,
    ) {
      return {
        ...state,
        locationsOfflineStatus: state.locationsOfflineStatus
          ? state.locationsOfflineStatus.map(locationStatus => {
              if (locationStatus.id === action.payload.id) {
                return action.payload;
              } else return locationStatus;
            })
          : null,
      };
    },
    putOfflinedLocationOperationFailure(
      state,
      action: PayloadAction<{ locationId: string; status: LockStatus }>,
    ) {
      return {
        ...state,
        locationsOfflineStatus: state.locationsOfflineStatus
          ? state.locationsOfflineStatus?.map(locationStatus => {
              return locationStatus.id === action.payload.locationId
                ? { ...locationStatus, status: action.payload.status }
                : locationStatus;
            })
          : null,
      };
    },
    getAdminCustomerUser(state, action: PayloadAction<string>) {
      return {
        ...state,
        users: initialState.users,
        usersLoading: true,
      };
    },
    getAdminCustomerUsers(
      state,
      action: PayloadAction<{
        customerId: string;
        page: number;
        perPage: number;
      }>,
    ) {
      return {
        ...state,
        users: initialState.users,
        usersLoading: true,
      };
    },
    getAdminCustomerUsersComplete(
      state,
      action: PayloadAction<User[] | undefined>,
    ) {
      return {
        ...state,
        usersLoading: initialState.usersLoading,
        users: action.payload ? action.payload : initialState.users,
      };
    },
    getCreateNewUser(state, action: PayloadAction<User>) {
      return {
        ...state,
        usersLoading: true,
      };
    },
    getUpdateUser(state, action: PayloadAction<User>) {
      return {
        ...state,
        usersLoading: true,
      };
    },
    putCreateNewUserComplete(state, action: Action) {
      return {
        ...state,
        usersLoading: false,
      };
    },
    putUpdateUserComplete(state, action: PayloadAction<User | undefined>) {
      return {
        ...state,
        usersLoading: false,
        users: action.payload ? [action.payload] : initialState.users,
      };
    },
    deleteUser(state, action: PayloadAction<string>) {
      return {
        ...state,
        usersLoading: true,
      };
    },
    deleteUserComplete(state, action: PayloadAction<string>) {
      return {
        ...state,
        users: state.users.reduce(
          (remainingUsers: User[], current: User) =>
            current.id === action.payload
              ? [...remainingUsers]
              : [...remainingUsers, current],
          [] as User[],
        ),
        usersLoading: false,
      };
    },
    deleteUserFailed(state, action: Action) {
      return {
        ...state,
        usersLoading: false,
      };
    },
    putMendixSyncingAvailable(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        mendixSyncingAvailable: action.payload,
      };
    },
    getUploadLogs(state, action: PayloadAction<any>) {
      return {
        ...state,
        customerId: action.payload.customerId,
        locationId: action.payload.locationId,
        uploadLogsLoading: true,
      };
    },
    getLogsComplete(state, action: PayloadAction<UploadLogsContent>) {
      return {
        ...state,
        uploadLogs: action.payload.content,
        uploadLogsLoading: false,
        uploadLogsPageInfo: {
          page: action.payload.number + 1,
          pageSize: action.payload.size,
          pageCount: action.payload.totalPages,
          totalRecords: action.payload.numberOfElements,
        },
      };
    },
    getLogsError(state, action: PayloadAction<string>) {
      return {
        ...state,
        uploadLogs: null,
        uploadLogsError: action.payload,
        uploadLogsLoading: false,
      };
    },
    setUploadLogsSort(state, action: PayloadAction<DataTableSort>) {
      return {
        ...state,
        uploadLogsSort: action.payload,
        uploadLogsLoading: true,
      };
    },
    setUploadLogsPageInfo(
      state,
      action: PayloadAction<{
        page: number;
        pageSize: number;
      }>,
    ) {
      return {
        ...state,
        uploadLogsLoading: true,
        uploadLogsPageInfo: {
          page: action.payload.page,
          pageSize: action.payload.pageSize,
        },
      };
    },

    getCountries(state) {
      return { ...state, countriesLoading: true };
    },
    getCountriesComplete(state, action: PayloadAction<NameId[]>) {
      return {
        ...state,
        countries: action.payload,
        countriesError: initialState.countriesError,
        countriesLoading: initialState.countriesLoading,
      };
    },
    getCountriesError(state, action: PayloadAction<string>) {
      return {
        ...state,
        countriesLoading: initialState.countriesLoading,
        countriesError: action.payload,
      };
    },

    getContractPhases(state) {
      return { ...state, contractPhasesLoading: true };
    },
    getContractPhasesComplete(state, action: PayloadAction<NameId[]>) {
      return {
        ...state,
        contractPhases: action.payload,
        contractPhasesLoading: initialState.contractPhasesLoading,
      };
    },
    getTechnicalUsers(state) {
      return { ...state, technicalUsersLoading: true };
    },
    getTechnicalUsersComplete(state, action: PayloadAction<User[]>) {
      return {
        ...state,
        technicalUsers: action.payload,
        technicalUsersLoading: initialState.technicalUsersLoading,
      };
    },
  },
});

export const { actions, reducer, name: sliceKey } = customerManagementSlice;
