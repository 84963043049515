import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';

import { translationString } from 'locales/translation';
import {
  CreateCustomerObject,
  CreateResourceFileObject,
  CustomerObject,
  EntityType,
  ResourceType,
  UpdateCustomerObject,
} from 'types';
import apiCall from 'services/utils/apiCall';
import { objectToFormData } from 'utils/form-data';
import RouteHelpers from 'utils/route-helpers';
import { notifyAxiosError, parseAxiosError } from 'utils/error-helpers';
import {
  getCustomers as getCustomersApi,
  putCustomer as putCustomerApi,
  deleteCustomer as deleteCustomerApi,
  postCustomer,
} from 'services/customersService';
import { postResource as postResourceAPI } from 'services/fileManagementServices';
import { actions as announcementActions } from 'app/containers/AnnouncementsToast/slice';
import { getCustomerLocations as getCustomerLocationsApi } from 'services/customersService';
import { actions } from './slice';
import {
  selectHasLocationSelector,
  selectLocations,
  selectPath,
  selectSelectedCustomer,
  selectSelectedLocation,
} from './selectors';
import { buildRedirectPath } from './utils';

export function* getCustomers() {
  try {
    const { data: customers } = yield apiCall(getCustomersApi);
    const sortedCustomers = customers.sort((a, b) =>
      a.name.localeCompare(b.name),
    );
    yield put(actions.getCustomersSuccess(sortedCustomers));
  } catch (error) {
    const effectError = parseAxiosError(
      error,
      translationString('ErrorMessage.FetchEntity.Customers'),
    );
    yield put(actions.getCustomersFailure(effectError));
  }
}

export function* postNewCustomer(
  action: PayloadAction<{ customer: CreateCustomerObject; logo: File }>,
) {
  try {
    const { customer, logo } = action.payload;
    const { data: createdCustomer } = yield apiCall(postCustomer, customer);

    if (logo) {
      const logoData: FormData = objectToFormData<CreateResourceFileObject>({
        customerId: createdCustomer.id,
        description: logo.name,
        entityType: EntityType.CUSTOMER,
        file: logo,
        resourceType: ResourceType.LOGO,
      });
      yield apiCall(postResourceAPI, logoData, createdCustomer.id);
    }

    const newCustomerObject: CustomerObject = {
      id: createdCustomer.id,
      ...customer,
    };

    yield put(actions.postCustomerComplete(newCustomerObject));

    yield put(
      push(
        RouteHelpers.buildAdminCustomerManageRoute(
          undefined,
          createdCustomer.id,
        ),
      ),
    );
  } catch (error) {
    const effectError = yield notifyAxiosError(
      error,
      translationString('ErrorMessage.CustomerUpdateError'),
    );
    yield put(actions.postCustomerError(effectError));
  }
}

export function* putCustomer(
  action: PayloadAction<{
    id: string;
    updateObject: UpdateCustomerObject;
    logo?: File;
  }>,
) {
  try {
    const { id, updateObject, logo } = action.payload;
    const { data: customer }: { data: CustomerObject } = yield apiCall(
      putCustomerApi,
      id,
      updateObject,
    );

    if (logo) {
      const logoData: FormData = objectToFormData<CreateResourceFileObject>({
        customerId: customer.id,
        description: logo.name,
        entityType: EntityType.CUSTOMER,
        file: logo,
        resourceType: ResourceType.LOGO,
      });
      yield apiCall(postResourceAPI, logoData, customer.id);
    }

    yield put(actions.putCustomerComplete(customer));
    yield put(
      announcementActions.addSuccess(
        translationString('SuccessMessage.SavedCustomer'),
      ),
    );
  } catch (error) {
    const effectError = yield notifyAxiosError(
      error,
      translationString('ErrorMessage.CustomerUpdateError'),
    );
    yield put(actions.customerUpdateError(effectError));
  }
}

export function* deleteCustomer(action: PayloadAction<string>) {
  try {
    yield apiCall(deleteCustomerApi, action.payload);

    yield put(actions.deleteCustomerComplete(action.payload));
    yield put(
      announcementActions.addSuccess(
        translationString('SuccessMessage.DeletedCustomer'),
      ),
    );
    yield put(push(RouteHelpers.buildAdminCustomerManageRoute()));
  } catch (error) {
    const effectError = yield notifyAxiosError(
      error,
      translationString('ErrorMessage.CustomerUpdateError'),
    );
    yield put(actions.customerUpdateError(effectError));
  }
}

export function* getCustomerLocations(action: PayloadAction<string>) {
  const customerId: string = action.payload;

  try {
    const { data: customerLocations } = yield apiCall(
      getCustomerLocationsApi,
      customerId,
    );
    yield put(actions.getCustomerLocationsSuccess(customerLocations));
  } catch (error) {
    const effectError = parseAxiosError(
      error,
      translationString('ErrorMessage.LocationsRetrievingError'),
    );
    yield put(actions.getCustomerLocationsFailure(effectError));
  }
}

export function* redirect() {
  const customerId = yield select(selectSelectedCustomer);
  const locationId = yield select(selectSelectedLocation);
  const path = yield select(selectPath);
  const hasLocationSelector = yield select(selectHasLocationSelector);
  const redirectTo = hasLocationSelector
    ? buildRedirectPath(path, customerId, locationId)
    : buildRedirectPath(path, customerId);
  yield put(push(redirectTo));
}

export function* setSelectedCustomer(action: PayloadAction<string>) {
  yield call(getCustomerLocations, action);
  const locations = yield select(selectLocations);
  const locationId = yield select(selectSelectedLocation);
  if (!locationId) {
    yield put(actions.setSelectedLocation(locations[0].id));
  }
}

export function* changeSelectedCustomer(action: PayloadAction<string>) {
  yield call(getCustomerLocations, action);
  const locations = yield select(selectLocations);
  yield put(actions.setSelectedLocation(locations[0].id));
  yield call(redirect);
}

export function* changeSelectedLocation(action: PayloadAction<string>) {
  yield call(redirect);
}

export function* customerControlsSaga() {
  yield takeLatest(actions.getCustomers.type, getCustomers);
  yield takeLatest(actions.postNewCustomer.type, postNewCustomer);
  yield takeLatest(actions.putCustomer.type, putCustomer);
  yield takeLatest(actions.deleteCustomer.type, deleteCustomer);
  yield takeLatest(actions.getCustomerLocations.type, getCustomerLocations);
  yield takeLatest(actions.setSelectedCustomer.type, setSelectedCustomer);
  yield takeLatest(actions.changeSelectedCustomer.type, changeSelectedCustomer);
  yield takeLatest(actions.changeSelectedLocation.type, changeSelectedLocation);
}
