import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components/macro';
import { Group } from '@visx/group';
import { ScaleLinear } from 'd3-scale';

import {
  ThresholdZone,
  getThresholdBackground,
} from 'utils/chartColorProvider';
import { ThresholdObject } from 'types';

interface Props {
  threshold?: ThresholdObject;
  valueScale: ScaleLinear<number, number>;
  width: number;
  height: number;
  offsetTop?: number;
  offsetLeft?: number;
}

export const Thresholds = ({
  threshold,
  valueScale,
  width,
  height,
  offsetTop = 0,
  offsetLeft = 0,
}: Props) => {
  const theme = useContext(ThemeContext);

  const getHeight = (threshold: number): number => {
    const value = valueScale(threshold);

    return value > height ? height : value;
  };

  const getColorArea = (
    value: number,
    lowValue: number,
  ): { y: number; height: number } => {
    let y = valueScale(value);
    let _height = valueScale(lowValue) - y;

    if (y < 0) {
      y = 0;
      _height = valueScale(lowValue);
    }

    if (valueScale(lowValue) > height) {
      _height = height - y;
    }

    return { y, height: _height };
  };

  if (!threshold)
    return (
      <Group top={offsetTop} left={offsetLeft}>
        <rect
          x={0}
          y={0}
          width={width}
          height={height}
          color={theme.colors.background.gray}
        />
      </Group>
    );

  const {
    dangerValue,
    warningValue,
    lowDangerValue,
    lowWarningValue,
  } = threshold;

  return (
    <Group top={offsetTop} left={offsetLeft}>
      {lowWarningValue && lowDangerValue ? (
        <>
          <rect
            x={0}
            y={0}
            width={width}
            height={height}
            color={getThresholdBackground(ThresholdZone.DANGER)}
          />

          {getColorArea(dangerValue, lowDangerValue).height > 0 && (
            <rect
              x={0}
              y={getColorArea(dangerValue, lowDangerValue).y}
              width={width}
              height={getColorArea(dangerValue, lowDangerValue).height}
              color={getThresholdBackground(ThresholdZone.WARNING)}
            />
          )}

          {getColorArea(warningValue, lowWarningValue).height > 0 && (
            <rect
              x={0}
              y={getColorArea(warningValue, lowWarningValue).y}
              width={width}
              height={getColorArea(warningValue, lowWarningValue).height}
              color={getThresholdBackground(ThresholdZone.GOOD)}
            />
          )}
        </>
      ) : (
        <>
          <rect
            x={0}
            y={0}
            width={width}
            height={height}
            color={getThresholdBackground(ThresholdZone.GOOD)}
          />

          {getHeight(warningValue) > 0 && (
            <rect
              x={0}
              y={0}
              width={width}
              height={getHeight(warningValue)}
              color={getThresholdBackground(ThresholdZone.WARNING)}
            />
          )}

          {getHeight(dangerValue) > 0 && (
            <rect
              x={0}
              y={0}
              width={width}
              height={getHeight(dangerValue)}
              color={getThresholdBackground(ThresholdZone.DANGER)}
            />
          )}
        </>
      )}
    </Group>
  );
};
