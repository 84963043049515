import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { EntityCollectionMap } from './types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.mediaGallery || initialState;

export const selectMediaItemsLoading = createSelector(
  [selectDomain, (_, entityId: string) => entityId],
  ({ entityItems }: { entityItems: EntityCollectionMap }, entityId) => {
    if (!entityItems.hasOwnProperty(entityId)) {
      return false;
    }
    return entityItems[entityId]?.loading;
  },
);

export const selectMediaItemsError = createSelector(
  [selectDomain, (_, entityId: string) => entityId],
  ({ entityItems }: { entityItems: EntityCollectionMap }, entityId) => {
    if (!entityItems.hasOwnProperty(entityId)) {
      return '';
    }
    return entityItems[entityId]?.error;
  },
);

export const selectMediaItems = createSelector(
  [selectDomain, (_, entityId: string) => entityId],
  ({ entityItems }: { entityItems: EntityCollectionMap }, entityId) => {
    if (
      !entityItems.hasOwnProperty(entityId) ||
      !entityItems[entityId]?.items
    ) {
      return [];
    }
    return entityItems[entityId]?.items;
  },
);

export const selectLightboxMediaItems = createSelector(
  [selectDomain, (_, entityId: string) => entityId],
  ({ entityItems }: { entityItems: EntityCollectionMap }, entityId) => {
    if (
      !entityItems.hasOwnProperty(entityId) ||
      !entityItems[entityId]?.items
    ) {
      return [];
    }
    return entityItems[entityId]?.items.map(res => ({
      src: res.url,
      thumbnail: res?.specificProperties?.small,
      caption: res.description,
      autoplay: false,
      showControls: true,
    }));
  },
);
