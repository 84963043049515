/**
 *
 * Notifications
 *
 */

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import transition from 'styled-transition-group';

import { useUserDetails } from 'app/providers/AuthProvider';
import { NotificationsOverlay } from 'app/components/Notifications/NotificationsOverlay';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { reducer, sliceKey, actions } from './slice';
import {
  selectOverlayVisible,
  selectDisplayedNotifications,
  selectCurrentCategory,
  selectCustomerOnlyFilter,
} from './selectors';
import { notificationsSaga } from './saga';

const panelWidth = 570;
const animationSpeed = 350;

export function Notifications() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: notificationsSaga });

  const userDetails = useUserDetails();
  const dispatch = useDispatch();
  const overlayVisible = useSelector(selectOverlayVisible);
  const notifications = useSelector(selectDisplayedNotifications);
  const selectedCategory = useSelector(selectCurrentCategory);
  const customerFiltered = useSelector(selectCustomerOnlyFilter);

  useEffect(() => {
    if (userDetails?.id) {
      dispatch(actions.getNotifications(userDetails.id));
    }
  }, [userDetails, dispatch]);

  return (
    <OverlayPanel in={overlayVisible} timeout={1000} unmountOnExit>
      <NotificationsOverlay
        onToggle={() => dispatch(actions.toggleOverlay())}
        selectedCategory={selectedCategory}
        onChangeCategory={category =>
          dispatch(actions.changeCurrentCategory(category))
        }
        onMarkAllRead={() => dispatch(actions.markAllNotificationsRead())}
        onMarkItemRead={(noteId, closePanel) =>
          dispatch(actions.markNotificationRead({ noteId, closePanel }))
        }
        notifications={notifications}
        customersFiltered={customerFiltered}
        onFilterByCustomer={filtered =>
          dispatch(actions.toggleNotificationCustomerFilter(filtered))
        }
      />
    </OverlayPanel>
  );
}

const OverlayPanel = transition.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: ${({ theme }) => theme.stacking.popUp};
  background: ${({ theme }) => theme.colors.background.white};
  max-width: ${panelWidth}px;
  overflow: hidden;
  box-shadow: 0 0 15px -5px rgba(0, 0, 0, 0.4);
  
  > * {
    width: ${panelWidth}px;
  }
  &:enter {
    opacity: 0.01;
    width: 0;
  }
  &:enter-active {
    opacity: 1;
    width: ${panelWidth}px;
    transition: all ${animationSpeed}ms ease-in;
  }
  &:exit {
    opacity: 1; 
    width: ${panelWidth}px; 
  }
  &:exit-active {
    opacity: 0.01;
    width: 0;
    transition: all ${animationSpeed}ms ease-in;
  }
`;
