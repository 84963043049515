import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { MenuState, SubMenuType } from './types';

export const initialState: MenuState = {
  isOpen: false,
  subMenu: null,
  isCreatingEquipmentType: false,
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    toggle(state) {
      return {
        ...state,
        isOpen: state.subMenu ? true : !state.isOpen,
        subMenu: null,
      };
    },
    openSubMenu(state, action: PayloadAction<SubMenuType>) {
      return {
        ...state,
        isOpen: true,
        subMenu: action.payload,
      };
    },
    close(state) {
      return {
        ...state,
        isOpen: initialState.isOpen,
        subMenu: initialState.subMenu,
      };
    },
    setIsCreatingEquipmentType(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isCreatingEquipmentType: action.payload,
        isOpen: initialState.isOpen,
        subMenu: initialState.subMenu,
      };
    },
  },
});

export const { actions, reducer, name: sliceKey } = menuSlice;
