import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { translationString } from 'locales/translation';
import apiCall from 'services/utils/apiCall';
import { notifyAxiosError } from 'utils/error-helpers';
import {
  getResourcesForEntity,
  deleteResource,
} from 'services/fileManagementServices';
import { Resource } from 'types';

import { EntityQuery } from './types';
import { actions } from './slice';

export function* getMediaItems(action: PayloadAction<EntityQuery>) {
  try {
    const {
      status,
      data: resourceItems,
    }: { status: number; data: Resource[] } = yield apiCall(
      getResourcesForEntity,
      action.payload.entityId,
      {
        entityType: action.payload.entityType,
        resourceTypes: action.payload.resourceTypes,
      },
    );

    const items: Resource[] =
      status === 200 && Array.isArray(resourceItems) && resourceItems.length > 0
        ? resourceItems?.sort((a, b) =>
            b.resourceType.localeCompare(a.resourceType),
          )
        : [];

    yield put(
      actions.getMediaItemsSuccessful({
        entityId: action.payload.entityId,
        items,
      }),
    );
  } catch (error) {
    const effectError = yield notifyAxiosError(
      error,
      translationString('ErrorMessage.ImagesRetrievingError'),
    );
    yield put(
      actions.getMediaItemsError({
        entityId: action.payload.entityId,
        error: effectError,
      }),
    );
  }
}

export function* removeMediaItem(
  action: PayloadAction<{ entityId: string; resourceId: string }>,
) {
  try {
    yield apiCall(
      deleteResource,
      action.payload.entityId,
      action.payload.resourceId,
    );
    yield put(
      actions.removeMediaItemSuccess({
        entityId: action.payload.entityId,
        resourceId: action.payload.resourceId,
      }),
    );
  } catch (error) {
    const effectError = yield notifyAxiosError(
      error,
      translationString('ErrorMessage.RemoveMediaError'),
    );
    yield put(
      actions.removeMediaItemError({
        entityId: action.payload.entityId,
        error: effectError,
      }),
    );
  }
}

export function* mediaGallerySaga() {
  yield takeLatest(actions.getMediaItems.type, getMediaItems);
  yield takeLatest(actions.removeMediaItem.type, removeMediaItem);
}
