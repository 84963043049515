import styled from 'styled-components/macro';

type CheckboxStyledProps = {
  small?: boolean;
};

export default styled.input.attrs(() => ({
  type: 'checkbox',
}))<CheckboxStyledProps>`
  appearance: none;
  position: relative;
  min-width: ${({ small }) => (small ? '16px' : '20px')};
  min-height: ${({ small }) => (small ? '16px' : '20px')};
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.colors.default.gray2};
  background-color: ${({ theme }) => theme.colors.default.white};
  transition: all 200ms;

  &:disabled {
    border: 1px solid ${({ theme }) => theme.colors.default.gray3};
    background-color: ${({ theme }) => theme.colors.default.gray3};
  }

  &:checked {
    background-color: ${({ theme }) => theme.colors.default.blue1};
    border-color: ${({ theme }) => theme.colors.default.blue1};

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      background-color: ${({ theme }) => theme.colors.default.white};
      clip-path: polygon(25% 43%, 45% 63%, 75% 23%, 81% 29%, 45% 74%, 19% 49%);
    }
  }
`;
