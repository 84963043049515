import styled from 'styled-components/macro';

import { BgColor, TextColor } from 'types';
import { getBGColor, getTextColor } from 'utils/getColor';

interface Props {
  position?: 'absolute' | 'relative' | 'fixed';
  display?: 'block' | 'inline-block' | 'none' | 'flex' | 'grid';
  align?: 'stretch' | 'center' | 'start' | 'end';
  justifyContent?:
    | 'start'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'flex-end';

  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse';

  bg?: BgColor;
  color?: TextColor;

  width?: string;
  height?: string;
  borderRad?: string;

  fs?: string;
  fw?: string;

  margin?: string;
  mt?: string;
  mb?: string;
  ml?: string;
  mr?: string;

  padding?: string;
  pt?: string;
  pb?: string;
  pl?: string;
  pr?: string;

  lineHeight?: string;

  gap?: string;

  cursor?: string;
}

export const Div = styled.div<Props>`
  display: ${({ display }) => display || 'block'};
  align-items: ${({ align }) => align || 'start'};
  justify-content: ${({ justifyContent }) => justifyContent || 'start'};


  ${({ position }) => position && `position: ${position};`}

  background-color: ${({ theme, bg }) => getBGColor(theme, bg)};
  color: ${({ theme, color }) => getTextColor(theme, color)};

  ${({ direction }) => direction && `flex-direction: ${direction};`}

  ${({ width }) => width && `width: ${width};`}
  ${({ height }) => height && `height: ${height};`}
  ${({ borderRad }) => borderRad && `border-radius: ${borderRad};`}

  ${({ fs }) => fs && `font-size: ${fs};`}
  ${({ fw }) => fw && `font-weight: ${fw};`}

  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ mt }) => mt && `margin-top: ${mt};`}
  ${({ mb }) => mb && `margin-bottom: ${mb};`}
  ${({ mr }) => mr && `margin-right: ${mr};`}
  ${({ ml }) => ml && `margin-left: ${ml};`}

  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ pt }) => pt && `padding-top: ${pt};`}
  ${({ pb }) => pb && `padding-bottom: ${pb};`}
  ${({ pr }) => pr && `padding-right: ${pr};`}
  ${({ pl }) => pl && `padding-left: ${pl};`}

  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight};`}

  ${({ gap }) => gap && `gap: ${gap};`}

  ${({ cursor }) => cursor && `cursor: ${cursor};`}
`;
