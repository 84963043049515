import { EquipmentFormAction } from 'app/containers/EquipmentForm/types';
import { useParams } from 'react-router-dom';

function useEquipmentParams() {
  const { customerId, locationId, miTypeId, miId, action } = useParams<{
    customerId: string;
    locationId: string;
    miTypeId: string;
    miId: string;
    action: EquipmentFormAction;
  }>();

  return {
    customerId,
    locationId,
    miTypeId,
    miId,
    action,
  };
}

export default useEquipmentParams;
