import styled, { css } from 'styled-components';
import { Icon } from '@eriksdigital/atomic-ui/components';

import { BgColor } from 'types';
import { styledIcon } from 'styles';
import { slideInFromRight, keyframeFadeIn } from 'utils/style-utils';
import { getBGColor } from 'utils/getColor';

export const convertDurationToCSS = (duration: number) => duration / 1000;

export const ModalWrapper = styled.div`
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${({ theme }) => theme.stacking.modal};
`;

export const Overlay = styled.div`
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.colors.overlay.dim};
  opacity: 0.6;
  overflow: hidden;
  pointer-events: none;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;

  ${({ theme }) => theme.breakpoints.tabletPortrait`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 0;
  `};
`;

export const animation = () => {
  let keyframe = css`
    ${keyframeFadeIn} ${convertDurationToCSS(300)}s ease-in-out;
  `;

  return keyframe;
};

export const Content = styled.div<{
  maxWidth?: number;
  overflowY: 'visible' | 'hidden' | 'scroll' | 'auto' | 'initial' | 'inherit';
  background?: BgColor;
}>`
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : `960px`)};
  height: auto;
  max-height: 100%;
  background: ${({ theme, background }) =>
    background ? getBGColor(theme, background) : theme.colors.background.white};
  border-radius: 3px;
  bottom: 0;
  top: 0;
  border: 0;
  z-index: 1;
  overflow-y: ${({ overflowY }) => overflowY};
  animation: ${slideInFromRight} 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  ${({ theme }) => theme.breakpoints.tabletPortrait`
    bottom: auto;
    position: relative;
    animation: ${animation};
  `};
`;

export const Frame = styled.div<{ withFrame?: boolean }>`
  padding: ${({ theme, withFrame }) => withFrame && theme.spacing.sp32};
`;

export const StyledIcon = styled(Icon)`
  ${styledIcon}
  margin-right: ${({ theme }) => theme.spacing.sp8};
`;
