import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeEvery, takeLatest, call } from 'redux-saga/effects';

import { translationString } from 'locales/translation';
import apiCall from 'services/utils/apiCall';
import {
  getTask as getTaskAPI,
  updateTask as updateTaskAPI,
  getWorklogs as getWorklogsAPI,
  subscribeToTask,
  unSubscribeTask,
  deactivateTask,
  getTaskTypeMetadata,
} from 'services/taskManagementService';
import { TaskReq } from 'types';
import { actions as announcementActions } from 'app/containers/AnnouncementsToast/slice';
import { notifyAxiosError } from 'utils/error-helpers';
import { actions } from './slice';

export function* getTask(action: PayloadAction<string>) {
  try {
    const { data: task } = yield apiCall(getTaskAPI, action.payload);

    yield put(actions.getTaskSuccessful(task));
  } catch (error) {
    const effectError = yield notifyAxiosError(
      error,
      translationString('ErrorMessage.TaskRetrievingError'),
    );

    yield put(actions.getTaskError(effectError));
  }
}

export function* getTaskMetadata() {
  try {
    const { data } = yield apiCall(getTaskTypeMetadata);
    yield put(actions.getMetadataSuccess(data));
  } catch (error) {
    yield notifyAxiosError(
      error,
      translationString('ErrorMessage.FetchEntity.TaskTypeMetadata'),
    );
  }
}

export function* getWorklogs(action: PayloadAction<string>) {
  try {
    const { data: worklogs } = yield apiCall(getWorklogsAPI, action.payload);

    yield put(actions.getWorklogsSuccessful(worklogs));
  } catch (error) {
    const effectError = yield notifyAxiosError(
      error,
      translationString('ErrorMessage.WorklogsRetrievingError'),
    );

    yield put(actions.getTaskError(effectError));
  }
}

export function* getNotifications(action: PayloadAction<string>) {
  try {
    yield apiCall(subscribeToTask, action.payload);

    yield put(actions.subscribeToTask(true));
    yield put(
      announcementActions.addSuccess(translationString('SuccessfullySaved')),
    );
  } catch (error) {
    yield notifyAxiosError(error, translationString('SubscribeToTaskFailed'));
  }
}

export function* stopNotifications(action: PayloadAction<string>) {
  try {
    yield apiCall(unSubscribeTask, action.payload);

    yield put(actions.subscribeToTask(false));
    yield put(
      announcementActions.addSuccess(translationString('SuccessfullySaved')),
    );
  } catch (error) {
    yield notifyAxiosError(error, translationString('UnSubscribeTaskFailed'));
  }
}

export function* updateTask(action: PayloadAction<TaskReq>) {
  try {
    const { data } = yield apiCall(updateTaskAPI, action.payload);

    yield call(getWorklogs, {
      payload: action.payload.id || '',
      type: 'taskDetails/getWorklogs',
    });

    yield put(actions.updateTaskSuccessful(data));
    yield put(
      announcementActions.addSuccess(translationString('SuccessfullySaved')),
    );
  } catch (error) {
    yield notifyAxiosError(error, translationString('SaveFailed'));
  }
}

export function* deleteTask(action: PayloadAction<string>) {
  try {
    yield apiCall(deactivateTask, action.payload);

    yield put(
      announcementActions.addSuccess(translationString('SuccessfullyDeleted')),
    );
  } catch (error) {
    yield notifyAxiosError(error, translationString('DeleteFailed'));
  }
}

export function* taskDetailsSaga() {
  yield takeEvery(actions.getTask, getTask);
  yield takeEvery(actions.getTaskMetadata, getTaskMetadata);
  yield takeLatest(actions.getWorklogs.type, getWorklogs);
  yield takeEvery(actions.updateTask.type, updateTask);
  yield takeEvery(actions.getNotifications.type, getNotifications);
  yield takeEvery(actions.stopNotifications.type, stopNotifications);
  yield takeLatest(actions.deleteTask.type, deleteTask);
}
