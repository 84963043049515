import { PayloadAction } from '@reduxjs/toolkit';
import { takeLatest, put, select } from 'redux-saga/effects';
import { translationString } from 'locales/translation';
import { notifyAxiosError } from 'utils/error-helpers';
import apiCall from 'services/utils/apiCall';
import {
  createInspection,
  getMIWorkOrders,
  getTaskTypeMetadata,
} from 'services/taskManagementService';
import { actions } from './slice';
import { selectInspectionsPayload, selectMetadata } from './selectors';
import { InspectionPayload } from './types';

export function* getInspections() {
  try {
    const payload = yield select(selectInspectionsPayload);
    const { data } = yield apiCall(getMIWorkOrders, payload);

    yield put(actions.getInspectionsSuccess(data));
  } catch (error) {
    const inspectionsError = yield notifyAxiosError(
      error,
      translationString('ErrorMessage.InspectionsError'),
    );
    yield put(actions.getInspectionsError(inspectionsError));
  }
}

export function* addInspection(action: PayloadAction<InspectionPayload>) {
  try {
    yield apiCall(createInspection, action.payload);
    yield put(actions.addInspectionSuccess());
    yield put(actions.getInspections());
  } catch (error) {
    yield put(actions.addInspectionFailed());
  }
}

export function* getMetadata() {
  try {
    const { metadata } = yield select(selectMetadata);
    if (!metadata) {
      const { data } = yield apiCall(getTaskTypeMetadata);
      yield put(actions.getMetadataSuccess(data));
    } else {
      yield put(actions.getMetadataSkipped());
    }
  } catch (error) {
    const effectError = yield notifyAxiosError(
      error,
      translationString('ErrorMessage.FetchEntity.TaskTypeMetadata'),
    );
    yield put(actions.getMetadataError(effectError));
  }
}

export function* equipmentInspectionSaga() {
  yield takeLatest(actions.getInspections.type, getInspections);
  yield takeLatest(actions.setInspectionsSort.type, getInspections);
  yield takeLatest(actions.setInspectionsPageInfo.type, getInspections);
  yield takeLatest(actions.addInspection.type, addInspection);
  yield takeLatest(actions.getMetadata.type, getMetadata);
}
