import React from 'react';
import { subDays } from 'date-fns';
import { Select } from 'app/elements/Fields';
import { formatDate } from 'locales/date-format-i18n';
import { translationString } from 'locales/translation';

const dateRanges = {
  '24Hours': {
    startDate: formatDate(subDays(new Date(), 1), 'yyyy-MM-dd'),
    endDate: formatDate(new Date(), 'yyyy-MM-dd'),
  },
  '48Hours': {
    startDate: formatDate(subDays(new Date(), 2), 'yyyy-MM-dd'),
    endDate: formatDate(new Date(), 'yyyy-MM-dd'),
  },
  '7Days': {
    startDate: formatDate(subDays(new Date(), 7), 'yyyy-MM-dd'),
    endDate: formatDate(new Date(), 'yyyy-MM-dd'),
  },
  '30Days': {
    startDate: formatDate(subDays(new Date(), 30), 'yyyy-MM-dd'),
    endDate: formatDate(new Date(), 'yyyy-MM-dd'),
  },
  '90Days': {
    startDate: formatDate(subDays(new Date(), 90), 'yyyy-MM-dd'),
    endDate: formatDate(new Date(), 'yyyy-MM-dd'),
  },
  '365Days': {
    startDate: formatDate(subDays(new Date(), 365), 'yyyy-MM-dd'),
    endDate: formatDate(new Date(), 'yyyy-MM-dd'),
  },
};

export function getRangeValue(key: string) {
  return { range: key, ...dateRanges[key] };
}

export type DateRangeValue = {
  range: keyof typeof dateRanges;
  startDate: string;
  endDate: string;
};

type Props = {
  value: DateRangeValue;
  onChange: (value: DateRangeValue) => void;
};

function DateRangeSelector({ value, onChange }: Props) {
  const dateRangeOptions = Object.keys(dateRanges).map(key => ({
    value: key,
    label: translationString(`DateFilter.${key}`),
  }));

  return (
    <Select
      options={dateRangeOptions}
      value={value.range}
      onChange={e => onChange(getRangeValue(e.currentTarget.value))}
    ></Select>
  );
}

export default DateRangeSelector;
