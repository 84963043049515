import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.authentication || initialState;

export const selectAuthentication = createSelector(
  [selectDomain],
  authenticationState => authenticationState,
);

export const selectUserCustomerId = createSelector([selectDomain], ({ user }) =>
  user ? user.customerId : null,
);

export const selectUserId = createSelector([selectDomain], ({ user }) =>
  user ? user.id : null,
);

export const selectUserRole = createSelector(
  [selectDomain],
  authenticationState => authenticationState.user?.role,
);

export const selectUserDetails = createSelector(
  [selectDomain],
  ({ user }) => user,
);

export const selectUserRolesMap = createSelector(
  [selectDomain],
  ({ userRoles }) => {
    const rolesMap = new Map<string, string>();
    if (userRoles) {
      for (const role of userRoles) {
        rolesMap.set(role.id, role.name);
      }
    }
    return rolesMap;
  },
);

export const selectUserSaving = createSelector(
  [selectDomain],
  ({ userSaving }) => userSaving || false,
);
