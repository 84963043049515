/**
 *
 * StatusLevelRing
 *
 * The tail fade is achieved by splitting the arc into two segments. The end
 * segment has a linear gradient applied to cause the fade out effect.
 *
 */
import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components/macro';
import { Span } from '@eriksdigital/atomic-ui/components';
import { LinearGradient } from '@visx/gradient';

import { Severity } from 'types';
import { describeArc } from 'utils/svg-utils';
import { clamp } from 'utils/math-utils';
import { getWarningLevel } from 'utils/severity';
import { getColorForSeverity } from 'utils/getColor';

interface Props {
  size: number;
  score: number;
  textSize?: string;
  className?: string;
}

/* Hand determined the rotation for the gradients in order
 * to get them to appear correctly */
const gradiantRotationMap = new Map<number, number>();
gradiantRotationMap.set(1, -80);
gradiantRotationMap.set(2, -45);
gradiantRotationMap.set(3, 0);
gradiantRotationMap.set(4, 25);
gradiantRotationMap.set(5, -90);
gradiantRotationMap.set(6, -70);
gradiantRotationMap.set(7, -30);
gradiantRotationMap.set(8, 0);
gradiantRotationMap.set(9, 30);
gradiantRotationMap.set(10, 80);

export const StatusLevelRing = ({
  score,
  size,
  textSize,
  className,
}: Props) => {
  const theme = useContext(ThemeContext);
  const clampedScore = clamp(score, 0, 10);
  if (isNaN(clampedScore)) return null;

  const type = getWarningLevel(score);

  const backgroundRingColor: string = '#eceef1';
  const signalColor: string = getColorForSeverity(type, theme);
  const endAngle: number = (clampedScore / 10) * 360;
  const tailAngleLength: number = 30; // number of degrees of rotation to allocate for tail fade
  const gradientId: string = `tailFade_${type}_${clampedScore}`; // Sets a unique id for a given score/status so the grad ids don't clash
  const svgCenterX: number = 14;
  const svgCenterY: number = 14.5;
  const ringRadius: number = 12;
  const ringThickness: number = 3;

  return (
    <StatusLevelRingContainer className={className}>
      <CountText $type={type} $textSize={textSize}>
        {clampedScore}
      </CountText>
      <SvgContainer
        width={size}
        height={size}
        viewBox="0 0 28 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <LinearGradient
          id={gradientId}
          from={clampedScore < 5 ? signalColor : backgroundRingColor}
          to={clampedScore < 5 ? backgroundRingColor : signalColor}
          rotate={gradiantRotationMap.get(clampedScore)}
        />
        <circle
          cx={svgCenterX}
          cy={svgCenterY}
          r={ringRadius}
          stroke={backgroundRingColor}
          strokeWidth={ringThickness}
        />
        {clampedScore > 0 && (
          <>
            <path
              d={describeArc(
                svgCenterX,
                svgCenterY,
                ringRadius,
                0,
                clampedScore < 10 ? endAngle - tailAngleLength : endAngle - 1,
              )}
              stroke={signalColor}
              strokeWidth={ringThickness}
              strokeLinecap="round"
            />
            {clampedScore < 10 && (
              <path
                d={describeArc(
                  svgCenterX,
                  svgCenterY,
                  ringRadius,
                  endAngle - tailAngleLength,
                  endAngle,
                )}
                strokeWidth={ringThickness}
                stroke={`url('#${gradientId}')`}
              />
            )}
          </>
        )}
      </SvgContainer>
    </StatusLevelRingContainer>
  );
};

const StatusLevelRingContainer = styled.div`
  position: relative;
  text-align: center;
`;
const SvgContainer = styled.svg`
  position: relative;
  z-index: 0;
`;
const CountText = styled(Span)<{ $type: Severity; $textSize: string }>`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -45%);
  font-weight: bold;
  font-size: ${({ theme, $textSize }) =>
    $textSize || theme.fonts.fontSize.ft14};
  color: ${({ theme, $type }) => getColorForSeverity($type, theme)};
`;
