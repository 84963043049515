import { PayloadAction } from '@reduxjs/toolkit';
import { compareAsc } from 'date-fns';

import { createSlice } from 'utils/@reduxjs/toolkit';
import {
  ContainerState,
  EquipmentDetailsPostResponse,
  WorkOrdersActionPayload,
} from './types';
import { DataTableSort } from 'app/components/DataTable/types';
import {
  AggregatedConditionScore,
  AggregatedSensorAlert,
  Alert,
  TaskReq,
  Task,
  TaskTypes,
  MaintainableItem,
} from 'types';
import { DateRangeValue, getRangeValue } from './components/DateRangeSelector';

// The initial state of the Equipment Details container
export const initialState: ContainerState = {
  miId: undefined,
  taskTypes: TaskTypes.WORK_ORDER,
  isLoading: false,
  isLoadingStatus: false,
  error: '',
  isPoolingEquipment: false,

  workOrders: {
    planned: {
      data: undefined,
      loading: false,
      error: undefined,
      sort: undefined,
      pageInfo: {
        pageSize: 10,
        page: 1,
        pageCount: undefined,
        totalRecords: undefined,
      },
    },
    previous: {
      data: undefined,
      loading: false,
      error: undefined,
      sort: undefined,
      pageInfo: {
        pageSize: 10,
        page: 1,
        pageCount: undefined,
        totalRecords: undefined,
      },
    },
  },
  alerts: {
    dateRange: getRangeValue('7Days'),
    onlyActive: true,
    isInit: false,
    isLoading: false,
    data: [],
    error: undefined,
  },
};

const equipmentSlice = createSlice({
  name: 'equipmentDetails',
  initialState,
  reducers: {
    getEquipment(state, action: PayloadAction<string>) {
      return {
        ...state,
        miId: action.payload,
        isLoading: true,
        error: initialState.error,
      };
    },
    getEquipmentSuccess(state, action: PayloadAction<MaintainableItem>) {
      return {
        ...state,
        equipment: action.payload,
        isLoading: initialState.isLoading,
        error: initialState.error,
      };
    },
    getEquipmentError(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoading: initialState.isLoading,
        error: action.payload,
      };
    },

    getEquipmentStatus(state, action: PayloadAction<string>) {
      return {
        ...state,
        miId: action.payload,
        isLoadingStatus: true,
        error: initialState.error,
        alerts: { ...initialState.alerts },
      };
    },
    getEquipmentStatusSuccess(
      state,
      action: PayloadAction<{
        condition: AggregatedConditionScore;
        status: AggregatedSensorAlert;
      }>,
    ) {
      const { condition, status } = action.payload;

      return {
        ...state,
        condition,
        status,
        error: initialState.error,
        isLoadingStatus: initialState.isLoadingStatus,
      };
    },
    getEquipmentStatusError(state, action: PayloadAction<string>) {
      return {
        ...state,
        isLoadingStatus: initialState.isLoadingStatus,
        error: action.payload,
      };
    },

    startPoolingEquipment(state) {
      return {
        ...state,
        isPoolingEquipment: true,
      };
    },
    stopPoolingEquipment(state) {
      return {
        ...state,
        isPoolingEquipment: false,
      };
    },
    getPlannedWorkOrders(
      state,
      action: PayloadAction<WorkOrdersActionPayload>,
    ) {
      return {
        ...state,
        miId: action.payload.miId,
        taskTypes: action.payload.taskTypes,
        workOrders: {
          ...state.workOrders,
          planned: {
            ...initialState.workOrders.planned,
            loading: true,
          },
        },
      };
    },
    getPreviousWorkOrders(
      state,
      action: PayloadAction<WorkOrdersActionPayload>,
    ) {
      return {
        ...state,
        miId: action.payload.miId,
        taskTypes: action.payload.taskTypes,
        workOrders: {
          ...state.workOrders,
          previous: {
            ...initialState.workOrders.previous,
            loading: true,
          },
        },
      };
    },

    getPlannedWorkOrdersSuccess(
      state,
      action: PayloadAction<EquipmentDetailsPostResponse>,
    ) {
      return {
        ...state,
        workOrders: {
          ...state.workOrders,
          planned: {
            ...state.workOrders.planned,
            data: action.payload.content,
            loading: initialState.workOrders.planned.loading,
            error: initialState.workOrders.planned.error,
            pageInfo: {
              ...state.workOrders.planned.pageInfo,
              pageSize: action.payload.size,
              pageCount: action.payload.totalPages,
              totalRecords: action.payload.numberOfElements,
            },
          },
        },
      };
    },
    getPreviousWorkOrdersSuccess(
      state,
      action: PayloadAction<EquipmentDetailsPostResponse>,
    ) {
      return {
        ...state,
        workOrders: {
          ...state.workOrders,
          previous: {
            ...state.workOrders.previous,
            data: action.payload.content,
            loading: initialState.workOrders.previous.loading,
            error: initialState.workOrders.previous.error,
            pageInfo: {
              ...state.workOrders.previous.pageInfo,
              pageSize: action.payload.size,
              pageCount: action.payload.totalPages,
              totalRecords: action.payload.numberOfElements,
            },
          },
        },
      };
    },
    getWorkOrdersPlannedError(state, action: PayloadAction<string>) {
      return {
        ...state,
        workOrders: {
          ...state.workOrders,
          planned: {
            ...initialState.workOrders.planned,
            error: action.payload,
          },
        },
      };
    },
    getWorkOrdersPreviousError(state, action: PayloadAction<string>) {
      return {
        ...state,
        workOrders: {
          ...state.workOrders,
          previous: {
            ...initialState.workOrders.previous,
            error: action.payload,
          },
        },
      };
    },
    setPlannedSort(state, action: PayloadAction<DataTableSort>) {
      return {
        ...state,
        workOrders: {
          ...state.workOrders,
          planned: {
            ...state.workOrders.planned,
            sort: action.payload,
            loading: true,
          },
        },
      };
    },
    setPreviousSort(state, action: PayloadAction<DataTableSort>) {
      return {
        ...state,
        workOrders: {
          ...state.workOrders,
          previous: {
            ...state.workOrders.previous,
            sort: action.payload,
            loading: true,
          },
        },
      };
    },
    setPlannedPageInfo(
      state,
      action: PayloadAction<{
        page: number;
        pageSize: number;
      }>,
    ) {
      return {
        ...state,
        workOrders: {
          ...state.workOrders,
          planned: {
            ...state.workOrders.planned,
            loading: true,
            pageInfo: {
              ...state.workOrders.planned.pageInfo,
              page: action.payload.page,
              pageSize: action.payload.pageSize,
            },
          },
        },
      };
    },
    setPreviousPageInfo(
      state,
      action: PayloadAction<{
        page: number;
        pageSize: number;
      }>,
    ) {
      return {
        ...state,
        workOrders: {
          ...state.workOrders,
          previous: {
            ...state.workOrders.previous,
            loading: true,
            pageInfo: {
              ...state.workOrders.previous.pageInfo,
              page: action.payload.page,
              pageSize: action.payload.pageSize,
            },
          },
        },
      };
    },
    setAlertsDateRange(state, action: PayloadAction<DateRangeValue>) {
      return {
        ...state,
        alerts: {
          ...state.alerts,
          dateRange: action.payload,
        },
      };
    },
    setAlertsOnlyActive(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        alerts: {
          ...state.alerts,
          onlyActive: action.payload,
        },
      };
    },
    getAlerts(state, action: PayloadAction<string>) {
      return {
        ...state,
        alerts: {
          ...state.alerts,
          isLoading: true,
          isInit: true,
        },
      };
    },
    getAlertsSuccess(state, action: PayloadAction<Alert[]>) {
      return {
        ...state,
        alerts: {
          ...state.alerts,
          isLoading: false,
          error: undefined,
          data: action.payload,
        },
      };
    },
    getAlertsError(state, action: PayloadAction<string>) {
      return {
        ...state,
        alerts: {
          ...state.alerts,
          isLoading: false,
          error: action.payload,
        },
      };
    },
    createTask(state, action: PayloadAction<TaskReq>) {
      return {
        ...state,
      };
    },
    createTaskSuccess(state, action: PayloadAction<Task>) {
      const tasks = [
        action.payload,
        ...(state.workOrders.planned.data || []),
      ].sort((a, b) =>
        compareAsc(new Date(b.createdDate), new Date(a.createdDate)),
      );

      const totalRecords =
        (state.workOrders.planned.pageInfo.totalRecords || 0) + 1;
      const pageCount = state.workOrders.planned.pageInfo.pageCount || 1;

      return {
        ...state,
        workOrders: {
          ...state.workOrders,
          planned: {
            ...state.workOrders.planned,
            data: tasks,
            pageInfo: {
              ...state.workOrders.planned.pageInfo,
              totalRecords,
              pageCount,
            },
          },
        },
      };
    },
  },
});

export const { actions, reducer, name: sliceKey } = equipmentSlice;
