import { put, PutEffect } from 'redux-saga/effects';
import { AxiosError } from 'axios';

import { translationString } from 'locales/translation';
import { actions as announcementActions } from 'app/containers/AnnouncementsToast/slice';

/* Axios Error Typeguard */
export function isAxiosError(error: any): error is AxiosError {
  return (error as AxiosError).isAxiosError !== undefined;
}

/**
 * If an error is an axios error, check for an error message in the response.
 * Otherwise return the fallback message. If it's not an axios message, return
 * a generic message and console out the error object's message.
 * @param err {error} - Error object to process
 * @param fallbackMessage {string} - Message to display if the API does not return a message
 * @param genericMessage {string} - Message to display if a non-api related error occurs
 */
export function parseAxiosError(
  err: Error | AxiosError | any,
  fallbackMessage: string,
): string {
  if (isAxiosError(err)) {
    return err?.response?.data?.errorMessages?.[0].message || fallbackMessage;
  }

  return translationString('ErrorMessage.GenericAppError');
}

/**
 * Generator function for processing errors caught in redux saga effects.
 * @param err {error} - Error object to process
 * @param fallbackMessage {string} - Message to display if the API does not return a message
 * @param genericMessage {string} - Message to display if a non-api related error occurs
 */
export function* notifyAxiosError(
  err: Error | AxiosError | any,
  fallbackMessage: string,
): Generator<PutEffect<{ payload: string; type: string }>, string, unknown> {
  const displayMessage: string = parseAxiosError(err, fallbackMessage);

  yield put(announcementActions.addError(displayMessage));
  return displayMessage;
}
