import { AssetStatusFilter } from '../types';
import { HierarchyNode } from 'types';

export const applyAssetFilters = (
  assets: HierarchyNode[],
  statusFilters: AssetStatusFilter[],
  searchFilter: string,
  assetIdFilter: string[],
): HierarchyNode[] => {
  let filteredAssets = [...assets];
  // Filter on condition status
  if (!statusFilters.includes('all')) {
    filteredAssets = filteredAssets.filter(
      d =>
        d.severity && statusFilters.includes(d.severity as AssetStatusFilter),
    );
  }

  // Filter on input text
  if (searchFilter.length > 0) {
    const compareValue = searchFilter.trim().toLowerCase();
    filteredAssets = filteredAssets.filter(
      d =>
        d.name.toLowerCase().indexOf(compareValue) >= 0 ||
        (d.description || '').toLowerCase().indexOf(compareValue) >= 0 ||
        (d.positionNumber || '').toLowerCase().indexOf(compareValue) >= 0,
    );
  }

  // Filter on ID match
  if (assetIdFilter.length) {
    filteredAssets = filteredAssets.filter(d => assetIdFilter.includes(d.id));
  }

  return filteredAssets;
};

export const assetSort = (a, b, sortField, sortDirection) => {
  const aValue: string | number = a[sortField] || '';
  const bValue: string | number = b[sortField] || '';
  if (aValue === '') {
    return sortDirection * -1;
  }
  if (bValue === '') {
    return sortDirection;
  }

  // Strings
  if (typeof aValue === 'string' && typeof bValue === 'string') {
    if (aValue.toLowerCase() < bValue.toLowerCase()) return sortDirection;
    if (aValue.toLowerCase() > bValue.toLowerCase()) return sortDirection * -1;
    return 0;
  }

  if (aValue < bValue) return sortDirection;
  if (aValue > bValue) return sortDirection * -1;
  return 0;
};
