import { createAbsoluteUrl, apiGet, apiPut, apiPost } from './utils/apiUtils';
import * as RESOURCE_PATHS from './resourcePaths';
import {
  ReplaceSensorObject,
  Sensor,
  SensorDevice,
  SensorOrientation,
  SensorType,
  SensorProperty,
  ConfiguredPack,
  PackProperty,
  PackMainConfiguration,
  Pack,
  SensorProperties,
  ThresholdRequestObject,
} from 'types';

const API_BASE_URL: string = process.env.REACT_APP_API_SENSOR_HOSTNAME || '';
const DEFAULT_SENSOR_TYPE = 'BV';

export const getPropertiesMetadata = async (): Promise<any> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.PROPERTIES_METADATA,
    ),
  );
};

export const getSensorValidProperties = async (
  sensorId: string,
  sensorType: string = DEFAULT_SENSOR_TYPE,
): Promise<any> => {
  const params = new URLSearchParams({ sensorId });

  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.SENSORS_PROPERTIES,
      params,
    ).replace(/%type/, sensorType),
  );
};

export const getSensorsValues = async (
  sensorId: string,
  properties: string[],
  startTime: string,
  endTime: string,
  onValues = 'false',
  aggregationMode?: string,
): Promise<SensorProperty[] | undefined> => {
  if (!properties.length) return;

  const params = aggregationMode
    ? new URLSearchParams({ endTime, startTime, onValues, aggregationMode })
    : new URLSearchParams({ endTime, startTime, onValues });

  properties.forEach(property => params.append('properties', property));

  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.SENSOR_VALUES.replace(/%id/, sensorId),
      params,
    ),
  );
};

export const getSensorProperties = async (
  sensorId: string,
): Promise<{ data: SensorProperties }> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.SENSOR_PROPERTIES.replace(/%sensorId/, sensorId),
    ),
  );
};

export const getLocationActiveSensors = async (
  locationId: string,
): Promise<Sensor[]> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.LOCATION_ACTIVE_SENSORS.replace(/%id/, locationId),
    ),
  );
};

export const getAssetActiveSensors = async (subUnitId: string) => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.ASSET_ACTIVE_SENSORS,
    ).replace(/%id/, subUnitId),
  );
};

export const getMIActiveSensors = async (
  maintainableItemId: string,
): Promise<{ data: Sensor[] }> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.MAINTAINABLE_ITEM_ACTIVE_SENSORS,
    ).replace(/%id/, maintainableItemId),
  );
};

export const getUnlinkedSensorsForLocation = async (
  locationId: string,
  sensorType: string = DEFAULT_SENSOR_TYPE,
) => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      // Temporary endpoint for Net-biter is different, needs to be normalized
      sensorType === 'NETBITER'
        ? RESOURCE_PATHS.PROVIDERS_LOCATION_UNLINKED
        : RESOURCE_PATHS.SENSORS_LOCATION_UNLINKED,
    )
      .replace(/%id/, locationId)
      .replace(/%type/, sensorType),
  );
};

export const getUnlinkedSensorProjects = async (
  sensorType: string = DEFAULT_SENSOR_TYPE,
) => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      // Temporary endpoint for Net-biter is different, needs to be normalized
      sensorType === 'NETBITER'
        ? RESOURCE_PATHS.PROVIDERS_PROJECTS_UNLINKED
        : RESOURCE_PATHS.SENSORS_PROJECTS_UNLINKED,
    ).replace(/%type/, sensorType),
  );
};

export const getSensorProjectLinkedToLocation = async (
  locationId: string,
  sensorType: string = DEFAULT_SENSOR_TYPE,
) => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      // Temporary endpoint for Net-biter is different, needs to be normalized
      sensorType === 'NETBITER'
        ? RESOURCE_PATHS.PROVIDERS_PROJECTS_LINKED_TO_LOCATION
        : RESOURCE_PATHS.SENSORS_PROJECTS_LINKED_TO_LOCATION,
    )
      .replace(/%id/, locationId)
      .replace(/%type/, sensorType),
  );
};

export const linkSensorProjectToLocation = async (
  locationId: string,
  projectId: number,
  sensorType: string = DEFAULT_SENSOR_TYPE,
) => {
  return apiPost(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      // Temporary endpoint for Net-biter is different, needs to be normalized
      sensorType === 'NETBITER'
        ? RESOURCE_PATHS.PROVIDERS_PROJECTS_LINKED_TO_LOCATION
        : RESOURCE_PATHS.SENSORS_PROJECTS_LINKED_TO_LOCATION,
    )
      .replace(/%id/, locationId)
      .replace(/%type/, sensorType),
    { projectId },
  );
};

export const postActivateNewSensor = async (
  maintainableItemId: string,
  sensorDevice: SensorDevice,
  orientation: SensorOrientation,
  linkedDate: string,
  sensorType: string = DEFAULT_SENSOR_TYPE,
) => {
  return apiPost(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      // Temporary endpoint for Net-biter is different, needs to be normalized
      sensorType === 'NETBITER'
        ? RESOURCE_PATHS.PROVIDERS_ACTIVATE_DEVICE
        : RESOURCE_PATHS.SENSORS_ACTIVATE_DEVICE,
    )
      .replace(/%id/, maintainableItemId)
      .replace(/%type/, sensorType),
    {
      deviceId: sensorDevice.deviceId,
      deviceName: sensorDevice.deviceName,
      linkStartTimestamp: linkedDate,
      orientationX: orientation.orientationX,
      orientationY: orientation.orientationY,
      orientationZ: orientation.orientationZ,
      provider: sensorType === SensorType.NETBITER ? 'NETBITER' : undefined,
    },
  );
};

export const unlinkSensorProjectFromLocation = async (
  locationId: string,
  projectId: number,
  sensorType: string = DEFAULT_SENSOR_TYPE,
) => {
  return apiPut(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      sensorType === 'NETBITER'
        ? RESOURCE_PATHS.PROVIDERS_PROJECT_UNLINKED_FROM_LOCATION
        : RESOURCE_PATHS.SENSORS_PROJECT_UNLINKED_FROM_LOCATION,
    )
      .replace(/%id/, locationId)
      .replace(/%type/, sensorType)
      .replace(/%projectId/, projectId.toString()),
    [''],
  );
};

export const putDeactivateSensor = async (sensorId: string) => {
  return apiPut(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.SENSORS_DEACTIVATE_DEVICE,
    ).replace(/%id/, sensorId),
    {},
  );
};

export const putUpdateSensorOrientation = async (
  sensorId: string,
  orientation: SensorOrientation,
) => {
  return apiPut(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.SENSORS_UPDATE_SENSOR_ORIENTATION,
    ).replace(/%id/, sensorId),
    {
      orientationX: orientation.orientationX,
      orientationY: orientation.orientationY,
      orientationZ: orientation.orientationZ,
    },
  );
};

export const postReplaceSensorDevice = async (
  sensorId: string,
  newSensorId: string,
  newSensorName: string,
  newSensorOrientation: SensorOrientation,
  replacementTime?: string,
): Promise<{ data: { sensorId: string } }> => {
  let postData: ReplaceSensorObject = {
    orientationX: newSensorOrientation.orientationX,
    orientationY: newSensorOrientation.orientationY,
    orientationZ: newSensorOrientation.orientationZ,
    replacementDeviceId: newSensorId,
    replacementDeviceName: newSensorName,
  };
  if (replacementTime) {
    postData.replacementTimestamp = replacementTime;
  }
  return apiPost(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.SENSORS_REPLACE_DEVICE,
    ).replace(/%sensorId/, sensorId),
    postData,
  );
};

export const postThreshold = async (
  sensorId: string,
  propertyId: string,
  threshold: ThresholdRequestObject,
) => {
  return apiPost(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.SENSOR_POST_THRESHOLD,
    )
      .replace(/%sensorId/, sensorId)
      .replace(/%propertyId/, propertyId),
    threshold,
  );
};

export const deactivateThreshold = async (thresholdId: string) => {
  return apiPut(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.SENSOR_DEACTIVATE_THRESHOLD,
    ).replace(/%id/, thresholdId),
    {},
  );
};

export const getPacksMetadata = async (): Promise<{
  data: { configurations: PackMainConfiguration[] };
}> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.PACKS_METADATA,
    ),
  );
};

export const getSubUnitActivePacks = async (
  subUnit: string,
): Promise<{ data: ConfiguredPack[] }> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.SUB_UNIT_ACTIVE_PACKS,
    ).replace(/%id/, subUnit),
  );
};

export const getSubUnitPacks = async (
  subUnit: string,
): Promise<{ data: Pack[] }> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.SUB_UNIT_PACKS,
    ).replace(/%id/, subUnit),
  );
};

export const postSubUnitPack = async (
  subUnitId: string,
  properties: PackProperty,
): Promise<any> => {
  return apiPost(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.SUB_UNIT_PACKS,
    ).replace(/%id/, subUnitId),
    properties,
  );
};

export const getPackSensors = async (packId: string): Promise<any> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.PACK_SENSORS,
    ).replace(/%id/, packId),
  );
};

export const getPackValues = async (
  packId: string,
  startTime: string,
  endTime: string,
  onValues: string,
  aggregationMode: string,
): Promise<any> => {
  const params = new URLSearchParams({
    endTime,
    startTime,
    onValues,
    aggregationMode,
  });

  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.PACK_VALUES.replace(/%id/, packId),
      params,
    ),
  );
};
