/**
 *
 * HeadwayWidget
 *
 */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import { ReactComponent as ReleaseNote } from 'assets/icons/release_note.svg';

interface Props {}

export function HeadwayWidget(props: Props) {
  const [unseenCount, setUnseenCount] = useState<number>(0);
  const hasUnseen = unseenCount > 0;

  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://cdn.headwayapp.co/widget.js';
    document.head.appendChild(script);

    var config = {
      selector: '.headway-badge',
      account: 'JmvGNy',
      callbacks: {
        onWidgetReady: function (widget) {
          setUnseenCount(widget.getUnseenCount());
        },
      },
    };

    script.onload = function () {
      //@ts-ignore
      window.Headway.init(config);
    };
  }, []);

  return (
    <LayoutWrapper className="headway-badge" hasUnseen={hasUnseen}>
      <ReleaseNote fill="currentColor" />
      {hasUnseen ? unseenCount : null}
    </LayoutWrapper>
  );
}

interface LayoutWrapperProps {
  hasUnseen: boolean;
}

const LayoutWrapper = styled.div<LayoutWrapperProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.sp4};
  width: auto;
  min-width: ${({ theme }) => theme.sizes.sz32};
  height: ${({ theme }) => theme.sizes.sz32};
  border-radius: ${({ theme }) => theme.sizes.sz32};
  font-size: ${({ theme }) => theme.fonts.fontSize.ft12};
  background-color: ${({ theme, hasUnseen }) =>
    hasUnseen ? theme.colors.default.red : theme.colors.default.blue1};
  color: ${({ theme }) => theme.colors.text.white};
  padding: 0 ${({ theme }) => theme.spacing.sp8};
  transition: all 300ms;

  &:hover {
    background-color: ${({ theme }) => theme.colors.default.blue2};
    cursor: pointer;
  }

  svg {
    width: ${({ theme }) => theme.sizes.sz16};
    height: ${({ theme }) => theme.sizes.sz16};
  }

  #HW_badge_cont {
    position: absolute;
  }

  #HW_badge {
    display: none;
  }
`;
