import { css } from 'styled-components';

export const headingStyles = css`
  font-weight: ${({ theme }) => theme.fonts.fontWeight.medium};
  line-height: ${({ theme }) => theme.fonts.lineHeight.heading};
  color: ${({ theme }) => theme.colors.default.blue1};
  margin: 0;
  padding: 0;
`;

export const label = css`
  font-weight: ${({ theme }) => theme.fonts.fontWeight.medium};
  font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
  line-height: ${({ theme }) => theme.fonts.lineHeight.text};
  color: ${({ theme }) => theme.colors.text.tertiary};
`;

export const inputBorder = css`
  background-color: ${({ theme }) => theme.colors.background.white};
  border: ${({ theme }) => `1px solid ${theme.colors.grays.midGray}`};
  border-radius: 3px;
`;

export const styledIcon = css`
  cursor: pointer;
  width: 20px;
  height: 20px;
`;
