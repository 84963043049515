import React, { ReactElement } from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { Icon } from '@eriksdigital/atomic-ui/components';
import {
  SideArrowIcon,
  CrossIcon,
} from '@eriksdigital/atomic-ui/components/Icons';
import { formatRelative } from 'date-fns';

import t, { translationString } from 'locales/translation';
import { pxToRem } from 'utils/style-utils';

interface Props {
  id: string;
  title: string | ReactElement;
  message: string;
  parameters?: Record<string, string>;
  messageDate: Date;
  actionRoute: string;
  unread?: boolean;
  onMarkRead: Function;
  locationName?: string;
}

export const NotificationItem = ({
  id,
  title,
  message,
  parameters,
  messageDate,
  actionRoute,
  unread = false,
  onMarkRead,
  locationName,
}: Props) => {
  return (
    <ItemWrapper id={id} data-testid={`notification_${id}`}>
      {unread && <UnreadMarker />}
      <Details>
        <h3>{title}</h3>

        <p>{t(`Notifications.${message}`, parameters)}</p>

        <ActionLink
          id={`notification_action_${id}`}
          data-testid={`notification_action_${id}`}
          to={actionRoute}
          onClick={() => onMarkRead(true)}
        >
          {t('NotificationsPanel.ItemActionButton')}{' '}
          <Icon as={SideArrowIcon} size="sz16" />
        </ActionLink>
        <DateDisplay>
          {formatRelative(messageDate, new Date())}{' '}
          {locationName && `(${locationName})`}
        </DateDisplay>
        {unread && (
          <ReadButton
            data-stringid="NotificationsPanel.MarkAsRead"
            onClick={() => onMarkRead()}
            title={translationString('NotificationsPanel.MarkAsRead')}
            data-testid={`notification_markread_${id}`}
          >
            <Icon as={CrossIcon} size="sz16" />
          </ReadButton>
        )}
      </Details>
    </ItemWrapper>
  );
};

const ReadButton = styled.button`
  position: absolute;
  top: ${({ theme }) => theme.spacing.sp8};
  right: ${({ theme }) => theme.spacing.sp40};
  border-width: 0;
  background: none;
  display: none;
  cursor: pointer;
`;

const ItemWrapper = styled.li`
  position: relative;
  padding: ${({ theme }) => theme.spacing.sp4}
    ${({ theme }) => theme.spacing.sp40} 0 ${pxToRem(80)};

  &:hover {
    background-color: ${({ theme }) => theme.colors.background.lightGray};
    ${ReadButton} {
      display: block;
    }
  }
`;

const UnreadMarker = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing.sp8};
  left: ${({ theme }) => theme.spacing.sp40};
  width: ${({ theme }) => theme.sizes.sz16};
  height: ${({ theme }) => theme.sizes.sz16};
  background: ${({ theme }) => theme.colors.background.primary};
  border-radius: 100px;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h3 {
    font-size: ${({ theme }) => theme.fonts.fontSize.ft16};
    color: ${({ theme }) => theme.colors.text.secondary};
    margin: 0;
  }

  p {
    font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
    margin: ${({ theme }) => theme.spacing.sp8} 0;
  }

  button {
    font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
  }
`;

const ActionLink = styled(Link)`
  font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
  color: ${({ theme }) => theme.colors.text.secondary};
  text-decoration: none;
`;

const DateDisplay = styled.p`
  color: ${({ theme }) => theme.colors.text.midGray};
`;
