import { keyframes } from 'styled-components/macro';
import { breakpointObj as breakpoints } from '@eriksdigital/atomic-ui/styles';

export const pxToRem = (pixels: number) => `${pixels / 16}rem`;

export const hexToRGB = (hex, alpha): string => {
  const isShortHex = hex.length < 6;
  let r = isShortHex
    ? parseInt(hex.slice(1, 1), 16)
    : parseInt(hex.slice(1, 3), 16);
  let g = isShortHex
    ? parseInt(hex.slice(2, 1), 16)
    : parseInt(hex.slice(3, 5), 16);
  let b = isShortHex
    ? parseInt(hex.slice(3, 1), 16)
    : parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
  }
};

export const keyframeFadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1
  }
`;

export const fadeSlideInFromLeft = keyframes`
0% {
  opacity: 0;
  transform: translateX(-10%);
}
100% {
  opacity: 1;
  transform: translateX(0);
}
`;

export const slideInFromRight = keyframes`
{
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
`;

const customMediaQuery = (minWidth: number) =>
  `@media (min-width: ${minWidth}px)`;

export const mediaQuery = {
  custom: customMediaQuery,
  phoneLarge: customMediaQuery(breakpoints.phoneLarge),
  tabletPortrait: customMediaQuery(breakpoints.tabletPortrait),
  tabletIpadPortrait: customMediaQuery(breakpoints.tabletIpadPortrait),
  tabletLandscape: customMediaQuery(breakpoints.tabletLandscape),
  desktop: customMediaQuery(breakpoints.desktop),
  giant: customMediaQuery(breakpoints.giant),
};

const customMediaQueryMax = (maxWidth: number) =>
  `@media (max-width: ${maxWidth}px)`;

export const mediaQueryMax = {
  custom: customMediaQueryMax,
  phoneLarge: customMediaQueryMax(breakpoints.phoneLarge),
  tabletPortrait: customMediaQueryMax(breakpoints.tabletPortrait),
  tabletIpadPortrait: customMediaQueryMax(breakpoints.tabletIpadPortrait),
  tabletLandscape: customMediaQueryMax(breakpoints.tabletLandscape),
  desktop: customMediaQueryMax(breakpoints.desktop),
  giant: customMediaQueryMax(breakpoints.giant),
};

const customMatchMedia = (minWidth: number) =>
  window.matchMedia(`(min-width: ${minWidth}px)`);

export const matchMedia = {
  custom: customMatchMedia,
  phoneLarge: customMatchMedia(breakpoints.phoneLarge),
  tabletPortrait: customMatchMedia(breakpoints.tabletPortrait),
  tabletIpadPortrait: customMatchMedia(breakpoints.tabletIpadPortrait),
  tabletLandscape: customMatchMedia(breakpoints.tabletLandscape),
  desktop: customMatchMedia(breakpoints.desktop),
  giant: customMatchMedia(breakpoints.giant),
};
