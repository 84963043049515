/**
 *
 * CustomerEditPanel
 *
 */
import React, { useState, useEffect } from 'react';
import { H1, H2, P, Button } from '@eriksdigital/atomic-ui/components';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import t from 'locales/translation';
import useTrackPageview from 'utils/hooks/useTrackPageview';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { CreateCustomerObject, UpdateCustomerObject } from 'types';
import { Modal } from 'app/components/Modal';
import { CustomerInfoForm } from 'app/components/Admin/CustomerInfoForm';
import { Card } from 'app/components/Card';
import { Controls } from 'app/components/Controls';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import {
  selectSelectedCustomerMeta,
  selectCustomerUpdateLoading,
} from 'app/containers/CustomerLocation/selectors';
import { actions as customerActions } from 'app/containers/CustomerLocation/slice';
import { useCustomerLocation } from 'app/containers/CustomerLocation';
import {
  actions,
  reducer,
  sliceKey,
} from 'app/containers/CustomerManagement/slice';
import {
  selectCountryOptions,
  selectContractPhaseOptions,
  selectTechnicalUsersOptions,
  selectCustomerManagement,
} from 'app/containers/CustomerManagement/selectors';
import { customerManagementSaga } from 'app/containers/CustomerManagement/saga';

interface FormFields {
  id?: string;
  name: string;
  country?: { label: string; value: string };
  customerId?: string;
  technicalContact?: string;
  contractStartDate?: Date;
  contractEndDate?: Date;
  contractInactivationDate?: Date;
  contractPhase?: { label: string; value: string };
}

const mapFieldsToMutationObject = (
  fields: FormFields,
  targetObject: UpdateCustomerObject | CreateCustomerObject,
): UpdateCustomerObject | CreateCustomerObject => {
  if (fields?.country) targetObject.country = fields.country.value;
  if (fields?.customerId) targetObject.customerId = fields.customerId;
  if (fields?.technicalContact)
    targetObject.technicalContact = fields.technicalContact;
  if (fields?.contractStartDate)
    targetObject.contractStartDate = fields.contractStartDate.toISOString();
  if (fields?.contractEndDate)
    targetObject.contractEndDate = fields.contractEndDate.toISOString();
  if (fields?.contractInactivationDate)
    targetObject.contractInactivationDate = fields.contractInactivationDate.toISOString();
  if (fields?.contractPhase)
    targetObject.contractPhase = fields.contractPhase.value;
  return targetObject;
};

export const CustomerInfo = () => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: customerManagementSaga });

  useCustomerLocation({
    hasLocationSelector: false,
  });

  const dispatch = useDispatch();
  useTrackPageview();

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const { customerId } = useParams() as {
    customerId: string;
  };
  const customer = useSelector(selectSelectedCustomerMeta);
  const customerUpdateLoading = useSelector(selectCustomerUpdateLoading);
  const countryOptions = useSelector(selectCountryOptions);
  const contractPhaseOptions = useSelector(selectContractPhaseOptions);
  const technicalUsersOptions = useSelector(selectTechnicalUsersOptions);
  const {
    countriesLoading,
    contractPhasesLoading,
    technicalUsersLoading,
  } = useSelector(selectCustomerManagement);

  useEffect(() => {
    dispatch(actions.getCountries());
    dispatch(actions.getContractPhases());
    dispatch(actions.getTechnicalUsers());
  }, [dispatch]);

  const handleUpdate = ({
    id,
    formFields,
    logo,
  }: {
    id: string;
    formFields: FormFields;
    logo: File;
  }) => {
    let updateObject: UpdateCustomerObject = { name: formFields.name };
    updateObject = mapFieldsToMutationObject(formFields, updateObject);
    dispatch(customerActions.putCustomer({ id, updateObject, logo }));
  };

  const handleNew = ({
    formFields,
    logo,
  }: {
    formFields: FormFields;
    logo: File;
  }) => {
    let createObject: CreateCustomerObject = { name: formFields.name };
    createObject = mapFieldsToMutationObject(formFields, createObject);
    dispatch(customerActions.postNewCustomer({ customer: createObject, logo }));
  };

  const handleDelete = id => {
    try {
      dispatch(customerActions.deleteCustomer(id));
      setDeleteModalOpen(false);
    } catch (e) {}
  };

  return (
    <>
      <H1>
        {t(customerId ? 'Customer.EditCustomer' : 'Customer.NewCustomer')}
      </H1>
      {customerUpdateLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Card>
            <CustomerInfoForm
              customer={customerId ? customer : undefined}
              onSubmit={customerId ? handleUpdate : handleNew}
              countriesLoading={countriesLoading}
              contractPhasesLoading={contractPhasesLoading}
              technicalContactsLoading={technicalUsersLoading}
              countryOptions={countryOptions}
              contractPhaseOptions={contractPhaseOptions}
              technicalContactsOptions={technicalUsersOptions}
            />
          </Card>

          {customerId && customer && (
            <PositionCard>
              <SubHeading>{t('Customer.DeleteCustomerHeading')}</SubHeading>
              <DeleteControlsContainer>
                <P>{t('Customer.DeleteCustomerInstructions')}</P>
                <Button
                  id="open_delete_modal_button"
                  stretch="auto"
                  onClick={() => setDeleteModalOpen(true)}
                >
                  {t('Delete')}
                </Button>
              </DeleteControlsContainer>
            </PositionCard>
          )}

          {deleteModalOpen && (
            <Modal
              onClose={() => setDeleteModalOpen(false)}
              withOutsideClose={true}
            >
              <SubHeading>{t('Customer.DeleteCustomerHeading')}</SubHeading>
              <P>{t('Customer.DeleteCustomerConfirm')}</P>
              <Controls>
                <Button
                  id="delete_button"
                  type="submit"
                  stretch="auto"
                  onClick={() => handleDelete(customerId)}
                >
                  {t('Delete')}
                </Button>
                <Button
                  id="cancel_button"
                  stretch="auto"
                  variant="ghost"
                  onClick={() => {
                    setDeleteModalOpen(false);
                  }}
                >
                  {t('Cancel')}
                </Button>
              </Controls>
            </Modal>
          )}
        </>
      )}
    </>
  );
};

const SubHeading = styled(H2)`
  margin: 0;
  font-size: ${({ theme }) => theme.fonts.fontSize.ft24};
`;

const PositionCard = styled(Card)`
  margin-top: ${({ theme }) => theme.spacing.sp24};
`;

const DeleteControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
