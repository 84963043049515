import {
  createAbsoluteUrl,
  apiGet,
  apiPost,
  apiPut,
  apiDelete,
} from './utils/apiUtils';
import { CustomerLocation, CustomerObject, UpdateCustomerObject } from 'types';
import * as RESOURCE_PATHS from './resourcePaths';
const API_BASE_URL: string = process.env.REACT_APP_API_ASSETMGT_HOSTNAME || '';

// Gets all customers
export const getCustomers = async (): Promise<any> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.CUSTOMERS,
    ),
  );
};

// Creates a customer
export const postCustomer = async (payload: { name: string }): Promise<any> => {
  return apiPost(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.CUSTOMERS,
    ),
    payload,
  );
};

// Deletes a customer
export const deleteCustomer = async (customerId: string): Promise<any> => {
  return apiDelete(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.CUSTOMERS_INFO.replace(/%id/, customerId),
    ),
  );
};

// Creates a customer
export const putCustomer = async (
  customerId: string,
  updateObject: UpdateCustomerObject,
): Promise<{ data: CustomerObject }> => {
  return apiPut(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.CUSTOMERS_INFO.replace(/%id/, customerId),
    ),
    updateObject,
  );
};

// Gets a Customer given its id
export const getCustomer = async (id: string): Promise<any> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      `${RESOURCE_PATHS.CUSTOMERS}/${id}`,
    ),
  );
};

// Gets a list of labels for a given customer
export const getCustomerLabels = async (id: string): Promise<any> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.CUSTOMERS_LABELS.replace(/%id/, id),
    ),
  );
};

// Gets a list of Location detail belonging to a given Customer
export const getCustomerLocations = async (id: string): Promise<any> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.CUSTOMERS_LOCATIONS.replace(/%id/, id),
    ),
  );
};

// Gets a list of Location detail belonging to a given Customer
export const getCustomerServices = async (id: string): Promise<any> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.CUSTOMERS_SERVICES.replace(/%id/, id),
    ),
  );
};

// Posts a new location
export const postLocationDetails = async (
  customerId: string,
  locationObject: CustomerLocation,
): Promise<any> => {
  return apiPost(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.CUSTOMERS_LOCATIONS.replace(/%id/, customerId),
    ),
    locationObject,
  );
};

// Create a new label
export const postNewLabel = async (
  customerId: string,
  labelName: string,
): Promise<any> => {
  return apiPost(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.CUSTOMERS_LABELS.replace(/%id/, customerId),
    ),
    { name: labelName },
  );
};
