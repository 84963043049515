/**
 *
 * Logs
 *
 */

import React, { useState, useMemo, useEffect, ReactNode } from 'react';
import styled from 'styled-components/macro';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Checkbox } from '@eriksdigital/atomic-ui/components';

import t, { translationString } from 'locales/translation';
import {
  Alert,
  TextColor,
  BgColor,
  AlertType,
  Severity,
  ValueLabel,
  EntityType,
} from 'types';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { getAlertSeverity } from 'utils/severity';
import { Div } from 'app/components/Div';
import { H4, Text } from 'app/components/Typography';
import { LogCard } from 'app/components/LogCard';
import { FinalSelect } from 'app/components/FinalField';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { selectAllAlerts } from 'app/containers/LogsPanel/selectors';
import { logsPanelSaga } from 'app/containers/LogsPanel/saga';
import { reducer, sliceKey, actions } from 'app/containers/LogsPanel/slice';
import { useCustomerLocation } from 'app/containers/CustomerLocation';
import { Pagination } from './Pagination';
import { BackLink } from './BackLink';
import { capitalizeFirstLetter } from 'utils/formatters';

export const applyAlertFilters = (
  alerts: Alert[],
  isActive: boolean,
  filters: { type: ValueLabel | null; severity: ValueLabel | null },
) => {
  let filteredAlerts = [...alerts];

  if (isActive) {
    filteredAlerts = filteredAlerts.filter(alert => alert.isActive);
  }

  if (filters?.type) {
    filteredAlerts = filteredAlerts.filter(
      alert => alert.type === filters.type?.value,
    );
  }

  if (filters?.severity) {
    filteredAlerts = filteredAlerts.filter(alert => {
      return getAlertSeverity(alert) === filters.severity?.value;
    });
  }

  return filteredAlerts;
};

export function Logs() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: logsPanelSaga });

  useCustomerLocation({
    hasLocationSelector: false,
    replacePath: '/',
  });

  const dispatch = useDispatch();
  const { miId } = useParams() as { miId: string };
  const { alerts, alertsLoading } = useSelector(selectAllAlerts);

  const [isActive, setIsActive] = useState<boolean>(true);
  const [type, setType] = useState<ValueLabel | null>(null);
  const [severity, setSeverity] = useState<ValueLabel | null>(null);

  const typeOptions: AlertType[] = [
    AlertType.STATUS,
    AlertType.CONDITION,
    AlertType.SURVEY,
  ];

  const severityOptions: Severity[] = [
    Severity.GOOD,
    Severity.CRITICAL,
    Severity.WARNING,
    Severity.UNKNOWN,
  ];

  const items: ReactNode[] = useMemo(() => {
    if (alerts.length === 0) return [];

    return applyAlertFilters(alerts, isActive, { type, severity }).map(
      (alert, i) => (
        <Div key={i} padding="0 12px">
          <LogCard key={i} alert={alert} />
        </Div>
      ),
    );
  }, [isActive, alerts, severity, type]);

  useEffect(() => {
    if (!miId || alerts.length > 0) return;

    dispatch(
      actions.getAlerts({
        id: miId,
        type: EntityType.MAINTAINABLE_ITEM,
      }),
    );
  }, [alerts.length, dispatch, miId]);

  return (
    <Div bg={BgColor.lightGray} pb="30px">
      <BackLink />

      <Div padding="16px 12px">
        <H4>{t('Logs')}</H4>

        <Div display="flex" justifyContent="space-between" mt="16px" mb="16px">
          <StyledFinalSelect
            placeholder={t('Type')}
            isClearable={true}
            input={{
              id: 'type',
              name: 'type',
              value: type,
              onChange: (option: ValueLabel | null) => {
                if (type?.value === option?.value) return;

                setType(option);
              },
            }}
            options={typeOptions}
            renderOptions={(value: AlertType) => {
              const type = capitalizeFirstLetter(value);

              return {
                value,
                label: translationString(`ConditionScore.${type}`),
              };
            }}
          />

          <StyledFinalSelect
            placeholder={t('Severity')}
            isClearable={true}
            input={{
              id: 'severity',
              name: 'severity',
              value: severity,
              onChange: (option: ValueLabel | null) => {
                if (severity?.value === option?.value) return;

                setSeverity(option);
              },
            }}
            options={severityOptions}
            renderOptions={(value: Severity) => {
              const severity = capitalizeFirstLetter(value);

              return {
                value,
                label: translationString(`StatusSeverity.${severity}`),
              };
            }}
          />
        </Div>

        <Div display="flex" justifyContent="space-around" align="center">
          <StyledCheckbox
            id="active_filter"
            name="active"
            small
            checked={isActive}
            onChange={() => setIsActive(!isActive)}
          >
            <Text color={TextColor.muted}>
              {translationString('ShowHistoricData')}
            </Text>
          </StyledCheckbox>
        </Div>
      </Div>

      {alertsLoading && <LoadingIndicator small />}

      <Pagination items={items} numberItems={10} />
    </Div>
  );
}

const StyledCheckbox = styled(Checkbox)`
  span: before {
    border-radius: 3px;
  }
`;

const StyledFinalSelect = styled(FinalSelect)`
  max-width: 150px;

  div {
    border-style: none;
  }
`;
