import styled from 'styled-components/macro';
import { headingStyles } from 'styles';

export const H1 = styled.h1<{
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  text?: 'capitalize' | 'uppercase' | 'lowercase';
}>`
  ${headingStyles};
  font-size: ${({ theme }) => theme.fonts.fontSize.ft24};
  text-transform: ${({ text }) => text || 'none'};
  margin-top: ${({ top }) => top};
  margin-bottom: ${({ bottom }) => bottom};
  margin-left: ${({ left }) => left};
  margin-right: ${({ right }) => right};
`;
