/**
 *
 * CustomerSelector
 *
 */
import React, { useState, useEffect, ReactNode, ReactElement } from 'react';
import styled, { css } from 'styled-components/macro';
import { Icon } from '@eriksdigital/atomic-ui/components';
import { AddAltIcon } from '@eriksdigital/atomic-ui/components/Icons';
import { useHistory } from 'react-router-dom';

import t from 'locales/translation';
import { CustomerObject } from 'types';
import { DropdownSelector } from 'app/components/DropdownSelector';
import { buildAdminCustomerManageRoute } from 'utils/route-helpers';

interface Props {
  onSelect?: Function;
  loading?: boolean;
  customers: CustomerObject[];
  selectedCustomerId: string | null;
  showCreateCustomer?: boolean;
}

type NewCustomerProps = {
  fixedButtonLabel?: string | ReactElement;
  fixedButtonIcon?: ReactNode;
  onFixedButtonClick?: Function;
};

export function CustomerSelector(props: Props) {
  const {
    customers,
    selectedCustomerId,
    onSelect = () => null,
    loading = false,
    showCreateCustomer,
  } = props;

  const history = useHistory();

  const [currentCustomer, setCurrentCustomer] = useState<CustomerObject>();

  useEffect(() => {
    if (selectedCustomerId) {
      const matchingCustomers = customers.filter(
        cust => cust.id === selectedCustomerId,
      );
      if (matchingCustomers.length > 0) {
        setCurrentCustomer(matchingCustomers[0]);
      }
    } else {
      setCurrentCustomer(undefined);
    }
  }, [selectedCustomerId, customers, onSelect]);

  const selectedCustomerDisplay = (
    <SelectedCustomer data-testid="selected-customer-display">
      {currentCustomer ? (
        <h4>{currentCustomer.name}</h4>
      ) : (
        <h4>{t('AlertMessage.NoCustomerSelected')}</h4>
      )}
      <span>{t('CUSTOMER')}</span>
    </SelectedCustomer>
  );

  const customersOptions = customers.map(cust => ({
    id: cust.id,
    text: cust.name,
    contents: (
      <CustomerOption selected={cust.id === selectedCustomerId}>
        {cust.name}
      </CustomerOption>
    ),
  }));

  const newCustomerProps: NewCustomerProps = {};
  if (showCreateCustomer) {
    newCustomerProps.fixedButtonLabel = t('Customer.NewCustomer');
    newCustomerProps.fixedButtonIcon = (
      <AddCustomerIconContainer>
        <Icon as={AddAltIcon} />
      </AddCustomerIconContainer>
    );
    newCustomerProps.onFixedButtonClick = () =>
      history.push(buildAdminCustomerManageRoute());
  }

  return (
    <>
      <DropdownSelector
        id="customer-selector"
        selectedItemDisplay={selectedCustomerDisplay}
        listItems={customersOptions}
        onSelect={onSelect}
        loading={loading}
        searchable
        {...newCustomerProps}
      />
    </>
  );
}

const SelectedCustomer = styled.div`
  position: relative;
  width: 100%;
  text-align: left;
  line-height: 1;

  span {
    font-weight: ${({ theme }) => theme.fonts.fontWeight.normal};
    font-size: ${({ theme }) => theme.fonts.fontSize.ft10};
    color: ${({ theme }) => theme.colors.default.gray3};
    text-transform: uppercase;
  }

  h4 {
    font-weight: ${({ theme }) => theme.fonts.fontWeight.medium};
    font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
    margin: 0;
    color: inherit;
    white-space: nowrap;
  }
`;

const CustomerOption = styled.div<{ selected: boolean }>`
  padding: ${({ theme }) => `${theme.spacing.sp12} 0`};
  font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
  color: ${({ theme }) => theme.colors.default.blue1};

  &:hover {
    text-decoration: underline;
  }

  ${({ selected }) =>
    selected &&
    css`
      font-weight: 600;
    `}
`;

const AddCustomerIconContainer = styled.span`
  margin-right: ${({ theme }) => theme.spacing.sp8};
  margin-bottom: 2px;

  svg {
    width: ${({ theme }) => theme.sizes.sz20};
    height: ${({ theme }) => theme.sizes.sz20};
    fill: ${({ theme }) => theme.colors.default.blue1};
  }
`;
