import React from 'react';

export function Valid() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.16">
        <rect width="16" height="16" rx="8" fill="#009F47" />
      </g>
      <g clipPath="url(#clip0_3408_28207)">
        <path
          d="M6.875 11L3.5 7.62498L4.03025 7.09473L6.875 9.9391L11.9698 4.84473L12.5 5.37498L6.875 11Z"
          fill="#009F47"
        />
      </g>
      <defs>
        <clipPath id="clip0_3408_28207">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
