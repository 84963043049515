import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './slice';
import { SubMenuType } from './types';

const selectDomain = (state: RootState) => state.menu || initialState;

export const selectMenuIsOpen = createSelector(
  [selectDomain],
  menuState => menuState.isOpen,
);

export const selectSubMenuIsOpen = (subMenu?: SubMenuType) =>
  createSelector([selectDomain], menuState =>
    subMenu ? menuState.subMenu === subMenu : !!menuState.subMenu,
  );

export const selectIsCreatingEquipmentType = createSelector(
  [selectDomain],
  menuState => menuState.isCreatingEquipmentType,
);
