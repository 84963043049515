/**
 *
 * Tasks
 *
 */

import React, { memo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import t from 'locales/translation';
import { BgColor } from 'types';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { Div } from 'app/components/Div';
import { H4 } from 'app/components/Typography';
import { TaskCard } from 'app/components/TaskCard';
import { selectEquipment } from 'app/containers/Equipment/selectors';
import { reducer, sliceKey, actions } from 'app/containers/Equipment/slice';
import { equipmentSaga } from 'app/containers/Equipment/saga';
import { useCustomerLocation } from 'app/containers/CustomerLocation';
import { BackLink } from './BackLink';

export const Tasks = memo(() => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: equipmentSaga });

  useCustomerLocation({
    hasLocationSelector: false,
    replacePath: '/',
  });

  const dispatch = useDispatch();
  const { miId } = useParams() as { miId: string };
  const { tasks } = useSelector(selectEquipment);

  useEffect(() => {
    if (!miId || tasks.length > 0) return;

    dispatch(actions.getTasks(miId));
    dispatch(actions.getLocation(miId));
  }, [dispatch, miId, tasks.length]);

  return (
    <Div bg={BgColor.lightGray} pb="30px">
      <BackLink />

      <Div padding="16px 12px">
        <H4>{t('Tasks')}</H4>
      </Div>

      <Div padding="0 12px">
        {tasks.map(task => (
          <TaskCard key={task.id} task={task} />
        ))}
      </Div>
    </Div>
  );
});
