import React from 'react';
import { Icon, Button } from '@eriksdigital/atomic-ui/components';
import {
  TrashBinIcon,
  LoadingIcon,
} from '@eriksdigital/atomic-ui/components/Icons';

import { translationString } from 'locales/translation';

interface Props {
  resourceId: string;
  loading?: boolean;
  onClick: Function;
}

export const DeleteResourceButton = ({
  resourceId,
  loading = false,
  onClick,
}: Props) => {
  return (
    <Button
      id={`${resourceId}_remove_button`}
      onClick={onClick}
      size="regular"
      stretch="auto"
      variant="square"
      weight="medium"
      type="button"
      isLoading={loading}
      data-stringid="Delete"
      aria-label={translationString('Delete')}
    >
      <Icon as={loading ? LoadingIcon : TrashBinIcon} size="sz32" />
    </Button>
  );
};
