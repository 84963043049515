import React, { Fragment } from 'react';
import styled from 'styled-components/macro';

type Props = {
  options: React.OptionHTMLAttributes<HTMLOptionElement>[];
} & React.InputHTMLAttributes<HTMLInputElement>;

export default function RadioButtonGroup({
  options,
  id,
  name,
  defaultValue,
  ...rest
}: Props) {
  return (
    <Wrapper>
      {options.map((option, index) => (
        <Fragment key={`RadioButtonGroup_${id}_${index}`}>
          <input
            {...rest}
            id={`${id}_${index}`}
            name={name}
            value={option.value}
            defaultChecked={defaultValue === option.value}
            type="radio"
          />
          <label htmlFor={`${id}_${index}`}>{option.label}</label>
        </Fragment>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.fieldset`
  display: flex;
  align-items: center;
  gap: 32px;
  border: none;
  height: 40px;
  padding: 0;
  margin: 0;

  input {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${({ theme }) => theme.colors.default.blue2};
    font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
    transition: all 200ms;
    cursor: pointer;

    &::before {
      content: '';
      width: 20px;
      height: 20px;
      border-radius: 20px;
      border: 1px solid ${({ theme }) => theme.colors.default.midGray};
    }
  }

  input:checked + label::before {
    border: 7px solid ${({ theme }) => theme.colors.default.blue1};
  }

  input:disabled + label {
    color: ${({ theme }) => theme.colors.default.gray2};

    &::before {
      border-color: ${({ theme }) => theme.colors.default.gray2};
    }
  }
`;
