import { AxiosResponse } from 'axios';
import {
  CustomerLocation,
  SpaceUpdateObject,
  CreateAssetObject,
  UpdateAssetObject,
  UpdateSubUnitObject,
  CreateSubUnitObject,
  SubUnit,
  MaintainableItem,
  UpdateMaintainableItemObject,
  LocationMaintainableItemObject,
  MaintainableItemMetadata,
  CreateMaintainableItemObject,
  EntityType,
  HierarchyNode,
  Tag,
  NameId,
  EquipmentType,
  SubUnitMetadata,
  EquipmentTag,
  Paged,
  TagSearchPayload,
} from 'types';

import * as RESOURCE_PATHS from './resourcePaths';
import {
  createAbsoluteUrl,
  apiGet,
  apiPut,
  apiPost,
  apiDelete,
  apiPatch,
} from './utils/apiUtils';
import { EquipmentsPostPayload } from 'app/containers/Equipments/types';
import {
  PatchMaintainableItemPayload,
  PostMaintainableItemPayload,
  PutMaintainableItemPayload,
} from 'app/containers/EquipmentForm/types';

const API_BASE_URL: string = process.env.REACT_APP_API_ASSETMGT_HOSTNAME || '';

// Returns entire hierarchy tree under an item (including the item)
export const getHierarchyTree = async (
  rootItemId: string,
  maxDepth?: number,
  lowestItemType?: EntityType,
): Promise<AxiosResponse<HierarchyNode>> => {
  const params = new URLSearchParams();

  if (maxDepth) {
    params.append('maxDepth', maxDepth.toString());
  }

  if (lowestItemType) {
    params.append('lowestLevelTreeItemType', lowestItemType);
  }

  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_TREE.replace(/%id/, rootItemId),
      params,
    ),
  );
};

export const getAllowedCountriesForLocation = async (): Promise<any> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_LOCATION_COUNTRIES,
    ),
  );
};

export const getAllowedContractPhases = async (): Promise<{
  data: NameId[];
}> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.CUSTOMERS_CONTRACT_PHASES,
    ),
  );
};

export const getAssetsForLocation = async (
  locationId: string,
): Promise<any> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_LOCATION_ASSETS.replace(/%id/, locationId),
    ),
  );
};

export const deleteLocation = async (locationId: string): Promise<any> => {
  return apiDelete(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_LOCATION_DETAILS.replace(/%id/, locationId),
    ),
  );
};

export const putLocationDetails = async (
  locationId: string,
  updateLocationObject: CustomerLocation,
): Promise<any> => {
  return apiPut(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_LOCATION_DETAILS.replace(/%id/, locationId),
    ),
    updateLocationObject,
  );
};

export const getLocationDetails = async (locationId: string): Promise<any> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_LOCATION_DETAILS.replace(/%id/, locationId),
    ),
  );
};

export const getAssetDetails = async (assetId: string): Promise<any> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_ASSET_DETAILS.replace(/%id/, assetId),
    ),
  );
};

export const putAssetDetails = async (
  assetId: string,
  updateAssetObject: UpdateAssetObject,
): Promise<any> =>
  apiPut(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_ASSET_DETAILS.replace(/%id/, assetId),
    ),
    updateAssetObject,
  );

export const postAssetDetails = async (
  spaceId: string,
  updateAssetObject: CreateAssetObject,
): Promise<any> =>
  apiPost(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_ASSETS_FROM_SPACE.replace(/%id/, spaceId),
    ),
    updateAssetObject,
  );

export const deleteAsset = async (assetId: string): Promise<any> =>
  apiDelete(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_ASSETS.replace(/%id/, assetId),
    ),
  );

export const getSpaceDetails = async (spaceId: string): Promise<any> =>
  apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_SPACE_DETAILS.replace(/%id/, spaceId),
    ),
  );

export const putSpaceDetails = async (
  spaceId: string,
  updateSpaceObject: SpaceUpdateObject,
): Promise<any> =>
  apiPut(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_SPACE_DETAILS.replace(/%id/, spaceId),
    ),
    updateSpaceObject,
  );

export const postSpaceDetails = async (
  parentId: string,
  updateSpaceObject: SpaceUpdateObject,
): Promise<any> =>
  apiPost(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_SPACES_DETAILS,
    ),
    { parentId, ...updateSpaceObject },
  );

export const deleteSpace = async (spaceId: string): Promise<any> =>
  apiDelete(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_SPACE_DETAILS.replace(/%id/, spaceId),
    ),
  );

export const reorderHierarchy = async (
  parentId: string,
  childList: string[],
): Promise<any> =>
  apiPost(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_SEQUENCE,
    ),
    { parentId, childList },
  );

export const getAssetsForSpace = async (spaceId: string): Promise<any> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_ASSETS_FROM_SPACE.replace(/%id/, spaceId),
    ),
  );
};

export const getParentTree = async (
  rootItemId: string,
  maxDepth?: number,
  includedTreeItemTypes?: EntityType[],
): Promise<any> => {
  const params = new URLSearchParams();

  if (maxDepth) {
    params.append('maxDepth', maxDepth.toString());
  }

  if (includedTreeItemTypes && includedTreeItemTypes.length) {
    includedTreeItemTypes.forEach(type =>
      params.append('includedTreeItemTypes', type),
    );
  }

  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.PARENT_TREE.replace(/%id/, rootItemId),
      params,
    ),
  );
};

export const getLocationOfflineChanges = async (
  locationId: string,
): Promise<any> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_LOCATION_OFFLINE_CHANGES.replace(
        /%id/,
        locationId,
      ),
    ),
  );
};

export const postLocationOfflineCheckin = async (
  locationId: string,
): Promise<any> => {
  return apiPost(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_LOCATION_OFFLINE_CHECKIN.replace(
        /%id/,
        locationId,
      ),
    ),
    {},
  );
};

export const postLocationOfflineCheckout = async (
  locationId: string,
): Promise<any> => {
  return apiPost(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_LOCATION_OFFLINE_CHECKOUT.replace(
        /%id/,
        locationId,
      ),
    ),
    {},
  );
};

export const postLocationOfflineClear = async (
  locationId: string,
): Promise<any> => {
  return apiPost(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_LOCATION_OFFLINE_CLEAR.replace(
        /%id/,
        locationId,
      ),
    ),
    {},
  );
};

export const getLocationOfflineStatus = async (
  locationId: string,
): Promise<any> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_LOCATION_OFFLINE_STATUS.replace(
        /%id/,
        locationId,
      ),
    ),
  );
};

export const getSubUnitDetails = async (
  subUnitId: string,
  expandAttributes: boolean = false,
): Promise<{ data: SubUnit }> => {
  const params = new URLSearchParams();

  if (expandAttributes) {
    params.append('expandAttributes', expandAttributes.toString());
  }

  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_SUB_UNIT_DETAILS.replace(/%id/, subUnitId),
      params,
    ),
  );
};

export const putUpdateSubUnit = async (
  subUnitId: string,
  updateObject: UpdateSubUnitObject,
): Promise<{ data: SubUnit }> => {
  return apiPut(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_SUB_UNIT_DETAILS.replace(/%id/, subUnitId),
    ),
    updateObject,
  );
};

export const deleteSubUnit = async (
  subUnitId: string,
): Promise<{ data: {} }> => {
  return apiDelete(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_SUB_UNIT_DETAILS.replace(/%id/, subUnitId),
    ),
  );
};

export const postNewSubUnit = async (
  assetId: string,
  createObject: CreateSubUnitObject,
): Promise<{ data: SubUnit }> => {
  return apiPost(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_ASSET_SUB_UNITS.replace(/%id/, assetId),
    ),
    createObject,
  );
};

export const getSubUnitTypes = async (): Promise<{
  data: { [name: string]: string }[];
}> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_SUB_UNIT_TYPES,
    ),
  );
};

export const getSubUnitMetadata = async (): Promise<{
  data: SubUnitMetadata;
}> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_SUB_UNIT_METADATA,
    ),
  );
};

export const getSubUnitMI = async (
  subUnitId: string,
): Promise<{ data: SubUnit }> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_SUB_UNIT_MAINTAINABLE_ITEMS.replace(
        /%id/,
        subUnitId,
      ),
    ),
  );
};
export const getSubUnitMiAttributes = async (
  subUnitId: string,
): Promise<{ data: MaintainableItem }> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_SUB_UNIT_MAINTAINABLE_ITEMS_ATTRIBUTES.replace(
        /%id/,
        subUnitId,
      ),
    ),
  );
};

export const getMaintainableItemsForLocation = async (
  locationId: string,
): Promise<{ data: LocationMaintainableItemObject[] }> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_MAINTAINABLE_ITEMS_FROM_LOCATION.replace(
        /%id/,
        locationId,
      ),
    ),
  );
};

//?expandAttributes=true
export const getMaintainableItemDetails = async (
  maintainableItemId: string,
  expandAttributes: boolean = false,
): Promise<{ data: MaintainableItem }> => {
  const params = new URLSearchParams();

  if (expandAttributes) {
    params.append('expandAttributes', expandAttributes.toString());
  }

  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_MAINTAINABLE_ITEM_DETAILS.replace(
        /%id/,
        maintainableItemId,
      ),
      params,
    ),
  );
};

export const postMaintainableItem = async ({
  locationId,
  type,
  attributes,
  spaceId,
}: PostMaintainableItemPayload): Promise<{ data: MaintainableItem }> =>
  apiPost(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_MAINTAINABLE_ITEMS,
    ),
    { locationId, type, attributes, spaceId },
  );

export const putMaintainableItem = async ({
  locationId,
  type,
  attributes,
  spaceId,
  subUnitId,
  miId,
}: PutMaintainableItemPayload): Promise<{ data: MaintainableItem }> =>
  apiPut(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_MAINTAINABLE_ITEM_DETAILS.replace(/%id/, miId),
    ),
    { locationId, type, attributes, spaceId, subUnitId },
  );

export const patchMaintainableItem = async ({
  miId,
  type,
  attributes,
  locationId,
  spaceId,
  subUnitId,
  assetId,
}: PatchMaintainableItemPayload): Promise<{ data: MaintainableItem }> =>
  apiPatch(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_MAINTAINABLE_ITEM_DETAILS.replace(/%id/, miId),
    ),
    { type, attributes, locationId, spaceId, subUnitId, assetId },
  );

export const putUpdateMaintainableItem = async (
  maintainableItemId: string,
  updateObject: UpdateMaintainableItemObject,
): Promise<{ data: MaintainableItem }> => {
  return apiPut(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_MAINTAINABLE_ITEM_DETAILS.replace(
        /%id/,
        maintainableItemId,
      ),
    ),
    updateObject,
  );
};

export const deleteMaintainableItem = async (
  maintainableObjectId: string,
): Promise<{ data: {} }> => {
  return apiDelete(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_MAINTAINABLE_ITEM_DETAILS.replace(
        /%id/,
        maintainableObjectId,
      ),
    ),
  );
};

export const postNewMaintainableItem = async (
  subUnitId: string,
  createObject: CreateMaintainableItemObject,
): Promise<{ data: MaintainableItem }> => {
  return apiPost(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_SUB_UNIT_MAINTAINABLE_ITEMS.replace(
        /%id/,
        subUnitId,
      ),
    ),
    createObject,
  );
};

export const getMaintainableItemsMetadata = async (): Promise<{
  data: MaintainableItemMetadata;
}> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_MAINTAINABLE_ITEMS_METADATA,
    ),
  );
};

export const getMaintainableItemsTypes = async (): Promise<any> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_MAINTAINABLE_ITEMS_TYPE,
    ),
  );
};

// TODO: not being used, remove?
export const getEquipment = async (): Promise<any> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_CUSTOMERS_TAGS_EQUIPMENT,
    ),
  );
};

export const getTag = async (id: string): Promise<{ data: Tag }> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_TAGS.replace(/%id/, id),
    ),
  );
};

export const getEquipmentTypes = async (
  id: string,
): Promise<{ data: EquipmentType[] }> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_EQUIPMENT_TYPES.replace(/%id/, id),
    ),
  );
};

export const getEquipments = async (
  payload: EquipmentsPostPayload,
): Promise<AxiosResponse<Paged<MaintainableItem>>> => {
  const { miTypeId, locationId, ...body } = payload;
  return apiPost(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.LOCATION_EQUIPMENTS.replace(
        /%locationId/,
        locationId,
      ).replace(/%miTypeId/, miTypeId),
    ),
    body,
  );
};

export const getTags = async (
  payload: TagSearchPayload,
): Promise<AxiosResponse<EquipmentTag[]>> => {
  return apiPost(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V2,
      RESOURCE_PATHS.HIERARCHY_TAGS_SEARCH,
    ),
    payload,
  );
};
