import React, { memo, useContext, useMemo } from 'react';
import styled, { ThemeContext } from 'styled-components/macro';
import { withParentSize } from '@visx/responsive';
import { LinePath, AreaClosed } from '@visx/shape';
import { GridColumns, GridRows } from '@visx/grid';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { scaleLinear, scaleTime } from '@visx/scale';
import { TextProps } from '@visx/text/lib/types';
import { Group } from '@visx/group';
import { max, extent } from 'd3-array';
import { sub, add } from 'date-fns';

import t from 'locales/translation';
import { WithParentSizeProps, ValueTimestamp, ThresholdObject } from 'types';
import {
  getSensorValueDate,
  getSensorValueDatum,
  multiFormat,
} from 'utils/charting';
import { getDataLineColor } from 'utils/chartColorProvider';
import { Thresholds } from '../Thresholds';

interface Props {
  data: ValueTimestamp[];
  extraData?: ValueTimestamp[];
  color?: string;
  extraColor?: string;
  leftLabel?: string;
  bottomLabel?: string;
  dashed?: boolean;
  opacity?: number;
  area?: boolean;
  dots?: boolean;
  gridX?: boolean;
  gridY?: boolean;
  strokeWidth?: number;
  parentWidth?: number;
  threshold?: ThresholdObject;
}

const yAxisStart = (values: ValueTimestamp[]) => {
  let yAxis = 0;

  values?.forEach(value => {
    if (value.value < yAxis) yAxis = value.value;
  });

  return yAxis;
};

const CHART_MARGIN = {
  top: 10,
  bottom: 20,
  left: 25,
  right: 20,
};

const Chart = ({
  data,
  extraData,
  color,
  extraColor,
  leftLabel,
  bottomLabel,
  dashed = false,
  opacity = 1,
  strokeWidth,
  area,
  dots,
  parentWidth,
  gridY,
  gridX,
  threshold,
}: Props & WithParentSizeProps) => {
  const theme = useContext(ThemeContext);

  const mainColor = color || getDataLineColor(0);
  const secondaryColor = extraColor || getDataLineColor(1);

  const width = parentWidth ?? 0;
  const height = Math.round(width / 2.5) ?? 0;

  const xMax = width - CHART_MARGIN.left - CHART_MARGIN.right;
  const yMax = height - CHART_MARGIN.top - CHART_MARGIN.bottom;
  const nTicks = Math.round(width / 100);

  const values = extraData ? [...data, ...extraData] : data;
  const maxValue = max(values, d => d.value);
  const minValue = yAxisStart(values);

  const circleRadio = strokeWidth || 0 + 2;
  const axisColor = theme.colors.grays.midGray;
  const axisLabelProps: TextProps = {
    fill: axisColor,
    fontSize: 10,
  };

  const dateScale = useMemo(() => {
    const values = extraData ? [...data, ...extraData] : data;
    let domain = extent(values, getSensorValueDate) as [Date, Date];

    if (values.length === 1) {
      const time = values[0].timestamp;

      domain = [
        sub(new Date(time), { hours: 1 }),
        add(new Date(time), { hours: 1 }),
      ];
    }

    return scaleTime<number>({
      range: [0, xMax],
      domain,
    });
  }, [xMax, data, extraData]);

  const valueScale = useMemo(() => {
    return scaleLinear<number>({
      range: [yMax, 0],
      domain: [minValue, maxValue],
      nice: true,
    });
  }, [yMax, minValue, maxValue]);

  return (
    <StyledSVG width={width} height={height}>
      <Thresholds
        threshold={threshold}
        valueScale={valueScale}
        width={xMax}
        height={yMax}
        offsetTop={CHART_MARGIN.top}
        offsetLeft={CHART_MARGIN.left}
      />

      <Group top={CHART_MARGIN.top} left={CHART_MARGIN.left}>
        {gridX && (
          <GridRows
            scale={valueScale}
            width={xMax}
            stroke={theme.colors.grays.lightGray}
            strokeWidth={0.2}
            numTicks={nTicks}
          />
        )}

        {gridY && (
          <GridColumns
            scale={dateScale}
            height={yMax}
            stroke={theme.colors.grays.lightGray}
            strokeWidth={0.2}
            numTicks={nTicks}
          />
        )}

        <AxisBottom
          scale={dateScale}
          top={valueScale(0)}
          stroke={axisColor}
          tickStroke={axisColor}
          tickLabelProps={() => axisLabelProps}
          hideTicks={true}
          tickClassName="tick"
          tickFormat={multiFormat}
          numTicks={nTicks}
          label={bottomLabel}
        />
        <AxisLeft
          scale={valueScale}
          stroke={theme.colors.grays.midGray}
          hideTicks={true}
          tickClassName="tick"
          tickLabelProps={() => ({
            ...axisLabelProps,
            textAnchor: 'end',
          })}
          numTicks={nTicks}
          label={leftLabel}
        />

        {area && (
          <AreaClosed
            data={data}
            yScale={valueScale}
            x={d => dateScale(getSensorValueDate(d)) ?? 0}
            y={d => valueScale(getSensorValueDatum(d)) ?? 0}
            fill={mainColor}
            className="area-fill"
            opacity={0.4}
          />
        )}

        {dots &&
          data.map((d, j) => (
            <circle
              key={j}
              r={circleRadio}
              cx={dateScale(getSensorValueDate(d))}
              cy={valueScale(getSensorValueDatum(d))}
              stroke={theme.colors.border.white}
              fill={mainColor}
            />
          ))}

        <LinePath
          data={data}
          x={d => dateScale(getSensorValueDate(d)) ?? 0}
          y={d => valueScale(getSensorValueDatum(d)) ?? 0}
          stroke={mainColor}
          strokeDasharray={dashed ? '4,3' : ''}
          strokeOpacity={opacity}
          strokeWidth={strokeWidth}
        />

        {extraData && extraData.length > 0 && (
          <>
            <LinePath
              data={extraData}
              x={d => dateScale(getSensorValueDate(d)) ?? 0}
              y={d => valueScale(getSensorValueDatum(d)) ?? 0}
              stroke={secondaryColor}
              strokeWidth={strokeWidth}
            />
            {area && (
              <AreaClosed
                data={extraData}
                yScale={valueScale}
                x={d => dateScale(getSensorValueDate(d)) ?? 0}
                y={d => valueScale(getSensorValueDatum(d)) ?? 0}
                fill={secondaryColor}
                className="area-fill"
                opacity={0.4}
              />
            )}
          </>
        )}
        {!data?.length && !extraData?.length && (
          <text x="50%" y="50%" textAnchor="end">
            {t('ErrorMessage.NoChartData')}
          </text>
        )}
      </Group>
    </StyledSVG>
  );
};

export const SensorSimpleChart = memo(withParentSize(Chart));

const StyledSVG = styled.svg`
  overflow: visible !important;
  max-width: 100%;
`;
