import React from 'react';
import { Icon } from '@eriksdigital/atomic-ui/components';
import { BackArrowIcon } from '@eriksdigital/atomic-ui/components/Icons';

import t from 'locales/translation';
import { Link } from 'app/elements/Links';

interface Props {
  label?: string;
  name: string;
  link: string;
  onClick?: (e: React.MouseEvent) => void;
}

export function BackNavigation({
  label = 'BackToPage',
  name,
  link,
  onClick,
}: Props) {
  return (
    <Link onClick={onClick} to={link}>
      <Icon as={BackArrowIcon} />
      {t(label, { name })}
    </Link>
  );
}
