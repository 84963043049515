/**
 *
 * ConfirmDialog
 *
 */
import React, { ReactElement } from 'react';
import styled from 'styled-components/macro';
import { Button, H2, P, Icon } from '@eriksdigital/atomic-ui/components';
import { CrossIcon, CheckIcon } from '@eriksdigital/atomic-ui/components/Icons';

import { translationString } from 'locales/translation';
import { Modal } from 'app/components/Modal';

interface Props {
  open: boolean;
  title?: string | ReactElement;
  message?: string | ReactElement;
  onCancel: Function;
  onConfirm: Function;
  cancelText?: string;
  confirmText?: string;
}

export function ConfirmDialog({
  open,
  title,
  message,
  onCancel,
  onConfirm,
  cancelText,
  confirmText,
}: Props) {
  cancelText = cancelText || translationString('Cancel');
  confirmText = confirmText || translationString('Confirm');

  return (
    <>
      {open && (
        <Modal
          onClose={onCancel}
          showCloseIcon={false}
          maxWidth={500}
          id="confirm_modal"
          data-testid="confirm_modal"
        >
          {title && <DialogHeader>{title}</DialogHeader>}

          {message && <DialogMessage>{message}</DialogMessage>}

          <ActionsWrapper>
            <Button
              data-stringid={cancelText || 'Cancel'}
              id="confirm_cancel"
              data-testid="confirm_cancel"
              text={cancelText}
              variant="secondary"
              stretch="auto"
              onClick={onCancel}
              leftIcon={<Icon as={CrossIcon} />}
            />
            <Button
              data-stringid={cancelText || 'Confirm'}
              id="confirm_approve"
              data-testid="confirm_approve"
              text={confirmText}
              stretch="auto"
              onClick={onConfirm}
              leftIcon={<Icon as={CheckIcon} />}
            />
          </ActionsWrapper>
        </Modal>
      )}
    </>
  );
}

const DialogHeader = styled(H2)`
  font-size: ${({ theme }) => theme.fonts.fontSize.ft24};
  margin: 0 0 ${({ theme }) => theme.spacing.sp12};
`;

const DialogMessage = styled(P)`
  margin: 0 0 ${({ theme }) => theme.spacing.sp24};
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  button + button {
    margin-left: ${({ theme }) => theme.spacing.sp8};
  }
`;
