import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';
import useRememberLocation from 'utils/hooks/useRememberLocation';
import { actions } from '../slice';
import { selectSelectedCustomer, selectSelectedLocation } from '../selectors';

type Props = {
  hasLocationSelector?: boolean;
  hasCustomerSelector?: boolean;
  replacePath?: string;
};

function useCustomerLocation({
  hasCustomerSelector = true,
  hasLocationSelector = true,
  replacePath,
}: Props) {
  useRememberLocation();
  const route = useRouteMatch();
  const dispatch = useDispatch();
  const selectedCustomerId = useSelector(selectSelectedCustomer);
  const selectedLocationId = useSelector(selectSelectedLocation);
  const { customerId, locationId } = useParams<{
    customerId: string;
    locationId: string;
  }>();

  useEffect(() => {
    dispatch(actions.setHasCustomerSelector(hasCustomerSelector));
  }, [hasCustomerSelector, dispatch]);

  useEffect(() => {
    dispatch(actions.setHasLocationSelector(hasLocationSelector));
  }, [hasLocationSelector, dispatch]);

  useEffect(() => {
    dispatch(actions.setPath(replacePath || route.path));
  }, [route.path, replacePath, dispatch]);

  useEffect(() => {
    if (customerId && !selectedCustomerId) {
      dispatch(actions.setSelectedCustomer(customerId));
    }
  }, [customerId, selectedCustomerId, dispatch]);

  useEffect(() => {
    if (locationId && !selectedLocationId) {
      dispatch(actions.setSelectedLocation(locationId));
    }
  }, [locationId, selectedLocationId, dispatch]);

  return {};
}

export default useCustomerLocation;
