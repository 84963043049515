export type Point = {
  x: number;
  y: number;
};

/**
 * Returns a point at a given angle on a circle in radians
 * @param centerX {number} X Coordinate of circle center
 * @param centerY {number} Y Coordinate of circle center
 * @param radius {number} Radius of circle
 * @param angleInDegrees {number} Angle of point to be deterimined
 */
export function polarToCartesian(
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number,
): Point {
  var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
}

/**
 * Returns an svg string describing a circular arc at a radius around a given point
 * @param x {number} X Coordinate of arc center point
 * @param y {number} Y Coordinate of arc center point
 * @param radius {number} Radius from center point which at which the arc should be
 * @param startAngle {number} Arc start angle (from the 12 o'clock position)
 * @param endAngle {number} Arc end angle (from the 12 o'clock position)
 */
export function describeArc(
  x: number,
  y: number,
  radius: number,
  startAngle: number,
  endAngle: number,
): string {
  var start = polarToCartesian(x, y, radius, endAngle);
  var end = polarToCartesian(x, y, radius, startAngle);

  var largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

  var d = [
    'M',
    start.x,
    start.y,
    'A',
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(' ');

  return d;
}
