/**
 *
 * TaskCard
 *
 */
import React, { memo, useState } from 'react';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';
import { Button } from '@eriksdigital/atomic-ui/components';
import { breakpointObj } from '@eriksdigital/atomic-ui/styles';

import t, { translationString } from 'locales/translation';
import { formatDate } from 'locales/date-format-i18n';
import { Task, BgColor, TextColor, EntityType } from 'types';
import { Status } from 'app/components/TaskStatus';
import { useWindowSize } from 'utils/hooks/useWindowSize';
import { Div } from 'app/components/Div';
import { Text } from 'app/components/Typography';
import { Modal } from 'app/components/Modal';
import { Details } from 'app/containers/TaskDetails/Details';
import { actions } from 'app/containers/Equipment/slice';

interface Props {
  task: Task;
}

export const TaskCard = memo(({ task }: Props) => {
  const { width } = useWindowSize();
  const isMobile = width <= breakpointObj.tabletPortrait;

  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState<boolean>(false);

  const {
    title,
    description,
    status,
    createdDate,
    closeDate,
    maintainableItemId,
    maintainableItemName,
    subUnitId,
    subUnitName,
  } = task;

  const item = maintainableItemId
    ? {
        id: maintainableItemId,
        name: maintainableItemName || '',
        type: EntityType.MAINTAINABLE_ITEM,
      }
    : {
        id: subUnitId || '',
        name: subUnitName || '',
        type: EntityType.SUB_UNIT,
      };

  return (
    <Div bg={BgColor.white} mt="8px" padding="24px 12px">
      <Div display="flex" justifyContent="space-between">
        <Text fontWeight="medium" text="capitalize">
          {title}
        </Text>

        <Status status={status} />
      </Div>
      <Div mt="16px">
        <Text fontWeight="normal">{description}</Text>
      </Div>
      <Div mt="24px">
        <Text color={TextColor.muted}>
          {t('Attributes.creationDate')}: {formatDate(createdDate)}
        </Text>
        {closeDate && (
          <>
            <br />
            <Text color={TextColor.muted}>
              {t('Attributes.closeDate')}: {formatDate(closeDate)}
            </Text>
          </>
        )}
      </Div>
      <Div mt="24px" display="flex" justifyContent="flex-end">
        <StyledButton
          id="details"
          data-testid="details"
          variant="ghost"
          text={translationString('Details')}
          data-stringid="Details"
          onClick={() => setShowModal(true)}
        />
      </Div>

      {showModal && (
        <Modal
          id="modal"
          data-testid="modal"
          onClose={() => {
            setShowModal(false);

            maintainableItemId &&
              dispatch(actions.getTasks(maintainableItemId));
          }}
          withFrame={!isMobile}
          showCloseIcon={!isMobile}
          showCloseHeader={isMobile}
          withOutsideClose={false}
          background={BgColor.lightGray}
        >
          <Div ml="12px">
            <Details item={item} task={task} />
          </Div>
        </Modal>
      )}
    </Div>
  );
});

const StyledButton = styled(Button)`
  font-size: ${({ theme }) => theme.fonts.fontSize.ft12};
`;
