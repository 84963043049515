import React from 'react';
import styled from 'styled-components/macro';
import { TrashBinIcon } from '@eriksdigital/atomic-ui/components/Icons';
import { ButtonIcon } from 'app/elements/Buttons';
import { StagingFile } from '../types';

type Props = {
  file: StagingFile;
  onDelete: (file: StagingFile) => void;
};

function StagedFile({ file, onDelete }: Props) {
  return (
    <StagedFileStyled>
      <span>{file.file.name}</span>
      <ButtonIcon
        type="button"
        icon={TrashBinIcon}
        size="sz16"
        onClick={() => onDelete(file)}
      />
    </StagedFileStyled>
  );
}

export default StagedFile;

const StagedFileStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 8px 12px;
  font-size: ${({ theme }) => theme.fonts.fontSize.ft12};
  color: ${({ theme }) => theme.colors.default.blue2};
  background-color: ${({ theme }) => theme.colors.default.gray2};

  button {
    color: ${({ theme }) => theme.colors.default.darkGray};
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
