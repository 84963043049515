import { timeFormat } from 'd3-time-format';
import {
  timeSecond,
  timeMinute,
  timeHour,
  timeDay,
  timeMonth,
  timeWeek,
  timeYear,
} from 'd3-time';

import {
  ValueTimestamp,
  SensorProperty,
  SensorType,
  ChartMeasurement,
} from 'types';
import { getDataLineColor } from 'utils/chartColorProvider';
import { createDeepDateSortOn } from './sortByDate';

export const SENSOR_TEMP_KEY = 'Temperature';

export const getSensorValueDate = (d: ValueTimestamp): Date =>
  new Date(d.timestamp);
export const getSensorValueDatum = (d: ValueTimestamp): number => d.value;

export const formatMillisecond = timeFormat('.%L'),
  formatSecond = timeFormat(':%S'),
  formatMinute = timeFormat('%H:%M'),
  formatHour = timeFormat('%H:%M'),
  formatDay = timeFormat('%d %b'),
  formatWeek = timeFormat('%d %b'),
  formatMonth = timeFormat('%b'),
  // formatQuarter = timeFormat('Q%q %y'),
  formatYear = timeFormat('%Y');

// Define filter conditions
export function multiFormat(date) {
  return (timeSecond(date) < date
    ? formatMillisecond
    : timeMinute(date) < date
    ? formatSecond
    : timeHour(date) < date
    ? formatMinute
    : timeDay(date) < date
    ? formatHour
    : timeMonth(date) < date
    ? timeWeek(date) < date
      ? formatDay
      : formatWeek
    : timeYear(date) < date
    ? formatMonth
    : formatYear)(date);
}

export const getFilteredSensorData = (
  sensorData: SensorProperty[],
  isTemp: boolean,
): SensorProperty[] => {
  return sensorData
    .filter(sensor => {
      if (!sensor) return false;
      if (isTemp && sensor.measurement !== SENSOR_TEMP_KEY) return false;
      return !(!isTemp && sensor.measurement === SENSOR_TEMP_KEY);
    })
    .map((sd, index) => {
      return { ...sd, color: getDataLineColor(index) };
    });
};

export const getMeasurements = (
  measurements: SensorProperty[],
  selectedSensors: string[],
): ChartMeasurement[] => {
  return (
    measurements
      .filter(m => selectedSensors.includes(m.sensor?.sensorId || ''))
      .map((m, index) => {
        const { sensor, axis, unitOfMeasurement, values } = m;

        return {
          name: `${sensor?.name} ${
            sensor?.sensorType !== SensorType.IMX ? axis : ''
          }`,
          color: getDataLineColor(index),
          unitOfMeasurement,
          values: [...values].sort(createDeepDateSortOn('timestamp')),
        };
      }) || []
  );
};

export const getSortedValues = (
  properties: SensorProperty[],
): ValueTimestamp[] => {
  return properties
    .reduce((acc: ValueTimestamp[], curr) => {
      if (curr && curr.values) {
        return acc.concat(curr.values);
      }
      return acc;
    }, [])
    .sort(createDeepDateSortOn('timestamp'));
};
