import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';

import {
  CreateCustomerObject,
  CustomerLocation,
  CustomerObject,
  UpdateCustomerObject,
} from 'types';

import { ContainerState } from './types';

// The initial state of the CustomerControls container
export const initialState: ContainerState = {
  customers: [],
  customersLoading: false,
  customersError: '',
  selectedCustomer: '',
  customerUpdateLoading: false,
  customerUpdateError: '',
  locations: null,
  locationsLoading: false,
  locationError: '',
  selectedLocation: null,
  hasCustomerSelector: true,
  hasLocationSelector: true,
  path: '/c/:customerId/dashboard/:locationId',
};

const customerControlsSlice = createSlice({
  name: 'customerControls',
  initialState,
  reducers: {
    getCustomers(state: ContainerState, action: PayloadAction): ContainerState {
      return { ...state, customersLoading: true };
    },
    getCustomersSuccess(state, action: PayloadAction<CustomerObject[]>) {
      return {
        ...state,
        customersLoading: initialState.customersLoading,
        customers: action.payload,
      };
    },
    getCustomersFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        customersLoading: initialState.customersLoading,
        customers: initialState.customers,
        customersError: action.payload,
      };
    },
    setSelectedCustomer(state, action: PayloadAction<string>) {
      return {
        ...state,
        selectedCustomer: action.payload,
        locations: [],
        locationsLoading: true,
      };
    },
    changeSelectedCustomer(state, action: PayloadAction<string>) {
      return {
        ...state,
        selectedCustomer: action.payload,
        locations: [],
        locationsLoading: true,
      };
    },
    deleteCustomer(state, action: PayloadAction<string>) {
      return {
        ...state,
        customerUpdateLoading: true,
      };
    },
    deleteCustomerComplete(state, action: PayloadAction<string>) {
      return {
        ...state,
        customers: state.customers.filter(c => c.id !== action.payload),
        customerUpdateLoading: initialState.customerUpdateLoading,
        customerUpdateError: initialState.customerUpdateError,
        selectedCustomer: initialState.selectedCustomer,
      };
    },
    putCustomer(
      state,
      action: PayloadAction<{
        id: string;
        updateObject: UpdateCustomerObject;
        logo?: File;
      }>,
    ) {
      return {
        ...state,
        customerUpdateLoading: true,
      };
    },
    putCustomerComplete(state, action: PayloadAction<CustomerObject>) {
      return {
        ...state,
        customers: state.customers.map(customer => {
          if (customer.id === action.payload.id) return action.payload;
          return customer;
        }),
        customerUpdateLoading: initialState.customerUpdateLoading,
        customerUpdateError: initialState.customerUpdateError,
      };
    },
    customerUpdateError(state, action: PayloadAction<string>) {
      return {
        ...state,
        customerUpdateLoading: initialState.customerUpdateLoading,
        customerUpdateError: action.payload,
      };
    },
    postNewCustomer(
      state,
      action: PayloadAction<{ customer: CreateCustomerObject; logo: File }>,
    ) {
      return {
        ...state,
        customerUpdateLoading: true,
        customerUpdateError: initialState.customerUpdateError,
      };
    },
    postCustomerComplete(state, action: PayloadAction<CustomerObject>) {
      return {
        ...state,
        customers: [...state.customers, action.payload],
        customerUpdateLoading: initialState.customerUpdateLoading,
        customerUpdateError: initialState.customerUpdateError,
        selectedCustomer: action.payload.id,
      };
    },
    postCustomerError(state, action: PayloadAction<string>) {
      return {
        ...state,
        customerUpdateLoading: true,
        customerUpdateError: action.payload,
      };
    },
    getCustomerLocations(state, action: PayloadAction<string>) {
      return { ...state, locations: [], locationsLoading: true };
    },
    getCustomerLocationsSuccess(
      state,
      action: PayloadAction<CustomerLocation[]>,
    ) {
      return {
        ...state,
        locationsLoading: initialState.locationsLoading,
        locations: action.payload,
      };
    },
    getCustomerLocationsFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        locationsLoading: initialState.locationsLoading,
        locations: initialState.locations,
        locationError: action.payload,
      };
    },
    setSelectedLocation(state, action: PayloadAction<string>) {
      return {
        ...state,
        selectedLocation: action.payload,
      };
    },
    changeSelectedLocation(state, action: PayloadAction<string>) {
      return {
        ...state,
        selectedLocation: action.payload,
      };
    },
    setHasCustomerSelector(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        hasCustomerSelector: action.payload,
      };
    },
    setHasLocationSelector(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        hasLocationSelector: action.payload,
      };
    },
    setPath(state, action: PayloadAction<string>) {
      return {
        ...state,
        path: action.payload,
      };
    },
  },
});

export const { actions, reducer, name: sliceKey } = customerControlsSlice;
