import { WrappedComponentProps } from './types';
import React from 'react';
import { RadioButton } from '@eriksdigital/atomic-ui/components';

export const RadioButtonAdapter = ({
  input,
  meta,
  ...otherProps
}: WrappedComponentProps) => {
  return (
    <RadioButton
      {...input}
      checked={input.value === otherProps.value}
      error={meta?.error && meta?.touched}
      disabled={meta?.submitting}
      onChange={val => input.onChange(val)}
      {...otherProps}
    />
  );
};
