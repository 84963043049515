/**
 *
 * StatusLevel
 *
 * Specific for equipment details page, where the status ring will be displayed
 * in different color based on severity
 *
 *
 */
import React from 'react';
import styled from 'styled-components/macro';

import { Severity } from 'types';
import { clamp } from 'utils/math-utils';
import { getWarningLevel } from 'utils/severity';
import { getColorForSeverity, getFillColorForSeverity } from 'utils/getColor';
import VectorIcon from 'assets/icons/Vector.svg';

interface Props {
  size: string;
  score: number;
}

export const StatusLevel = ({ size, score }: Props) => {
  const clampedScore = clamp(score, 0, 10);
  if (isNaN(clampedScore)) return null;

  const type = getWarningLevel(score);
  const fillColor = getFillColorForSeverity(type);

  return (
    <StatusLevelContainer>
      <CountText type={type}>
        {type === 'unknown' ? (
          <img src={VectorIcon} alt="vector" />
        ) : (
          clampedScore
        )}
      </CountText>
      <Circle size={size} fillColor={fillColor} />
    </StatusLevelContainer>
  );
};

const StatusLevelContainer = styled.div`
  position: relative;
  text-align: center;
`;

const CountText = styled.span<{ type: Severity }>`
  position: absolute;
  left: 50%;
  top: ${({ type }) => (type === 'unknown' ? '40%' : '50%')};
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
  color: ${({ theme, type }) => getColorForSeverity(type, theme)};
  z-index: 1;
`;

const Circle = styled.div<{ size; fillColor }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 50%;
  background-color: ${({ fillColor }) => fillColor};
  position: relative;
  z-index: 0;
`;
