import { HierarchyNode } from 'types';
import { hasChildren } from './hasChildren';

export const deepFindSpaceById = (children: HierarchyNode[], id: string) => {
  for (let i = 0; i < children.length; i++) {
    if (children[i].id === id) {
      return children[i];
    }
    if (hasChildren(children[i])) {
      const space = deepFindSpaceById(children[i].children, id);
      if (space) return space;
    }
  }
};
