import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Valid, Invalid, Warning } from 'app/components/Icons';
import { CertificateStatus } from 'types';

type StyledProps = {
  size?: '16px' | '24px';
};

type Props = {
  certificateStatus: CertificateStatus;
} & StyledProps;

function CertificateStatusIcon({ certificateStatus, size = '24px' }: Props) {
  const icon = {
    [CertificateStatus.VALID]: <Valid />,
    [CertificateStatus.ALMOST_EXPIRED]: <Warning />,
    [CertificateStatus.FAILED]: <Invalid />,
    [CertificateStatus.EXPIRED]: <Invalid />,
  };

  return <Wrapper size={size}>{icon[certificateStatus]}</Wrapper>;
}

export default CertificateStatusIcon;

export const Wrapper = styled.div<StyledProps>`
  ${({ size }) => css`
    svg {
      min-width: ${size};
      min-height: ${size};
    }
  `}
`;
