import { call, delay, put } from 'redux-saga/effects';
import get from 'lodash/get';

import { REQUEST_TIMEOUT_ERR_MESSAGE } from './apiUtils';

const MAX_RETRY_ATTEMPTS = 3;
const RETRY_DELAY = 2000;
const NETWORK_ERROR_MESSAGE = 'Network Error'; // This is what Axios gives us

const consoleRetryWarning = (message: string, attempt: number): void => {
  console.warn(`${message}, Retrying ${attempt} of ${MAX_RETRY_ATTEMPTS}`);
};

/**
 * This generator util is meant to help us retrying the APIs calls in case of network failure or timeout
 * We can also handle high level auth errors here as well.
 */
export default function* (fnDescriptor: any, ...args: any) {
  let apiError: Error;
  let attemptsRemaining: number = MAX_RETRY_ATTEMPTS;
  let retryConditionsMet: boolean = false;

  do {
    try {
      attemptsRemaining--;
      return yield call(fnDescriptor, ...args);
    } catch (e) {
      apiError = e;
      // Catch and retry for network errors and timeouts
      if (
        e.message === NETWORK_ERROR_MESSAGE ||
        e.message === REQUEST_TIMEOUT_ERR_MESSAGE
      ) {
        retryConditionsMet = true;
        consoleRetryWarning(e.message, MAX_RETRY_ATTEMPTS - attemptsRemaining);
        yield delay(RETRY_DELAY);
      }
      // For API errors, check the code and act accordingly
      else {
        const statusCode = get(apiError, 'response.status', 0);
        switch (statusCode) {
          case 401:
            // TODO integrate logging out
            yield put({ type: 'LOGOUT' });
            break;
        }
      }
    }
  } while (attemptsRemaining > 0 && retryConditionsMet);

  // Rethow API error if all applicable retries are unsuccessful
  throw apiError;
}
