import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.logsPanel || initialState;

export const selectAllAlerts = createSelector(
  [selectDomain],
  ({ alerts, alertsError, alertsLoading }) => ({
    alerts,
    alertsError,
    alertsLoading,
  }),
);
