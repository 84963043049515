import { DataTableSort } from 'app/components/DataTable/types';
import {
  CustomerLocation,
  User,
  OfflinedLocationInfoObject,
  SensorType,
  TemplateType,
  PageInfo,
  NameId,
} from 'types';
/* --- STATE --- */
export interface CustomerManagementState {
  customerId: string | null;
  locationId: string | null;
  sensorProjectLinkUnlinkLoading: boolean;
  linkedSensorProject: SensorProject | null;
  linkedSensorProjectLoading: boolean;
  linkedSensorProjectError: string;
  unlinkedSensorProjects: SensorProject[] | null;
  unlinkedSensorProjectsLoading: boolean;
  surveyTypes: SurveyType[] | null;
  surveyTypesLoading: boolean;
  surveyTypesError: string;
  locations: CustomerLocation[] | null;
  locationsOfflineStatus: OfflinedLocationInfoObject[] | null;
  locationsLoading: boolean;
  locationsError: string;
  locationUpdateError: string;
  locationUpdateLoading: boolean;
  locationDeleteError: string;
  locationDeleteLoading: boolean;
  users: User[];
  usersLoading: boolean;
  mendixSyncingAvailable: boolean;
  uploadLogs: UploadLog[] | null;
  uploadLogsLoading: boolean;
  uploadLogsError: string;
  uploadLogsSort?: DataTableSort;
  uploadLogsPageInfo: PageInfo;

  countries: NameId[];
  countriesLoading: boolean;
  countriesError: string;

  contractPhases: NameId[];
  contractPhasesLoading: boolean;

  technicalUsers: User[];
  technicalUsersLoading: boolean;
}

export type ContainerState = CustomerManagementState;

export type LocationWithOfflineStatus = CustomerLocation &
  OfflinedLocationInfoObject;

export type SurveyType = {
  description: string;
  extension: string;
  id: string;
};

export type SensorProject = {
  description?: string;
  projectName?: string;
  projectId: number | string;
  creationTimestamp?: string;
};

export type LinkUnlinkSensorPayload = {
  projectId: string;
  locationId: string;
  vendor: SensorType;
};

export type LocationVendor = {
  locationId: string;
  vendor: SensorType;
};

export enum LockStatus {
  LOCKED = 'LOCKED',
  UNLOCKED = 'UNLOCKED',
  SYNCING = 'SYNCHING',
}

export type UploadLog = {
  id: string;
  fileName: string;
  templateType: TemplateType;
  createdBy: string;
  creationDate: Date | string;
  status: string;
  originalFilePath: string;
  errorFilePath: string;
};

export type UploadLogsContent = {
  content: Array<UploadLog>;
  number: number;
  numberOfElements: number;
  totalPages: number;
  size: number;
};

export type LogsPayload = {
  customerId: string;
  locationId: string;
  pageSize: string;
  pageIndex: string;
  sortField: string;
  sortDirection: string;
};
