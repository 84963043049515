import { DataTableSort } from 'app/components/DataTable/types';
import { ProcessStatus } from 'app/components/ProcessStatusIndicator/types';
import { Resource, ResourceType } from 'types';

export type EquipmentAttachmentsState = {
  data?: Resource[];
  loading: boolean;
  error?: string;
  sort?: DataTableSort;
  isUploading: boolean;
  uploadingStatus?: ProcessStatus;
  resourceTypes?: ResourceType[];
};

export type StagingFile = {
  id: string;
  file: File;
};

export type ResourcePayload = {
  file: File;
  resourceType: ResourceType;
  reportDate?: string;
  specificProperties?: {
    isValid: boolean;
    validUntil?: string;
  };
};

export type UploadResourcePayload = {
  customerId: string;
  locationId: string;
  equipmentId: string;
  attachments: ResourcePayload[];
};

export enum CertificateValidity {
  Default = '12',
  Other = 'other',
}

export enum CertificateResult {
  Pass = 'pass',
  Fail = 'fail',
}
