import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { reducer, sliceKey, actions } from './slice';
import { mediaGallerySaga } from './saga';
import { selectMediaItemsError } from './selectors';
import { Modal } from 'app/components/Modal';
import { SignalMessage, Signal } from 'app/components/SignalMessage';
import { MediaManagement } from 'app/containers/MediaManagement';
import { usePrevious } from 'utils/hooks/usePrevious';
import { MediaGalleryProps } from './types';
import MediaCarousel from './components/MediaCarousel';

export const MediaGallery = memo((props: MediaGalleryProps) => {
  const {
    entityType,
    entityId,
    entityName = '',
    resourceTypes,
    className = '',
    canManage = false,
  } = props;
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: mediaGallerySaga });

  const dispatch = useDispatch();

  const { customerId, locationId } = useParams<{
    customerId: string;
    locationId: string;
  }>();

  const [mangeMediaModalOpen, setManageMediaModalOpen] = useState<boolean>(
    false,
  );

  const error = useSelector(state => selectMediaItemsError(state, entityId));

  const prevEntityId = usePrevious(entityId);

  useEffect(() => {
    if (entityId !== prevEntityId) {
      dispatch(
        actions.getMediaItems({
          entityType,
          entityId,
          resourceTypes,
        }),
      );
    }
  }, [dispatch, entityId, entityType, prevEntityId, resourceTypes]);

  if (error) return <SignalMessage type={Signal.ALERT} message={error} />;

  return (
    <div className={className}>
      <MediaCarousel
        {...props}
        onManageMediaClick={() => setManageMediaModalOpen(true)}
      />
      {mangeMediaModalOpen && canManage && (
        <Modal
          onClose={() => setManageMediaModalOpen(false)}
          withOutsideClose={true}
        >
          <MediaManagement
            customerId={customerId}
            locationId={locationId}
            resourceTypes={resourceTypes}
            entityType={entityType}
            entityId={entityId}
            entityName={entityName}
          />
        </Modal>
      )}
    </div>
  );
});
