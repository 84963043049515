import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice, nanoid } from 'utils/@reduxjs/toolkit';

import { ContainerState, AnnouncementItem } from './types';

// The initial state of the announcements state
export const initialState: ContainerState = {
  announcements: [],
};

const addAnnouncementReducer = (
  state,
  action: PayloadAction<AnnouncementItem>,
) => {
  return {
    ...state,
    announcements: [...state.announcements, action.payload],
  };
};

const announcementsSlice = createSlice({
  name: 'announcements',
  initialState,
  reducers: {
    addError: {
      reducer: addAnnouncementReducer,
      // @ts-ignore -> FIXME: Typescript doesn't detect action type correctly
      prepare: (message: string) => ({
        payload: { id: nanoid(), type: 'error', message },
      }),
    },
    addSuccess: {
      reducer: addAnnouncementReducer,
      // @ts-ignore
      prepare: (message: string) => ({
        payload: { id: nanoid(), type: 'success', message },
      }),
    },
    addWarning: {
      reducer: addAnnouncementReducer,
      // @ts-ignore
      prepare: (message: string) => ({
        payload: { id: nanoid(), type: 'warning', message },
      }),
    },
    removeAnnouncement(state, action: PayloadAction<string>) {
      return {
        ...state,
        announcements: state.announcements.filter(
          announcement => announcement.id !== action.payload,
        ),
      };
    },
  },
});

export const { actions, reducer, name: sliceKey } = announcementsSlice;
