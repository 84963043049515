import { AxiosResponse } from 'axios';
import { sub, format } from 'date-fns';

import {
  AggregatedConditionScore,
  AggregatedSensorAlert,
  Alert,
  SensorPropertyScore,
  ManualSurvey,
} from 'types';
import * as RESOURCE_PATHS from './resourcePaths';
import { createAbsoluteUrl, apiGet, apiPost, apiPut } from './utils/apiUtils';

const API_BASE_URL: string =
  process.env.REACT_APP_API_COND_MONITORING_HOSTNAME || '';

export const getSurveys = async (): Promise<any> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V4,
      RESOURCE_PATHS.CONDITION_MONITORING_SURVEYS,
    ),
  );
};

// Returns entire hierarchy tree under an item (including the item)
export const getHierarchyTreeWithSeverity = async (
  rootItemId: string,
): Promise<any> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V4,
      RESOURCE_PATHS.CONDITION_MONITORING_HIERARCHY_TREE.replace(
        /%id/,
        rootItemId,
      ),
    ),
  );
};

export const getGroupedSeverityByLocation = async (
  locationId: string,
): Promise<any> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V4,
      RESOURCE_PATHS.CONDITION_MONITORING_GROUPED_SEVERITY_BY_LOCATION.replace(
        /%id/,
        locationId,
      ),
    ),
  );
};

export const getAssetAggregatedConditionScore = async (
  assetId: string,
): Promise<{ data: AggregatedConditionScore }> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V4,
      RESOURCE_PATHS.CONDITION_MONITORING_ASSET_AGGREGATED_CONDITION_SCORE.replace(
        /%id/,
        assetId,
      ),
    ),
  );
};

export const getAssetAggregatedSensorAlert = async (
  assetId: string,
): Promise<{ data: AggregatedSensorAlert }> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V4,
      RESOURCE_PATHS.CONDITION_MONITORING_ASSET_AGGREGATED_SENSOR_ALERT.replace(
        /%id/,
        assetId,
      ),
    ),
  );
};

export const getLocationAssetsConditionScores = async (
  locationId: string,
  date: string,
): Promise<any> => {
  const params = new URLSearchParams();
  params.append('targetDate', date);

  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V4,
      RESOURCE_PATHS.CONDITION_MONITORING_LOCATION_ASSETS_CONDITION_SCORES.replace(
        /%id/,
        locationId,
      ),
      params,
    ),
  );
};

export const getAssetConditionScoreSensorHistory = async (
  assetId: string,
  startDate: string,
  endDate: string,
): Promise<any> => {
  const params = new URLSearchParams();
  params.append('startDate', startDate);
  params.append('endDate', endDate);

  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V4,
      RESOURCE_PATHS.CONDITION_MONITORING_ASSET_CONDITION_SCORE_SENSOR_HISTORY.replace(
        /%id/,
        assetId,
      ),
      params,
    ),
  );
};

export const getAssetConditionScoresSkf = async (
  assetId: string,
  startDate: string,
  endDate: string,
): Promise<any> => {
  const params = new URLSearchParams();
  params.append('startDate', startDate);
  params.append('endDate', endDate);

  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V4,
      RESOURCE_PATHS.CONDITION_MONITORING_ASSET_CONDITION_SCORE_SKF.replace(
        /%id/,
        assetId,
      ),
      params,
    ),
  );
};
export const getMIConditionScoresSkf = async (
  maintainableItemId: string,
  startDate: string,
  endDate: string,
): Promise<any> => {
  const params = new URLSearchParams();
  params.append('startDate', startDate);
  params.append('endDate', endDate);

  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V4,
      RESOURCE_PATHS.CONDITION_MONITORING_MAINTAINABLE_ITEM_CONDITION_SCORE_SKF.replace(
        /%id/,
        maintainableItemId,
      ),
      params,
    ),
  );
};

export const getSUAggregatedConditionScore = async (
  subUnitId: string,
): Promise<{ data: AggregatedConditionScore }> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V4,
      RESOURCE_PATHS.CONDITION_MONITORING_SUB_UNIT_AGGREGATED_CONDITION_SCORE,
    ).replace(/%id/, subUnitId),
  );
};

export const getMIAggregatedConditionScore = async (
  maintainableItemId: string,
): Promise<{ data: AggregatedConditionScore }> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V4,
      RESOURCE_PATHS.CONDITION_MONITORING_MAINTAINABLE_ITEM_AGGREGATED_CONDITION_SCORE,
    ).replace(/%id/, maintainableItemId),
  );
};

export const getMIAggregatedSensorAlert = async (
  maintainableItemId: string,
): Promise<{ data: AggregatedSensorAlert }> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V4,
      RESOURCE_PATHS.CONDITION_MONITORING_MAINTAINABLE_ITEM_AGGREGATED_SENSOR_ALERT,
    ).replace(/%id/, maintainableItemId),
  );
};

export const getMISensorHistory = async (
  maintainableItemId: string,
  startDate: string,
  endDate: string,
): Promise<any> => {
  const params = new URLSearchParams();
  params.append('startDate', startDate);
  params.append('endDate', endDate);

  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V4,
      RESOURCE_PATHS.CONDITION_MONITORING_MAINTAINABLE_ITEM_SENSOR_HISTORY.replace(
        /%id/,
        maintainableItemId,
      ),
      params,
    ),
  );
};

export const getMILatestSensor = async (
  maintainableItemId: string,
): Promise<any> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V4,
      RESOURCE_PATHS.CONDITION_MONITORING_MAINTAINABLE_ITEM_LATEST_SENSOR.replace(
        /%id/,
        maintainableItemId,
      ),
    ),
  );
};

export const postSurveyToLocation = async (
  payload: FormData,
  customerId: string,
  locationId: string,
  surveyType: string,
): Promise<any> => {
  return apiPost(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V4,
      RESOURCE_PATHS.CONDITION_MONITORING_POST_SURVEY_TO_LOCATION.replace(
        /%surveyType/,
        surveyType,
      )
        .replace(/%customerId/, customerId)
        .replace(/%locationId/, locationId),
    ),
    payload,
  );
};

export const getHistoryForSensor = async (
  sensorId: string,
  startDate: string,
  endDate: string,
): Promise<any> => {
  const params = new URLSearchParams();
  params.append('start', startDate);
  params.append('end', endDate);

  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V4,
      RESOURCE_PATHS.CONDITION_MONITORING_SENSOR_CONDITION_SCORE_SENSOR_HISTORY.replace(
        /%id/,
        sensorId,
      ),
      params,
    ),
  );
};

export const getHistoryForPack = async (
  packId: string,
  startDate: Date,
  endDate: Date,
): Promise<any> => {
  const params = new URLSearchParams();

  params.append('startDate', format(startDate, 'yyyy-MM-dd'));
  params.append('endDate', format(endDate, 'yyyy-MM-dd'));

  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V4,
      RESOURCE_PATHS.CONDITION_MONITORING_PACK_CONDITION_SCORE_HISTORY.replace(
        /%id/,
        packId,
      ),
      params,
    ),
  );
};

export const getLocationReportDownload = async (
  locationId: string,
  assetIds: string[],
): Promise<AxiosResponse<string>> => {
  return apiPost(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V4,
      RESOURCE_PATHS.CONDITION_MONITORING_REPORT.replace(
        /%locationId/,
        locationId,
      ),
    ),
    assetIds,
  );
};

export const postMIManualSurvey = async (
  maintainableItemId: string,
  values: ManualSurvey,
): Promise<any> => {
  return apiPost(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V4,
      RESOURCE_PATHS.CONDITION_MONITORING_MAINTAINABLE_ITEM_MANUAL_SURVEY.replace(
        /%id/,
        maintainableItemId,
      ),
    ),
    values,
  );
};

export const updateSurvey = async (
  id: string,
  values: ManualSurvey,
): Promise<any> => {
  const body = {
    ...values,
    creationTime: undefined,
  };

  return apiPut(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V4,
      `${RESOURCE_PATHS.CONDITION_MONITORING_SURVEYS}/${id}`,
    ),
    body,
  );
};

export const getSensorScoresByProperties = async (
  sensorId: string,
): Promise<{ data: SensorPropertyScore[] }> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V4,
      RESOURCE_PATHS.CONDITION_MONITORING_SENSOR_PROPERTIES_LATEST_SENSOR_SCORE.replace(
        /%sensorId/,
        sensorId,
      ),
    ),
  );
};

export const getSUSensorAlerts = async (
  maintainableItemId: string,
): Promise<{ data: Alert[] }> => {
  const params = new URLSearchParams();
  const now = new Date();
  const start = sub(now, { months: 3 });

  params.append('start', start.toISOString());
  params.append('end', now.toISOString());

  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V4,
      RESOURCE_PATHS.CONDITION_MONITORING_SUB_UNIT_SENSOR_ALERTS.replace(
        /%id/,
        maintainableItemId,
      ),
      params,
    ),
  );
};

export const getMISensorAlerts = async (
  maintainableItemId: string,
  start: Date = sub(new Date(), { months: 3 }),
  end: Date = new Date(),
): Promise<{ data: Alert[] }> => {
  const params = new URLSearchParams();

  params.append('start', start.toISOString());
  params.append('end', end.toISOString());

  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V4,
      RESOURCE_PATHS.CONDITION_MONITORING_MAINTAINABLE_ITEM_SENSOR_ALERTS.replace(
        /%id/,
        maintainableItemId,
      ),
      params,
    ),
  );
};

export const getPackConditionScore = async (packId: string): Promise<any> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V4,
      RESOURCE_PATHS.CONDITION_MONITORING_PACK_CONDITION_SCORE.replace(
        /%id/,
        packId,
      ),
    ),
  );
};

export const getPropertyLatestAlert = async (
  propertyId: string,
  sensorIds: string[],
): Promise<{ data: Alert[] }> => {
  const params = new URLSearchParams();
  sensorIds.forEach(id => params.append('sensors', id));

  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V4,
      RESOURCE_PATHS.CONDITION_MONITORING_PROPERTY_ALERT.replace(
        /%propertyId/,
        propertyId,
      ),
      params,
    ),
  );
};
