import { Alert, AlertType } from 'types';
import { formatDate, formatTime } from 'locales/date-format-i18n';

export const getTime = (alert: Alert): string => {
  const endTime = formatTime(alert.endTime);

  const time = alert.type === AlertType.CONDITION ? '' : endTime;

  return time;
};

export const getDate = (alert: Alert): string => {
  const startTime = formatDate(alert.startTime);
  const endTime = formatDate(alert.endTime);

  const customTime =
    startTime === endTime ? startTime : `${startTime} - ${endTime}`;

  const date = alert.type === AlertType.SURVEY ? endTime : customTime;

  return date;
};
