import React from 'react';
import styled from 'styled-components/macro';

export default function InputFile(
  props: React.InputHTMLAttributes<HTMLInputElement>,
) {
  return (
    <Wrapper>
      <input type="file" {...props} />
      <label htmlFor={props.id}>{props.title}</label>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;

  &::before {
    content: '';
    background-color: ${({ theme }) => theme.colors.default.gray1};
    flex: 1;
  }

  &:has(input:disabled)::before {
    background-color: ${({ theme }) => theme.colors.default.gray2};
  }

  label {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 16px;
    font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
    font-weight: ${({ theme }) => theme.fonts.fontWeight.medium};
    color: ${({ theme }) => theme.colors.default.blue1};
    border: 1px solid ${({ theme }) => theme.colors.default.blue1};
    cursor: pointer;
  }

  input[type='file'] {
    display: none;
  }

  input:disabled + label {
    color: ${({ theme }) => theme.colors.default.gray2};
    border: 1px solid ${({ theme }) => theme.colors.default.gray2};
  }
`;
