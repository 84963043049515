import { HierarchyNode, EntityType } from 'types';
import { hasChildren } from './hasChildren';

export function deepGetAssets(tree: HierarchyNode[]): HierarchyNode[] {
  let assetNodes: HierarchyNode[] = [];
  for (let i = 0; i < tree.length; i++) {
    if (tree[i].type === EntityType.ASSET) {
      assetNodes = assetNodes.concat(tree[i]);
    }
    if (hasChildren(tree[i])) {
      assetNodes = assetNodes.concat(deepGetAssets(tree[i].children));
    }
  }

  return assetNodes;
}
