import { ButtonSecondary } from 'app/elements/Buttons';
import React from 'react';
import { Icon } from '@eriksdigital/atomic-ui/components';
import { AddAltIcon } from '@eriksdigital/atomic-ui/components/Icons';
import useInspections from '../hooks/useInspections';
import t from 'locales/translation';

function InspectionNewButton() {
  const { openInspectionSideBar } = useInspections();

  return (
    <ButtonSecondary onClick={openInspectionSideBar}>
      <Icon as={AddAltIcon} size="sz20" />
      {t('Inspections.new')}
    </ButtonSecondary>
  );
}

export default InspectionNewButton;
