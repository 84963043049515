import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeEvery } from 'redux-saga/effects';

import { EntityType } from 'types';
import { translationString } from 'locales/translation';
import { notifyAxiosError } from 'utils/error-helpers';
import apiCall from 'services/utils/apiCall';
import { getTaskReport } from 'services/taskManagementService';
import { getLocationReportDownload } from 'services/conditionMonitoringService';
import { actions } from './slice';

export function* getGenerateReport(
  action: PayloadAction<{
    type: 'Condition' | 'Task';
    entityId: string;
    entityType: EntityType;
    filterIds: string[];
  }>,
) {
  const { type, entityId, entityType, filterIds } = action.payload;

  // Currently only location reports are supported
  if (entityType !== EntityType.LOCATION) return;

  try {
    const { data, headers } = yield apiCall(
      type === 'Condition' ? getLocationReportDownload : getTaskReport,
      entityId,
      filterIds,
    );

    const fileName = headers['content-disposition'].split('"')[1];
    const file = new Blob([...data], { type: headers['content-type'] });

    yield put(actions.generateReportSuccess({ type, file, fileName }));
  } catch (error) {
    yield notifyAxiosError(
      error,
      translationString('ErrorMessage.AssetStatusCounts'),
    );
    yield put(actions.generateReportFailed(entityId));
  }
}

export function* reportProviderSaga() {
  yield takeEvery(actions.generateReport.type, getGenerateReport);
}
