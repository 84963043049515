/**
 *
 * TaskStatus
 *
 */
import React from 'react';
import styled from 'styled-components/macro';

import t from 'locales/translation';
import { TaskStatus } from 'types';
import { Div } from 'app/components/Div';
import { getBGForStatus, getColorForStatus } from 'utils/getColor';

interface Props {
  status: TaskStatus;
}

export function Status({ status }: Props) {
  return (
    <StatusCell display="flex" status={status}>
      {t(status)}
    </StatusCell>
  );
}

const StatusCell = styled(Div)<{ status: TaskStatus }>`
  min-width: 50px;

  span {
    font-weight: ${({ theme }) => theme.fonts.fontWeight.normal};
    font-size: ${({ theme }) => theme.fonts.fontSize.ft12};
    line-height: ${({ theme }) => theme.fonts.fontSize.ft12};
    padding: 6px 8px;

    background: ${({ status, theme }) => getBGForStatus(status, theme)};
    color: ${({ status, theme }) => getColorForStatus(status, theme)};
    border-radius: 3px;
  }
`;
