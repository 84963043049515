import { put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { sub, add } from 'date-fns';

import { translationString } from 'locales/translation';
import {
  Alert,
  AlertType,
  SensorProperty,
  ManualSurvey,
  EntityType,
} from 'types';
import apiCall from 'services/utils/apiCall';
import {
  getMISensorAlerts,
  getSUSensorAlerts,
  postMIManualSurvey,
  updateSurvey,
} from 'services/conditionMonitoringService';
import { getSensorsValues } from 'services/sensorService';
import { actions as announcementActions } from 'app/containers/AnnouncementsToast/slice';
import {
  selectSensors,
  selectPacks,
} from 'app/containers/AssetDashboard/selectors';
import { notifyAxiosError } from 'utils/error-helpers';
import { getAlertName } from 'utils/alerts';
import { actions } from './slice';

export function* getAlerts(
  action: PayloadAction<{ id: string; type: EntityType }>,
) {
  try {
    const { id, type } = action.payload;

    const { data: alerts }: { data: Alert[] } = yield apiCall(
      type === EntityType.SUB_UNIT ? getSUSensorAlerts : getMISensorAlerts,
      id,
    );

    const { sensors } = yield select(selectSensors);
    const packs = yield select(selectPacks);

    const filteredAlerts = alerts
      .filter(
        alert =>
          alert.conditionScore !== 0 ||
          (alert.conditionScore === 0 && alert.type === AlertType.SURVEY),
      )
      .map(alert => {
        const sensor = sensors?.find(s => s.sensorId === alert.sensorId || '');

        const pack = alert.packId
          ? packs?.find(p => p.properties?.id === alert.packId)
          : undefined;
        const packType = pack?.configuration.type;

        return {
          ...alert,
          name: sensor ? sensor.name : getAlertName(alert.type, packType),
        };
      });

    yield put(actions.getAlertsSuccessful(filteredAlerts));
  } catch (error) {
    const effectError = yield notifyAxiosError(
      error,
      translationString('ErrorMessage.AlertsRetrievingError'),
    );
    yield put(actions.getAlertsError(effectError));
  }
}

export function* createManualSurvey(
  action: PayloadAction<{ id: string; values: ManualSurvey }>,
) {
  const { id, values } = action.payload;

  try {
    yield apiCall(postMIManualSurvey, id, values);

    yield put(actions.getAlerts({ id, type: EntityType.MAINTAINABLE_ITEM }));
    yield put(
      announcementActions.addSuccess(
        translationString('SuccessMessage.SaveManualSurvey'),
      ),
    );
  } catch (error) {
    yield notifyAxiosError(
      error,
      translationString('ErrorMessage.SaveManualSurvey'),
    );
  }
}

export function* updateManualSurvey(
  action: PayloadAction<{
    id: string;
    maintainableItemId: string;
    values: ManualSurvey;
  }>,
) {
  const { id, values, maintainableItemId } = action.payload;

  try {
    yield apiCall(updateSurvey, id, values);

    yield put(
      actions.getAlerts({
        id: maintainableItemId,
        type: EntityType.MAINTAINABLE_ITEM,
      }),
    );
    yield put(
      announcementActions.addSuccess(
        translationString('SuccessMessage.SaveManualSurvey'),
      ),
    );
  } catch (error) {
    yield notifyAxiosError(
      error,
      translationString('ErrorMessage.SaveManualSurvey'),
    );
  }
}

export function* getIncidentGraphValues(action: PayloadAction<Alert>) {
  const { id, sensorId, propertyId, startTime, endTime } = action.payload;

  try {
    if (!id || !propertyId || !startTime || startTime > endTime) return;

    const start = sub(new Date(startTime), { minutes: 30 });
    const end = add(new Date(endTime), { minutes: 10 });

    const { data: sensorValues }: { data: SensorProperty[] } = yield apiCall(
      getSensorsValues,
      sensorId,
      [propertyId],
      start.toISOString(),
      end.toISOString(),
    );

    yield put(
      actions.getIncidentGraphValuesSuccess({
        alertId: id,
        values: sensorValues,
      }),
    );
  } catch (error) {
    yield notifyAxiosError(
      error,
      translationString('ErrorMessage.SensorDataRetrievingError'),
    );
  }
}

export function* logsPanelSaga() {
  yield takeLatest(actions.getAlerts.type, getAlerts);
  yield takeLatest(actions.createManualSurvey.type, createManualSurvey);
  yield takeLatest(actions.updateManualSurvey.type, updateManualSurvey);
  yield takeEvery(actions.getIncidentGraphValues.type, getIncidentGraphValues);
}
