import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { Task, Worklog, TaskReq, TaskTypeMetadata } from 'types';
import { ContainerState } from './types';

// The initial state of the TaskDetails container
export const initialState: ContainerState = {
  taskLoading: false,
  taskError: '',

  metadata: [],

  worklogs: [],
  worklogLoading: false,
};

const taskDetailsSlice = createSlice({
  name: 'taskDetails',
  initialState,
  reducers: {
    getTask(state, action: PayloadAction<string>) {
      return {
        ...state,
        taskLoading: true,
      };
    },
    getTaskSuccessful(state, action: PayloadAction<Task>) {
      return {
        ...state,
        task: action.payload,
        taskLoading: initialState.taskLoading,
        taskError: initialState.taskError,
      };
    },
    getTaskError(state, action: PayloadAction<string>) {
      return {
        ...state,
        task: initialState.task,
        taskLoading: initialState.taskLoading,
        taskError: action.payload,
      };
    },

    updateTask(state, action: PayloadAction<TaskReq>) {
      return {
        ...state,
        worklogLoading: true,
      };
    },
    updateTaskSuccessful(state, action: PayloadAction<Task>) {
      return {
        ...state,
        task: action.payload,
      };
    },

    getTaskMetadata(state) {
      return {
        ...state,
      };
    },
    getMetadataSuccess(state, action: PayloadAction<TaskTypeMetadata[]>) {
      return {
        ...state,
        metadata: action.payload,
      };
    },

    getNotifications(state, action: PayloadAction<string>) {
      return {
        ...state,
      };
    },
    stopNotifications(state, action: PayloadAction<string>) {
      return {
        ...state,
      };
    },
    subscribeToTask(state, action: PayloadAction<boolean>) {
      if (state.task) {
        return {
          ...state,
          task: { ...state.task, notifyMe: action.payload },
        };
      }
      return {
        ...state,
      };
    },

    deleteTask(state, action: PayloadAction<string>) {
      return {
        ...state,
      };
    },

    getWorklogs(state, action: PayloadAction<string>) {
      return {
        ...state,
      };
    },
    getWorklogsSuccessful(state, action: PayloadAction<Worklog[]>) {
      return {
        ...state,
        worklogs: action.payload,
        worklogLoading: initialState.worklogLoading,
      };
    },

    componentWillUnmount() {
      return initialState;
    },
  },
});

export const { actions, reducer, name: sliceKey } = taskDetailsSlice;
