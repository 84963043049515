import { format, parse, isValid } from 'date-fns';
import { enGB, fr, de, nl } from 'date-fns/locale';
import { timeFormatDefaultLocale } from 'd3-time-format';

import { USER_LANGUAGE_CODE, LANGUAGE_CODE } from 'utils/constants';
import { timescale_en_GB } from './d3-times-scales';

timeFormatDefaultLocale(timescale_en_GB);

const locales = { en: enGB, fr, de, nl };

const getLocale = (): string =>
  localStorage.getItem(USER_LANGUAGE_CODE) ||
  localStorage.getItem(LANGUAGE_CODE) ||
  'en';

// convert date string from dd-MM-yyyy to yyyy-MM-dd
export const convertToIsoDateFormat = (dateStr: string) => {
  const parsedDate = parse(dateStr, 'dd-MM-yyyy', new Date());
  return format(parsedDate, 'yyyy-MM-dd');
};

export const getDate = (date: Date | string): Date => {
  let parsedDate: Date;

  if (typeof date === 'string') {
    const isoDate = new Date(date);
    if (isValid(isoDate)) {
      // Check if the date string is in ISO format,if yes use it directly
      return isoDate;
    }

    // Try parsing with format 'dd-MM-yyyy'
    parsedDate = parse(date, 'dd-MM-yyyy', new Date());
    if (!isValid(parsedDate)) {
      // Try parsing with format 'yyyy-MM-dd'
      parsedDate = parse(date, 'yyyy-MM-dd', new Date());
    }
  } else {
    // If it's already a Date object, no need to parse
    parsedDate = date;
  }

  return isValid(parsedDate) ? parsedDate : new Date(NaN);
};

const formatDateWithLocale = (
  date: Date | string,
  formatStr: string,
  locale: string,
): string => {
  const newDate = getDate(date);
  return isValid(newDate)
    ? format(newDate, formatStr, { locale: locales[locale] })
    : date.toString();
};

export const formatDate = (
  date: Date | string,
  formatStr: string = 'dd MMM y',
): string => formatDateWithLocale(date, formatStr, getLocale());

export const formatDateTime = (
  date: Date | string,
  formatStr: string = 'dd MMM y, HH:mm',
): string => formatDateWithLocale(date, formatStr, getLocale());

export const formatTime = (
  date: Date | string,
  formatStr: string = 'HH:mm',
): string => {
  const newDate = getDate(date);
  return isValid(newDate) ? format(newDate, formatStr) : date.toString();
};

export const isISODateString = (str: string) => {
  return /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)Z$/gm.test(
    str,
  );
};
