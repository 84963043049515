import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.qrCode || initialState;

export const selectQrCode = createSelector(
  [selectDomain],
  ({ loading, tag, graphs }) => ({
    loading,
    tag,
    graphs,
  }),
);

export const selectIsLoading = createSelector(
  [selectDomain],
  ({ loading }) => loading,
);

export const selectRedirectInfo = createSelector(
  [selectDomain],
  ({ tag, parentTree }) => {
    const customerId = tag?.customerId;
    const miId = tag?.maintainableItemId;
    const locationId = parentTree?.parent?.id;

    return customerId && miId && locationId
      ? { customerId, miId, locationId }
      : undefined;
  },
);
