/**
 *
 * PrivateRoute
 *
 */
import React, { FunctionComponent, ComponentClass } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAuth } from 'app/providers/AuthProvider';

interface Props extends RouteProps {
  component: FunctionComponent<any> | ComponentClass<any, any>;
  requiredRoles: string[];
}

export function PrivateRoute({ component, requiredRoles, ...rest }: Props) {
  let auth = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.userHasRole(requiredRoles) ? (
          React.createElement(component)
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
