import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { reducer, sliceKey, actions } from '../slice';
import { equipmentsSaga } from '../saga';
import { selectIsLoadingMetadata, selectMetadata } from '../selectors';

function useMetadata() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: equipmentsSaga });

  const dispatch = useDispatch();

  const metadata = useSelector(selectMetadata);
  const isLoadingMetadata = useSelector(selectIsLoadingMetadata);

  const fetchMetadata = useCallback(() => {
    dispatch(actions.getMetadata());
  }, [dispatch]);

  return { metadata, isLoadingMetadata, fetchMetadata };
}

export default useMetadata;
