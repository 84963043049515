import { Notification, NotificationPanelItem } from './types';
import {
  buildDashboardAssetRoute,
  buildDashboardAssetsRoute,
  buildDashboardAttachmentsRoute,
  buildDashboardAssetMIRoute,
} from 'utils/route-helpers';
import { EntityType } from 'types';

export const mapNotificationsToPanelItems = (
  notifications: Notification[],
): NotificationPanelItem[] => {
  return notifications.map(note => {
    let actionRoute = '';

    // Calculate the action route
    if (note.eventCategory === 'FILE_UPLOAD') {
      actionRoute = buildDashboardAttachmentsRoute(
        note.customerId,
        note.locationId,
      );
    } else if (note.eventCategory === 'MANUAL_SURVEY_UPLOAD') {
      actionRoute = buildDashboardAssetsRoute(
        note.customerId,
        note.locationId,
        note.eventItems.map(eventItem => eventItem.itemId),
      );
    } else if (note.eventCategory === 'STATUS_ALERT') {
      let assetId = '';
      let maintainableItemId = '';

      note.eventItems.forEach(item => {
        if (item.itemType === EntityType.ASSET) {
          assetId = item.itemId;
        }
        if (item.itemType === EntityType.MAINTAINABLE_ITEM) {
          maintainableItemId = item.itemId;
        }
      });

      if (assetId) {
        if (maintainableItemId) {
          actionRoute = buildDashboardAssetMIRoute(
            note.customerId,
            note.locationId,
            assetId,
            maintainableItemId,
          );
        } else {
          actionRoute = buildDashboardAssetRoute(
            note.customerId,
            note.locationId,
            assetId,
          );
        }
      } else {
        actionRoute = buildDashboardAssetsRoute(
          note.customerId,
          note.locationId,
        );
      }
    } else if (note.eventCategory === 'ASSET_SEVERITY_CHANGE') {
      // More than one item, show filtered asset table
      if (note.eventItems.length > 1) {
        actionRoute = buildDashboardAssetsRoute(
          note.customerId,
          note.locationId,
          note.eventItems.map(eventItem => eventItem.itemId),
        );
      }
      // Single asset, link directly to the asset
      else {
        actionRoute = buildDashboardAssetRoute(
          note.customerId,
          note.locationId,
          note.eventItems[0].itemId,
        );
      }
    }

    return {
      ...note,
      eventCreatedTime: new Date(note.eventCreatedTime),
      actionRoute: actionRoute,
    };
  });
};
