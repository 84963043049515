import React from 'react';
import styled from 'styled-components/macro';
import t from 'locales/translation';

function NothingFound() {
  return <NothingFoundStyled>{t('NothingFound')}</NothingFoundStyled>;
}

export default NothingFound;

const NothingFoundStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  color: ${({ theme }) => theme.colors.default.gray3};
  font-size: ${({ theme }) => theme.fonts.fontSize.ft12};
  text-transform: uppercase;
  border-top: 1px solid ${({ theme }) => theme.colors.default.gray1};
  border-bottom: 1px solid ${({ theme }) => theme.colors.default.gray1};
`;
