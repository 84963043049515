import React from 'react';
import { MetadataTypeAttributes } from 'types';
import EquipmentLinkOut from 'app/containers/Equipments/components/EquipmentLinkOut';
import { isEquipmentLinkOut } from 'app/containers/Equipments/utils';
import { formatDate } from 'locales/date-format-i18n';

type Props = {
  attribute: MetadataTypeAttributes;
};

function AttributeValue({ attribute }: Props) {
  if (!attribute.value || !attribute.displayValue) {
    return <>--</>;
  }

  if (isEquipmentLinkOut(attribute)) {
    return (
      <EquipmentLinkOut miId={attribute.value} label={attribute.displayValue} />
    );
  }

  if (attribute.type === 'date' || attribute.type === 'datetime') {
    return <>{formatDate(attribute.displayValue)}</>;
  }

  return <>{attribute.displayValue}</>;
}

export default AttributeValue;
