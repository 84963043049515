import React from 'react';
import styled from 'styled-components/macro';
import { useParams, useHistory } from 'react-router-dom';
import { Icon } from '@eriksdigital/atomic-ui/components';
import { BackArrowIcon } from '@eriksdigital/atomic-ui/components/Icons';

import t from 'locales/translation';
import { styledIcon } from 'styles';
import { BgColor, TextColor } from 'types';
import { buildEquipmentRoute } from 'utils/route-helpers';
import { Div } from 'app/components/Div';
import { Text } from 'app/components/Typography';

export function BackLink() {
  const history = useHistory();
  const { customerId, miId } = useParams() as {
    customerId: string;
    miId: string;
  };

  return (
    <Div
      display="flex"
      align="center"
      padding="16px"
      bg={BgColor.white}
      color={TextColor.secondary}
      onClick={() => history.push(buildEquipmentRoute(customerId, miId))}
    >
      <StyledIcon as={BackArrowIcon} size="sz20" />
      <Text fontSize="ft14">{t('Back')}</Text>
    </Div>
  );
}

const StyledIcon = styled(Icon)`
  ${styledIcon}
  margin-right: ${({ theme }) => theme.spacing.sp8};
`;
