import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@eriksdigital/atomic-ui/components';
import { AddAltIcon } from '@eriksdigital/atomic-ui/components/Icons';
import t, { translationString } from 'locales/translation';
import { H1 } from 'app/components/Typography';
import { ReactComponent as EquipmentIcon } from 'assets/icons/equipment.svg';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { buildEquipmentsRoute } from 'utils/route-helpers';
import { ButtonSecondary } from 'app/elements/Buttons';
import {
  selectSelectedCustomer,
  selectSelectedLocation,
} from 'app/containers/CustomerLocation/selectors';
import useEquipmentTypes from '../../Equipments/hooks/useEquipmentTypes';
import { actions } from '../slice';
import { selectSubMenuIsOpen } from '../selectors';
import MenuItem from './MenuItem';

function MenuEquipment() {
  const customerId = useSelector(selectSelectedCustomer);
  const locationId = useSelector(selectSelectedLocation);
  const subMenu = 'equipments';
  const isOpen = useSelector(selectSubMenuIsOpen(subMenu));
  const {
    equipmentTypes,
    isLoadingEquipmentTypes,
    fetchEquipmentTypes,
  } = useEquipmentTypes({ locationId });
  const dispatch = useDispatch();

  useEffect(() => {
    if (locationId) {
      fetchEquipmentTypes();
    }
  }, [fetchEquipmentTypes, locationId]);

  useEffect(() => {
    if (isOpen) {
      fetchEquipmentTypes();
    }
  }, [isOpen, fetchEquipmentTypes]);

  return (
    <MenuItem
      icon={EquipmentIcon}
      label={translationString('Equipment')}
      subMenu={subMenu}
      bottom={
        <>
          <H1>{t('EquipmentType.Add')}</H1>
          <ButtonAddEquipmentType
            onClick={() => dispatch(actions.setIsCreatingEquipmentType(true))}
          >
            <Icon as={AddAltIcon} size="sz20" />
            {t('EquipmentType.AddNewType')}
          </ButtonAddEquipmentType>
        </>
      }
    >
      <H1>{t('Equipment')}</H1>

      {isLoadingEquipmentTypes && <LoadingIndicator />}

      {!isLoadingEquipmentTypes &&
        equipmentTypes?.map(et => (
          <Link
            key={`EquipmentType_${et.miTypeId}`}
            to={buildEquipmentsRoute(customerId, locationId || '', et.miTypeId)}
          >
            {`${et.translation} (${et.miTypeCount})`}
          </Link>
        ))}
    </MenuItem>
  );
}

export const ButtonAddEquipmentType = styled(ButtonSecondary)`
  color: ${({ theme }) => theme.colors.default.white};
  margin-bottom: 16px;
`;

export default MenuEquipment;
