/**
 *
 * Avatar
 *
 */

import React from 'react';
import styled from 'styled-components/macro';

import { TextColor } from 'types';
import { Div } from 'app/components/Div';
import { Text } from 'app/components/Typography';

interface Props {
  name: string;
  size?: number;
  fontSize?: number;
}

export function Avatar({ name, size, fontSize }: Props) {
  const formatName = (name: string): string => {
    const names = name.split(' ');
    let label = names[0].charAt(0);
    if (names.length > 1) {
      label += names[names.length - 1].charAt(0);
    }
    return label;
  };

  return (
    <AvatarStyle display="flex" align="center" justifyContent="center">
      <Text fontWeight="medium" text="uppercase" color={TextColor.secondary}>
        {formatName(name)}
      </Text>
    </AvatarStyle>
  );
}

const AvatarStyle = styled(Div)`
  width: 28px;
  height: 28px;
  border: 2px solid #005ca9;
  border-radius: 50%;
  margin-right: 8px;
`;
