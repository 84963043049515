import React from 'react';
import styled from 'styled-components/macro';
import t from 'locales/translation';
import { ButtonGroup, ButtonPrimary } from 'app/elements/Buttons';

type Props = {
  onClose: () => void;
};

function InspectionTypesNone({ onClose }: Props) {
  return (
    <Wrapper>
      {t('Inspections.Type.None')}
      <ButtonGroup>
        <ButtonPrimary onClick={onClose}>{t('Close')}</ButtonPrimary>
      </ButtonGroup>
    </Wrapper>
  );
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 24px;

  span {
    font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
    color: ${({ theme }) => theme.colors.default.blue2};
  }
`;

export default InspectionTypesNone;
