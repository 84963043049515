import { AxiosResponse } from 'axios';
import { add } from 'date-fns';
import { EntityType, MaintainableItem, Resource, ResourceType } from 'types';
import { postResource as postResourceService } from 'services/fileManagementServices';
import { objectToFormData } from 'utils/form-data';
import { StagingFile, ResourcePayload, CertificateResult } from './types';
import { DataTableColumn, DataTableRow } from 'app/components/DataTable/types';
import { formatDate, isISODateString } from 'locales/date-format-i18n';
import { translationString } from 'locales/translation';
import React from 'react';
import { capitalizeFirstLetter } from 'utils/formatters';
import { CertificateStatus } from 'types';
import CertificatesTableActions from './components/CertificatesTableActions';
import { CertificatesTableIsValid } from './components/CertificatesTableIsValid';

export const beautifyBytes = (sizeInBytes: number): string => {
  if (sizeInBytes < 1024) {
    return `${sizeInBytes} Bytes`;
  } else if (sizeInBytes < 1024 ** 2) {
    return `${sizeInBytes / 1024} KB`;
  } else if (sizeInBytes < 1024 ** 3) {
    return `${sizeInBytes / 1024 ** 2} MB`;
  } else {
    return `${sizeInBytes / 1024 ** 3} GB`;
  }
};

export function buildResourcePayload(
  formData: FormData,
  files: StagingFile[],
): ResourcePayload[] {
  return files.map(file => {
    const resourceType = formData.get(
      `resourceType#${file.id}`,
    ) as ResourceType;
    const reportDate = formData.get(`reportDate#${file.id}`) as string;
    const specificProperties = (() => {
      if (resourceType !== ResourceType.CERTIFICATE) {
        return undefined;
      }

      const isValid =
        formData.get(`isValid#${file.id}`) === CertificateResult.Pass;

      if (!isValid) {
        return { isValid };
      }

      const months =
        formData.get(`validUntil#${file.id}`) === 'other'
          ? Number(formData.get(`validUntilOther#${file.id}`))
          : 12;
      const validUntil = add(new Date(reportDate), { months }).toISOString();

      return { isValid, validUntil };
    })();

    return { file: file.file, resourceType, reportDate, specificProperties };
  });
}

export function buildResoucePayloadFormData({
  customerId,
  locationId,
  payload,
}: {
  customerId: string;
  locationId: string;
  payload: ResourcePayload;
}) {
  return objectToFormData({
    customerId,
    locationId,
    entityType: EntityType.MAINTAINABLE_ITEM,
    resourceType: payload.resourceType,
    file: payload.file,
    description: payload.file.name,
    reportDate: payload.reportDate
      ? new Date(payload.reportDate).toISOString()
      : undefined,
    specificProperties: payload.specificProperties
      ? JSON.stringify(payload.specificProperties)
      : '',
  });
}

export function postEquipmentResource({
  customerId,
  locationId,
  equipmentId,
  payload,
}: {
  customerId: string;
  locationId: string;
  equipmentId: string;
  payload: ResourcePayload;
}): Promise<AxiosResponse<Resource>> {
  return postResourceService(
    buildResoucePayloadFormData({ customerId, locationId, payload }),
    equipmentId,
  );
}

export function postEquipmentImage({
  customerId,
  locationId,
  equipmentId,
  image,
}: {
  customerId: string;
  locationId: string;
  equipmentId: string;
  image: StagingFile;
}): Promise<AxiosResponse<Resource>> {
  return postEquipmentResource({
    customerId,
    locationId,
    equipmentId,
    payload: {
      file: image.file,
      resourceType: ResourceType.IMAGE,
      reportDate: new Date().toISOString(),
    },
  });
}

const certificatesTableColumnTitles: Array<string> = [
  'description',
  'resourceType',
  'creationDate',
  'reportDate',
  'validUntil',
  'actions',
];

export const certificatesTableColumns = (): Array<DataTableColumn> =>
  certificatesTableColumnTitles.map((columnData: string, index: number) => {
    return {
      order: index,
      visible: true,
      type: 'string',
      id: columnData,
      label: translationString(`Certificates.${columnData}`),
      enumValues: [],
      // Disabling the sorting for now, since it's not implemented on the BE.
      // Values are being stored on redux, but not being sent to the API, so besides
      // these lines, the service call has to be adjusted as well
      // sortable: ['actions', 'validUntil'].includes(columnData) ? false : true,
      sortable: false,
    };
  });

export const mapCertificatesToDataTableRow = (document: any): DataTableRow => ({
  id: document.id,
  values: certificatesTableAttributes(document),
});

export const certificatesTableAttributes = (document: Resource) => {
  return [
    {
      id: 'description',
      value: capitalizeFirstLetter(document.description),
    },
    {
      id: 'resourceType',
      value: capitalizeFirstLetter(document.resourceType.replace(/_/g, ' ')),
    },
    {
      id: 'creationDate',
      value: formatDate(document.creationDate),
    },
    {
      id: 'reportDate',
      value: formatDate(document.reportDate),
    },
    {
      id: 'validUntil',
      value: <CertificatesTableIsValid document={document} />,
    },
    {
      id: 'actions',
      value: <CertificatesTableActions resource={document} />,
    },
  ];
};

export function getCertificateInfo(equipment: MaintainableItem) {
  const certificateStatus = equipment.attributes?.find(
    ({ id }) => id === 'certificateStatus',
  )?.value as CertificateStatus;
  const validUntil = equipment.attributes?.find(
    ({ id }) => id === 'certificateValidUntil',
  )?.value;

  const validUntilFormatted =
    validUntil && isISODateString(validUntil)
      ? formatDate(validUntil)
      : validUntil;
  const validUntilLabel = {
    [CertificateStatus.VALID]: `${translationString(
      'Certificates.validUntil',
    )} ${validUntilFormatted}`,
    [CertificateStatus.ALMOST_EXPIRED]: `${translationString(
      'Certificates.validUntil',
    )} ${validUntilFormatted}`,
    [CertificateStatus.FAILED]: translationString('Certificates.status.failed'),
    [CertificateStatus.EXPIRED]: translationString(
      'Certificates.status.expired',
    ),
  }[certificateStatus];

  return {
    certificateStatus,
    validUntil,
    validUntilLabel,
  };
}
