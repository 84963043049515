import React, { ReactElement, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components/macro';
import { Span, Icon } from '@eriksdigital/atomic-ui/components';
import {
  SuccessIcon,
  ErrorIcon,
  TooltipIcon,
  WarningIcon,
} from '@eriksdigital/atomic-ui/components/Icons';
import { Signal } from './types';

interface SignalMessageProps {
  message: string | ReactElement;
  type: Signal;
  className?: string;
}

export const SignalMessage = ({
  message,
  type,
  className,
}: SignalMessageProps) => {
  const themeContext = useContext(ThemeContext);
  const icon = {
    warning: WarningIcon,
    success: SuccessIcon,
    alert: ErrorIcon,
    info: TooltipIcon,
  }[type];

  return (
    <StyledSpan className={className} $type={type}>
      <StyledIcon
        as={icon}
        size="sz16"
        color={themeContext.colors.signal[type]}
      />
      {message}
    </StyledSpan>
  );
};

export { Signal };

const StyledSpan = styled(Span)`
  display: flex;
  align-items: center;
  color: ${({ theme, $type }) => theme.colors.signal[$type]};
`;
const StyledIcon = styled(Icon)`
  padding-right: ${({ theme }) => theme.spacing.sp8};
`;
