import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState } from './types';
import { SensorProperty, ManualSurvey, EntityType, Alert } from 'types';

// The initial state of the LogsPanel container
export const initialState: ContainerState = {
  alerts: [],
  alertsLoading: false,
  alertsError: '',
};

const logsPanelSlice = createSlice({
  name: 'logsPanel',
  initialState,
  reducers: {
    // Alerts
    getAlerts(state, action: PayloadAction<{ id: string; type: EntityType }>) {
      return {
        ...state,
        alertsLoading: true,
      };
    },
    getAlertsSuccessful(state, action: PayloadAction<Alert[]>) {
      return {
        ...state,
        alerts: action.payload,
        alertsLoading: initialState.alertsLoading,
        alertsError: initialState.alertsError,
      };
    },
    getAlertsError(state, action: PayloadAction<string>) {
      return {
        ...state,
        alerts: initialState.alerts,
        alertsLoading: initialState.alertsLoading,
        alertsError: action.payload,
      };
    },

    getIncidentGraphValues(state, action: PayloadAction<Alert>) {
      return state;
    },
    getIncidentGraphValuesSuccess(
      state,
      action: PayloadAction<{
        alertId: string;
        values: SensorProperty[];
      }>,
    ) {
      const { alertId, values } = action.payload;
      const alert = state.alerts.find(alert => alert.id === alertId);

      if (alert && values.length) {
        alert.graphValues = values[0];
      }

      return state;
    },

    // ManualSurvey
    createManualSurvey(
      state,
      action: PayloadAction<{
        id: string;
        values: ManualSurvey;
      }>,
    ) {
      return state;
    },
    updateManualSurvey(
      state,
      action: PayloadAction<{
        id: string;
        maintainableItemId: string;
        values: ManualSurvey;
      }>,
    ) {
      return state;
    },
  },
});

export const { actions, reducer, name: sliceKey } = logsPanelSlice;
