import React from 'react';
import styled from 'styled-components/macro';
import { Icon } from '@eriksdigital/atomic-ui/components';
import { DownArrowIcon } from '@eriksdigital/atomic-ui/components/Icons';

type Props = {
  options: React.OptionHTMLAttributes<HTMLOptionElement>[];
} & React.SelectHTMLAttributes<HTMLSelectElement>;

export default function Select({ options, placeholder, id, ...rest }: Props) {
  return (
    <Wrapper>
      <SelectStyled id={id} {...rest}>
        {placeholder && <option value="">{placeholder}</option>}
        {options.map((option, index) => (
          <option key={`SelectOption_${id}_${index}`} value={option.value}>
            {option.label}
          </option>
        ))}
      </SelectStyled>
      <Icon as={DownArrowIcon} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;

  svg {
    position: absolute;
    right: ${({ theme }) => theme.spacing.sp8};
    top: calc(20px - 8px);
    width: ${({ theme }) => theme.sizes.sz16};
    height: ${({ theme }) => theme.sizes.sz16};
    color: ${({ theme }) => theme.colors.default.gray3};
  }

  &:has(select:disabled) svg {
    color: ${({ theme }) => theme.colors.default.white};
  }
`;

const SelectStyled = styled.select`
  appearance: none;
  border: none;
  height: 40px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.default.gray1};
  font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
  color: ${({ theme }) => theme.colors.default.gray3};
  padding: ${({ theme }) => `0 ${theme.spacing.sp8}`};
  border-radius: 0;
  outline: none;

  &:disabled {
    opacity: 1;
    background-color: ${({ theme }) => theme.colors.default.gray2};
    color: ${({ theme }) => theme.colors.default.white};
    cursor: default;
  }
`;
