import { translationString } from 'locales/translation';
import { capitalizeFirstLetter } from 'utils/formatters';
import { Alert, AlertType, Sensor, Pack, EntityType } from 'types';
import { getAssetActiveSensors } from 'services/sensorService';
import {
  getMISensorAlerts,
  getSUSensorAlerts,
} from 'services/conditionMonitoringService';

export const getAlertName = (type: AlertType, packType?: string): string => {
  let defaultName: string;

  if (packType) {
    defaultName = `${capitalizeFirstLetter(packType)} ${translationString(
      'UnitMonitoring',
    )}`;
  } else {
    switch (type) {
      case AlertType.SURVEY:
        defaultName = translationString('ConditionScore.Survey');
        break;
      case AlertType.STATUS:
        defaultName = translationString('ConditionScore.SensorAlert');
        break;
      case AlertType.CONDITION:
        defaultName = translationString('ConditionScore.PackScore');
        break;
      default:
        defaultName = '';
    }
  }

  return defaultName;
};

export const getActiveAlerts = (alerts: Alert[]): Alert[] => {
  const filteredAlerts = alerts.filter(alert => alert.isActive);

  return filteredAlerts;
};

export const getFilteredAlerts = (alerts: Alert[]): Alert[] => {
  const filteredAlerts = alerts.filter(
    alert =>
      alert.conditionScore !== 0 ||
      (alert.conditionScore === 0 && alert.type === AlertType.SURVEY),
  );

  return filteredAlerts;
};

export const getAlertWithName = ({
  alert,
  sensors,
  packs,
}: {
  alert: Alert;
  sensors?: Sensor[];
  packs?: Pack[];
}): Alert => {
  const sensor = sensors?.find(s => s.sensorId === alert.sensorId || '');
  const pack = alert.packId
    ? packs?.find(p => p.properties?.id === alert.packId)
    : undefined;
  const packType = pack?.configuration.type;

  const alertWithName = {
    ...alert,
    name: sensor ? sensor.name : getAlertName(alert.type, packType),
  };

  return alertWithName;
};

export const getAlertsWithName = ({
  alerts,
  sensors,
  packs,
}: {
  alerts: Alert[];
  sensors?: Sensor[];
  packs?: Pack[];
}): Alert[] => {
  const alertsWithName = alerts.map(alert => {
    return getAlertWithName({ alert, sensors, packs });
  });

  return alertsWithName;
};

export const fetchAlerts = async (
  id: string,
  type: EntityType,
  assetId?: string,
): Promise<Alert[]> => {
  let sensors: Sensor[] = [];
  if (assetId) {
    const { data } = await getAssetActiveSensors(assetId);
    sensors = data;
  }

  let data: Alert[] = [];
  if (type === EntityType.MAINTAINABLE_ITEM) {
    ({ data } = await getMISensorAlerts(id));
  } else if (type === EntityType.SUB_UNIT) {
    ({ data } = await getSUSensorAlerts(id));
  }

  return getAlertsWithName({ alerts: data, sensors });
};
