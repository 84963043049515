import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SlidingSideBar } from 'app/components/SlidingSideBar';
import { translationString } from 'locales/translation';
import EquipmentAttachmentsForm from './EquipmentAttachmentsForm';
import { actions } from '../slice';
import { ResourcePayload } from '../types';
import useEquipmentParams from '../../Equipments/hooks/useEquipmentParams';
import EquipmentAttachmentsProcess from './EquipmentAttachmentsProcess';
import { selectMiId } from 'app/containers/EquipmentDetail/selectors';
import { selectUploadStatus } from '../selectors';

function EquipmentAttachmentsSidebar() {
  const dispatch = useDispatch();
  const { customerId, locationId } = useEquipmentParams();
  const equipmentId = useSelector(selectMiId);
  const { uploadingStatus } = useSelector(selectUploadStatus);

  const handleSubmit = (payload: ResourcePayload[]) => {
    if (!equipmentId) {
      return;
    }

    dispatch(
      actions.uploadAttachments({
        customerId,
        locationId,
        equipmentId,
        attachments: payload,
      }),
    );
  };

  return (
    <SlidingSideBar
      title={translationString('Attachments.Upload')}
      locked={uploadingStatus === 'running'}
      onClose={() => dispatch(actions.setIsUploading(false))}
      render={handleClose => {
        if (uploadingStatus) {
          return (
            <EquipmentAttachmentsProcess
              status={uploadingStatus}
              onClose={handleClose}
            />
          );
        }

        return (
          <EquipmentAttachmentsForm
            onSubmit={handleSubmit}
            onClose={handleClose}
          />
        );
      }}
    />
  );
}

export default EquipmentAttachmentsSidebar;
