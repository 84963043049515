import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.taskDetails || initialState;

export const selectTaskDetails = createSelector(
  [selectDomain],
  taskDetailsState => taskDetailsState,
);

export const selectTask = createSelector(
  [selectDomain],
  ({ task, taskLoading, taskError }) => ({
    task,
    taskLoading,
    taskError,
  }),
);

export const selectTaskMetadata = createSelector(
  [selectDomain],
  ({ metadata }) => ({
    metadata,
  }),
);

export const selectWorklogs = createSelector(
  [selectDomain],
  ({ worklogs, worklogLoading }) => ({
    worklogs,
    worklogLoading,
  }),
);
