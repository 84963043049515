/**
 *
 * CookieStatement
 *
 */
import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { breakpoints } from '@eriksdigital/atomic-ui/styles';

import { useCustomerLocation } from 'app/containers/CustomerLocation';
import { useUserDetails } from 'app/providers/AuthProvider';
import useTrackPageview from 'utils/hooks/useTrackPageview';

const { REACT_APP_SET_COOKIE_SCRIPT } = process.env;

export function CookieStatement() {
  useCustomerLocation({
    hasCustomerSelector: false,
    hasLocationSelector: false,
  });

  useTrackPageview();

  const lang = useUserDetails()?.language || 'en';

  useEffect(() => {
    if (REACT_APP_SET_COOKIE_SCRIPT !== 'true') return;

    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.id = 'CookieDeclaration';
    script.src =
      'https://consent.cookiebot.com/a3c18b6f-3ed7-42e9-a226-92e883362ae5/cd.js';
    script.setAttribute('data-culture', lang);

    document.body.appendChild(script);

    return () => {
      // clean up the script when the component in unmounted
      document.body.removeChild(script);
    };
  }, [lang]);

  return (
    <Layout>
      <StatementWrapper id="CookieStatement" />
    </Layout>
  );
}

const Layout = styled.div`
  text-align: center;
  padding: ${({ theme }) => theme.spacing.sp24};

  ${breakpoints.tabletLandscape`
    padding: ${({ theme }) => theme.spacing.sp64};
  `}
`;

const StatementWrapper = styled.div`
  max-width: 1800px;
  margin: 0 auto;
  text-align: left;
`;
