import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { useParams } from 'react-router-dom';
import t from 'locales/translation';
import DateRangeSelector from './DateRangeSelector';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from 'app/elements/Fields';
import { Div } from 'app/components/Div';
import {
  selectAlertsDateRange,
  selectAlertsIsInit,
  selectAlertsOnlyActive,
} from '../selectors';
import { actions } from '../slice';
import AlertsDataTable from './AlertsDataTable';
import { AlertType } from 'types';
import { mediaQuery } from 'utils/style-utils';

type Props = {
  alertTypes: AlertType[];
};

function Alerts({ alertTypes }: Props) {
  const dateRange = useSelector(selectAlertsDateRange());
  const onlyActive = useSelector(selectAlertsOnlyActive());
  const isInit = useSelector(selectAlertsIsInit());
  const dispatch = useDispatch();

  const { miId } = useParams() as {
    miId: string;
  };

  useEffect(() => {
    if (!isInit) {
      dispatch(actions.getAlerts(miId));
    }
  }, [isInit, miId, dispatch]);

  return (
    <Wrapper>
      <Filters>
        <DateRangeSelector
          value={dateRange}
          onChange={value => {
            dispatch(actions.setAlertsDateRange(value));
            dispatch(actions.getAlerts(miId));
          }}
        />
        <Div display="flex" align="center" gap="8px">
          <Checkbox
            id="checkbox-active"
            checked={onlyActive}
            onChange={e =>
              dispatch(actions.setAlertsOnlyActive(e.currentTarget.checked))
            }
          />
          <label htmlFor="checkbox-active">{t('ConditionScore.Active')}</label>
        </Div>
      </Filters>
      <AlertsDataTable alertTypes={alertTypes} />
    </Wrapper>
  );
}

export default Alerts;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  margin-top: 0;

  ${mediaQuery.phoneLarge} {
    gap: 24px;
    padding: 16px;
    margin-top: 16px;
    background-color: ${({ theme }) => theme.colors.background.white};
  }
`;

const Filters = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  align-items: center;
  gap: 24px;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.background.white};

  label {
    color: ${({ theme }) => theme.colors.default.darkGray};
  }

  ${mediaQuery.phoneLarge} {
    padding: 0;
    background-color: transparent;
    grid-template-columns: 300px 1fr;
  }
`;
