import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components/macro';

import t from 'locales/translation';

interface Props {
  heading: string | ReactElement;
  onMarkAllRead?: Function;
}

export const NotificationGroup: FunctionComponent<Props> = ({
  heading,
  onMarkAllRead,
  children,
}) => {
  return (
    <GroupWrapper>
      <GroupHeader>
        <h2>{heading}</h2>
        {onMarkAllRead && (
          <ClearButton onClick={() => onMarkAllRead()}>
            {t('NotificationsPanel.MarkAllRead')}
          </ClearButton>
        )}
      </GroupHeader>
      <GroupItems>{children}</GroupItems>
    </GroupWrapper>
  );
};

const GroupWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.sp8} 0;
  margin-top: ${({ theme }) => theme.spacing.sp8};
`;

const GroupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${({ theme }) => theme.spacing.sp40};

  h2 {
    margin: 0;
    font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
    color: ${({ theme }) => theme.colors.text.darkGray};
    font-weight: ${({ theme }) => theme.fonts.fontWeight.normal};
  }
`;

const ClearButton = styled.button`
  display: inline-block;
  margin: 0;
  padding: 0;
  border-width: 0;
  background: none;
  font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
  color: ${({ theme }) => theme.colors.text.darkGray};
  font-weight: ${({ theme }) => theme.fonts.fontWeight.normal};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.text.secondary};
  }
`;

const GroupItems = styled.ul`
  list-style: none;
  padding: ${({ theme }) => theme.spacing.sp8} 0 0;
  margin: 0;

  li + li {
    margin-top: ${({ theme }) => theme.spacing.sp8};
  }
  &:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border.darkGray};
    margin: ${({ theme }) => `${theme.spacing.sp8} ${theme.spacing.sp40} 0`};
  }
`;
