/**
 *
 * AttachmentCard
 *
 */
import React, { memo } from 'react';
import styled from 'styled-components/macro';
import { Icon } from '@eriksdigital/atomic-ui/components';
import { DownloadIcon } from '@eriksdigital/atomic-ui/components/Icons';

import { Resource, BgColor, TextColor } from 'types';
import { Div } from 'app/components/Div';
import { Text } from 'app/components/Typography';

interface Props {
  attachment: Resource;
}

export const AttachmentCard = memo(({ attachment }: Props) => {
  return (
    <Container
      display="flex"
      align="start"
      justifyContent="space-between"
      bg={BgColor.white}
      color={TextColor.secondary}
      mt="4px"
      padding="14px 12px"
      onClick={() => window.open(attachment.url, '_blank')}
    >
      <Text text="capitalize">{attachment.description}</Text>

      <Icon as={DownloadIcon} size="sz16" />
    </Container>
  );
});

const Container = styled(Div)`
  cursor: pointer;
`;
