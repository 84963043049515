import styled from 'styled-components/macro';

export default styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  transition: margin-bottom 300ms;

  &:has(input[aria-invalid='true'], select[aria-invalid='true'], textarea[aria-invalid='true']) {
    margin-bottom: 8px;
  }
`;
