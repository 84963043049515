import React, { ReactElement } from 'react';

import t, { translationString } from 'locales/translation';

export const getValue = (
  name: 'Advice' | 'Diagnostic',
  defaultValue?: string,
  codes?: string,
): string | ReactElement | undefined => {
  // if defaultValue and codes empty show no data message
  if (!defaultValue && !codes) {
    if (name === 'Advice') {
      return t('Diagnostics.NoAdviceAvailable');
    }

    if (name === 'Diagnostic') {
      return t('Diagnostics.NoDiagnosticDataAvailable');
    }
  }

  // split string to array in case of more then one advice, example "A1, A2"
  const codeArray = codes?.split(',');

  const codeArrayString = codeArray?.reduce<string[]>((acc, code) => {
    const trimedCode = code.trim();

    if (trimedCode) {
      acc.push(`Code.${name}.${trimedCode}`);
    }
    return acc;
  }, []);

  const newValue = codeArrayString ? (
    <>
      {codeArrayString.map(e => (
        <span key={e} data-stringid={e}>
          {translationString(e)}
        </span>
      ))}
    </>
  ) : (
    defaultValue
  );

  return newValue;
};
