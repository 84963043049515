/**
 *
 * Equipment
 *
 */

import React, { useEffect, useState, Fragment } from 'react';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Button } from '@eriksdigital/atomic-ui/components';
import { breakpointObj } from '@eriksdigital/atomic-ui/styles';

import t from 'locales/translation';
import { formatDate } from 'locales/date-format-i18n';
import { useWindowSize } from 'utils/hooks/useWindowSize';
import { buildEquipmentItemsRoute } from 'utils/route-helpers';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { useAuth } from 'app/providers/AuthProvider';
import { EntityType, Alert, BgColor, TextColor } from 'types';
import { Div } from 'app/components/Div';
import { Modal } from 'app/components/Modal';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { ErrorMessage } from 'app/components/FinalField/ErrorMessage';
import { H3, Text } from 'app/components/Typography';
import { StatusLevelRing } from 'app/components/StatusLevelRing';
import { LogCard } from 'app/components/LogCard';
import { TaskCard } from 'app/components/TaskCard';
import { AttachmentCard } from 'app/components/AttachmentCard';
import SensorAlert from 'app/components/SensorAlert';
import { Specifications } from 'app/containers/Equipment/components/Specifications';
import { BlurredCards } from 'app/containers/Equipment/components/BlurredCards';
import { selectAllAlerts } from 'app/containers/LogsPanel/selectors';
import { logsPanelSaga } from 'app/containers/LogsPanel/saga';
import {
  reducer as logReducer,
  sliceKey as logSliceKey,
  actions as logsActions,
} from 'app/containers/LogsPanel/slice';
import { useCustomerLocation } from '../CustomerLocation';
import { reducer, sliceKey, actions } from './slice';
import { selectEquipment, selectAttributes } from './selectors';
import { equipmentSaga } from './saga';

interface Props {}

export function Equipment(props: Props) {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: equipmentSaga });

  useInjectReducer({ key: logSliceKey, reducer: logReducer });
  useInjectSaga({ key: logSliceKey, saga: logsPanelSaga });

  useCustomerLocation({
    hasLocationSelector: false,
    replacePath: '/',
  });

  const dispatch = useDispatch();
  const history = useHistory();
  const { customerId, miId } = useParams() as {
    customerId: string;
    miId: string;
  };
  const { width } = useWindowSize();
  const { isAuthenticated } = useAuth();
  const isAuthedUser = isAuthenticated();

  const attributes = useSelector(selectAttributes());
  const { alerts } = useSelector(selectAllAlerts);
  const {
    loading,
    error,
    maintainableItem,
    metadata,
    condition,
    status,
    tasks,
    images,
    attachments,
  } = useSelector(selectEquipment);

  const [activeAlert, setActiveAlert] = useState<Alert>();
  const [showModal, setShowModal] = useState<boolean>(false);

  const isMobile = width <= breakpointObj.tabletPortrait;

  useEffect(() => {
    if (!miId) return;
    if (maintainableItem) return;

    dispatch(actions.getEquipment(miId));

    if (isAuthedUser) {
      dispatch(actions.getLocation(miId));
      dispatch(actions.getResources(miId));
      dispatch(actions.getTasks(miId));
      dispatch(
        logsActions.getAlerts({
          id: miId,
          type: EntityType.MAINTAINABLE_ITEM,
        }),
      );
    }
  }, [dispatch, isAuthedUser, maintainableItem, miId]);

  useEffect(() => {
    if (alerts.length === 0) return;

    const activeAlert = alerts.find(alert => alert.isActive);

    if (activeAlert) {
      setActiveAlert(activeAlert);
    }
  }, [alerts]);

  return (
    <Fragment>
      <Helmet>
        <title>Equipment</title>
        <meta name="description" content="Description of Equipment" />
      </Helmet>

      {loading && <LoadingIndicator />}
      {error && <ErrorMessage>{error}</ErrorMessage>}

      {maintainableItem && (
        <Container bg={BgColor.lightGray} padding="12px">
          <Div display="flex" mb="20px">
            {images[0] ? (
              <Image src={images[0].url} alt="image" />
            ) : (
              <NoImagePlaceholder
                display="flex"
                align="center"
                justifyContent="center"
                width="80px"
                height="80px"
                borderRad="3px"
                padding="5px"
              >
                {t('Gallery.NoImagesFound')}
              </NoImagePlaceholder>
            )}

            <Div width="100%" ml="12px">
              <H3>{maintainableItem.name}</H3>

              {attributes.map((attribute, i) => (
                <Div
                  display="flex"
                  key={`${i}_${attribute.id}`}
                  justifyContent="space-between"
                  mt="8px"
                >
                  <Text fontWeight="medium" color={TextColor.tertiary}>
                    {t(`Attributes.${attribute.id}`)}
                  </Text>

                  <Text>
                    {attribute.id === 'webshopUrl' ? (
                      <a
                        title={attribute.value}
                        href={attribute.value}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Ellipsis>{attribute.value}</Ellipsis>
                      </a>
                    ) : (
                      attribute.displayValue || attribute.value
                    )}
                  </Text>
                </Div>
              ))}

              <Div mt="12px">
                <Button
                  id="all_specifications"
                  data-testid="all_specifications"
                  variant="ghost"
                  onClick={() => setShowModal(true)}
                >
                  <Text>{t('AllSpecifications')}</Text>
                </Button>
              </Div>
            </Div>
          </Div>

          <Div
            display="flex"
            align="center"
            justifyContent="space-between"
            bg={BgColor.white}
            borderRad="3px"
            mt="4px"
            padding="12px"
          >
            <Text
              fontWeight="medium"
              text="uppercase"
              color={TextColor.tertiary}
            >
              {t('ConditionScore')}
            </Text>
            {condition?.conditionScore ? (
              <StatusLevelRing
                size={22}
                score={condition.conditionScore}
                textSize="12px"
              />
            ) : (
              <Div display="flex" mr="10px">
                -
              </Div>
            )}
          </Div>

          <Div
            display="flex"
            align="center"
            justifyContent="space-between"
            bg={BgColor.white}
            borderRad="3px"
            mt="4px"
            padding="12px"
          >
            <Text
              fontWeight="medium"
              text="uppercase"
              color={TextColor.tertiary}
            >
              {t('LiveStatus')}
            </Text>

            {status?.severity ? (
              <SensorAlert severity={status.severity} />
            ) : (
              <Div display="flex" mr="10px">
                -
              </Div>
            )}
          </Div>

          <Div
            display="flex"
            justifyContent="space-between"
            bg={BgColor.white}
            borderRad="3px"
            mt="4px"
            padding="12px"
          >
            <Text
              fontWeight="medium"
              text="uppercase"
              color={TextColor.tertiary}
            >
              {t('LastInspection')}
            </Text>

            <Text>
              {activeAlert?.endTime ? (
                formatDate(activeAlert.endTime)
              ) : (
                <Div display="flex" mr="10px">
                  -
                </Div>
              )}
            </Text>
          </Div>

          {!isAuthedUser && <BlurredCards />}

          {/* Logs  */}
          {alerts.length > 0 && (
            <Div mt="24px">
              <Text
                fontWeight="medium"
                text="uppercase"
                color={TextColor.tertiary}
              >
                {t('LatestLog')}
              </Text>

              <LogCard alert={activeAlert} />

              <Div mt="24px" display="flex" justifyContent="center">
                <Button
                  id="all_logs"
                  data-testid="all_logs"
                  variant="ghost"
                  onClick={() =>
                    history.push(
                      buildEquipmentItemsRoute(customerId, miId, 'allLogs'),
                    )
                  }
                >
                  <Text>{t('ViewAllLogs')}</Text>
                </Button>
              </Div>
            </Div>
          )}

          {/* Tasks  */}
          {tasks[0] && (
            <Div mt="24px">
              <Text
                fontWeight="medium"
                text="uppercase"
                color={TextColor.tertiary}
              >
                {t('Task')}
              </Text>

              <TaskCard task={tasks[0]} />

              <Div mt="24px" display="flex" justifyContent="center">
                <Button
                  id="all_tasks"
                  data-testid="all_tasks"
                  variant="ghost"
                  onClick={() =>
                    history.push(
                      buildEquipmentItemsRoute(customerId, miId, 'allTasks'),
                    )
                  }
                >
                  <Text>{t('ViewAllTasks')}</Text>
                </Button>
              </Div>
            </Div>
          )}

          {/* Attachments  */}
          {attachments.length > 0 && (
            <Div mt="24px">
              <Text
                fontWeight="medium"
                text="uppercase"
                color={TextColor.tertiary}
              >
                {t('Attachments')}
              </Text>

              <Div mt="8px">
                {attachments.slice(0, 3).map(attachment => (
                  <AttachmentCard key={attachment.id} attachment={attachment} />
                ))}
              </Div>

              <Div mt="24px" display="flex" justifyContent="center">
                <Button
                  id="all_attachments"
                  data-testid="all_attachments"
                  variant="ghost"
                  onClick={() =>
                    history.push(
                      buildEquipmentItemsRoute(
                        customerId,
                        miId,
                        'allAttachments',
                      ),
                    )
                  }
                >
                  <Text>{t('ViewAllAttachments')}</Text>
                </Button>
              </Div>
            </Div>
          )}
        </Container>
      )}

      {showModal && (
        <Modal
          id="modal"
          data-testid="modal"
          onClose={() => setShowModal(false)}
          withFrame={!isMobile}
          showCloseIcon={!isMobile}
          showCloseHeader={isMobile}
          withOutsideClose={true}
          background={BgColor.lightGray}
        >
          <Specifications
            groups={metadata?.attributeGroups || []}
            attributes={maintainableItem?.attributes || []}
          />
        </Modal>
      )}
    </Fragment>
  );
}

const Container = styled(Div)`
  flex: 1 1 100%;
  padding-bottom: 32px;
  overflow-y: auto;
`;

const NoImagePlaceholder = styled(Div)`
  font-weight: ${({ theme }) => theme.fonts.fontWeight.normal};
  font-size: ${({ theme }) => theme.fonts.fontSize.ft12};
  background: ${({ theme }) => theme.colors.background.lightGray};
  color: ${({ theme }) => theme.colors.grays.midGray};
  border: 1px solid #ddd;
`;

const Image = styled.img`
  object-fit: cover;
  width: 80px;
  height: 80px;
  border-radius: 3px;
`;

const Ellipsis = styled.div`
  text-align: justify;
  text-align-last: right;

  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
