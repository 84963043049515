import React, { memo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Icon } from '@eriksdigital/atomic-ui/components';
import { AddAltIcon } from '@eriksdigital/atomic-ui/components/Icons';
import t from 'locales/translation';
import { Modal } from 'app/components/Modal';
import { TaskEditor } from 'app/components/TaskEditor';
import { ButtonSecondary } from 'app/elements/Buttons';
import { EntityType, TaskReq } from 'types';
import { actions } from '../slice';

type Props = {
  miId: string;
  name: string;
};

function TaskEditorModal({ miId, name }: Props) {
  const dispatch = useDispatch();
  const [openCreateTask, setOpenCreateTask] = useState(false);

  return (
    <>
      <ButtonSecondary
        onClick={() => {
          setOpenCreateTask(true);
        }}
      >
        <Icon as={AddAltIcon} size="sz20" />
        {t('WorkOrders.new')}
      </ButtonSecondary>
      {openCreateTask && (
        <Modal
          onClose={() => setOpenCreateTask(false)}
          withOutsideClose
          maxWidth={1200}
        >
          <TaskEditor
            item={{
              id: miId,
              name,
              type: EntityType.MAINTAINABLE_ITEM,
            }}
            onSubmit={(task: TaskReq) => {
              dispatch(actions.createTask(task));
              setOpenCreateTask(false);
            }}
            onCancel={() => setOpenCreateTask(false)}
          />
        </Modal>
      )}
    </>
  );
}

export default memo(TaskEditorModal);
