/**
 *
 * QrCode
 *
 */

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import t from 'locales/translation';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { buildEquipmentDetailRoute } from 'utils/route-helpers';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { Div } from 'app/components/Div';
import { useCustomerLocation } from '../CustomerLocation';
import { reducer, sliceKey, actions } from './slice';
import { qrCodeSaga } from './saga';
import { selectIsLoading, selectRedirectInfo } from './selectors';

export function QrCode() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: qrCodeSaga });

  useCustomerLocation({
    hasCustomerSelector: false,
    hasLocationSelector: false,
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const { qrId } = useParams<{ qrId: string }>();

  const isLoading = useSelector(selectIsLoading);
  const redirectInfo = useSelector(selectRedirectInfo);

  useEffect(() => {
    if (qrId) {
      dispatch(actions.getTag(qrId));
    }
  }, [dispatch, qrId]);

  useEffect(() => {
    if (redirectInfo) {
      const { customerId, locationId, miId } = redirectInfo;
      history.push(buildEquipmentDetailRoute(customerId, locationId, miId));
    }
  }, [history, redirectInfo]);

  return (
    <>
      <Helmet>
        <title>QrCode</title>
        <meta name="description" content="Description of QrCode" />
      </Helmet>

      {isLoading && <LoadingIndicator />}

      {!isLoading && !redirectInfo && (
        <Div display="flex" mt="50px" pl="16px">
          {t('UnlinkedMaintainableItem')}
        </Div>
      )}
    </>
  );
}
