import React from 'react';
import styled from 'styled-components/macro';
import { ReactSelect } from '@eriksdigital/atomic-ui/components';

import { WrappedComponentProps } from './types';

export const SelectAdapter = ({
  input,
  meta,
  ...otherProps
}: WrappedComponentProps) => {
  return (
    <StyledReactSelect
      className="select_input"
      classNamePrefix="select"
      {...input}
      error={meta?.error && meta?.touched}
      errorMessage={meta?.error && meta?.touched ? meta.error : undefined}
      disabled={meta?.submitting}
      {...otherProps}
    />
  );
};

const StyledReactSelect = styled(ReactSelect)`
  font-size: 16px;

  .select__option {
    font-size: 12px;
  }
  .select__option.select__option--is-focused {
    font-size: 12px;
  }
`;
