import { AxiosResponse } from 'axios';

import { TaskReq, Task, TaskTypeMetadata } from 'types';
import * as RESOURCE_PATHS from './resourcePaths';
import {
  createAbsoluteUrl,
  apiGet,
  apiPost,
  apiPut,
  apiDelete,
} from './utils/apiUtils';
import {
  EquipmentDetailsPostPayload,
  EquipmentDetailsPostResponse,
} from 'app/containers/EquipmentDetail/types';
import { InspectionPayloadPost } from 'app/containers/EquipmentInspection/types';

const API_BASE_URL: string =
  process.env.REACT_APP_API_COND_MONITORING_HOSTNAME || '';

export const getLocationTasks = async (
  id: string,
): Promise<AxiosResponse<Task[]>> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V4,
      RESOURCE_PATHS.LOCATION_TASKS.replace(/%id/, id),
    ),
  );
};

export const getSUTasks = async (
  id: string,
): Promise<AxiosResponse<Task[]>> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V4,
      RESOURCE_PATHS.SUB_UNIT_TASKS.replace(/%id/, id),
    ),
  );
};

export const getMITasks = async (
  id: string,
): Promise<AxiosResponse<Task[]>> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V4,
      RESOURCE_PATHS.MAINTAINABLE_ITEM_TASKS.replace(/%id/, id),
    ),
  );
};

export const getTask = async (id: string): Promise<AxiosResponse<Task>> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V4,
      RESOURCE_PATHS.TASK.replace(/%id/, id),
    ),
  );
};

export const createTask = async (
  payload: TaskReq,
): Promise<AxiosResponse<Task>> => {
  return apiPost(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V4,
      RESOURCE_PATHS.TASKS,
    ),
    payload,
  );
};

export const updateTask = async (
  payload: TaskReq,
): Promise<AxiosResponse<Task>> => {
  return apiPut(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V4,
      `${RESOURCE_PATHS.TASKS}/${payload.id}`,
    ),
    payload,
  );
};

export const subscribeToTask = async (
  id: string,
): Promise<AxiosResponse<any>> => {
  return apiPost(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V4,
      RESOURCE_PATHS.TASK_SUBSCRIBE.replace(/%id/, id),
    ),
    null,
  );
};

export const unSubscribeTask = async (
  id: string,
): Promise<AxiosResponse<any>> => {
  return apiDelete(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V4,
      RESOURCE_PATHS.TASK_SUBSCRIBE.replace(/%id/, id),
    ),
  );
};

export const assignTasks = async (payload: {
  assigneeId: string;
  taskIds: string[];
}): Promise<AxiosResponse<any>> => {
  return apiPost(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V4,
      RESOURCE_PATHS.ASSIGN_TASKS,
    ),
    payload,
  );
};

export const deactivateTask = async (id: string) => {
  return apiPost(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V4,
      RESOURCE_PATHS.DEACTIVATE_TASK.replace(/%id/, id),
    ),
    '',
  );
};

export const getWorklogs = async (id: string): Promise<AxiosResponse<any>> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V4,
      RESOURCE_PATHS.WORKLOGS.replace(/%id/, id),
    ),
  );
};

export const getTaskReport = async (
  locationId: string,
): Promise<AxiosResponse<string>> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V4,
      RESOURCE_PATHS.TASK_REPORT.replace(/%id/, locationId),
    ),
  );
};

export const getMIWorkOrders = async ({
  miId,
  pageSize,
  pageIndex,
  sortField,
  sortOrder,
  taskTypes,
  statusTypes,
}: EquipmentDetailsPostPayload): Promise<
  AxiosResponse<EquipmentDetailsPostResponse>
> => {
  const params = new URLSearchParams();
  params.append('pageSize', pageSize.toString());
  params.append('pageIndex', pageIndex.toString());
  params.append('sortField', sortField);
  params.append('sortOrder', sortOrder);
  params.append('taskTypes', taskTypes);
  if (!!statusTypes) {
    statusTypes.forEach(statusType => {
      params.append('statusTypes', statusType);
    });
  }
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V5,
      RESOURCE_PATHS.MAINTAINABLE_ITEM_TASKS.replace(/%id/, miId),
      params,
    ),
  );
};

export const getTaskTypeMetadata = async (): Promise<
  AxiosResponse<TaskTypeMetadata[]>
> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V4,
      RESOURCE_PATHS.TASK_TYPE_METADATA,
    ),
  );
};

export const createInspection = async (
  payload: InspectionPayloadPost,
): Promise<AxiosResponse<Task>> => {
  return apiPost(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V4,
      RESOURCE_PATHS.TASKS,
    ),
    payload,
  );
};
