import { createSelector } from '@reduxjs/toolkit';
import { useLocation } from 'react-router-dom';

import { EntityType, RootState, ValueLabel, URLParams } from 'types';
import { initialState } from './slice';
import { flattenParentTree } from './utils/flattenParentTree';

const selectDomain = (state: RootState) => state.assetDashboard || initialState;

export const selectAssetDashboard = createSelector(
  [selectDomain],
  assetDashboardState => assetDashboardState,
);

export const selectAsset = createSelector([selectDomain], ({ asset }) => asset);
export const selectSubUnits = createSelector(
  [selectDomain],
  ({ subUnits }) => subUnits,
);
export const selectAssetLoading = createSelector(
  [selectDomain],
  ({ assetLoading }) => assetLoading,
);
export const selectAssetError = createSelector(
  [selectDomain],
  ({ assetError }) => assetError,
);

export const selectAssetImagesLoading = createSelector(
  [selectDomain],
  ({ assetImages }) => assetImages.loading,
);

export const selectAssetImagesError = createSelector(
  [selectDomain],
  ({ assetImages }) => assetImages.error,
);

export const selectParentSpaces = createSelector(
  [selectDomain],
  ({ assetParentTree }) => {
    if (
      !assetParentTree ||
      !assetParentTree.parentTree ||
      !assetParentTree.parentTree.parent
    ) {
      return [];
    }
    return flattenParentTree(assetParentTree.parentTree.parent);
  },
);

export const selectParentTreeLoading = createSelector(
  [selectDomain],
  ({ assetParentTree }) => assetParentTree.loading,
);

export const selectParentTreeError = createSelector(
  [selectDomain],
  ({ assetParentTree }) => assetParentTree.error,
);

export const selectSelectedItem = createSelector(
  [selectDomain],
  ({ selectedItem }) => selectedItem,
);

export const selectSensors = createSelector(
  [selectDomain],
  ({ sensors, sensorsError, sensorsLoading }) => ({
    sensors,
    sensorsError,
    sensorsLoading,
  }),
);

export const selectLocationUsers = createSelector(
  [selectDomain],
  ({ locationUsers }) => locationUsers,
);

export const selectLocation = createSelector(
  [selectDomain],
  ({ location }) => location,
);

export const selectOptions = (type: EntityType) =>
  createSelector([selectDomain], ({ asset }) => {
    const urlParams = new URLSearchParams(useLocation().search);
    const suId = urlParams.get(URLParams.SU);

    let options: ValueLabel[] = [];

    if (type === EntityType.SUB_UNIT) {
      options =
        asset?.children?.map(mi => ({
          label: mi.name,
          value: mi.id,
        })) || [];
    }

    if (type === EntityType.MAINTAINABLE_ITEM && suId) {
      const subUnit = asset?.children?.find(su => su.id === suId);

      options =
        subUnit?.children.map(mi => ({
          label: mi.name,
          value: mi.id,
        })) || [];
    }

    return options;
  });

export const selectPacks = createSelector([selectDomain], ({ packs }) => packs);
