import React from 'react';

import t, { translationString } from 'locales/translation';
import { TaskTypeField } from 'types';
import { Select, Input } from 'app/elements/Fields/withValidation';
import { Field, Label } from 'app/elements/Fields';
import InspectionFieldDate from './InspectionFieldDate';

type Props = {
  field: TaskTypeField;
  defaultValue?: string;
  onChange: (value: string | boolean, field) => void;
};

function InspectionField({ field, defaultValue, onChange }: Props) {
  const { id, fieldName, fieldType, isMandatory, taskTypeFieldEnums } = field;

  const renderField = () => {
    if (fieldType === 'enum') {
      return (
        <Select
          options={taskTypeFieldEnums.map(item => ({
            value: item.id,
            label: item.name,
          }))}
          placeholder={translationString('SelectPlaceholder')}
          id={id}
          name={fieldName}
          required={isMandatory}
          onChange={e => onChange?.(e.currentTarget.value, field)}
        />
      );
    }

    if (fieldType === 'date') {
      return <InspectionFieldDate field={field} />;
    }

    if (fieldType === 'integer' || fieldType === 'decimal') {
      return (
        <Input
          type="number"
          step={fieldType === 'decimal' ? 0.1 : 1}
          id={id}
          name={fieldName}
          required={isMandatory}
          onChange={e => onChange?.(e.currentTarget.value, field)}
        />
      );
    }

    return (
      <Input
        type="text"
        id={id}
        name={fieldName}
        required={isMandatory}
        defaultValue={defaultValue}
      />
    );
  };

  return (
    <Field>
      <Label htmlFor={id} required={isMandatory}>
        {t(`Attributes.${fieldName}`)}
      </Label>
      {renderField()}
    </Field>
  );
}

export default InspectionField;
