/**
 *
 * ReportProvider
 *
 */

import React from 'react';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { reducer, sliceKey } from './slice';
import { reportProviderSaga } from './saga';

export function ReportProvider() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: reportProviderSaga });
  return <></>;
}
