import React from 'react';
import Pagination from '../../../components/Pagination/Pagination';
import usePaginationRedux from 'app/components/Pagination/usePaginationRedux';

export const TasksPagination = ({ selector, action }) => {
  const pageInfo = usePaginationRedux({
    selector,
    action,
  });

  return pageInfo.pageCount ? <Pagination {...pageInfo} /> : null;
};

export default TasksPagination;
