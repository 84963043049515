import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { LocationSelector } from 'app/components/LocationSelector';

import {
  selectHasLocationSelector,
  selectLocations,
  selectLocationsLoading,
  selectSelectedLocation,
} from '../selectors';
import { reducer, sliceKey, actions } from '../slice';
import { customerControlsSaga } from '../saga';

interface Props {}

export const LocationControls = (props: Props) => {
  useInjectReducer({
    key: sliceKey,
    reducer: reducer,
  });
  useInjectSaga({
    key: sliceKey,
    saga: customerControlsSaga,
  });

  const dispatch = useDispatch();
  const locationsLoading = useSelector(selectLocationsLoading);
  const locations = useSelector(selectLocations);
  const selectedLocationId = useSelector(selectSelectedLocation);
  const hasLocationSelector = useSelector(selectHasLocationSelector);

  if (!hasLocationSelector) {
    return <></>;
  }

  if (locationsLoading) {
    return <LoadingIndicator small />;
  }

  return (
    <LocationSelector
      locations={locations || []}
      selectedLocation={selectedLocationId}
      loading={locationsLoading}
      onSelectLocation={locationId => {
        dispatch(actions.changeSelectedLocation(locationId));
      }}
    />
  );
};
