import React, { useContext, forwardRef, useState, useRef } from 'react';
import { ThemeContext } from 'styled-components/macro';
import { format } from 'date-fns';
import { Icon } from '@eriksdigital/atomic-ui/components';
import { CrossIcon } from '@eriksdigital/atomic-ui/components/Icons';

import { Datepicker, ChildComponentHandle } from 'app/components/DatePicker';
import { Calendar } from 'app/components/Icons';
import { Div } from 'app/components/Div';
import { BgColor } from 'types';
import { DataTableColumn } from '../types';

export type DateFilterProps = {
  column: DataTableColumn;
  selected: string[];
  onSubmit: (selected: string[]) => void;
};

type DivProps = React.HTMLProps<HTMLDivElement>;

function DateFilter({ column, selected, onSubmit }: DateFilterProps) {
  const theme = useContext(ThemeContext);
  const datePickerRef = useRef<ChildComponentHandle>(null);

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>();
  const [endDate, setEndDate] = useState<Date | null>();

  const [start, end] = selected;
  const isFilterActive = !!start && !!end;

  const handleChange = (date: [Date | null, Date | null]) => {
    const [start, end] = date;

    setStartDate(start);
    setEndDate(end);

    if (start && end) {
      onSubmit([format(start, 'yyyy-MM-dd'), format(end, 'yyyy-MM-dd')]);
      if (datePickerRef.current) {
        datePickerRef.current.closeDatePicker();
      }
    }
  };

  const clearFilter = () => {
    setStartDate(null);
    setEndDate(null);
    onSubmit([]);
  };

  const CustomInput = forwardRef<HTMLDivElement, DivProps>(function CustomInput(
    { onClick },
    ref,
  ) {
    return (
      <Div
        onClick={onClick}
        ref={ref}
        bg={
          isCalendarOpen
            ? BgColor.lightBlue
            : isFilterActive
            ? BgColor.darkBlue
            : BgColor.lighterGray
        }
        padding="8px"
        cursor="pointer"
      >
        <Calendar active={!isCalendarOpen && isFilterActive}></Calendar>
      </Div>
    );
  });

  return (
    <Div display="flex" width="200px">
      <Datepicker
        id={`date_filter_${column.label}`}
        customInput={<CustomInput />}
        onCalendarOpen={() => setIsCalendarOpen(true)}
        onCalendarClose={() => setIsCalendarOpen(false)}
        onChange={handleChange}
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        ref={datePickerRef}
      />

      {isFilterActive && (
        <Div padding="8px" cursor="pointer" onClick={clearFilter}>
          <Icon as={CrossIcon} size="sz16" color={theme.colors.signal.alert} />
        </Div>
      )}
    </Div>
  );
}

export default DateFilter;
