import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components/macro';
import {
  BackArrowIcon,
  SideArrowIcon,
} from '@eriksdigital/atomic-ui/components/Icons';
import { Icon } from '@eriksdigital/atomic-ui/components';
import { breakpointObj } from '@eriksdigital/atomic-ui/styles';

import { useWindowSize } from 'utils/hooks/useWindowSize';

type Props = {
  page: number;
  pageSize: number;
  pageCount?: number;
  totalRecords?: number;
  pageSizeOptions: number[];
  onChange: (page: number) => void;
  onChangePageSize: (pageSize: number) => void;
  renderPageCount: (page: number, pageCount: number) => ReactNode;
  renderTotalRecords: (totalRecords: number) => ReactNode;
  renderShow: () => ReactNode;
};

const Pagination = ({
  page,
  pageCount,
  pageSize,
  pageSizeOptions,
  totalRecords,
  onChange,
  onChangePageSize,
  renderPageCount,
  renderTotalRecords,
  renderShow,
}: Props) => {
  const { width } = useWindowSize();
  const isMobile = width <= breakpointObj.tabletPortrait;

  const handlePrevious = () => {
    onChange(page - 1);
  };

  const handleNext = () => {
    onChange(page + 1);
  };

  return (
    <PaginationStyled>
      <Wrapper>
        <PaginationButton
          id="button-pagination-prev"
          data-testid="button-pagination-prev"
          onClick={handlePrevious}
          disabled={page <= 1}
        >
          <Icon as={BackArrowIcon} />
        </PaginationButton>
        <PaginationButton
          id="button-pagination-next"
          data-testid="button-pagination-next"
          onClick={handleNext}
          disabled={pageCount ? page >= pageCount : false}
        >
          <Icon as={SideArrowIcon} />
        </PaginationButton>
        {pageCount && <span>{renderPageCount(page, pageCount)}</span>}
      </Wrapper>
      <Wrapper>
        {totalRecords && <span>{renderTotalRecords(totalRecords)}</span>}
        {!isMobile && <span>{renderShow()}</span>}
        <SelectStyled
          value={pageSize}
          onChange={e => onChangePageSize(Number(e.target.value))}
        >
          {pageSizeOptions.map(option => (
            <option key={`PageSizeOption_${option}`} value={option}>
              {option}
            </option>
          ))}
        </SelectStyled>
      </Wrapper>
    </PaginationStyled>
  );
};

export default Pagination;

const PaginationStyled = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: ${theme.spacing.sp16};
    padding: ${theme.spacing.sp24} 0;
    color: ${theme.colors.default.gray3};
    font-size: ${theme.fonts.fontSize.ft14};
  `}
`;

const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing.sp8};
  `}
`;

const SelectStyled = styled.select`
  ${({ theme }) => css`
    border: none;
    color: ${theme.colors.default.gray3};
    background-color: ${theme.colors.default.white};
    font-size: ${theme.fonts.fontSize.ft14};
    height: ${theme.sizes.sz40};
    padding: 0 ${theme.spacing.sp16};
    cursor: pointer;
  `}
`;

const PaginationButton = styled.button`
  ${({ theme }) => css`
    border: none;
    font-size: ${theme.fonts.fontSize.ft14};
    color: ${theme.colors.default.gray3};
    height: ${theme.sizes.sz40};
    width: ${theme.sizes.sz40};
    padding: 0;
    cursor: pointer;
    background-color: ${theme.colors.default.white};
    :hover {
      background-color: ${theme.colors.default.blue1};
    }
    &:disabled {
      color: ${theme.colors.default.gray2};
      cursor: default;
      background-color: ${theme.colors.default.gray2};
      svg {
        color: ${theme.colors.default.white};
      }
    }
    svg {
      width: 20px;
      height: 20px;
      :hover {
        color: ${theme.colors.default.white};
      }
    }
  `}
`;
