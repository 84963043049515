import React from 'react';

export function Warning() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="8" fill="#D99300" fill-opacity="0.2" />
      <g clipPath="url(#clip0_3712_17709)">
        <path d="M8.5 4H7.5V9.5H8.5V4Z" fill="#D99300" />
        <path
          d="M8 11C7.85166 11 7.70666 11.044 7.58332 11.1264C7.45999 11.2088 7.36386 11.3259 7.30709 11.463C7.25032 11.6 7.23547 11.7508 7.26441 11.8963C7.29335 12.0418 7.36478 12.1754 7.46967 12.2803C7.57456 12.3852 7.7082 12.4567 7.85368 12.4856C7.99917 12.5145 8.14997 12.4997 8.28701 12.4429C8.42406 12.3861 8.54119 12.29 8.6236 12.1667C8.70601 12.0433 8.75 11.8983 8.75 11.75C8.75 11.5511 8.67098 11.3603 8.53033 11.2197C8.38968 11.079 8.19891 11 8 11Z"
          fill="#D99300"
        />
      </g>
      <defs>
        <clipPath id="clip0_3712_17709">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
