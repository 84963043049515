/**
 * UploadImageLink
 */

import React, { useState, useRef, ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { Icon, Button } from '@eriksdigital/atomic-ui/components';
import {
  UploadIcon,
  LoadingIcon,
} from '@eriksdigital/atomic-ui/components/Icons';

import t, { translationString } from 'locales/translation';
import { postResource } from 'services/fileManagementServices';
import { objectToFormData } from 'utils/form-data';
import { EntityType, ResourceType } from 'types';
import { actions as announcementActions } from 'app/containers/AnnouncementsToast/slice';
import { actions as mediaActions } from 'app/containers/MediaGallery/slice';

import { SignalMessage } from 'app/components/SignalMessage';
import { Signal } from 'app/components/SignalMessage/types';

interface UploadImageLinkProps {
  entityType: EntityType;
  entityId: string;
  customerId: string;
  locationId: string;
  onChange?: Function;
  acceptedTypes: ResourceType[];
  resourceType?: ResourceType;
  multiple?: boolean;
  accept?: string;
  text?: string;
  small?: boolean;
}

export const UploadImageLink = ({
  entityType,
  entityId,
  customerId,
  locationId,
  onChange = res => null,
  acceptedTypes,
  resourceType,
  multiple,
  accept,
  text,
  small = false,
}: UploadImageLinkProps) => {
  const inputFile = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadingError, setUploadingError] = useState<string | ReactElement>(
    '',
  );

  const handleUpload = async (files: FileList | null) => {
    setIsUploading(true);
    const fileArray = Array.from(files || []);
    const uploadCalls = fileArray.map(file =>
      postResource(
        objectToFormData({
          customerId,
          description: file.name,
          entityType,
          file,
          locationId,
          resourceType: resourceType || ResourceType.IMAGE,
        }),
        entityId,
      ),
    );

    try {
      const responses = await Promise.all(uploadCalls);
      const newResources = responses.map(response => response.data);
      dispatch(
        mediaActions.getMediaItems({
          entityType,
          entityId,
          resourceTypes: acceptedTypes,
        }),
      );
      dispatch(announcementActions.addSuccess(t('SuccessMessage.ImageUpload')));
      onChange(newResources);
    } catch (e) {
      setUploadingError(t('ErrorMessage.ImagesUploadingError'));
      dispatch(announcementActions.addError(e.message));
    }
    setIsUploading(false);
  };

  return (
    <>
      {!acceptedTypes.includes(ResourceType.IMAGE) ? (
        <SignalMessage
          type={Signal.ALERT}
          message={t('AlertMessage.EntityDoesntSupportUploadingImages', {
            entityType,
          })}
        />
      ) : (
        <>
          <UploadLinkContainer>
            <HiddenUploadInput
              id="carouselGallery_uploadHiddenInput"
              accept={accept}
              multiple={multiple}
              data-testid="dropzone"
              type="file"
              ref={inputFile}
              // OnClick event required to clear previous selection of files, so onChange will be fired if same files selected again
              onClick={e => {
                const element = e.target as HTMLInputElement;
                element.value = '';
              }}
              onChange={e => handleUpload(e.target.files)}
            />
            <StyledButton
              id="btn_UploadMedia"
              onClick={() =>
                inputFile && inputFile.current ? inputFile.current.click() : {}
              }
              data-stringid={text ? text : 'Gallery.UploadImage'}
              text={text ? text : translationString('Gallery.UploadImage')}
              stretch="auto"
              weight="normal"
              leftIcon={
                <UploadLinkIcon as={isUploading ? LoadingIcon : UploadIcon} />
              }
              variant={small ? 'ghost' : 'primary'}
            />
          </UploadLinkContainer>
          {uploadingError && (
            <SignalMessage type={Signal.ALERT} message={uploadingError} />
          )}
        </>
      )}
    </>
  );
};

const HiddenUploadInput = styled.input`
  display: none;
`;

const UploadLinkContainer = styled.div`
  padding-top: ${({ theme }) => theme.spacing.sp8};
`;

const UploadLinkIcon = styled(Icon)`
  width: ${({ theme }) => theme.sizes.sz16};
  height: ${({ theme }) => theme.sizes.sz16};
  margin-right: ${({ theme }) => theme.spacing.sp8};
`;

const StyledButton = styled(Button)``;
