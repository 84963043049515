import { createContext } from 'react';
import { HierarchyNode } from '../../../types';

interface nodeContextInterface {
  newNodeId?: string;
  setNewNode: (node: HierarchyNode | null) => void;
}

export const NodeContext = createContext<nodeContextInterface>({
  newNodeId: undefined,
  setNewNode: () => {},
});
