import styled from 'styled-components/macro';

const StyledInput = styled.input`
  border: none;
  height: 40px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.default.gray1};
  font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
  color: ${({ theme }) => theme.colors.default.gray3};
  padding: ${({ theme }) => `0 ${theme.spacing.sp8}`};
  outline: none;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:disabled {
    background-color: ${({ theme }) => theme.colors.default.gray2};
    color: ${({ theme }) => theme.colors.default.white};
    cursor: default;
  }
`;

export default StyledInput;
