import React, { ReactNode, useRef, useState } from 'react';
import { Icon } from '@eriksdigital/atomic-ui/components';
import { DownArrowIcon } from '@eriksdigital/atomic-ui/components/Icons';
import styled from 'styled-components';
import t from 'locales/translation';
import { useClickOutside } from 'utils/hooks/useClickOutside';
import Checkbox from 'app/elements/Fields/Checkbox';
import { ButtonSecondary } from 'app/elements/Buttons';
import Label from 'app/elements/Fields/Label';

export type SelectOption = {
  id: string;
  content: ReactNode;
  disabled?: boolean;
  depth?: number;
};

type Props = {
  name: string;
  options: SelectOption[];
  selected: string[];
  onSelect: (id: string) => void;
  onClose?: () => void;
  onSubmit?: (selected: string[]) => void;
};

function Select({
  options,
  selected,
  name,
  onSelect,
  onClose,
  onSubmit,
}: Props) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useClickOutside(ref, () => {
    if (isOpen) {
      setIsOpen(false);
      onClose?.();
    }
  });

  const toggle = () => {
    if (isOpen) {
      setIsOpen(false);
      onClose?.();
    } else {
      setIsOpen(true);
    }
  };

  const handleSubmit = () => {
    setIsOpen(false);
    onSubmit?.(selected);
  };

  return (
    <Wrapper aria-expanded={isOpen} ref={ref}>
      <SelectButton type="button" onClick={toggle}>
        {selected.length === 0 && t('SelectPlaceholder')}
        {selected.length === 1 && (
          <span>{options.find(item => item.id === selected[0])?.content}</span>
        )}
        {selected.length > 1 && (
          <SelectionCount>{selected.length}</SelectionCount>
        )}
        <Icon as={DownArrowIcon} />
      </SelectButton>
      <SelectDropdown>
        <Options>
          {options.map((option, index) => (
            <Option key={`SelectOption_${name}_${index}`} depth={option.depth}>
              <Checkbox
                id={`SelectCheckbox_${name}_${option.id}`}
                name={`SelectCheckbox_${name}_${option.id}`}
                small
                checked={selected.includes(option.id)}
                onChange={() => onSelect(option.id)}
                disabled={option.disabled}
              />
              <Label htmlFor={`SelectCheckbox_${name}_${option.id}`}>
                {option.content}
              </Label>
            </Option>
          ))}
        </Options>
        {onSubmit && (
          <SelectFooter>
            <ButtonSecondary onClick={handleSubmit}>
              {t('Submit')}
            </ButtonSecondary>
          </SelectFooter>
        )}
      </SelectDropdown>
    </Wrapper>
  );
}

export const SelectDropdown = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  z-index: 2;
  max-height: 0;
  background-color: ${({ theme }) => theme.colors.default.gray1};
  transition: all 300ms;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
`;

const Options = styled.ul`
  flex: 1;
  margin: 0;
  padding: 0;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    appearance: none;
    width: ${({ theme }) => theme.sizes.sz8};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.default.gray2};
    border-radius: 8px;
  }
`;

type OptionProps = {
  depth?: number;
};

const Option = styled.li<OptionProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  list-style-type: none;
  padding: ${({ theme }) => `${theme.spacing.sp4} ${theme.spacing.sp12}`};
  font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
  color: ${({ theme }) => theme.colors.default.blue2};
  margin-left: ${({ depth }) => `calc(${depth || 0}*16px)`};

  label {
    font-weight: ${({ theme }) => theme.fonts.fontWeight.normal};
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }

  input:disabled + label {
    color: ${({ theme }) => theme.colors.default.gray3};
  }
`;

export const SelectFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: ${({ theme }) => `${theme.spacing.sp4} ${theme.spacing.sp12}`};
  border-top: 1px solid ${({ theme }) => theme.colors.default.white};
  button {
    padding: ${({ theme }) => `${theme.spacing.sp12}`};
  }
`;

export const SelectionCount = styled.span`
  background-color: ${({ theme }) => theme.colors.default.blue1};
  color: ${({ theme }) => theme.colors.default.white};
  padding: ${({ theme }) => `${theme.spacing.sp4} ${theme.spacing.sp8}`};
`;

const SelectButton = styled.button`
  border: none;
  background-color: transparent;
  font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${({ theme }) => theme.spacing.sp12};
  color: ${({ theme }) => theme.colors.default.gray3};
  cursor: pointer;

  svg {
    width: ${({ theme }) => theme.sizes.sz16};
    height: ${({ theme }) => theme.sizes.sz16};
    transition: all 300ms;
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Wrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.default.gray1};
  height: 40px;
  width: fill-available;

  &[aria-expanded='true'] {
    ${SelectDropdown} {
      max-height: 300px;
      box-shadow: 0px 4px 4px 0px rgba(22, 22, 22, 0.1);
    }

    ${SelectButton} svg {
      transform: rotate(-180deg);
    }
  }
`;

export default Select;
