import { Severity, Alert } from 'types';
import { CUTOFF_WARNING, CUTOFF_GOOD, CUTOFF_UNKNOWN } from './constants';

export const getWarningLevel = (score: number): Severity => {
  if (score >= CUTOFF_WARNING) {
    return Severity.CRITICAL;
  }
  if (score >= CUTOFF_GOOD) {
    return Severity.WARNING;
  }

  if (score >= CUTOFF_UNKNOWN) {
    return Severity.GOOD;
  }

  return Severity.UNKNOWN;
};

export const getAlertSeverity = (alert: Alert) => {
  if (alert.severity) {
    // Severity wording differs from condition to status monitoring
    switch (alert.severity.toUpperCase()) {
      case 'SERIOUS':
        return Severity.CRITICAL;
      case 'WARNING':
        return Severity.WARNING;
      case 'GOOD':
        return Severity.GOOD;
    }
    return Severity.UNKNOWN;
  } else {
    return getWarningLevel(alert.conditionScore ?? 0);
  }
};
