import React from 'react';
import styled from 'styled-components/macro';
import t from 'locales/translation';
import { ProcessStatus } from 'app/components/ProcessStatusIndicator/types';
import ProcessStatusIndicator from 'app/components/ProcessStatusIndicator';
import { ButtonGroup, ButtonPrimary } from 'app/elements/Buttons';

type Props = {
  status: ProcessStatus;
  onClose: () => void;
};

function InspectionProcess({ status, onClose }: Props) {
  const statusMessage = {
    success: t('Inspections.Success'),
    failed: t('Inspections.Failed'),
    running: t('Processing'),
  };

  return (
    <Wrapper>
      <ProcessStatusIndicator status={status}>
        {statusMessage[status]}
      </ProcessStatusIndicator>
      <ButtonGroup>
        <ButtonPrimary onClick={onClose} disabled={status === 'running'}>
          {t('Close')}
        </ButtonPrimary>
      </ButtonGroup>
    </Wrapper>
  );
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 24px;

  ${({ theme }) => theme.breakpoints.tabletLandscape`
    width: 500px;
  `};
`;

export default InspectionProcess;
