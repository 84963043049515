import { PayloadAction } from '@reduxjs/toolkit';
import { takeLatest, put, select, all, call, delay } from 'redux-saga/effects';
import { translationString } from 'locales/translation';
import { Alert, AlertType, TaskReq } from 'types';
import { notifyAxiosError } from 'utils/error-helpers';
import apiCall from 'services/utils/apiCall';
import { getMaintainableItemDetails } from 'services/hierarchyServices';
import {
  getMIWorkOrders,
  createTask as createTaskAPI,
} from 'services/taskManagementService';
import {
  getMIAggregatedConditionScore,
  getMIAggregatedSensorAlert,
  getMISensorAlerts,
} from 'services/conditionMonitoringService';
import { actions as announcementActions } from 'app/containers/AnnouncementsToast/slice';
import { actions } from './slice';
import { EquipmentDetailsPostPayload } from './types';
import {
  selectAlertsDateRange,
  selectWorkOrdersPayload,
  selectMiId,
  selectIsPoolingEquipment,
} from './selectors';
import { DateRangeValue } from './components/DateRangeSelector';

export function* getEquipment(action: PayloadAction<string>) {
  try {
    const { data } = yield apiCall(
      getMaintainableItemDetails,
      action.payload,
      true,
    );

    yield put(actions.getEquipmentSuccess(data));
  } catch (error) {
    const effectError = yield notifyAxiosError(
      error,
      translationString('ErrorMessage.EquipmentError'),
    );
    yield put(actions.getEquipmentError(effectError));
  }
}

export function* getEquipmentStatus(action: PayloadAction<string>) {
  try {
    const miId = action.payload;

    const [{ data: condition }, { data: status }] = yield all([
      apiCall(getMIAggregatedConditionScore, miId),
      apiCall(getMIAggregatedSensorAlert, miId),
    ]);

    yield put(
      actions.getEquipmentStatusSuccess({
        condition,
        status,
      }),
    );
  } catch (error) {
    const effectError = yield notifyAxiosError(
      error,
      translationString('ErrorMessage.EquipmentError'),
    );

    yield put(actions.getEquipmentStatusError(effectError));
  }
}

export function* poolEquipment() {
  yield delay(30000); // 30 seconds
  const isPooling = yield select(selectIsPoolingEquipment);
  if (!isPooling) {
    return;
  }
  const miId = yield select(selectMiId);

  yield call(getEquipmentStatus, {
    type: actions.getEquipmentStatus.type,
    payload: miId,
  });

  yield call(getEquipment, {
    type: actions.getEquipment.type,
    payload: miId,
  });

  yield call(poolEquipment);
}

export function* getPlannedWorkOrders() {
  try {
    const payload: EquipmentDetailsPostPayload = yield select(
      selectWorkOrdersPayload('planned'),
    );
    const { data } = yield apiCall(getMIWorkOrders, payload);

    yield put(actions.getPlannedWorkOrdersSuccess(data));
  } catch (error) {
    const workOrdersError = yield notifyAxiosError(
      error,
      translationString('ErrorMessage.WorkOrdersError'),
    );
    yield put(actions.getWorkOrdersPlannedError(workOrdersError));
  }
}

export function* getPreviousWorkOrders() {
  try {
    const payload: EquipmentDetailsPostPayload = yield select(
      selectWorkOrdersPayload('previous'),
    );
    const { data } = yield apiCall(getMIWorkOrders, payload);

    yield put(actions.getPreviousWorkOrdersSuccess(data));
  } catch (error) {
    const workOrdersError = yield notifyAxiosError(
      error,
      translationString('ErrorMessage.WorkOrdersError'),
    );
    yield put(actions.getWorkOrdersPreviousError(workOrdersError));
  }
}

export function* getAlerts(action: PayloadAction<string>) {
  try {
    const miId = action.payload;
    const dateRange: DateRangeValue = yield select(selectAlertsDateRange());
    const { data }: { data: Alert[] } = yield apiCall(
      getMISensorAlerts,
      miId,
      new Date(dateRange.startDate),
      new Date(dateRange.endDate),
    );
    const alerts = data.filter(
      alert =>
        alert.conditionScore !== 0 ||
        (alert.conditionScore === 0 && alert.type === AlertType.SURVEY),
    );
    yield put(actions.getAlertsSuccess(alerts));
  } catch (error) {
    const effectError = yield notifyAxiosError(
      error,
      translationString('ErrorMessage.AlertsRetrievingError'),
    );
    yield put(actions.getAlertsError(effectError));
  }
}

export function* createTask(action: PayloadAction<TaskReq>) {
  try {
    const { data } = yield apiCall(createTaskAPI, action.payload);

    yield put(actions.createTaskSuccess(data));
    yield put(
      announcementActions.addSuccess(translationString('SuccessfullySaved')),
    );
  } catch (error) {
    yield notifyAxiosError(error, translationString('SaveFailed'));
  }
}

export function* equipmentDetailsSaga() {
  yield takeLatest(actions.getPlannedWorkOrders.type, getPlannedWorkOrders);
  yield takeLatest(actions.createTask.type, createTask);
  yield takeLatest(actions.setPlannedSort.type, getPlannedWorkOrders);
  yield takeLatest(actions.setPlannedPageInfo.type, getPlannedWorkOrders);
  yield takeLatest(actions.getPreviousWorkOrders.type, getPreviousWorkOrders);
  yield takeLatest(actions.setPreviousSort.type, getPreviousWorkOrders);
  yield takeLatest(actions.setPreviousPageInfo.type, getPreviousWorkOrders);
  yield takeLatest(actions.getEquipment.type, getEquipment);
  yield takeLatest(actions.getEquipmentStatus.type, getEquipmentStatus);
  yield takeLatest(actions.startPoolingEquipment.type, poolEquipment);
  yield takeLatest(actions.getAlerts.type, getAlerts);
}
