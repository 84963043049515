import {
  createAbsoluteUrl,
  apiGet,
  apiPut,
  apiPost,
  apiDelete,
} from './utils/apiUtils';
import * as RESOURCE_PATHS from './resourcePaths';
import { PutHierarchyNotificationPreferencesPayload } from 'app/containers/NotificationPreferences/types';
import { User } from 'types';

const API_BASE_URL: string = process.env.REACT_APP_API_USER_MANAGEMENT || '';

export const getMe = () => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.USER_MGMT_PROFILE_ME,
    ),
  );
};

export const getUserRoles = async (): Promise<any> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.USER_MGMT_USER_ROLES,
    ),
  );
};

export const getLanguages = async (): Promise<any> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.USER_MGMT_LANGUAGES,
    ),
  );
};

export const getUserById = async (userId: string): Promise<any> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.USER_MGMT_USER_USER.replace(/%id/, userId),
    ),
  );
};

export const getAllUsers = async (
  role: string,
  searchString: string,
  page: number = 1,
  pageSize: number = 100,
): Promise<any> => {
  const params = new URLSearchParams();
  params.append('role', role);
  params.append('searchString', searchString);
  params.append('pageIndex', page.toString());
  params.append('pageSize', pageSize.toString());

  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.USER_MGMT_USER_ALL_USERS,
      params,
    ),
  );
};
export const getUsersForCustomer = async (
  customerId: string,
  page: number = 1,
  pageSize: number = 100,
): Promise<any> => {
  const params = new URLSearchParams();
  params.append('pageIndex', page.toString());
  params.append('pageSize', pageSize.toString());

  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.USER_MGMT_CUMGMT_USERS.replace(/%id/, customerId),
      params,
    ),
  );
};

export const getUsersForLocation = async (
  locationId: string,
  showAll: boolean = false,
  pageSize: number = 1000,
): Promise<any> => {
  const params = new URLSearchParams();
  params.append('pageSize', pageSize.toString());
  params.append('showAll', showAll.toString());

  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.USER_MGMT_LOCATIONS_USERS.replace(/%id/, locationId),
      params,
    ),
  );
};

export const createNewUser = async (user: User): Promise<any> => {
  const saveUserObject: any = { ...user };
  delete saveUserObject.id;

  return apiPost(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.USER_MGMT_USER_ALL_USERS,
    ),
    saveUserObject,
  );
};

export const updateUserDetails = async (user: User): Promise<any> => {
  return apiPut(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.USER_MGMT_USER_USER.replace(/%id/, user.id),
    ),
    user,
  );
};

export const deleteUser = async (userId: string): Promise<any> => {
  return apiDelete(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.USER_MGMT_USER_USER.replace(/%id/, userId),
    ),
  );
};

export const getContactsVerification = async (id: string): Promise<any> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.USER_MGMT_CONTACTS_VERIFICATION.replace(/%id/, id),
    ),
  );
};

export const postVerificationCode = async (
  id: string,
  otp: string,
): Promise<any> => {
  return apiPost(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.USER_MGMT_CONTACTS_VERIFICATION_CODE.replace(
        /%id/,
        id,
      ).replace(/%otp/, otp),
    ),
    '',
  );
};

export const getNotificationTypes = async (): Promise<any> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.USER_NOTIFICATION_TYPES,
    ),
  );
};

export const getNotificationHierarchyPreferences = async (
  customerId: string,
  userId: string,
  notificationId: string,
): Promise<any> => {
  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.USER_CUSTOMER_NOTIFICATION_HIERARCHY_PREFERENCES.replace(
        /%customerId/,
        customerId,
      )
        .replace(/%userId/, userId)
        .replace(/%notificationId/, notificationId),
    ),
  );
};

export const putNotificationHierarchyPreferences = async (
  payload: PutHierarchyNotificationPreferencesPayload,
): Promise<any> => {
  const { notificationId, userId, updateObjects } = payload;
  return apiPut(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.USER_NOTIFICATION_HIERARCHY_PREFERENCES.replace(
        /%userId/,
        userId,
      ).replace(/%notificationId/, notificationId),
    ),
    updateObjects,
  );
};
