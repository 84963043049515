import { createSelector } from '@reduxjs/toolkit';

import { RootState, Attribute } from 'types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.equipment || initialState;

export const selectEquipment = createSelector(
  [selectDomain],
  ({
    loading,
    error,
    maintainableItem,
    metadata,
    images,
    attachments,
    condition,
    status,
    tasks,
    location,
    languages,
  }) => ({
    loading,
    error,
    maintainableItem,
    metadata,
    images,
    attachments,
    condition,
    status,
    tasks,
    location,
    languages,
  }),
);

export const selectAttributes = () =>
  createSelector([selectDomain], ({ maintainableItem }) => {
    const attributes: Attribute[] =
      maintainableItem?.attributes
        ?.filter(
          att =>
            att.value &&
            att.value !== 'DONT_KNOW' &&
            att.id !== 'displayName' &&
            att.id !== 'name',
        )
        .sort((a, b) => a.order - b.order)
        .slice(0, 3) || [];

    return attributes;
  });
