import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from './slice';

const selectDomain = (state: RootState) =>
  state.equipmentAttachments || initialState;

export const selectUploadStatus = createSelector(
  [selectDomain],
  ({ isUploading, uploadingStatus }) => ({ isUploading, uploadingStatus }),
);

export const selectDocumentsAndCertificates = createSelector(
  [selectDomain],
  ({ data }) => data,
);

export const selectIsLoadingDocuments = createSelector(
  [selectDomain],
  ({ loading }) => loading,
);

export const selectCertificatesSort = createSelector(
  [selectDomain],
  ({ sort }) => sort,
);

export const selectResourceTypes = createSelector(
  [selectDomain],
  ({ resourceTypes }) => resourceTypes,
);
