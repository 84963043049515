import { AccountInfo } from '@azure/msal-browser';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState } from './types';
import { User, UserRole } from 'types';

// The initial state of the AssetsNavigator container
export const initialState: ContainerState = {};

const authSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    saveAccount(state, action: PayloadAction<AccountInfo>) {
      return Object.assign({}, state, {
        account: action.payload,
      });
    },
    saveUser(state, action: PayloadAction<User>) {
      return Object.assign({}, state, {
        user: action.payload,
      });
    },
    setUserFetchError(state, action: PayloadAction<string>) {
      return {
        ...state,
        userError: action.payload,
      };
    },
    getUserRolesComplete(state, action: PayloadAction<UserRole[]>) {
      return {
        ...state,
        userRoles: action.payload,
      };
    },
    updateUser(state, action: PayloadAction<User>) {
      return {
        ...state,
        user: action.payload,
        userSaving: true,
      };
    },
    updateUserSuccess(state, action: PayloadAction<User>) {
      return {
        ...state,
        userSaving: false,
        user: action.payload,
      };
    },
    updateUserFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        userSaving: false,
      };
    },
  },
});

export const { actions, reducer, name: sliceKey } = authSlice;
