import React from 'react';
import styled from 'styled-components/macro';
import { Resource, ResourceType } from 'types';
import { Text } from 'app/components/Typography';
import { formatDate } from 'locales/date-format-i18n';
import CertificateStatusIcon from './CertificateStatusIcon';
import InspectionNewButton from 'app/containers/EquipmentInspection/components/InspectionNewButton';

export const CertificatesTableIsValid = ({
  document,
}: {
  document: Resource;
}) => {
  const { resourceType, specificProperties } = document;
  const { certificateStatus, validUntil } = specificProperties || {};

  return (
    <Wrapper>
      {!!certificateStatus && (
        <CertificateStatusIcon
          certificateStatus={certificateStatus}
          size="16px"
        />
      )}
      {!!validUntil && <Text fontSize="ft14">{formatDate(validUntil)}</Text>}
      {resourceType === ResourceType.CERTIFICATE && <InspectionNewButton />}
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  > :last-child {
    margin-left: auto;
  }
`;
