/**
 *
 * CustomerControls
 *
 */

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { CustomerSelector } from 'app/components/CustomerSelector';
import { reducer, sliceKey, actions } from '../slice';
import {
  selectCustomerControls,
  selectAccountHasManyCustomers,
  selectSelectedCustomer,
  selectHasCustomerSelector,
} from '../selectors';
import { customerControlsSaga } from '../saga';
import { TAM, FAM } from 'app/providers/AuthProvider/roles';
import { useAuth } from 'app/providers/AuthProvider';

interface Props {}

export const CustomerControls = (props: Props) => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: customerControlsSaga });
  const dispatch = useDispatch();
  const { userHasRole } = useAuth();

  const showCustomerPicker = useSelector(selectAccountHasManyCustomers);
  const { customers, customersLoading } = useSelector(selectCustomerControls);
  const selectedCustomerId = useSelector(selectSelectedCustomer);
  const hasCustomerSelector = useSelector(selectHasCustomerSelector);

  useEffect(() => {
    dispatch(actions.getCustomers());
  }, [dispatch]);

  if (!userHasRole([TAM, FAM]) || !showCustomerPicker || !hasCustomerSelector) {
    return <></>;
  }

  if (customersLoading) {
    return <LoadingIndicator small data-testid="customer-loader" />;
  }

  return (
    <CustomerSelector
      onSelect={customerId => {
        dispatch(actions.changeSelectedCustomer(customerId));
      }}
      customers={customers}
      selectedCustomerId={selectedCustomerId}
      data-testid="customer-picker"
      showCreateCustomer={userHasRole([TAM, FAM])}
    />
  );
};
