import { DropdownListItem } from './types';

export const filterListItems = (
  listItems: DropdownListItem[],
  searchTerm: string,
): DropdownListItem[] =>
  listItems.filter(item =>
    item.text
      ? item.text.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
      : false,
  );
