/**
 *
 * AppHeaderBar
 *
 */

import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components/macro';
import { Button, Hamburger } from '@eriksdigital/atomic-ui/components';
import { breakpointObj } from '@eriksdigital/atomic-ui/styles';

import { Div } from 'app/components/Div';
import { HeadwayWidget } from 'app/components/HeadwayWidget';
import { NotificationsIndicator } from 'app/components/Notifications/NotificationsIndicator';
import { UsernameDisplayMenu } from 'app/components/UsernameDisplayMenu';
import {
  CustomerControls,
  LocationControls,
} from 'app/containers/CustomerLocation';
import { selectMenuIsOpen } from 'app/containers/SideMenu/selectors';
import { actions as menuActions } from 'app/containers/SideMenu/slice';
import { useAuth } from 'app/providers/AuthProvider';
import t from 'locales/translation';
import { mediaQuery, pxToRem } from 'utils/style-utils';
import { useWindowSize } from 'utils/hooks/useWindowSize';

export function AppHeaderBar() {
  const dispatch = useDispatch();
  const isMenuOpen = useSelector(selectMenuIsOpen);
  const theme = useContext(ThemeContext);
  const { isAuthenticated, login } = useAuth();
  const { pathname } = useLocation();
  const { width } = useWindowSize();

  const handleClick = e => {
    // Prevent the link from navigating in mobile view
    const isMobile = width <= breakpointObj.tabletPortrait;

    if (isMobile) e.preventDefault();
  };

  const hideComponent = (): boolean => {
    const array = pathname.split('/');
    const isElementInArray =
      array.includes('allLogs') ||
      array.includes('allTasks') ||
      array.includes('allAttachments');

    return isElementInArray;
  };

  if (hideComponent()) {
    return null;
  }

  return (
    <>
      <Container display="flex" align="center" justifyContent="space-between">
        <LeftSection>
          <HamburgerWrapper>
            <Hamburger
              open={isMenuOpen}
              onClick={() => dispatch(menuActions.toggle())}
              color={theme.colors.default.blue1}
            />
          </HamburgerWrapper>

          <Logo>
            <Link to="/" onClick={handleClick} title="Eriks"></Link>
          </Logo>
          {isAuthenticated() && (
            <MobileWrapper>
              <CustomerControls />
            </MobileWrapper>
          )}
          <MobileWrapper>
            <LocationControls />
          </MobileWrapper>
        </LeftSection>
        <MobileWrapper>
          {isAuthenticated() ? (
            <Div display="flex" align="center" gap={theme.spacing.sp16}>
              <NotificationsIndicator />
              <HeadwayWidget />
              <UsernameDisplayMenu />
            </Div>
          ) : (
            <Div>
              <Button
                id="login-button"
                data-testid="login"
                onClick={login}
                variant="ghost"
              >
                {t('LogIn')}
              </Button>
            </Div>
          )}
        </MobileWrapper>
      </Container>
      <Placeholder />
    </>
  );
}

export default AppHeaderBar;

const Container = styled(Div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 40px;
  z-index: ${({ theme }) => theme.stacking.topBar};
  padding: 0 ${({ theme }) => theme.spacing.sp12};
  background-color: ${({ theme }) => theme.colors.background.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.background.lightGray};

  ${mediaQuery.phoneLarge} {
    height: 80px;
    padding: 0 ${({ theme }) => theme.spacing.sp16};
  }
`;

const Placeholder = styled(Div)`
  min-height: 40px;

  ${mediaQuery.phoneLarge} {
    min-height: 80px;
  }
`;

const Logo = styled.div`
  a {
    display: block;
    background-image: url(${`${process.env.PUBLIC_URL}/eriks.svg`});
    background-size: contain;
    background-repeat: no-repeat;
    height: ${pxToRem(20)};
    width: ${pxToRem(70)};

    ${mediaQuery.phoneLarge} {
      height: ${pxToRem(32)};
      width: ${pxToRem(100)};
    }
  }
`;

// TODO: the actual mobile header is still to be defined, this is a temporary solution to hide
// what is not in the mobile view
const MobileWrapper = styled.div`
  display: none;

  ${mediaQuery.phoneLarge} {
    display: block;
  }
`;

// hide the hamburger menu for now also for mobile view
const HamburgerWrapper = styled.div`
  display: none;

  ${mediaQuery.phoneLarge} {
    display: none;
  }
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sp12};

  ${mediaQuery.phoneLarge} {
    gap: ${({ theme }) => theme.spacing.sp24};
  }
`;
