import React from 'react';
import styled from 'styled-components/macro';
import t from 'locales/translation';
import { DropdownSelector } from 'app/components/DropdownSelector';
import { Checkbox } from 'app/elements/Fields';
import { DataTableRow } from '../types';
import { useSelectContext } from './SelectContextProvider';

type Props = {
  data: DataTableRow[];
};

function SelectAll({ data }: Props) {
  const { selected, select, unselect, clear } = useSelectContext();
  const hasSelectedAll =
    !!selected.length && data.every(row => selected?.includes(row.id));
  const ids = data.map(row => row.id);

  return (
    <>
      <Wrapper>
        <DropdownSelector
          id="data-table-select-dropdown"
          closeOnSelect
          selectedItemDisplay={
            <CheckboxStyled
              small
              name="DataTableSelectAll"
              checked={hasSelectedAll}
              onChange={() => null}
            />
          }
          listItems={[
            {
              id: 'select-all-this-page',
              contents: (
                <ActionItem onClick={() => select(ids)}>
                  {t('SelectAllThisPage')}
                </ActionItem>
              ),
            },
            {
              id: 'unselect-all-this-page',
              contents: (
                <ActionItem onClick={() => unselect(ids)}>
                  {t('UnselectAllThisPage')}
                </ActionItem>
              ),
            },
            {
              id: 'unselect-all',
              contents: (
                <ActionItem onClick={() => clear()}>
                  {t('UnselectAll')}
                </ActionItem>
              ),
            },
          ]}
        />
      </Wrapper>
    </>
  );
}

const CheckboxStyled = styled(Checkbox)`
  cursor: pointer;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  height: 100%;

  button svg {
    color: ${({ theme }) => theme.colors.default.gray3};
  }
`;

const ActionItem = styled.div`
  padding: 8px 0;
  font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
  color: ${({ theme }) => theme.colors.default.blue1};
  font-weight: ${({ theme }) => theme.fonts.fontWeight.normal};
  text-align: left;
`;

export default SelectAll;
