import React from 'react';

interface Props {
  active?: boolean;
}

export function Calendar({ active }: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em">
      <g clipPath="">
        <path
          d="M13 2H11V1H10V2H6V1H5V2H3C2.45 2 2 2.45 2 3V13C2 13.55 2.45 14 3 14H13C13.55 14 14 13.55 14 13V3C14 2.45 13.55 2 13 2ZM13 13H3V6H13V13ZM13 5H3V3H5V4H6V3H10V4H11V3H13V5Z"
          fill={active ? 'white' : '#002C4E'}
        />
      </g>
    </svg>
  );
}
