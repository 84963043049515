import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';

import { initialState } from './slice';
import { LocationWithOfflineStatus } from './types';

const selectDomain = (state: RootState) =>
  state.customerManagement || initialState;

export const selectCustomerManagement = createSelector(
  [selectDomain],
  ({
    countriesLoading,
    countriesError,
    contractPhasesLoading,
    technicalUsersLoading,
  }) => ({
    countriesLoading,
    countriesError,
    contractPhasesLoading,
    technicalUsersLoading,
  }),
);
export const selectSensorProjectLinkUnlinkLoading = createSelector(
  [selectDomain],
  ({ sensorProjectLinkUnlinkLoading }) => sensorProjectLinkUnlinkLoading,
);

export const selectLinkedSensorProject = createSelector(
  [selectDomain],
  ({ linkedSensorProject }) => linkedSensorProject,
);

export const selectLinkedSensorProjectError = createSelector(
  [selectDomain],
  ({ linkedSensorProjectError }) => linkedSensorProjectError,
);

export const selectLinkedSensorProjectLoading = createSelector(
  [selectDomain],
  ({ linkedSensorProjectLoading }) => linkedSensorProjectLoading,
);

export const selectUnlinkedSensorProjects = createSelector(
  [selectDomain],
  ({ unlinkedSensorProjects }) => unlinkedSensorProjects,
);

export const selectUnlinkedSensorProjectsLoading = createSelector(
  [selectDomain],
  ({ unlinkedSensorProjectsLoading }) => unlinkedSensorProjectsLoading,
);

export const selectSurveyTypesMappedAsOptions = createSelector(
  [selectDomain],
  ({ surveyTypes }) =>
    surveyTypes
      ? surveyTypes.map(st => ({
          label: st.description,
          value: st.id,
          extension: st.extension,
        }))
      : null,
);

export const selectSurveyTypesError = createSelector(
  [selectDomain],
  ({ surveyTypesError }) => surveyTypesError,
);

export const selectSurveyTypesLoading = createSelector(
  [selectDomain],
  ({ surveyTypesLoading }) => surveyTypesLoading,
);

export const selectCustomerLocationsLoading = createSelector(
  [selectDomain],
  customerManagementState => customerManagementState.locationsLoading,
);

export const selectCustomerLocationsError = createSelector(
  [selectDomain],
  customerManagementState => customerManagementState.locationsError,
);

export const selectCustomerLocations = createSelector(
  [selectDomain],
  ({ locations, locationsOfflineStatus }) =>
    locations?.map(loc => {
      const matchingOfflineStatus = locationsOfflineStatus?.filter(status => {
        return status.id === loc.id;
      });
      if (matchingOfflineStatus) {
        return { ...loc, ...matchingOfflineStatus[0] };
      }
      return loc;
    }) as LocationWithOfflineStatus[],
);

export const selectCustomerLocationsMappedAsOptions = createSelector(
  [selectDomain],
  customerManagementState =>
    customerManagementState.locations
      ? customerManagementState.locations.map(location => ({
          label: location.name,
          value: location.id,
        }))
      : [],
);

export const selectCustomerLocationUpdateError = createSelector(
  [selectDomain],
  customerManagementState => customerManagementState.locationUpdateError,
);

export const selectCustomerLocationUpdateLoading = createSelector(
  [selectDomain],
  ({ locationUpdateLoading }) => locationUpdateLoading,
);

export const selectCustomerLocationDeleteError = createSelector(
  [selectDomain],
  customerManagementState => customerManagementState.locationDeleteError,
);

export const selectCustomerLocationDeleteLoading = createSelector(
  [selectDomain],
  ({ locationDeleteLoading }) => locationDeleteLoading,
);

export const selectCustomerLocation = id =>
  createSelector([selectDomain], customerManagementState =>
    customerManagementState.locations?.find(l => l.id === id),
  );

export const selectCustomerUsersLoading = createSelector(
  [selectDomain],
  customerManagementState => customerManagementState.usersLoading,
);

export const selectCustomerUsers = createSelector(
  [selectDomain],
  customerManagementState => customerManagementState.users,
);

export const selectMendixAvailable = createSelector(
  [selectDomain],
  ({ mendixSyncingAvailable }) => mendixSyncingAvailable,
);

export const selectUploadLogsSort = createSelector(
  [selectDomain],
  customerManagementState => customerManagementState.uploadLogsSort,
);

export const selectUploadLogsPageInfo = createSelector(
  [selectDomain],
  customerManagementState => customerManagementState.uploadLogsPageInfo,
);

export const selectUploadLogs = () =>
  createSelector(
    [selectDomain],
    customerManagementState => customerManagementState.uploadLogs,
  );

export const selectUploadLogsLoading = () =>
  createSelector(
    [selectDomain],
    customerManagementState => customerManagementState.uploadLogsLoading,
  );

export const selectUploadLogsPayload = createSelector(
  [selectDomain],
  customerManagementState => {
    const {
      customerId,
      locationId,
      uploadLogsSort,
      uploadLogsPageInfo,
    } = customerManagementState;

    return {
      locationId,
      customerId,
      pageSize: uploadLogsPageInfo.pageSize || 50,
      pageIndex: uploadLogsPageInfo.page || 1,
      sortField: uploadLogsSort?.columnId || 'createdDate',
      sortDirection: uploadLogsSort?.sortDirection || 'DESC',
    };
  },
);

export const selectCountryOptions = createSelector(
  [selectDomain],
  ({ countries }) =>
    countries
      ? countries.map(country => ({
          label: country.name,
          value: country.id,
        }))
      : [],
);

export const selectContractPhaseOptions = createSelector(
  [selectDomain],
  ({ contractPhases }) =>
    contractPhases
      ? contractPhases.map(phase => ({
          label: phase.name,
          value: phase.id,
        }))
      : [],
);

export const selectTechnicalUsersOptions = createSelector(
  [selectDomain],
  ({ technicalUsers }) =>
    technicalUsers
      ? technicalUsers.map(user => ({
          value: user.email,
          label: `${user.name} (${user.email})`,
        }))
      : [],
);
