import { all, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import apiCall from '../../../services/utils/apiCall';
import {
  getMe,
  getUserRoles,
  updateUserDetails,
} from 'services/userManagementService';
import { User, UserRole } from 'types';
import { actions } from './slice';
import { parseAxiosError } from '../../../utils/error-helpers';
import { translationString } from 'locales/translation';

export function* SaveUser() {
  try {
    const [{ data: userData }, { data: roles }]: [
      { data: User },
      { data: UserRole[] },
    ] = yield all([apiCall(getMe), apiCall(getUserRoles)]);
    yield put(actions.saveUser(userData));
    yield put(actions.getUserRolesComplete(roles));
  } catch (error) {
    const effectError = parseAxiosError(
      error,
      translationString('ErrorMessage.FetchEntity.User'),
    );
    yield put(actions.setUserFetchError(effectError));
  }
}

export function* UpdateUser(action: PayloadAction<User>) {
  try {
    const { data: user }: { data: User } = yield apiCall(
      updateUserDetails,
      action.payload,
    );
    yield put(actions.updateUserSuccess(user));
  } catch (error) {
    const effectError = parseAxiosError(
      error,
      translationString('ErrorMessage.UpdateEntity.User'),
    );
    yield put(actions.updateUserFailure(effectError));
  }
}

export function* authenticationSaga() {
  yield takeLatest(actions.saveAccount.type, SaveUser);
  yield takeLatest(actions.updateUser.type, UpdateUser);
}
