/**
 *
 * Specifications
 *
 */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { SearchForm, Button } from '@eriksdigital/atomic-ui/components';

import t, { translationString } from 'locales/translation';
import { BgColor, TextColor, Attribute, AttributeGroup } from 'types';
import { useAuth } from 'app/providers/AuthProvider';
import { Div } from 'app/components/Div';
import { H4, Text } from 'app/components/Typography';

interface Props {
  groups: AttributeGroup[];
  attributes: Attribute[];
}

export function Specifications({ attributes, groups }: Props) {
  const { isAuthenticated, login } = useAuth();

  const [attr, setAttr] = useState<Attribute[]>(attributes);
  const [attrGroups, setAttrGroups] = useState<
    Record<string, AttributeGroup>
  >();

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();

    if (value.length > 0) {
      const filterAttributes = attributes.filter(atr => {
        return atr.id.toLowerCase().match(value);
      });

      setAttr(filterAttributes);
    } else {
      setAttr(attributes);
    }
  };

  useEffect(() => {
    if (groups.length === 0 || attr.length === 0) return;

    const initialGroups: Record<string, AttributeGroup> = [...groups].reduce(
      (acc, group) => {
        acc[group.name] = { attributes: [], ...group };
        return acc;
      },
      {},
    );

    const attributeGroups = attr.reduce<Record<string, AttributeGroup>>(
      (acc, attribute) => {
        if (attribute.attributeGroupName && acc[attribute.attributeGroupName]) {
          const { attributes, showAll } = acc[attribute.attributeGroupName];

          if (attributes) {
            if (showAll) {
              attributes.push(attribute);
            } else {
              if (attribute.value && attribute.value !== 'DONT_KNOW') {
                attributes.push(attribute);
              }
            }
          }
        }
        return acc;
      },
      initialGroups,
    );

    setAttrGroups(attributeGroups);
  }, [attr, groups]);

  return (
    <Div>
      <Div padding="16px 12px">
        <H4>{t('Specifications')}</H4>

        <Div mt="16px">
          <SearchForm
            id="search"
            data-testid="search"
            placeholder={translationString('Search')}
            data-stringid="Search"
            onChange={handleSearch}
            searchButton
          />
        </Div>

        {attrGroups &&
          Object.values(attrGroups).map((group, index) => (
            <Div key={index} mt="24px">
              {group?.attributes && group.attributes.length > 0 && (
                <>
                  <Div mb="12px">
                    <Text
                      fontSize="ft14"
                      fontWeight="medium"
                      color={TextColor.secondary}
                    >
                      {t(`Group.${group.name}`)}
                    </Text>
                  </Div>

                  {group.attributes?.map((attribute, i) => (
                    <Div
                      key={i}
                      display="flex"
                      justifyContent="space-between"
                      padding="10px 12px"
                      bg={i % 2 === 0 ? BgColor.white : BgColor.lightGray}
                    >
                      <Text
                        fontSize="ft12"
                        fontWeight="medium"
                        color={TextColor.tertiary}
                      >
                        {t(`Attributes.${attribute.id}`)}
                      </Text>
                      <Text>
                        {attribute.id === 'webshopUrl' ? (
                          <a
                            title={attribute.value}
                            href={attribute.value}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Ellipsis>{attribute.value}</Ellipsis>
                          </a>
                        ) : (
                          attribute.displayValue || attribute.value
                        )}
                      </Text>
                    </Div>
                  ))}
                </>
              )}
            </Div>
          ))}
      </Div>

      {/*  */}

      {!isAuthenticated() && (
        <Div
          mt="26px"
          mb="26px"
          display="flex"
          justifyContent="center"
          align="center"
        >
          <Button
            id="log-in-button"
            type="button"
            stretch="auto"
            onClick={login}
          >
            <Text fontWeight="medium">{t('LogInToSeeAllSpecifications')}</Text>
          </Button>
        </Div>
      )}
    </Div>
  );
}

const Ellipsis = styled.div`
  text-align: justify;
  text-align-last: right;

  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
