// API returns wrong type for netbiter
import { SensorType } from 'types';

export const getSensorType = (sensor): SensorType => {
  if (['PROVIDER_DEVICE', 'NETBITER'].includes(sensor)) {
    return SensorType.NETBITER;
  }
  return sensor ?? SensorType.BV;
};

export const getSensorRealType = (sensor: SensorType): string => {
  if (['PROVIDER_DEVICE', 'NETBITER'].includes(sensor)) {
    return 'NETBITER';
  }
  return sensor ?? SensorType.BV;
};
