import { PayloadAction } from '@reduxjs/toolkit';
import { takeLatest, put, all } from 'redux-saga/effects';

import { translationString } from 'locales/translation';
import { EntityType, Resource } from 'types';
import { notifyAxiosError } from 'utils/error-helpers';
import apiCall from 'services/utils/apiCall';
import {
  getMaintainableItemDetails,
  getMaintainableItemsMetadata,
  getParentTree,
} from 'services/hierarchyServices';
import { getMITasks } from 'services/taskManagementService';
import {
  getMIAggregatedConditionScore,
  getMIAggregatedSensorAlert,
} from 'services/conditionMonitoringService';
import { getResourcesForEntity } from 'services/fileManagementServices';
import { getLanguages as getLanguagesAPI } from 'services/userManagementService';
import { actions } from './slice';
import {
  DOCUMENTS_RESOURCE_TYPES,
  MEDIA_RESOURCE_TYPES,
} from 'utils/constants';

export function* getEquipment(action: PayloadAction<string>) {
  try {
    const miId = action.payload;

    const [
      { data: metadata },
      { data: languages },
      { data: maintainableItem },
      { data: images },
      { data: condition },
      { data: status },
    ] = yield all([
      apiCall(getMaintainableItemsMetadata),
      apiCall(getLanguagesAPI),
      apiCall(getMaintainableItemDetails, miId, true),
      apiCall(getResourcesForEntity, miId, {
        entityType: EntityType.MAINTAINABLE_ITEM,
        resourceTypes: MEDIA_RESOURCE_TYPES,
      }),
      apiCall(getMIAggregatedConditionScore, miId),
      apiCall(getMIAggregatedSensorAlert, miId),
    ]);

    const sortedItems: Resource[] =
      (images &&
        images?.sort((a, b) => b.resourceType.localeCompare(a.resourceType))) ||
      [];

    yield put(
      actions.getEquipmentSuccess({
        metadata,
        languages,
        maintainableItem,
        images: sortedItems,
        condition,
        status,
      }),
    );
  } catch (error) {
    const effectError = yield notifyAxiosError(
      error,
      translationString('ErrorMessage.EquipmentError'),
    );

    yield put(actions.getEquipmentError(effectError));
  }
}

export function* getResources(action: PayloadAction<string>) {
  try {
    const { data } = yield apiCall(getResourcesForEntity, action.payload, {
      entityType: EntityType.MAINTAINABLE_ITEM,
      resourceTypes: DOCUMENTS_RESOURCE_TYPES,
    });

    yield put(actions.getResourcesSuccess(data || []));
  } catch (error) {
    yield notifyAxiosError(
      error,
      translationString('ErrorMessage.AttachmentsError'),
    );
  }
}

export function* getTasks(action: PayloadAction<string>) {
  try {
    const { data: tasks } = yield apiCall(getMITasks, action.payload);

    yield put(actions.getTasksSuccess(tasks));
  } catch (error) {
    yield notifyAxiosError(error, translationString('ErrorMessage.TasksError'));
  }
}

export function* getLocation(action: PayloadAction<string>) {
  try {
    const { data } = yield apiCall(getParentTree, action.payload, null, [
      EntityType.LOCATION,
    ]);

    yield put(actions.getLocationSuccess(data.parent));
  } catch (error) {
    yield notifyAxiosError(
      error,
      translationString('ErrorMessage.LocationError'),
    );
  }
}

export function* equipmentSaga() {
  yield takeLatest(actions.getEquipment.type, getEquipment);

  yield takeLatest(actions.getResources.type, getResources);
  yield takeLatest(actions.getTasks.type, getTasks);
  yield takeLatest(actions.getLocation.type, getLocation);
}
