import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { translationString } from 'locales/translation';
import { ValueLabel } from 'types';
import { useAuth } from 'app/providers/AuthProvider';
import { FAM, TAM } from 'app/providers/AuthProvider/roles';
import { getUsersForLocation } from 'services/userManagementService';
import { actions as announcementActions } from 'app/containers/AnnouncementsToast/slice';

export const useUserList = () => {
  const dispatch = useDispatch();

  const { locationId } = useParams<{ locationId: string }>();
  const { userHasRole } = useAuth();
  const showAll = userHasRole([FAM, TAM]);

  const [userList, setUserList] = useState<ValueLabel[]>([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { data } = await getUsersForLocation(locationId, showAll, 2000);
        const users =
          data.content?.map(user => ({
            label: user.name,
            value: user.id,
          })) || [];

        setUserList([
          { label: translationString('Unassigned'), value: '' },
          ...users,
        ]);
      } catch (e) {
        dispatch(announcementActions.addError(e.message));
      }
    };

    fetchUsers();
  }, [dispatch, locationId, showAll]);

  return userList;
};
