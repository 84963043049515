import axios, { ResponseType } from 'axios';
import { URLSearchParams as IURLSearchParams } from 'url';
import { acquireToken } from 'app/providers/AuthProvider';

const {
  REACT_APP_GUEST_TOKEN_NAME = '',
  REACT_APP_GUEST_TOKEN_VALUE,
} = process.env;

export const REQUEST_TIMEOUT_ERR_MESSAGE = 'Request timeout exceeded';
const DEFAULT_AXIOS_CONFIG = {
  timeoutErrorMessage: REQUEST_TIMEOUT_ERR_MESSAGE,
};

export const createAbsoluteUrl = (
  baseUrl: string,
  apiVersion: string,
  resourcePath: string,
  params: IURLSearchParams = new URLSearchParams(),
): string => {
  const url = new URL(
    (baseUrl || window.location.origin) + `/${apiVersion}/${resourcePath}`,
  );
  url.search = params.toString();

  return url.href;
};

export const createWebsocketUrl = (
  baseUrl: string,
  resourcePath: string,
  params: IURLSearchParams = new URLSearchParams(),
): string => {
  const url = new URL(
    (baseUrl || window.location.origin).replace('https', 'wss') +
      `/${resourcePath}`,
  );
  url.search = params.toString();

  return url.href;
};

export const authHeaders = async () => {
  const token = await acquireToken();
  let header = { Accept: 'application/json' };

  if (!token) {
    header[REACT_APP_GUEST_TOKEN_NAME] = REACT_APP_GUEST_TOKEN_VALUE;
  } else {
    header['Authorization'] = `Bearer ${token.idToken}`;
  }

  return header;
};

export const apiGet = async (
  path: string,
  contentType: string = 'x-www-form-urlencoded',
  accept: string = 'application/json',
  responseType?: ResponseType,
): Promise<any> => {
  const headers = await authHeaders();
  headers['Content-Type'] = `application/${contentType}; charset=UTF-8;`;
  headers['Accept'] = accept;

  return await axios.get(path, {
    headers,
    ...DEFAULT_AXIOS_CONFIG,
    responseType,
  });
};

export const apiPost = async (
  path: string,
  data: any,
  contentType: string = 'json',
  accept: string = 'application/json',
  responseType?: ResponseType,
): Promise<any> => {
  const headers = await authHeaders();
  headers['Content-Type'] = `application/${contentType}; charset=UTF-8;`;
  headers['Accept'] = accept;

  return await axios.post(path, data, {
    headers,
    ...DEFAULT_AXIOS_CONFIG,
    responseType,
  });
};

export const apiPut = async (
  path: string,
  data: any,
  contentType: string = 'json',
): Promise<any> => {
  const headers = await authHeaders();
  headers['Content-Type'] = `application/${contentType}; charset=UTF-8;`;

  return await axios.put(path, data, {
    headers,
    ...DEFAULT_AXIOS_CONFIG,
  });
};

export const apiPatch = async (
  path: string,
  data: any,
  contentType: string = 'json',
): Promise<any> => {
  const headers = await authHeaders();
  headers['Content-Type'] = `application/${contentType}; charset=UTF-8;`;

  return await axios.patch(path, data, {
    headers,
    ...DEFAULT_AXIOS_CONFIG,
  });
};

export const apiDelete = async (path: string): Promise<any> => {
  const headers = await authHeaders();

  return await axios.delete(path, { headers, ...DEFAULT_AXIOS_CONFIG });
};
