import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '@eriksdigital/atomic-ui/components';
import styled from 'styled-components/macro';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { useDispatch, useSelector } from 'react-redux';
import msal, { scopes } from 'services/msal';

import t from 'locales/translation';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { ANNOUNCE_NEW_LAYOUT, REMEMBER_CUSTOMER_KEY } from 'utils/constants';
import { initialCookie } from 'utils/cookie';
import { validateAnnouncement } from 'utils/validators';
import { actions, reducer, sliceKey } from './slice';
import { authenticationSaga } from './saga';
import {
  selectAuthentication,
  selectUserDetails,
  selectUserId,
  selectUserRole,
} from './selectors';

export const acquireToken = () => {
  const accounts = msal.getAllAccounts();
  if (accounts.length < 1) return null;

  return msal.acquireTokenSilent({
    scopes,
    account: accounts[0],
  });
};

export const useAuth = () => {
  const userId = useSelector(selectUserId);
  const userRole = useSelector(selectUserRole);
  const announcement = validateAnnouncement();

  return {
    isAuthenticated: () => {
      return !!userId;
    },
    login: useCallback(() => {
      const loginRequest = {
        scopes,
      };

      if (localStorage.getItem(ANNOUNCE_NEW_LAYOUT)) {
        localStorage.removeItem(ANNOUNCE_NEW_LAYOUT);
      }

      if (announcement) {
        initialCookie(announcement.key, 'true', announcement.endDate);
      }

      return msal.loginRedirect(loginRequest);
    }, [announcement]),
    logout: async () => {
      await msal.logout();
      localStorage.removeItem(REMEMBER_CUSTOMER_KEY);
    },
    userHasRole: (roles: string[]): boolean => {
      if (!userRole) {
        return false;
      }
      return roles.includes(userRole);
    },
  };
};

export const useUserDetails = () => {
  return useSelector(selectUserDetails);
};

export default function AuthProvider(props) {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: authenticationSaga });

  const authentication = useSelector(selectAuthentication);
  const authenticatedUserId = useSelector(selectUserId);
  const dispatch = useDispatch();

  const { login, logout } = useAuth();
  const [msalLoaded, setMsalLoaded] = useState(false);
  const [msalError, setMsalError] = useState();
  const [authReady, setAuthReady] = useState(false);

  useEffect(() => {
    msal
      .handleRedirectPromise()
      .then(response => {
        if (response) {
          dispatch(actions.saveAccount(response.account));
        }
        setMsalLoaded(true); // can only check accounts once the handleredirect has been initialised
      })
      .catch(err => {
        setMsalError(err);
      });
  }, [dispatch, login]);

  useEffect(() => {
    if (!msalLoaded) {
      return;
    }

    if (msalError) {
      return;
    }

    const accounts = msal.getAllAccounts();
    if (accounts.length < 1) {
      setAuthReady(true);
      return;
    }

    if (!authentication.account) {
      dispatch(actions.saveAccount(accounts[0]));
    }
  }, [login, msalLoaded, dispatch, authentication, msalError]);

  useEffect(() => {
    if (!!authenticatedUserId) {
      setAuthReady(true);
    }
  }, [authenticatedUserId]);

  // ERROR In the MSAL Authentication Setup
  if (msalError) {
    return (
      <AuthenticationMessageContainer>
        {t('ErrorMessage.AuthError')}
      </AuthenticationMessageContainer>
    );
  }

  // Error in the MSAL Authentication Process
  if (authentication.userError) {
    // If the error is a 401 Unauthorized error, keep the old logic
    if (authentication.userError.match(/401/g)) {
      return (
        <AuthenticationMessageContainer>
          <AuthenticationMessage>
            {t('ErrorMessage.AccountNotInSystem')}
            <Button id="logout-button" onClick={logout}>
              {t('Logout')}
            </Button>
          </AuthenticationMessage>
        </AuthenticationMessageContainer>
      );
    } else {
      // If it's any other user-related error, log out the user automatically
      logout();
    }

    // Return null to stop further rendering of the component in case of an error
    return null;
  }

  // If either
  // 1. There is no account authentication, only public routes
  // 2. There is account authentication AND the user data has loaded from /me
  // Then show the children.
  if (authReady) {
    return props.children;
  }

  // Otherwise we show the loading messaging.
  return (
    <AuthenticationMessageContainer>
      <AuthenticationMessage>
        {t('StatusMessage.CheckAuth')}
        <LoadingIndicator />
      </AuthenticationMessage>
    </AuthenticationMessageContainer>
  );
}

const AuthenticationMessageContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const AuthenticationMessage = styled.div`
  flex-basis: auto;
  text-align: center;
`;
