import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './slice';
import { NotificationPanelItem } from './types';
import { selectSelectedCustomer } from '../CustomerLocation/selectors';

const selectDomain = (state: RootState) => state.notifications || initialState;

export const selectNotifications = createSelector(
  [selectDomain],
  notificationsState => notificationsState,
);

export const selectOverlayVisible = createSelector(
  [selectDomain],
  ({ overlayVisible }) => overlayVisible,
);

export const selectUnreadCount = createSelector(
  [selectDomain],
  ({ unreadCount }) => unreadCount,
);

export const selectCurrentCategory = createSelector(
  [selectDomain],
  ({ currentCategory }) => currentCategory,
);

export const selectUnreadMessageIds = createSelector(
  [selectDomain],
  ({ notifications }) =>
    notifications.reduce(
      (ids: string[], current) =>
        !current.isRead ? [...ids, current.id] : ids,
      [],
    ),
);

export const selectCurrentNotifications = createSelector(
  [selectDomain],
  ({ notifications }) => notifications,
);

export const selectDisplayedNotifications = createSelector(
  [selectDomain, selectSelectedCustomer],
  (
    { notifications, currentCategory, currentCustomerOnly },
    currentCustomer,
  ) => {
    const panelNotifications = notifications.reduce(
      (filteredNotifications: NotificationPanelItem[], current) => {
        const categoryMatched =
          currentCategory === 'ALL' ||
          current.eventCategory === currentCategory;
        const customerMatched =
          !currentCustomerOnly ||
          (currentCustomerOnly && current.customerId === currentCustomer);

        return categoryMatched && customerMatched
          ? [...filteredNotifications, current]
          : filteredNotifications;
      },
      [],
    );
    return panelNotifications as NotificationPanelItem[];
  },
);

export const selectCustomerOnlyFilter = createSelector(
  [selectDomain],
  ({ currentCustomerOnly }) => currentCustomerOnly,
);
