import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { isToday, isYesterday, compareDesc } from 'date-fns';
import { Icon, Checkbox } from '@eriksdigital/atomic-ui/components';
import { CrossIcon } from '@eriksdigital/atomic-ui/components/Icons';

import t from 'locales/translation';
import {
  NotificationEventCategory,
  NotificationPanelItem,
} from 'app/containers/Notifications/types';
import { NotificationGroup } from '../NotificationGroup';
import { NotificationItem } from '../NotificationItem';
import { useAuth } from 'app/providers/AuthProvider';
import { FAM, TAM } from 'app/providers/AuthProvider/roles';

interface Props {
  notifications: NotificationPanelItem[];
  onToggle: Function;
  selectedCategory?: NotificationEventCategory;
  onChangeCategory: Function;
  onMarkAllRead: Function;
  onMarkItemRead: Function;
  customersFiltered: boolean;
  onFilterByCustomer: (boolean) => void;
}

interface TimeGroupedNotifications {
  A_TODAY?: NotificationPanelItem[];
  B_YESTERDAY?: NotificationPanelItem[];
  C_OLDER?: NotificationPanelItem[];
}

const Categories: NotificationEventCategory[] = [
  'ALL',
  'FILE_UPLOAD',
  'ASSET_SEVERITY_CHANGE',
  'STATUS_ALERT',
  'MANUAL_SURVEY_UPLOAD',
];

export const NotificationsOverlay = ({
  notifications,
  onToggle,
  selectedCategory = 'ALL',
  onChangeCategory,
  onMarkAllRead,
  onMarkItemRead,
  customersFiltered,
  onFilterByCustomer,
}: Props) => {
  const { userHasRole } = useAuth();

  const [groupedNotifications, setGroupedNotifications] = useState<
    TimeGroupedNotifications
  >({});

  useEffect(() => {
    const grouped: TimeGroupedNotifications = {};
    for (let note of notifications) {
      if (isToday(note.eventCreatedTime))
        grouped.A_TODAY = [...(grouped?.A_TODAY || []), note];
      else if (isYesterday(note.eventCreatedTime))
        grouped.B_YESTERDAY = [...(grouped?.B_YESTERDAY || []), note];
      else grouped.C_OLDER = [...(grouped?.C_OLDER || []), note];
    }
    setGroupedNotifications(grouped);
  }, [notifications]);

  return (
    <OverlayPanel>
      <PanelHeader>
        <h1>{t('NotificationsPanel.Header')}</h1>
        <CloseButton
          onClick={() => onToggle()}
          id="notification-panel-close-button"
          data-testid="notification-panel-close-button"
        >
          <Icon as={CrossIcon} />
        </CloseButton>
      </PanelHeader>
      {userHasRole([FAM, TAM]) && (
        <CurrentCustomerControl>
          <Checkbox
            id="notif_customer_filter"
            data-testid="notif_customer_filter"
            name="customerFilter"
            checked={customersFiltered}
            onChange={e => onFilterByCustomer(e.target.checked)}
          >
            {t('NotificationsPanel.CustomerFilter')}
          </Checkbox>
        </CurrentCustomerControl>
      )}
      <CategoryTabs>
        {Categories.map(c => (
          <Tab
            role="button"
            key={c}
            onClick={() => {
              onChangeCategory(c);
            }}
            active={c === selectedCategory}
            id={`notification-tab-${c}`}
            data-testid={`notification-tab-${c}`}
          >
            {t(`NotificationsPanel.Tabs.${c}`)}
          </Tab>
        ))}
      </CategoryTabs>

      <NotificationListPane>
        {Object.keys(groupedNotifications)
          .sort()
          .map((key, idx) => (
            <NotificationGroup
              heading={t(`NotificationsPanel.TimeGroups.${key.split('_')[1]}`)}
              key={key}
              onMarkAllRead={idx === 0 ? () => onMarkAllRead() : undefined}
            >
              {groupedNotifications[key]
                .sort((a, b) =>
                  compareDesc(a.eventCreatedTime, b.eventCreatedTime),
                )
                .map(note => (
                  <NotificationItem
                    key={note.id}
                    id={note.id}
                    title={t(
                      `NotificationsPanel.EventTitle.${note.eventCategory}`,
                    )}
                    message={note.text}
                    parameters={note.parameters}
                    messageDate={note.eventCreatedTime}
                    actionRoute={note.actionRoute}
                    unread={!note.isRead}
                    onMarkRead={(closePanel = false) =>
                      onMarkItemRead(note.id, closePanel)
                    }
                    locationName={note?.locationName}
                  />
                ))}
            </NotificationGroup>
          ))}
      </NotificationListPane>
    </OverlayPanel>
  );
};

const OverlayPanel = styled.section`
  background: ${({ theme }) => theme.colors.background.white};
  padding: ${({ theme }) => theme.spacing.sp16} 0;
  display: flex;
  flex-flow: column;
  height: 100%;
`;

const PanelHeader = styled.header`
  flex: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 ${({ theme }) => theme.spacing.sp40};

  h1 {
    font-size: ${({ theme }) => theme.fonts.fontSize.ft20};
  }
`;

const CloseButton = styled.button`
  background: none;
  border-width: 0;
  cursor: pointer;
`;

const CurrentCustomerControl = styled.div`
  margin: 0 ${({ theme }) => theme.spacing.sp40};
  background: ${({ theme }) => theme.colors.background.lightGray};
  padding: ${({ theme }) => theme.spacing.sp8};
`;

const CategoryTabs = styled.div`
  flex: 0;
  width: auto;
  display: flex;
  align-content: stretch;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.darkGray};
  margin: 0 ${({ theme }) => theme.spacing.sp40};
`;

const Tab = styled.div<{ active: boolean }>`
  flex-grow: 1;
  text-align: center;
  padding: ${({ theme }) => theme.spacing.sp16} 0;
  cursor: pointer;
  border-bottom: 2px solid
    ${({ theme, active }) =>
      active ? theme.colors.border.primary : theme.colors.border.white};
`;

const NotificationListPane = styled.div`
  flex: 1;
  min-height: 0;
  overflow: auto;
`;
