import React from 'react';
import styled from 'styled-components/macro';
import { StatusBadge } from '@eriksdigital/atomic-ui/components';
import { AlertSeverity } from 'types';

type Props = {
  severity: keyof typeof AlertSeverity;
};

function SensorAlert({ severity }: Props) {
  return (
    <SensorAlertStyled coloring={AlertSeverity[severity]}>
      <StatusBadge
        coloring={AlertSeverity[severity]}
        variant="inline"
        animated={false}
      />
    </SensorAlertStyled>
  );
}

export default SensorAlert;

const SensorAlertStyled = styled.div<{ coloring: string }>`
  background: ${({ coloring }) => coloring}33;
  display: flex;
  border-radius: 50%;
  width: 24px;
  height: 24px;

  div {
    &::before {
      width: 8px;
      height: 8px;
      left: 8px;
      transform: translateY(-50%);
    }
  }
`;
