import * as Fields from '../';
import Form from './Form';
import withValidation from './withValidation';

const Input = withValidation(Fields.Input);
const TextArea = withValidation(Fields.TextArea);
const Checkbox = withValidation(Fields.Checkbox);
const Select = withValidation(Fields.Select);
const RadioButtonGroup = withValidation(Fields.RadioButtonGroup);
const InputFile = withValidation(Fields.InputFile);

export { Input, Checkbox, Select, RadioButtonGroup, Form, TextArea, InputFile };
