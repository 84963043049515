import { createAbsoluteUrl, apiGet, apiPost } from './utils/apiUtils';
import * as RESOURCE_PATHS from './resourcePaths';
import {
  NotificationQuery,
  NotificationsPage,
} from 'app/containers/Notifications/types';

const API_BASE_URL: string = process.env.REACT_APP_API_MESSAGING || '';

export const getUserNotifications = async (
  userId: string,
  pageSize: number = 20,
  pageIndex: number = 1,
  query?: NotificationQuery,
): Promise<{ data: NotificationsPage }> => {
  const params = new URLSearchParams({
    pageSize: pageSize.toString(),
    pageIndex: pageIndex.toString(),
  });

  if (query) {
    if (query?.readStatus)
      params.append('readStatus', query.readStatus.toString());
    if (query?.eventCategory)
      params.append('eventCategory', query.eventCategory);
  }

  return apiGet(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.MESSAGING_USER_NOTIFICATIONS.replace(/%userId/, userId),
      params,
    ),
  );
};

export const postMarkNotificationsRead = async (
  notificationIds: string[] = [],
): Promise<{ data: null }> => {
  return apiPost(
    createAbsoluteUrl(
      API_BASE_URL,
      RESOURCE_PATHS.API_V1,
      RESOURCE_PATHS.MESSAGING_MARK_NOTIFICATIONS_READ,
    ),
    notificationIds,
  );
};
