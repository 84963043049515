import React from 'react';
import styled from 'styled-components/macro';
import { TextArea } from '@eriksdigital/atomic-ui/components';

import { WrappedComponentProps } from './types';

export const TextAreaAdapter = ({
  input,
  meta,
  label,
  id,
  disabledResize,
  ...otherProps
}: WrappedComponentProps) => {
  return (
    <>
      {label && <label htmlFor={id}>{label}</label>}

      <StyledTextArea
        {...input}
        id={id}
        error={meta?.error && meta?.touched}
        errorMessage={meta?.error && meta?.touched ? meta.error : undefined}
        disabled={meta?.submitting}
        disabledResize={disabledResize}
        {...otherProps}
      />
    </>
  );
};

const StyledTextArea = styled(TextArea)<{ disabledResize: boolean }>`
  ${({ disabledResize }) => disabledResize && 'textarea {resize: none;}'}
`;
