import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';

import {
  MaintainableItem,
  MaintainableItemMetadata,
  AggregatedConditionScore,
  AggregatedSensorAlert,
  Resource,
  Task,
  ParentTreeNode,
  Language,
} from 'types';
import { ContainerState } from './types';

// The initial state of the Equipment container
export const initialState: ContainerState = {
  loading: false,
  error: '',

  images: [],
  attachments: [],
  tasks: [],
  languages: [],
};

const equipmentSlice = createSlice({
  name: 'equipment',
  initialState,
  reducers: {
    getEquipment(state, action: PayloadAction<string>) {
      return {
        ...state,
        loading: true,
        error: initialState.error,
      };
    },
    getEquipmentSuccess(
      state,
      action: PayloadAction<{
        metadata: MaintainableItemMetadata;
        languages: Language[];
        maintainableItem: MaintainableItem;
        condition: AggregatedConditionScore;
        status: AggregatedSensorAlert;
        images: Resource[];
      }>,
    ) {
      const {
        metadata,
        languages,
        maintainableItem,
        images,
        condition,
        status,
      } = action.payload;

      return {
        ...state,
        loading: initialState.loading,
        error: initialState.error,
        metadata,
        languages,
        maintainableItem,
        images,
        condition,
        status,
      };
    },
    getEquipmentError(state, action: PayloadAction<string>) {
      return {
        ...state,
        loading: initialState.loading,
        error: action.payload,
      };
    },

    getResources(state, action: PayloadAction<string>) {
      return {
        ...state,
      };
    },
    getResourcesSuccess(state, action: PayloadAction<Resource[]>) {
      return {
        ...state,
        attachments: action.payload,
      };
    },

    getTasks(state, action: PayloadAction<string>) {
      return {
        ...state,
      };
    },
    getTasksSuccess(state, action: PayloadAction<Task[]>) {
      return {
        ...state,
        tasks: action.payload,
      };
    },

    getLocation(state, action: PayloadAction<string>) {
      return {
        ...state,
      };
    },
    getLocationSuccess(state, action: PayloadAction<ParentTreeNode>) {
      return {
        ...state,
        location: action.payload,
      };
    },

    componentWillUnmount() {
      return initialState;
    },
  },
});

export const { actions, reducer, name: sliceKey } = equipmentSlice;
