import React from 'react';

import {
  translationString,
  translateAttributeLabel,
} from 'locales/translation';
import { formatDate } from 'locales/date-format-i18n';
import {
  MaintainableItem,
  Task,
  LinkPath,
  MetadataTypeAttributes,
  Attribute,
} from 'types';
import { capitalizeFirstLetter } from 'utils/formatters';
import { buildTaskDetailsRoute } from 'utils/route-helpers';
import { Link } from 'app/elements/Links';
import {
  DataTableColumn,
  DataTableRow,
  DataTableValue,
} from 'app/components/DataTable/types';
import { FormAttributeGroup } from '../EquipmentForm/types';

const workOrdersTableColumnTitles: Array<string> = [
  'TaskType',
  'Title',
  'CreatedTime',
  'Status',
  'Assignee',
];

export const workOrdersTableColumns = (): Array<DataTableColumn> =>
  workOrdersTableColumnTitles.map((columnData: string, index: number) => {
    return {
      order: index,
      visible: true,
      type: 'string',
      id: columnData,
      label: translationString(
        columnData === 'TaskType' ? 'Inspections.taskType' : columnData,
      ),
      enumValues: [],
    };
  });

export const mapWorkOrdersToDataTableRow = (
  taskItem: Task,
  customerId: string,
  locationId: string,
  pathname: string,
): DataTableRow => ({
  id: taskItem.id,
  values: workOrderTableAttributes(taskItem, customerId, locationId, pathname),
});

export const workOrderTableAttributes = (
  taskItem: Task,
  customerId: string,
  locationId: string,
  pathname: string,
): DataTableValue[] => {
  const linkPath: LinkPath = {
    pathname: buildTaskDetailsRoute(customerId, locationId, taskItem.id),
    state: {
      prevPath: pathname,
      prevName: taskItem.maintainableItemName,
    },
  };

  return [
    {
      id: 'TaskType',
      value: capitalizeFirstLetter(
        taskItem.taskType.mainType.replace(/_/g, ' '),
      ),
    },
    {
      id: 'Title',
      value: <Link to={linkPath}>{taskItem.title}</Link>,
    },
    {
      id: 'CreatedTime',
      value: formatDate(taskItem.createdDate),
    },
    {
      id: 'Status',
      value: capitalizeFirstLetter(taskItem.status),
    },
    {
      id: 'Assignee',
      value: <div>{taskItem.assignee?.name}</div>,
    },
  ];
};

export const hydrateEquipmentEnumLabels = (enumLabels: {
  [key: string]: string;
}) => (equipment: MaintainableItem) => ({
  ...equipment,
  attributes: equipment.attributes?.map(attr => ({
    ...attr,
    displayValue:
      attr.type === 'enum'
        ? enumLabels?.[`${equipment.type}_${attr.id}_${attr.value}`] ||
          attr.displayValue ||
          attr.value
        : attr.displayValue || attr.value,
  })),
});

export function hydrateAttributesWithValues(
  attributes: MetadataTypeAttributes[],
  attributeValues: Attribute[],
) {
  const valuesMap = new Map<string, { value: string; displayValue: string }>();
  attributeValues?.forEach(({ id, value, displayValue }) =>
    valuesMap.set(id, { value, displayValue }),
  );

  return attributes.map(attr => {
    const values = valuesMap.get(attr.id);

    return {
      ...attr,
      value: values?.value,
      displayValue: values?.displayValue,
    };
  });
}

export const searchAttributesAndGroups = (
  attributeGroup: FormAttributeGroup,
  searchTerm: string,
): FormAttributeGroup | null => {
  const filteredAttributes: MetadataTypeAttributes[] = [];
  const filteredSubGroups: FormAttributeGroup[] = [];

  if (attributeGroup.attributes) {
    for (const attribute of attributeGroup.attributes) {
      const groupName = attributeGroup.name?.toLowerCase();
      const value = attribute.value?.toLowerCase();
      const translatedLabel = translateAttributeLabel(
        attribute.id,
        attribute.label,
      )?.toLowerCase();

      if (
        groupName?.includes(searchTerm) ||
        value?.includes(searchTerm) ||
        translatedLabel.includes(searchTerm)
      ) {
        filteredAttributes.push(attribute);
      }
    }
  }

  if (attributeGroup.subGroups) {
    for (const subGroup of attributeGroup.subGroups) {
      const result = searchAttributesAndGroups(subGroup, searchTerm);
      if (result) {
        filteredSubGroups.push(result);
      }
    }
  }

  if (filteredAttributes.length === 0 && filteredSubGroups.length === 0) {
    return null;
  }

  return {
    ...attributeGroup,
    attributes: filteredAttributes.length > 0 ? filteredAttributes : undefined,
    subGroups: filteredSubGroups.length > 0 ? filteredSubGroups : undefined,
  };
};

export const sortAttributesByPriority = (
  attributes: MetadataTypeAttributes[],
): MetadataTypeAttributes[] => {
  return attributes.sort((a, b) => {
    // Determine priority based on the rules
    const getPriority = (attr: MetadataTypeAttributes): number => {
      if (attr.highlight && attr.value) return 1;
      if (!attr.highlight && attr.value) return 2;
      if (attr.highlight && attr.value === undefined) return 3;
      if (!attr.highlight && attr.value === undefined) return 4;
      return 5;
    };

    const priorityA = getPriority(a);
    const priorityB = getPriority(b);

    if (priorityA !== priorityB) {
      return priorityA - priorityB;
    } else {
      // If priorities are the same, sort by 'order' field
      return a.order - b.order;
    }
  });
};
