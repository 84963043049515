import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';

import {
  ParentTreeNode,
  Resource,
  HierarchyNode,
  Pack,
  Sensor,
  ValueLabel,
  LocationDetails,
} from 'types';
import { ContainerState, SelectedItem } from './types';

// The initial state of the LocationDashboard container
export const initialState: ContainerState = {
  subUnits: [],
  assetLoading: false,
  assetError: '',

  sensors: [],
  sensorsLoading: false,
  sensorsError: '',

  locationUsers: [],

  assetImages: {
    loading: false,
    error: '',
  },
  assetParentTree: {
    loading: false,
    error: '',
  },

  packs: [],
};

const assetDashboardSlice = createSlice({
  name: 'assetDashboard',
  initialState,
  reducers: {
    setSelectedItem(state, action: PayloadAction<SelectedItem>) {
      return {
        ...state,
        selectedItem: action.payload,
      };
    },

    getAsset(state, action: PayloadAction<string>) {
      return {
        ...state,
        assetLoading: true,
      };
    },
    getAssetSuccessful(state, action: PayloadAction<HierarchyNode>) {
      return {
        ...state,
        assetLoading: initialState.assetLoading,
        asset: action.payload,
        assetError: initialState.assetError,
      };
    },
    getSubUnitsSuccessful(state, action: PayloadAction<HierarchyNode[]>) {
      return {
        ...state,
        assetLoading: initialState.assetLoading,
        subUnits: action.payload,
      };
    },
    getAssetError(state, action: PayloadAction<string>) {
      return {
        ...state,
        assetLoading: initialState.assetLoading,
        asset: initialState.asset,
        subUnits: initialState.subUnits,
        assetError: action.payload,
      };
    },

    getAssetImages(state, action: PayloadAction<string>) {
      return {
        ...state,
        assetImages: {
          loading: true,
        },
      };
    },
    getAssetImagesSuccess(state, action: PayloadAction<Resource[]>) {
      return {
        ...state,
        assetImages: {
          loading: initialState.assetImages.loading,
          resources: action.payload,
        },
      };
    },
    getAssetImagesError(state, action: PayloadAction<string>) {
      return {
        ...state,
        assetImages: {
          loading: initialState.assetImages.loading,
          error: action.payload,
        },
      };
    },

    getAssetParentTree(state, action: PayloadAction<string>) {
      return {
        ...state,
        assetParentTree: {
          loading: true,
        },
      };
    },
    getAssetParentTreeSuccess(state, action: PayloadAction<ParentTreeNode>) {
      return {
        ...state,
        assetParentTree: {
          loading: initialState.assetParentTree.loading,
          parentTree: action.payload,
        },
      };
    },
    getAssetParentTreeError(state, action: PayloadAction<string>) {
      return {
        ...state,
        assetParentTree: {
          loading: initialState.assetParentTree.loading,
          error: action.payload,
        },
      };
    },

    // Sensors
    getSensors(state, action: PayloadAction<string>) {
      state.sensorsLoading = true;
      return state;
    },
    getSensorsSuccess(state, action: PayloadAction<Sensor[]>) {
      state.sensorsLoading = initialState.sensorsLoading;
      state.sensors = action.payload;
      state.sensorsError = initialState.sensorsError;

      return state;
    },
    getSensorsError(state, action: PayloadAction<string>) {
      state.sensorsLoading = initialState.sensorsLoading;
      state.sensors = initialState.sensors;
      state.sensorsError = action.payload;

      return state;
    },

    getLocationUsers(
      state,
      action: PayloadAction<{ locationId: string; showAll: boolean }>,
    ) {
      return state;
    },
    getLocationUsersSuccess(state, action: PayloadAction<ValueLabel[]>) {
      return {
        ...state,
        locationUsers: action.payload,
      };
    },

    getLocation(state, action: PayloadAction<string>) {
      return state;
    },
    getLocationSuccess(state, action: PayloadAction<LocationDetails>) {
      return {
        ...state,
        location: action.payload,
      };
    },

    getPacks(state, action: PayloadAction<string>) {
      return state;
    },
    getPacksSuccess(state, action: PayloadAction<Pack[]>) {
      return {
        ...state,
        packs: action.payload,
      };
    },
    getPacksError(state, action: PayloadAction<string>) {
      return state;
    },
  },
});

export const { actions, reducer, name: sliceKey } = assetDashboardSlice;
