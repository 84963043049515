import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { Icon } from '@eriksdigital/atomic-ui/components';
import {
  AddAltIcon,
  DownArrowIcon,
} from '@eriksdigital/atomic-ui/components/Icons';

import t, { translationString } from 'locales/translation';
import { Div } from 'app/components/Div';
import { TextColor, ValueLabel, BgColor } from 'types';
import { LoadingIndicator } from '../LoadingIndicator';
import { ButtonSecondary } from 'app/elements/Buttons';

export type CustomSelectOption = ValueLabel & {
  depth?: number;
};

interface CustomSelectProps {
  selectedItem?: ValueLabel;
  handleSelection: (item: ValueLabel) => void;
  placeholder?: string;
  options: CustomSelectOption[];
  disabled?: boolean;
  isSearchable?: boolean;
  buttonLabel?: string;
  handleButtonClick?: () => void;
  width?: string;
  onSearch?: (searchTerm: string) => void;
  isLoading?: boolean;
  onClearSelection?: () => void;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  selectedItem,
  handleSelection,
  placeholder,
  options,
  disabled,
  isSearchable,
  buttonLabel,
  handleButtonClick,
  width,
  onSearch,
  isLoading = false,
  onClearSelection,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const ref = useRef<HTMLDivElement | null>(null);

  const toggleDropdown = () => {
    if (!disabled && !ref.current?.closest('fieldset:disabled')) {
      setIsOpen(!isOpen);
    }
  };

  const handleOptionClick = (option: ValueLabel) => {
    handleSelection(option);
    setIsOpen(false);
    if (!onSearch) {
      setSearchTerm('');
    }
  };

  const handleOutsideClick = (e: MouseEvent) => {
    if (ref.current && !ref.current.contains(e.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => document.removeEventListener('click', handleOutsideClick);
  }, []);

  const filteredOptions = onSearch
    ? options
    : options.filter(option =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase()),
      );

  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    onSearch?.(searchTerm);
  };

  return (
    <Wrapper ref={ref} width={width}>
      <Div
        display="flex"
        align="center"
        justifyContent="space-between"
        padding="8px"
        gap="8px"
        cursor={disabled ? 'not-allowed' : 'pointer'}
        color={disabled ? TextColor.inverted : TextColor.muted}
        bg={disabled ? BgColor.gray : BgColor.lighterGray}
        onClick={toggleDropdown}
      >
        {selectedItem?.label ||
          placeholder ||
          translationString('SelectPlaceholder')}
        <Icon as={DownArrowIcon} size="sz16" />
      </Div>

      {isOpen && (
        <Div
          bg={BgColor.lighterGray}
          padding="8px"
          position="absolute"
          className="custom-select"
        >
          {isSearchable && (
            <input
              type="text"
              placeholder={translationString('Search')}
              value={searchTerm}
              onChange={e => handleSearch(e.target.value)}
              autoFocus
            />
          )}

          <ul>
            {!isLoading &&
              filteredOptions.map(option => (
                <ListItem
                  depth={option.depth}
                  key={option.value}
                  onClick={() => {
                    // If the same option is clicked, close the dropdown
                    if (selectedItem?.value === option.value) {
                      setIsOpen(false);
                      return;
                    }

                    handleOptionClick(option);
                  }}
                  className={
                    option.value === selectedItem?.value
                      ? 'selected-option'
                      : ''
                  }
                >
                  {option.label}
                </ListItem>
              ))}

            {filteredOptions.length === 0 && searchTerm && !isLoading && (
              <Div padding="8px" color={TextColor.secondaryNew}>
                {translationString('NoResults')}
              </Div>
            )}

            {isLoading && <LoadingIndicator />}

            {buttonLabel && handleButtonClick && (
              <Footer
                onClick={() => {
                  setIsOpen(false);
                  handleButtonClick();
                }}
              >
                <Icon as={AddAltIcon} size="sz16" />
                <Div ml="8px">{buttonLabel}</Div>
              </Footer>
            )}
          </ul>
          {!!onClearSelection && !!selectedItem && (
            <ClearSelectionWrapper>
              <ButtonSecondary
                type="button"
                onClick={() => {
                  setIsOpen(false);
                  onClearSelection();
                }}
              >
                {t('ClearSelection')}
              </ButtonSecondary>
            </ClearSelectionWrapper>
          )}
        </Div>
      )}
    </Wrapper>
  );
};

export default CustomSelect;

const Wrapper = styled.div<{ width?: string }>`
  position: relative;
  display: inline-block;
  width: ${({ width }) => width || '100%'};
  font-size: 14px;

  fieldset:disabled & div:first-child {
    background-color: ${({ theme }) => theme.colors.default.gray2};
    color: ${({ theme }) => theme.colors.default.white};
  }

  .custom-select {
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
    border-top: 1px solid #c1c7cd;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  }

  .custom-select input {
    width: 100%;
    padding: 8px;
    border: none;
    background: ${({ theme }) => theme.colors.default.white};
    outline: none;
  }

  .custom-select ul {
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 150px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      background-color: ${({ theme }) => theme.colors.default.gray1};
      appearance: none;
      height: 8px;
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.default.gray2};
      border-radius: 8px;
    }
  }
`;

type ListItemProps = {
  depth?: number;
};

const ListItem = styled.li<ListItemProps>`
  padding: 8px;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.colors.default.gray2};
  color: ${({ theme }) => theme.colors.default.blue2};
  padding-left: ${({ depth }) =>
    depth ? `calc((${depth} * 16px) + 8px)` : '8px'};

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.background.tertiary};
    color: ${({ theme }) => theme.colors.default.blue2} !important;
  }

  &.selected-option {
    background: ${({ theme }) => theme.colors.default.blue1};
    color: ${({ theme }) => theme.colors.default.white} !important;
  }
`;

const Footer = styled.li`
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding-top: 10px;
  cursor: pointer;
  color: #005fbe !important;

  :hover {
    background: #f2f4f8 !important;
  }
`;

const ClearSelectionWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.default.gray2};
  padding: 12px 0;
`;
