/**
 *
 * LogCard
 *
 */
import React, { useState, useEffect, memo } from 'react';
import { Button } from '@eriksdigital/atomic-ui/components';
import { useDispatch, useSelector } from 'react-redux';

import t, { translationString } from 'locales/translation';
import {
  Alert,
  BgColor,
  TextColor,
  AlertType,
  SensorProperty,
  AlertSeverity,
} from 'types';
import { getTime, getDate } from 'utils/alertDateFormat';
import { getValue } from 'utils/getDiagnosticAdviceValue';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { LiveStatusBadge } from 'app/components/LiveStatusBadge';
import { StatusLevelRing } from 'app/components/StatusLevelRing';
import { SensorSimpleChart } from 'app/components/Charting/SensorSimpleChart';
import { Div } from 'app/components/Div';
import { Text } from 'app/components/Typography';
import { actions, sliceKey, reducer } from 'app/containers/QrCode/slice';
import { qrCodeSaga } from 'app/containers/QrCode/saga';
import { selectQrCode } from 'app/containers/QrCode/selectors';

interface Props {
  alert?: Alert;
}

export const LogCard = memo(({ alert }: Props) => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: qrCodeSaga });

  const dispatch = useDispatch();
  const { graphs } = useSelector(selectQrCode);

  const [showLoadButton, setShowLoadButton] = useState(false);
  const [graphData, setGraphData] = useState<SensorProperty | null>(null);

  useEffect(() => {
    if (!graphs || !alert?.id) return;

    if (graphs[alert.id]) {
      setGraphData(graphs[alert.id]);
    } else {
      setGraphData(null);
    }
  }, [alert, graphs]);

  useEffect(() => {
    if (!alert) return;

    if (alert.sensorId && alert.propertyId && !graphData) {
      setShowLoadButton(true);
    } else {
      setShowLoadButton(false);
    }
  }, [alert, graphData]);

  return (
    <Div bg={BgColor.white} mt="8px" padding="24px 12px">
      {alert ? (
        <>
          <Div display="flex" justifyContent="space-between">
            <Div>
              <Text text="uppercase" color={TextColor.muted}>
                {t(`ConditionScore.${alert.type}`)}
              </Text>

              {/* Circle */}
              <Div
                display="inline-block"
                align="center"
                justifyContent="center"
                bg={BgColor.darkGray}
                borderRad="3px"
                width="3px"
                height="3px"
                margin="3px 8px"
              />

              <Text text="capitalize" color={TextColor.muted}>
                {alert.name}
              </Text>
            </Div>

            <Div ml="12px">
              {alert.type === AlertType.STATUS && (
                <LiveStatusBadge
                  color={AlertSeverity[alert.severity]}
                  value={`${alert.value?.toFixed(2) || ''} ${
                    alert.measurementUnit || ''
                  } `}
                  animated={false}
                />
              )}

              <StatusLevelRing
                size={22}
                score={alert.conditionScore}
                textSize="12px"
              />
            </Div>
          </Div>

          <Div mt="24px">
            <Text fontWeight="medium">{t('Diagnostic')}</Text>
            <br />
            <Text fontWeight="normal">
              {getValue('Diagnostic', alert.diagnostic, alert.diagnosticCode)}
            </Text>
          </Div>
          <Div mt="16px">
            <Text fontWeight="medium">{t('Advice')}</Text>
            <br />
            <Text fontWeight="normal">
              {getValue('Advice', alert.advice, alert.adviceCode)}
            </Text>
          </Div>

          {showLoadButton && (
            <Div
              mt="16px"
              mb="26px"
              display="flex"
              justifyContent="center"
              align="center"
            >
              <Button
                id="load-graph-button"
                type="button"
                variant="secondary"
                onClick={() => {
                  setShowLoadButton(false);
                  dispatch(actions.getGraphValues(alert));
                }}
              >
                <Text fontWeight="medium">{t('LoadGraph')}</Text>
              </Button>
            </Div>
          )}

          {graphData && (
            <Div mt="16px" ml="14px">
              <SensorSimpleChart
                data={graphData.values}
                dots={graphData.values.length === 1}
                threshold={graphData.threshold}
                leftLabel={graphData.measurement}
                bottomLabel={translationString('Time')}
                gridX
                gridY
              />
            </Div>
          )}

          <Div mt="24px">
            <Text color={TextColor.muted}>
              {getDate(alert)}
              {getTime(alert) ? ` - ${getTime(alert)}` : ''}
            </Text>
          </Div>
        </>
      ) : (
        <Text color={TextColor.muted}>{t('NoActiveAlert')}</Text>
      )}
    </Div>
  );
});
