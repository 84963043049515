import { matchPath } from 'react-router-dom';
import { Location } from 'history';
import { subDays } from 'date-fns';

import { SensorType, URLParams, TABS } from 'types';
import { EquipmentDetailTab } from 'app/containers/EquipmentDetail/types';

export const buildCustomerRoute = (customerId: string): string =>
  `/c/${customerId}`;

export const buildDashboardRoute = (
  customerId: string,
  locationId: string = '',
): string => {
  const customerSegment = buildCustomerRoute(customerId);
  if (!locationId.length) return `${customerSegment}/dashboard`;
  else return `${customerSegment}/dashboard/${locationId}`;
};

export const buildDashboardSpaceRoute = (
  customerId: string,
  locationId: string,
  spaceId: string,
): string => `${buildDashboardRoute(customerId, locationId)}/space/${spaceId}`;

export const buildDashboardAssetRoute = (
  customerId: string,
  locationId: string,
  assetId: string,
): string => `${buildDashboardRoute(customerId, locationId)}/asset/${assetId}`;

export const buildTaskDetailsRoute = (
  customerId: string,
  locationId: string,
  taskId: string,
): string => {
  return `${buildDashboardRoute(customerId, locationId)}/task/${taskId}`;
};

export const buildDashboardAssetMIRoute = (
  customerId: string,
  locationId: string,
  assetId: string,
  maintainableItemId: string,
): string =>
  `${buildDashboardRoute(customerId, locationId)}/asset/${assetId}?${
    URLParams.MI
  }=${maintainableItemId}`;

export const buildDashboardAssetsRoute = (
  customerId: string,
  locationId: string = '',
  assetsFilter?: string[],
): string => {
  let queryString;
  if (assetsFilter?.length) {
    let filterQuery = new URLSearchParams();

    filterQuery.set(URLParams.FilterAssets, assetsFilter.join(','));
    queryString = `?${filterQuery.toString()}`;
  }

  return `${buildDashboardRoute(customerId, locationId)}/assets${
    queryString || ''
  }`;
};

export const buildDashboardTasksRoute = (
  customerId: string,
  locationId: string = '',
): string => `${buildDashboardRoute(customerId, locationId)}/tasks`;

export const buildDashboardAttachmentsRoute = (
  customerId: string,
  locationId: string = '',
): string => `${buildDashboardRoute(customerId, locationId)}/attachments`;

export const buildAdminCustomerManageRoute = (
  aspect?: string,
  customerId?: string,
): string =>
  aspect
    ? `/admin/customer/${customerId}/${aspect}`
    : `/admin/customer/${customerId || ''}`;

export const buildAdminCustomerManageLocationEditRoute = (
  customerId: string,
  locationId: string,
): string =>
  `${buildAdminCustomerManageRoute(
    'locations',
    customerId,
  )}/${locationId}/edit`;

export const buildAdminCustomerManageLocationLinkRoute = (
  customerId: string,
  locationId: string,
  vendor: SensorType,
): string =>
  `${buildAdminCustomerManageRoute(
    'locations',
    customerId,
  )}/${locationId}/link/${vendor}`;

export const buildAdminCustomerManageLocationAddRoute = (
  customerId: string,
): string => `${buildAdminCustomerManageRoute('locations', customerId)}/new`;

export const buildAdminCustomerManageUserRoute = (
  customerId: string,
  userId: string,
): string =>
  `${buildAdminCustomerManageRoute('users', customerId)}/detail/${userId}`;

export const buildAdminCustomerManageRouteWithLocation = (
  aspect: string,
  customerId: string,
  locationId?: string,
): string =>
  `${buildAdminCustomerManageRoute(aspect, customerId)}/${locationId}`;

export const buildSubUnitSensorRoute = (
  customerId: string,
  locationId: string,
  assetId: string,
  subUnitId: string,
  tab: TABS,
  sensorId?: string,
): string => {
  const startDate = subDays(new Date(), 2).toISOString();
  const endDate = new Date().toISOString();

  const url = sensorId
    ? `sensors?${URLParams.SubunitId}=${subUnitId}&${URLParams.TAB}=${tab}&${URLParams.SensorIds}=${sensorId}`
    : `sensors?${URLParams.SubunitId}=${subUnitId}&${URLParams.TAB}=${tab}`;

  return `${buildDashboardAssetRoute(customerId, locationId, assetId)}/${url}&${
    URLParams.StartDate
  }=${startDate}&${URLParams.EndDate}=${endDate}`;
};

export const buildSensorManagementRoute = (
  customerId: string,
  locationId: string,
  maintainableItemId: string,
): string =>
  `${buildAdminCustomerManageRoute(
    'sensors',
    customerId,
  )}/${locationId}/mi/${maintainableItemId}`;

export const buildPackManagementRoute = (
  customerId: string,
  locationId: string,
  subUnitId: string,
): string =>
  `${buildAdminCustomerManageRoute(
    'sensors',
    customerId,
  )}/${locationId}/su/${subUnitId}/pump-pack`;

export const buildEquipmentRoute = (
  customerId: string,
  maintainableItemId: string,
): string => `${buildCustomerRoute(customerId)}/mi/${maintainableItemId}`;

export const buildEquipmentsRoute = (
  customerId: string,
  locationId: string,
  miTypeId: string,
): string =>
  `${buildCustomerRoute(
    customerId,
  )}/dashboard/${locationId}/equipments/${miTypeId}`;

export const buildEquipmentDetailRoute = (
  customerId: string,
  locationId: string,
  miId: string,
  tab: EquipmentDetailTab = 'condition-alerts',
): string => `/c/${customerId}/dashboard/${locationId}/mi/${miId}/${tab}`;

export const buildEquipmentItemsRoute = (
  customerId: string,
  maintainableItemId: string,
  items: string,
): string => `${buildEquipmentRoute(customerId, maintainableItemId)}/${items}`;

export const getSensorFromRoute = (
  location: Location,
  path: string,
): string => {
  const match = matchPath<{ sensorType: SensorType }>(location.pathname, {
    path: path,
    exact: true,
    strict: false,
  });
  return match?.params?.sensorType ? match.params.sensorType : '';
};

export const buildEquipmentCreateRoute = (
  customerId: string,
  locationId: string,
  miTypeId: string,
): string => `${buildEquipmentsRoute(customerId, locationId, miTypeId)}/create`;

export const buildEquipmentCloneRoute = (
  customerId: string,
  locationId: string,
  miTypeId: string,
  miId: string,
): string =>
  `${buildEquipmentsRoute(customerId, locationId, miTypeId)}/clone/${miId}`;

export const buildEquipmentEditRoute = (
  customerId: string,
  locationId: string,
  miTypeId: string,
  miId: string,
): string =>
  `${buildEquipmentsRoute(customerId, locationId, miTypeId)}/edit/${miId}`;

export default {
  buildCustomerRoute,
  buildDashboardRoute,
  buildDashboardSpaceRoute,
  buildDashboardAssetRoute,
  buildTaskDetailsRoute,
  buildDashboardAssetsRoute,
  buildDashboardAttachmentsRoute,
  buildAdminCustomerManageRoute,
  buildAdminCustomerManageUserRoute,
  buildSubUnitSensorRoute,
  buildDashboardTasksRoute,
  buildEquipmentsRoute,
  buildEquipmentDetailRoute,
  buildEquipmentCreateRoute,
  buildEquipmentEditRoute,
  buildEquipmentCloneRoute,
  buildAdminCustomerManageRouteWithLocation,
};
