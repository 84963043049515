import { createSelector } from '@reduxjs/toolkit';
import { AlertType, RootState, TaskStatus } from 'types';
import { initialState } from './slice';
import { WorkOrderTableType } from './types';
import {
  selectAttributeGroups,
  selectEnumLabels,
  selectMetadata,
} from '../Equipments/selectors';
import {
  hydrateAttributesWithValues,
  hydrateEquipmentEnumLabels,
} from './utils';
import { getAttributeGroups } from '../EquipmentForm/utils';

const selectDomain = (state: RootState) =>
  state.equipmentDetails || initialState;

export const selectEquipmentStatus = createSelector(
  [selectDomain],
  ({ condition, status, isLoadingStatus }) => ({
    condition,
    status,
    isLoadingStatus,
  }),
);

export const selectEquipment = createSelector(
  [selectDomain],
  ({ equipment }) => equipment,
);

export const selectEquipmentHydrated = createSelector(
  [selectEquipment, selectEnumLabels],
  (equipment, enumLabels) =>
    equipment && hydrateEquipmentEnumLabels(enumLabels)(equipment),
);

export const selectEquipmentType = createSelector(
  [selectEquipment, selectMetadata],
  (equipment, metadata) =>
    metadata?.maintainableItemTypes.find(
      et => et.maintainableItemType === equipment?.type,
    ),
);

export const selectEquipmentTypeId = createSelector(
  [selectEquipmentType],
  equipmentType => equipmentType?.maintainableItemTypeId,
);

export const selectEquipmentMetadataAttributes = createSelector(
  [selectEquipmentType],
  equipmentType => equipmentType?.attributes,
);

export const selectGroupedAttributes = createSelector(
  [
    selectEquipmentHydrated,
    selectEquipmentMetadataAttributes,
    selectAttributeGroups,
  ],
  (equipment, attributes, attributeGroups) =>
    equipment?.attributes && attributes && attributeGroups
      ? getAttributeGroups(
          hydrateAttributesWithValues(attributes, equipment.attributes),
          attributeGroups,
          'listVisible',
        )
      : undefined,
);

export const selectMiId = createSelector(
  [selectDomain],
  equipmentDetailsState => equipmentDetailsState.miId,
);

export const selectIsPoolingEquipment = createSelector(
  [selectDomain],
  equipmentDetailsState => equipmentDetailsState.isPoolingEquipment,
);

export const selectIsLoading = createSelector(
  [selectDomain],
  ({ isLoading }) => isLoading,
);

export const selectWorkOrdersIsLoading = (workOrderType: WorkOrderTableType) =>
  createSelector(
    [selectDomain],
    equipmentDetailsState =>
      equipmentDetailsState.workOrders[workOrderType].loading,
  );

export const selectWorkOrders = (workOrderType: WorkOrderTableType) =>
  createSelector(
    [selectDomain],
    equipmentDetailsState =>
      equipmentDetailsState.workOrders[workOrderType].data,
  );

export const selectSort = (workOrderType: WorkOrderTableType) =>
  createSelector(
    [selectDomain],
    equipmentDetailsState =>
      equipmentDetailsState.workOrders[workOrderType].sort,
  );

export const selectPageInfo = (workOrderType: WorkOrderTableType) =>
  createSelector(
    [selectDomain],
    equipmentDetailsState =>
      equipmentDetailsState.workOrders[workOrderType].pageInfo,
  );

export const selectWorkOrdersPayload = (workOrderType: WorkOrderTableType) =>
  createSelector([selectDomain], equipmentDetailsState => {
    const { miId, taskTypes } = equipmentDetailsState;
    const { pageInfo, sort } = equipmentDetailsState.workOrders[workOrderType];
    const statusTypes =
      workOrderType === 'planned'
        ? [TaskStatus.DRAFT, TaskStatus.TODO]
        : [TaskStatus.DONE];

    return {
      miId,
      pageIndex: pageInfo?.page,
      pageSize: pageInfo?.pageSize,
      sortField: sort?.columnId || 'createdTime',
      sortOrder: sort?.sortDirection || 'DESC',
      taskTypes,
      statusTypes,
    };
  });

export const selectAlerts = () =>
  createSelector(
    [selectDomain],
    equipmentDetailsState => equipmentDetailsState.alerts,
  );

export const selectAlertsDateRange = () =>
  createSelector([selectAlerts()], alerts => alerts.dateRange);

export const selectAlertsOnlyActive = () =>
  createSelector([selectAlerts()], alerts => alerts.onlyActive);

export const selectAlertsIsInit = () =>
  createSelector([selectAlerts()], alerts => alerts.isInit);

export const selectAlertsIsLoading = () =>
  createSelector([selectAlerts()], alerts => alerts.isLoading);

export const selectAlertsActive = () =>
  createSelector([selectAlerts()], alerts =>
    alerts.onlyActive
      ? alerts.data.filter(alert => alert.isActive)
      : alerts.data,
  );

export const selectAlertsByType = (alertTypes: AlertType[]) =>
  createSelector([selectAlertsActive()], alerts =>
    alerts.filter(alert => alertTypes.includes(alert.type)),
  );
