import React, { useState, useEffect, Fragment } from 'react';
import styled from 'styled-components/macro';
import { SearchForm } from '@eriksdigital/atomic-ui/components';

import {
  translateAttributeLabel,
  translationString,
} from 'locales/translation';
import { TextColor } from 'types';
import { Div } from 'app/components/Div';
import { Text } from 'app/components/Typography';
import { StyledDiv } from './AttributesTable';
import { FormAttributeGroup } from 'app/containers/EquipmentForm/types';
import { searchAttributesAndGroups } from '../utils';
import AttributeValue from './AttributeValue';

type Props = {
  groupedAttributes: FormAttributeGroup[];
};

export const AttributesDetails = ({ groupedAttributes }: Props) => {
  const [inputString, setInputString] = useState<string>('');

  useEffect(() => {
    return () => setInputString('');
  }, []);

  const searchInAttributesAndGroups = (): FormAttributeGroup[] => {
    if (!inputString.trim()) return groupedAttributes;

    return groupedAttributes.reduce<FormAttributeGroup[]>(
      (resultGroups, group) => {
        const result = searchAttributesAndGroups(
          group,
          inputString.trim().toLowerCase(),
        );
        if (result) resultGroups.push(result);
        return resultGroups;
      },
      [],
    );
  };

  const renderAttributes = (
    attributes: typeof groupedAttributes[0]['attributes'],
  ) =>
    attributes?.map(attributeItem => (
      <Div key={attributeItem.id} display="flex" justifyContent="space-between">
        <Text fontSize="ft14" color={TextColor.secondaryNew}>
          {translateAttributeLabel(attributeItem.id, attributeItem.label)}
        </Text>
        <Text fontSize="ft14" color={TextColor.secondaryNew}>
          <AttributeValue attribute={attributeItem} />
        </Text>
      </Div>
    ));

  const renderGroup = (group: FormAttributeGroup, index: number) => (
    <Fragment key={index}>
      {group.attributes?.length !== 0 && (
        <Div display="flex" align="center" pl="16px" mt="32px" mb="9px">
          <Text
            fontSize="ft14"
            fontWeight="medium"
            color={TextColor.secondaryNew}
          >
            {translationString(`Group.${group.name}`)}
          </Text>
        </Div>
      )}
      <StyledGrid>{renderAttributes(group.attributes)}</StyledGrid>
      {group.subGroups?.map(renderSubGroup)}
    </Fragment>
  );

  const renderSubGroup = (subgroup: FormAttributeGroup, index: number) => (
    <Fragment key={index}>
      <Div display="flex" align="center" pl="16px" mt="32px" mb="9px">
        <Text
          fontSize="ft14"
          fontWeight="medium"
          color={TextColor.secondaryNew}
        >
          {translationString(`Group.${subgroup.name}`)}
        </Text>
      </Div>
      <StyledGrid>{renderAttributes(subgroup.attributes)}</StyledGrid>
    </Fragment>
  );

  return (
    <Wrapper>
      <SearchBarWrapper>
        <SearchForm
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setInputString(e.target.value)
          }
          onClick={() => {}}
          placeholder={translationString('SearchFor')}
          searchButton
          data-testid="search-input"
          id="search"
          value={inputString}
        />
      </SearchBarWrapper>
      {searchInAttributesAndGroups().map(renderGroup)}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 460px;
  max-width: 100vw;
`;

const StyledGrid = styled(StyledDiv)`
  & > div {
    padding: 9px 16px;
  }
`;

const SearchBarWrapper = styled.div`
  padding: 0 16px;
  div {
    border-radius: 0;
    border: none;
    background: ${({ theme }) => theme.colors.default.gray1};
    &:focus-within {
      border: none;
    }
  }
  div input {
    font-size: 14px;
  }
  div input::placeholder {
    color: ${({ theme }) => theme.colors.default.gray3};
    font-size: 14px;
  }
  div input:focus {
    border: none;
  }
`;
