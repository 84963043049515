import React from 'react';

import { Alert, AlertType, AlertSeverity } from 'types';
import { LiveStatusBadge } from 'app/components/LiveStatusBadge';
import { StatusLevelRing } from 'app/components/StatusLevelRing';

export const getConditionOrStatus = (alert: Alert) => {
  if (alert.type === AlertType.STATUS) {
    return (
      <LiveStatusBadge
        color={AlertSeverity[alert.severity]}
        value={`${alert.value?.toFixed(2) || ''} ${
          alert.measurementUnit || ''
        } `}
        animated={false}
      />
    );
  } else {
    return <StatusLevelRing size={24} score={alert.conditionScore} />;
  }
};
