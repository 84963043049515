import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';

export default () => {
  const { pathname } = useLocation();
  const [previousPathname, setPreviousPathname] = useState<string>('');

  useEffect(() => {
    if (pathname !== previousPathname) {
      setPreviousPathname(pathname);

      TagManager.dataLayer({
        dataLayer: {
          page: {
            url: window.location.href,
            path: window.location.pathname,
          },
          event: 'virtualPageView',
        },
      });
    }
  }, [pathname, previousPathname]);
};
