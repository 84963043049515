import React from 'react';

export function Invalid() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.16">
        <rect width="16" height="16" rx="8" fill="#D61C1C" />
      </g>
      <g clipPath="url(#clip0_6069_9409)">
        <path
          d="M11 5.525L10.475 5L8 7.475L5.525 5L5 5.525L7.475 8L5 10.475L5.525 11L8 8.525L10.475 11L11 10.475L8.525 8L11 5.525Z"
          fill="#D61C1C"
        />
      </g>
      <defs>
        <clipPath id="clip0_6069_9409">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
