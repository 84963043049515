import { Icon } from '@eriksdigital/atomic-ui/components';
import { SideArrowIcon } from '@eriksdigital/atomic-ui/components/Icons';
import React, { ReactNode, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import { useClickOutside } from 'utils/hooks/useClickOutside';
import { matchMedia, mediaQuery } from 'utils/style-utils';
import { Div } from '../../../components/Div';
import { selectSubMenuIsOpen } from '../selectors';
import { actions } from '../slice';
import { SubMenuType } from '../types';

type Props = {
  icon: ReactNode;
  label: string;
  to?: string;
  children?: ReactNode;
  bottom?: ReactNode;
  subMenu?: SubMenuType;
};

function MenuItem({ icon, label, to, children, subMenu, bottom }: Props) {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const isOpen = useSelector(selectSubMenuIsOpen(subMenu));

  useClickOutside(ref, () => {
    if (isOpen && matchMedia.phoneLarge.matches) {
      dispatch(actions.toggle());
    }
  });

  const handleMenuItemClick = () => {
    if (subMenu) {
      isOpen
        ? dispatch(actions.toggle())
        : dispatch(actions.openSubMenu(subMenu));
    }
  };

  if (to) {
    return (
      <MenuItemWrapper>
        <Link to={to}>
          <Icon as={icon} />
          <span>{label}</span>
        </Link>
      </MenuItemWrapper>
    );
  }

  return (
    <MenuItemWrapper ref={ref} aria-expanded={isOpen}>
      <Div onClick={handleMenuItemClick}>
        <Icon as={icon} />
        <span>{label}</span>
        <SideArrowWrapper>
          <Icon as={SideArrowIcon} />
        </SideArrowWrapper>
      </Div>
      <SubMenu>
        <SubMenuContainer>{children}</SubMenuContainer>
        {bottom && <SubMenuBottom>{bottom}</SubMenuBottom>}
      </SubMenu>
    </MenuItemWrapper>
  );
}

export default MenuItem;

const MenuContentCss = css`
  h1 {
    color: ${({ theme }) => theme.colors.default.purple};
    font-size: ${({ theme }) => theme.fonts.fontSize.ft16};
    margin-bottom: ${({ theme }) => theme.spacing.sp12};
  }

  h2 {
    color: ${({ theme }) => theme.colors.default.purple};
    font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
    margin-bottom: ${({ theme }) => theme.spacing.sp12};
  }

  a {
    display: block;
    color: ${({ theme }) => theme.colors.default.white};
    font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
    margin-left: ${({ theme }) => theme.spacing.sp12};
    margin-bottom: ${({ theme }) => theme.spacing.sp12};
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }
  }
`;

const SubMenu = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.default.blue2};
  transition: all 300ms;
  visibility: hidden;
  overflow: hidden;
  z-index: 3;
  max-width: 0;
`;

export const SubMenuBottom = styled.div`
  background-color: ${({ theme }) => theme.colors.default.blue2};
  padding: ${({ theme }) => theme.spacing.sp16};
  opacity: 0;

  ${MenuContentCss}
`;

const SubMenuContainer = styled.div`
  min-width: 224px;
  width: 100vw;
  flex: 1;
  padding: ${({ theme }) => theme.spacing.sp16};
  margin: ${({ theme }) => theme.spacing.sp4};
  overflow-y: auto;
  opacity: 0;
  transition: all 500ms;

  ${mediaQuery.phoneLarge} {
    width: fit-content;
  }

  ${MenuContentCss}

  &::-webkit-scrollbar {
    appearance: none;
    width: ${({ theme }) => theme.spacing.sp4};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.default.gray2};
    border-radius: ${({ theme }) => theme.spacing.sp4};
  }
`;

const MenuItemWrapper = styled.li`
  height: 60px;
  user-select: none;

  ${mediaQuery.phoneLarge} {
    height: 80px;
  }

  > a,
  > div:first-child {
    display: flex;
    gap: ${({ theme }) => theme.spacing.sp8};
    align-items: center;
    color: ${({ theme }) => theme.colors.default.white};
    text-decoration: none;
    font-weight: 600;
    height: 100%;
    transition: all 300ms;
    padding: 0 ${({ theme }) => theme.spacing.sp16};

    &:hover {
      background-color: ${({ theme }) => theme.colors.default.blue2};
      cursor: pointer;
    }

    > span {
      font-size: ${({ theme }) => theme.fonts.fontSize.ft12};
      text-transform: uppercase;
    }

    > svg {
      width: ${({ theme }) => theme.sizes.sz24};
      height: ${({ theme }) => theme.sizes.sz24};
    }

    ${mediaQuery.phoneLarge} {
      flex-direction: column;
      justify-content: center;
    }
  }

  &[aria-expanded='true'] {
    background-color: ${({ theme }) => theme.colors.default.blue2};

    ${SubMenuContainer}, ${SubMenuBottom} {
      opacity: 1;
    }

    ${SubMenu} {
      visibility: visible;
      z-index: 3;
      max-width: 100vw;

      ${mediaQuery.phoneLarge} {
        max-width: 400px;
      }
    }
  }
`;

const SideArrowWrapper = styled.div`
  margin-left: auto;

  > svg {
    width: ${({ theme }) => theme.sizes.sz24};
    height: ${({ theme }) => theme.sizes.sz24};
  }

  ${mediaQuery.phoneLarge} {
    display: none;
  }
`;
