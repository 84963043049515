import styled from 'styled-components/macro';
import { inputBorder, label } from 'styles';

export const FieldWrapper = styled.div<{ required?: boolean }>`
  margin-top: ${({ theme }) => theme.spacing.sp16};
  margin-bottom: ${({ theme }) => theme.spacing.sp4};

  div {
    font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
  }

  label {
    ${label}
    margin-bottom: ${({ theme }) => theme.spacing.sp8};
    display: block;
  }

  input,
  textarea {
    ${inputBorder};
  }

  ${({ required }) =>
    required &&
    `label::after {content: '*'; color: #D61C1D; margin-left: 5px;}`};

  .select_input > :nth-child(2) {
    ${inputBorder};
  }
`;
