import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { actions, reducer, sliceKey } from '../slice';
import { equipmentInspectionSaga } from '../saga';
import { selectAddingStatus } from '../selectors';

function useInspections() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: equipmentInspectionSaga });

  const dispatch = useDispatch();

  const { isAdding: isAddingInspection } = useSelector(selectAddingStatus);

  const fetchInspections = useCallback(() => {
    dispatch(actions.getInspections());
  }, [dispatch]);

  const fetchMetadata = useCallback(() => {
    dispatch(actions.getMetadata());
  }, [dispatch]);

  const openInspectionSideBar = useCallback(() => {
    dispatch(actions.setIsAdding(true));
  }, [dispatch]);

  return {
    fetchInspections,
    fetchMetadata,
    openInspectionSideBar,
    isAddingInspection,
  };
}

export default useInspections;
