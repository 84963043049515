import { useCallback, useState } from 'react';

function useSelection() {
  const [selected, setSelected] = useState<string[]>([]);

  const select = useCallback((ids: string[]) => {
    setSelected(current => Array.from(new Set([...current, ...ids])));
  }, []);

  const unselect = useCallback((ids: string[]) => {
    setSelected(current => current.filter(item => !ids.includes(item)));
  }, []);

  const toggle = useCallback(
    (id: string) => {
      selected.includes(id) ? unselect([id]) : select([id]);
    },
    [selected, select, unselect],
  );

  const clear = useCallback(() => {
    setSelected([]);
  }, [setSelected]);

  return { selected, select, unselect, toggle, clear };
}

export default useSelection;
