import styled from 'styled-components/macro';

export const Card = styled.div<{
  inset?: boolean;
  mt?: string;
  mb?: string;
  ml?: string;
  mr?: string;
}>`
  position: relative;
  background: ${({ inset, theme }) =>
    inset ? theme.colors.background.lightGray : theme.colors.background.white};
  box-shadow: ${({ inset, theme }) =>
    inset
      ? 'none'
      : `0 2px ${theme.spacing.sp16}
    rgba(102, 102, 102, 0.16)`};
  border-radius: 3px;
  padding: ${({ theme }) => theme.spacing.sp16};
  ${({ mt }) => mt && `margin-top: ${mt};`}
  ${({ mb }) => mb && `margin-bottom: ${mb};`}
  ${({ mr }) => mr && `margin-right: ${mr};`}
  ${({ ml }) => ml && `margin-left: ${ml};`}
`;

Card.defaultProps = {
  inset: false,
};
