import styled from 'styled-components/macro';

export default styled.label<{ required?: boolean }>`
  font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
  color: ${({ theme }) => theme.colors.default.blue2};
  font-weight: ${({ theme }) => theme.fonts.fontWeight.medium};

  ${({ required }) =>
    required &&
    `
    ::after {
      content: '*';
      color: #d61c1c;
      margin-left: 4px; 
    }
  `}
`;
