/**
 *
 * TreeNode
 *
 */
import React, { useState, useEffect, useContext } from 'react';
import styled, { css } from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DownArrowIcon } from '@eriksdigital/atomic-ui/components/Icons';
import { IconMenu, Icon } from '@eriksdigital/atomic-ui/components';
import {
  OverflowVerticalIcon,
  WarningIcon,
} from '@eriksdigital/atomic-ui/components/Icons';
import {
  DragDropContext,
  Droppable,
  DroppableProvided,
  Draggable,
  DraggableProvided,
  OnDragEndResponder,
} from 'react-beautiful-dnd';

import { translationString } from 'locales/translation';
import RouteHelpers from 'utils/route-helpers';
import { pxToRem } from 'utils/style-utils';
import { NodeContext } from 'app/containers/CustomerManagement/nodeContext';
import { actions } from 'app/containers/DashboardPage/slice';
import { HierarchyNode, EntityType, Severity, AlertSeverity } from 'types';
import draggableIcon from 'assets/images/draggable.svg';

interface Props {
  data: HierarchyNode;
  customerId: string;
  locationId?: string;
  defaultOpen?: boolean;
  canToggle?: boolean;
  activeItem?: string;
  withStatus: boolean;
  rootNode: string;
  buildOverflowMenu?: Function;
  nodeTypesOnClick?: {
    [key in EntityType]?: Function;
  };
  cutNode?: HierarchyNode | null;
  canDrag: boolean;
  firstNode: boolean;
  isDragEnabled?: boolean;
  draggableprovided?: DraggableProvided;
}

export function TreeNode({
  data,
  customerId,
  locationId,
  defaultOpen = false,
  canToggle = true,
  activeItem,
  withStatus,
  rootNode,
  buildOverflowMenu,
  nodeTypesOnClick,
  cutNode,
  canDrag,
  isDragEnabled = false,
  firstNode,
  draggableprovided,
}: Props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    id,
    name,
    depth,
    hasAlert,
    alertSeverity,
    hasChildren,
    children,
    type,
    severity,
    specificProperties,
  } = data;

  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);
  const { newNodeId, setNewNode } = useContext(NodeContext);

  const showToggle: boolean = canToggle && hasChildren;
  const location = type === EntityType.LOCATION ? id : locationId;

  useEffect(() => {
    if (defaultOpen) return;
    if (id === newNodeId) {
      setNewNode(data);
    }
  }, [
    activeItem,
    children,
    data,
    defaultOpen,
    hasChildren,
    id,
    newNodeId,
    setNewNode,
  ]);

  // Checks in a passed in dictionary of node type handlers for
  // an override tree node click handler
  const getNodeOnClick = (nodeData: HierarchyNode) => {
    if (!nodeTypesOnClick || !nodeTypesOnClick[nodeData.type]) {
      return getDefaultNodeOnClick(nodeData);
    }
    return nodeTypesOnClick[nodeData.type]({ ...nodeData, locationId });
  };

  const getDefaultNodeOnClick = (nodeData: HierarchyNode) => {
    switch (nodeData.type) {
      case EntityType.ASSET:
        return history.push(
          RouteHelpers.buildDashboardAssetRoute(
            customerId,
            rootNode,
            nodeData.id,
          ),
        );
      case EntityType.SPACE:
        return history.push(
          RouteHelpers.buildDashboardSpaceRoute(
            customerId,
            rootNode,
            nodeData.id,
          ),
        );
      default:
        return history.push(
          RouteHelpers.buildDashboardRoute(customerId, nodeData.id),
        );
    }
  };

  const overflowMenu = buildOverflowMenu
    ? buildOverflowMenu({ ...data, locationId })
    : undefined;

  const formatSubUnitName = (name: string = ''): string =>
    name
      ? `${translationString('Subunit')}: ${name}`
      : translationString('Subunit');

  const formatMIName = (name: string = '', type: string = ''): string =>
    `${translationString(type)}: ${name}`;

  const displayName =
    type === EntityType.SUB_UNIT
      ? formatSubUnitName(name)
      : type === EntityType.MAINTAINABLE_ITEM
      ? formatMIName(name, specificProperties?.type)
      : name;

  const handleReorder = async (e: OnDragEndResponder) => {
    if (!e.destination) return;
    if (e.destination.index === e.source.index) return;

    const parentId = e.source.droppableId;
    const sourceIndex = e.source.index;
    const destinationIndex = e.destination.index;

    const childIds: string[] = children?.map(c => c.id) || [];
    const temp = childIds[sourceIndex];
    childIds.splice(sourceIndex, 1);
    childIds.splice(destinationIndex, 0, temp);

    dispatch(
      actions.reorderHierarchy({
        customerId,
        parentId,
        childIds,
      }),
    );
  };

  return (
    <NodeWrapper>
      <DragDropContext onDragEnd={handleReorder}>
        <Droppable droppableId={id} type={name}>
          {(droppableProvided: DroppableProvided) => (
            <div
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
            >
              <PrimaryNode
                withToggle={showToggle}
                active={id === activeItem}
                depth={depth}
                isCut={id === cutNode?.id}
                data-testid={`treenode-${id}`}
                canDrag={canDrag}
                firstNode={firstNode}
                ref={draggableprovided?.innerRef}
                {...draggableprovided?.draggableProps}
                {...draggableprovided?.dragHandleProps}
              >
                {showToggle && (
                  <ToggleButton
                    open={isOpen}
                    data-stringid={
                      isOpen
                        ? 'Hierarchy.ToggleNodeClosed'
                        : 'Hierarchy.ToggleNodeOpen'
                    }
                    onClick={() => setIsOpen(!isOpen)}
                    data-testid={`treenode-${id}-btn-toggle`}
                    aria-label={
                      isOpen
                        ? translationString('Hierarchy.ToggleNodeClosed')
                        : translationString('Hierarchy.ToggleNodeOpen')
                    }
                  >
                    <Icon as={DownArrowIcon} />
                  </ToggleButton>
                )}

                {draggableprovided && isDragEnabled && (
                  <DraggableIcon src={draggableIcon} alt="draggableIcon" />
                )}

                {withStatus && (
                  <StatusIndicator
                    severity={severity}
                    data-testid={`treenode-${id}-severity`}
                  />
                )}

                <NodeName role="link" onClick={() => getNodeOnClick(data)}>
                  {displayName}
                  {hasAlert && alertSeverity && (
                    <WarningIconMargin
                      as={WarningIcon}
                      color={AlertSeverity[alertSeverity] || ''}
                    />
                  )}
                </NodeName>

                {overflowMenu && overflowMenu.length > 0 && (
                  <IconMenu
                    icon={OverflowVerticalIcon}
                    title="Node Actions"
                    id={`NodeActionMenu_${id}`}
                    menuItems={overflowMenu}
                    menuSide="right"
                  />
                )}
              </PrimaryNode>

              {isOpen && hasChildren && (
                <NodeChildren>
                  {children.map((child, index) => (
                    <Draggable key={index} draggableId={child.id} index={index}>
                      {(draggableprovided: DraggableProvided) => (
                        <TreeNode
                          key={child.id}
                          data={child}
                          canToggle={canToggle}
                          activeItem={activeItem}
                          withStatus={withStatus}
                          rootNode={rootNode}
                          customerId={customerId}
                          locationId={location}
                          buildOverflowMenu={buildOverflowMenu}
                          nodeTypesOnClick={nodeTypesOnClick}
                          cutNode={cutNode}
                          canDrag={canDrag}
                          firstNode={false}
                          isDragEnabled={canDrag && children.length > 1}
                          draggableprovided={draggableprovided}
                        />
                      )}
                    </Draggable>
                  ))}
                </NodeChildren>
              )}

              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </NodeWrapper>
  );
}

const NodeWrapper = styled.li`
  position: relative;
  margin: 0;
`;

const PrimaryNode = styled.div<{
  withToggle: boolean;
  active: boolean;
  depth: number;
  isCut: boolean;
  canDrag: boolean;
  firstNode: boolean;
}>`
  --depth: ${({ depth }) => depth};
  --active-text-decoration: ${({ active }) => (active ? 'underline' : 'none')};
  --active-color: ${({ active, theme }) =>
    active ? theme.colors.default.blueS : theme.colors.default.white};


  position: relative;
  display: flex;
  align-items: center;
  border-radius: 3px;
  background: ${({ theme, active, canDrag }) =>
    active ? theme.colors.background.secondary : canDrag ? '#F8F8F8' : 'white'};
  ${({ theme, isCut }) => isCut && `1px dashed ${theme.colors.text.gray};`}
  ${({ firstNode, canDrag }) => firstNode && canDrag && 'padding: 8px 0;'}
  ${({ withToggle, depth, canDrag }) => {
    let indentAmount = depth * 12;
    if (!withToggle) indentAmount += 24;
    const value = pxToRem(indentAmount);

    return canDrag
      ? `margin-bottom: 8px; margin-left: ${value}; padding-left: 8px;`
      : `padding-top: 8px; padding-bottom: 8px; padding-left: ${value};`;
  }}
`;

const NodeName = styled.span`
  font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
  color: ${({ theme }) => theme.colors.text.darkGray};
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1 1 auto;
  min-width: 0;
  cursor: pointer;
`;

const ToggleButton = styled.button<{ open: boolean }>`
  position: relative;
  border: 0;
  padding: 0;
  margin: 0;
  background: transparent;
  width: ${pxToRem(24)};
  height: ${pxToRem(20)};
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text.darkGray};

  svg {
    width: ${({ theme }) => theme.sizes.sz16};
    height: ${({ theme }) => theme.sizes.sz16};
    transition: all 300ms;

    ${({ open }) =>
      open &&
      css`
        transform: rotate(-180deg);
      `}
  }
`;

const NodeChildren = styled.ul`
  padding-left: 0;
  list-style-type: none;
`;

const StatusIndicator = styled.span<{ severity: Severity | undefined }>`
  display: inline-block;
  height: ${pxToRem(15)};
  border-radius: 3px;
  background: ${({ theme }) => theme.colors.background.gray};
  margin: 0 ${pxToRem(6)};
  flex: 0 0 ${pxToRem(4)};

  ${({ severity, theme }) => {
    let indicatorColor;
    switch (severity) {
      case Severity.CRITICAL:
        indicatorColor = theme.colors.signal.alert;
        break;
      case Severity.GOOD:
        indicatorColor = theme.colors.text.proceed;
        break;
      case Severity.WARNING:
        indicatorColor = theme.colors.default.yellowB;
        break;
      default:
        indicatorColor = theme.colors.signal.unavailable;
    }
    return css`
      background: ${indicatorColor};
    `;
  }}
`;

const WarningIconMargin = styled(Icon)`
  margin-left: 8px;
  margin-bottom: 2px;
  width: 15px;
  height: 15px;
`;

const DraggableIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 5px;
  opacity: 0.5;
`;

export const TreeNodeStyles = {
  StatusIndicator,
  ToggleButton,
  PrimaryNode,
  NodeName,
};
