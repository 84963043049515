/**
 *
 * LoadingIndicator
 *
 */
import React, { ReactElement, useContext } from 'react';
import { Icon } from '@eriksdigital/atomic-ui/components';
import { LoadingIcon } from '@eriksdigital/atomic-ui/components/Icons';
import styled, { ThemeContext } from 'styled-components/macro';

interface Props {
  small?: boolean;
  className?: string;
  message?: string | ReactElement;
}

export function LoadingIndicator({ className, message, small = false }: Props) {
  const themeContext = useContext(ThemeContext);
  return (
    <LoadingWrapper small={small} className={className}>
      <Icon
        as={LoadingIcon}
        size={small ? 'sz24' : 'sz32'}
        color={themeContext.colors.text.secondary}
        data-testid="loader"
      />
      {message && <LoadingMessage small={small}>{message}</LoadingMessage>}
    </LoadingWrapper>
  );
}

const LoadingWrapper = styled.div<{ small: boolean }>`
  flex: 1 1 100%;
  padding: ${({ theme, small }) =>
    small ? theme.spacing.sp8 : theme.spacing.sp24};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingMessage = styled.span<{ small: boolean }>`
  padding-left: ${({ theme, small }) =>
    small ? theme.spacing.sp16 : theme.spacing.sp24};
  font-size: ${({ theme, small }) =>
    small ? theme.fonts.fontSize.ft12 : theme.fonts.fontSize.ft24};
  color: ${({ theme }) => theme.colors.text.darkGray};
`;
