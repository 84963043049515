import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { actions, reducer, sliceKey } from '../slice';
import { equipmentAttachmentsSaga } from '../saga';
import { selectResourceTypes } from '../selectors';

export default function useEquipmentAttachments() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: equipmentAttachmentsSaga });

  const dispatch = useDispatch();

  const resourceTypes = useSelector(selectResourceTypes);

  const fetchResourceTypes = useCallback(() => {
    dispatch(actions.getResourceTypes());
  }, [dispatch]);

  return {
    resourceTypes,
    fetchResourceTypes,
  };
}
