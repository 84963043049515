/**
 *
 * Blurred Cards
 *
 */

import React, { ReactElement } from 'react';
import styled from 'styled-components/macro';
import { Icon, Button } from '@eriksdigital/atomic-ui/components';
import { DownloadIcon } from '@eriksdigital/atomic-ui/components/Icons';

import t from 'locales/translation';
import { TextColor, BgColor, TaskStatus } from 'types';
import { StatusLevelRing } from 'app/components/StatusLevelRing';
import { useAuth } from 'app/providers/AuthProvider';
import { Div } from 'app/components/Div';
import { Text } from 'app/components/Typography';
import { getBGForStatus, getColorForStatus } from 'utils/getColor';

interface Card {
  total: number;
  id: string;
  title: string | ReactElement;
  viewAll: string | ReactElement;
  body: ReactElement;
}

export function BlurredCards() {
  const { login } = useAuth();

  const cards: Card[] = [
    {
      total: 0,
      id: 'all_logs',
      title: t('LatestLog'),
      viewAll: t('ViewAllLogs'),
      body: (
        <Container>
          <Login>
            <Button
              id="log-in-button"
              type="button"
              stretch="auto"
              onClick={login}
            >
              <Text fontWeight="medium">{t('LogIn')}</Text>
            </Button>
          </Login>

          <Blurred bg={BgColor.white} mt="8px" padding="24px 12px">
            <Div display="flex" justifyContent="space-between">
              <Div>
                <Text color={TextColor.muted}>Condition score</Text>

                {/* Circle */}
                <Div
                  display="inline-block"
                  align="center"
                  justifyContent="center"
                  bg={BgColor.darkGray}
                  borderRad="3px"
                  width="3px"
                  height="3px"
                  margin="3px 8px"
                />

                <Text color={TextColor.muted}>Sensor NDE 12345</Text>
              </Div>

              <Div ml="12px">
                <StatusLevelRing size={22} score={4} textSize="12px" />
              </Div>
            </Div>

            <Div mt="24px">
              <Text fontWeight="medium">{t('Diagnostic')}</Text>
              <br />
              <Text>
                Diagnostic The ratios of directional vibration readings are not
                looseness [D2]
              </Text>
            </Div>
            <Div mt="16px">
              <Text fontWeight="medium">{t('Advice')}</Text>
              <br />
              <Text>
                Advice A start up/coast down analysis may be beneficial to
                identify the cause of the vibration response. [A2]
              </Text>
            </Div>

            <Div mt="24px">
              <Text color={TextColor.muted}>12 Mar 2023 - 12:27:00</Text>
            </Div>
          </Blurred>
        </Container>
      ),
    },
    {
      total: 0,
      id: 'all_tasks',
      title: t('Task'),
      viewAll: t('ViewAllTasks'),
      body: (
        <Container>
          <Login>
            <Button
              id="log-in-button"
              type="button"
              stretch="auto"
              onClick={login}
            >
              <Text fontWeight="medium">{t('LogIn')}</Text>
            </Button>
          </Login>

          <Blurred bg={BgColor.white} mt="8px" padding="24px 12px">
            <Div display="flex" justifyContent="space-between">
              <Text fontWeight="medium">
                Task title rem ipsum dolor sit amet dolor sit
              </Text>

              <Status display="flex" ml="12px" status={TaskStatus.TODO}>
                <span>To do</span>
              </Status>
            </Div>

            <Div mt="16px">
              <Text>
                Lorem ipsum dolor sit amet, consetur adipiscing elitess sit
                amet, consetur adipiscing sit amet, consetur adipiscing.
              </Text>
            </Div>

            <Div mt="24px">
              <Text color={TextColor.muted}>Start date: 20 Jan 2023</Text>
              <br />
              <Text color={TextColor.muted}>Due date: 25 Jan 2023</Text>
            </Div>

            <Div mt="24px" display="flex" justifyContent="flex-end">
              <Button variant="ghost">
                <Text>Details</Text>
              </Button>
            </Div>
          </Blurred>
        </Container>
      ),
    },
    {
      total: 0,
      id: 'all_attachments',
      title: t('Attachments'),
      viewAll: t('ViewAllAttachments'),
      body: (
        <Container>
          <Login>
            <Button
              id="log-in-button"
              type="button"
              stretch="auto"
              onClick={login}
            >
              <Text fontWeight="medium">{t('LogIn')}</Text>
            </Button>
          </Login>
          <Blurred>
            {[1, 2, 3].map(item => (
              <Div
                key={item}
                display="flex"
                align="start"
                justifyContent="space-between"
                bg={BgColor.white}
                color={TextColor.secondary}
                mt="4px"
                padding="14px 12px"
              >
                <Text>Safety_datasheet.pdf</Text>
                <Icon as={DownloadIcon} size="sz16" />
              </Div>
            ))}
          </Blurred>
        </Container>
      ),
    },
  ];

  return (
    <Div>
      {cards.map(card => (
        <Div key={card.id} mt="24px">
          <Text fontWeight="medium" color={TextColor.tertiary}>
            {card.title}
          </Text>

          {card.body}

          <Div mt="24px" display="flex" justifyContent="center">
            <Button
              id={card.id}
              data-testid={card.id}
              variant="ghost"
              onClick={login}
            >
              <Text>
                {card.viewAll}
                {/* {` (${card.total})`} */}
              </Text>
            </Button>
          </Div>
        </Div>
      ))}
    </Div>
  );
}

const Container = styled.div`
  position: relative;
`;

const Blurred = styled(Div)`
  filter: blur(3px);
`;

const Login = styled(Div)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const Status = styled(Div)<{ status: TaskStatus }>`
  min-width: 50px;

  span {
    font-weight: ${({ theme }) => theme.fonts.fontWeight.normal};
    font-size: ${({ theme }) => theme.fonts.fontSize.ft12};
    padding: 6px 8px;
    background: ${({ status, theme }) => getBGForStatus(status, theme)};
    color: ${({ status, theme }) => getColorForStatus(status, theme)};
    border-radius: 3px;
  }
`;
