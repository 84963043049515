import React, { ReactNode, createContext, useContext } from 'react';
import useSelection from '../useSelection';

type Props = {
  children: ReactNode;
};

function SelectContextProvider({ children }: Props) {
  const { selected, select, unselect, toggle, clear } = useSelection();

  return (
    <SelectContext.Provider
      value={{ selected, select, unselect, toggle, clear }}
    >
      {children}
    </SelectContext.Provider>
  );
}

export default SelectContextProvider;

export const SelectContext = createContext(
  {} as ReturnType<typeof useSelection>,
);

export const useSelectContext = () => useContext(SelectContext);
