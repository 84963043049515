import styled from 'styled-components/macro';

export const Controls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.breakpoints.tabletLandscape`
    justify-content: flex-end;
  `};

  button + button {
    margin-left: ${({ theme }) => theme.spacing.sp24};
  }
`;
