import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState } from './types';
import { EntityType } from '../../../types';

// The initial state of the ReportProvider container
export const initialState: ContainerState = {
  reports: {},
};

const reportProviderSlice = createSlice({
  name: 'reportProvider',
  initialState,
  reducers: {
    generateReport(
      state,
      action: PayloadAction<{
        type: 'Condition' | 'Task';
        entityId: string;
        entityType: EntityType;
        filterIds: string[];
      }>,
    ) {
      const { type, entityType, filterIds } = action.payload;
      return {
        ...state,
        reports: {
          ...state.reports,
          [type]: {
            entityType,
            filterIds,
            loading: true,
          },
        },
      };
    },
    generateReportSuccess(
      state,
      action: PayloadAction<{
        type: 'Condition' | 'Task';
        file: Blob;
        fileName: string;
      }>,
    ) {
      const { type, file, fileName } = action.payload;

      return {
        ...state,
        reports: {
          ...state.reports,
          [type]: {
            ...state.reports[type],
            loading: false,
            created: new Date(),
            reportData: file,
            reportName: fileName,
          },
        },
      };
    },
    generateReportFailed(state, action: PayloadAction<string>) {
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.payload]: {
            ...state.reports[action.payload],
            loading: false,
            created: undefined,
            reportData: undefined,
            reportName: undefined,
          },
        },
      };
    },
    clearReportData(state, action: PayloadAction<string>) {
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.payload]: {
            ...state.reports[action.payload],
            loading: false,
            created: undefined,
            reportData: undefined,
            reportName: undefined,
          },
        },
      };
    },
  },
});

export const { actions, reducer, name: sliceKey } = reportProviderSlice;
