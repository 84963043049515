import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { GridColumn, GridRow } from '@eriksdigital/atomic-ui/components';

import t, {
  translateAttributeLabel,
  translationString,
} from 'locales/translation';
import { BgColor, TextColor, MetadataTypeAttributes } from 'types';
import { ButtonSecondary } from 'app/elements/Buttons';
import { Text } from 'app/components/Typography';
import { Div } from 'app/components/Div';
import { FormAttributeGroup } from 'app/containers/EquipmentForm/types';
import AttributeValue from './AttributeValue';
import { sortAttributesByPriority } from '../utils';

interface Props {
  groupedAttributes: FormAttributeGroup[];
  openDetails: (open: boolean) => void;
}

interface SingleGroupProps {
  attributes: MetadataTypeAttributes[];
}

export const AttributesTable = ({ groupedAttributes, openDetails }: Props) => {
  const groupAttributesById = useMemo(() => {
    return groupedAttributes.reduce<Record<string, MetadataTypeAttributes[]>>(
      (acc, group) => {
        const { attributeGroupId, attributes = [], subGroups = [] } = group;

        if (!acc[attributeGroupId]) {
          acc[attributeGroupId] = [];
        }

        acc[attributeGroupId].push(...attributes);

        subGroups.forEach(subGroup => {
          if (subGroup.attributes) {
            acc[attributeGroupId].push(...subGroup.attributes);
          }
        });

        return acc;
      },
      {},
    );
  }, [groupedAttributes]);

  return (
    <>
      <StyledGridRow>
        {groupedAttributes.map((group, index) => (
          <StyledGridColumn key={index} desktop={6} mobile={12}>
            <GroupColumn
              group={group}
              attributesByGroup={groupAttributesById}
            />
          </StyledGridColumn>
        ))}

        <Div
          display="flex"
          align="center"
          justifyContent="flex-end"
          bg={BgColor.white}
          padding="16px"
          width="100%"
          height="46px"
        >
          <ButtonSecondary onClick={() => openDetails(true)}>
            {t('All_Specifications')}
          </ButtonSecondary>
        </Div>
      </StyledGridRow>
    </>
  );
};

const GroupColumn = ({
  group,
  attributesByGroup,
}: {
  group: FormAttributeGroup;
  attributesByGroup: Record<string, MetadataTypeAttributes[]>;
}) => {
  const attributes = attributesByGroup[group.attributeGroupId];

  return (
    <>
      <Div
        display="flex"
        align="center"
        padding="16px"
        height="46px"
        bg={BgColor.white}
      >
        <Text color={TextColor.secondary} fontWeight="medium" fontSize="ft14">
          {translationString(`Group.${group.name}`)}
        </Text>
      </Div>
      <SingleGroupColumn attributes={attributes} />
    </>
  );
};

const SingleGroupColumn = ({ attributes }: SingleGroupProps) => {
  const sortedAttributes = sortAttributesByPriority(attributes).slice(0, 7);

  return (
    <StyledDiv display="flex" direction="column">
      {sortedAttributes.map(attribute => (
        <AttributeRow key={attribute.id} attribute={attribute} />
      ))}
    </StyledDiv>
  );
};

const AttributeRow = ({ attribute }: { attribute: MetadataTypeAttributes }) => (
  <Div
    display="flex"
    justifyContent="space-between"
    lineHeight="14px"
    width="100%"
  >
    <Text fontSize="ft14" color={TextColor.tertiary} lh="ft14">
      {translateAttributeLabel(attribute.id, attribute.label)}
    </Text>
    <Text fontSize="ft14" color={TextColor.muted} lh="ft14">
      <AttributeValue attribute={attribute} />
    </Text>
  </Div>
);

export const StyledDiv = styled(Div)`
  flex-basis: 50%;
  & > div:nth-child(odd) {
    background-color: ${({ theme }) => theme.colors.default.gray1};
  }
  & > div:nth-child(even) {
    background-color: ${({ theme }) => theme.colors.default.white};
  }
  & > div {
    padding: 11px 16px;
  }
`;

const StyledGridRow = styled(GridRow)`
  margin: 0;
`;

const StyledGridColumn = styled(GridColumn)`
  margin-bottom: 24px;
  padding: 0;

  ${({ theme }) => theme.breakpoints.tabletPortrait`
    margin-bottom: 0;
  `};
`;
