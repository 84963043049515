export function capitalizeFirstLetter(key: string = ''): string {
  // "asset" to "Asset"
  // "ASSET" to "Asset"

  return key.charAt(0).toUpperCase() + key.slice(1).toLowerCase();
}

export function convertToUnderscoreCase(input: string): string {
  // "asset name" to "asset_name"
  return input.trim().replace(/\s+/g, '_');
}
