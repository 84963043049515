/**
 *
 * MediaManagement
 *
 */

import React from 'react';
import styled from 'styled-components/macro';
import { H1, H2 } from '@eriksdigital/atomic-ui/components';
import { useDispatch, useSelector } from 'react-redux';

import t from 'locales/translation';
import { UploadImageLink } from 'app/components/UploadImageLink';
import { EntityType, Resource, ResourceType } from 'types';
import { selectMediaItems } from '../MediaGallery/selectors';
import { actions as mediaActions } from '../MediaGallery/slice';

interface Props {
  customerId: string;
  locationId: string;
  resourceTypes: ResourceType[];
  entityType: EntityType;
  entityId: string;
  entityName: string;
}

export function MediaManagement({
  customerId,
  locationId,
  resourceTypes,
  entityType,
  entityId,
  entityName,
}: Props) {
  const dispatch = useDispatch();

  const resources: Resource[] = useSelector(state =>
    selectMediaItems(state, entityId),
  );

  return (
    <>
      <Header>{t('Gallery.ManageMedia')}</Header>
      <SubHeader>
        {t(entityType)}: {entityName}
      </SubHeader>
      <Gallery>
        {resources.map(res => {
          const imgUrl = res?.specificProperties?.small || res.url;
          return (
            <MediaItem key={res.id}>
              <Thumbnail src={imgUrl} alt={`${res.resourceType}: ${res.id}`} />
              <MediaDetails>
                <li>
                  <strong>{t('Type')}:</strong>{' '}
                  {t(`ResourceTypes.${res.resourceType}`)}
                </li>
                <li>
                  <RemoveButton
                    onClick={() =>
                      dispatch(
                        mediaActions.removeMediaItem({
                          entityId,
                          resourceId: res.id,
                        }),
                      )
                    }
                  >
                    {t('Remove')}
                  </RemoveButton>
                </li>
              </MediaDetails>
            </MediaItem>
          );
        })}
      </Gallery>
      <UploadControls>
        <UploadImageLink
          entityType={entityType}
          accept="image/x-png,image/gif,image/jpeg"
          customerId={customerId}
          locationId={locationId}
          acceptedTypes={resourceTypes}
          entityId={entityId}
          multiple
        />
      </UploadControls>
    </>
  );
}

const Header = styled(H1)`
  margin: 0;
`;

const SubHeader = styled(H2)`
  font-size: ${({ theme }) => theme.fonts.fontSize.ft20};
  color: ${({ theme }) => theme.colors.text.primary};
  margin-top: 0;
`;

const Gallery = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
`;

const MediaItem = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border.gray};
  flex: 0 0 calc(25% - ${({ theme }) => theme.spacing.sp8});
  margin-right: ${({ theme }) => theme.spacing.sp8};
  margin-bottom: ${({ theme }) => theme.spacing.sp8};
  padding: ${({ theme }) => theme.spacing.sp8};
  text-align: center;
`;

const Thumbnail = styled.img`
  max-width: 100%;
  margin: 0 auto;
`;

const MediaDetails = styled.ul`
  text-align: left;
  list-style: none;
  margin: ${({ theme }) => theme.spacing.sp12} 0 0;
  padding: 0;
  font-size: ${({ theme }) => theme.fonts.fontSize.ft12};

  li {
    margin-top: ${({ theme }) => theme.spacing.sp4};
  }
`;

const RemoveButton = styled.button`
  border: none;
  padding: 0;
  background: none;
  color: ${({ theme }) => theme.colors.text.secondary};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const UploadControls = styled.div`
  padding-top: ${({ theme }) => theme.spacing.sp12};
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
