/**
 *
 * SubMenuAssets
 *
 */

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SpacesTree } from 'app/components/SpacesTree';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import {
  selectActiveTreeNodeId,
  selectHierarchy,
  selectHierarchyLoading,
} from '../../DashboardPage/selectors';
import {
  selectLocationsLoading,
  selectSelectedCustomer,
  selectSelectedLocation,
} from '../../CustomerLocation/selectors';
import styled from 'styled-components';
import { TreeNodeStyles } from '../../../components/TreeNode';
import { SpacesTreeStyles } from '../../../components/SpacesTree';
import { pxToRem } from 'utils/style-utils';
import { ReactComponent as AssetsIcon } from 'assets/icons/assets.svg';
import { H1 } from '../../../components/Typography';
import MenuItem from './MenuItem';
import t, { translationString } from 'locales/translation';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { reducer, sliceKey, actions } from '../../DashboardPage/slice';
import { assetsNavigatorSaga } from '../../DashboardPage/saga';

function MenuAssets() {
  useInjectReducer({
    key: sliceKey,
    reducer: reducer,
  });
  useInjectSaga({
    key: sliceKey,
    saga: assetsNavigatorSaga,
  });

  const dispatch = useDispatch();
  const hierarchyData = useSelector(selectHierarchy);
  const hierarchyLoading = useSelector(selectHierarchyLoading);
  const locationsLoading = useSelector(selectLocationsLoading);
  const customerId = useSelector(selectSelectedCustomer);
  const locationId = useSelector(selectSelectedLocation);
  const activeTreeNodeId = useSelector(selectActiveTreeNodeId);

  useEffect(() => {
    if (locationId) {
      dispatch(actions.getHierarchy({ locationId }));
    }
  }, [dispatch, locationId]);

  const isLoading = hierarchyLoading || locationsLoading;

  return (
    <MenuItem
      icon={AssetsIcon}
      label={translationString('Assets')}
      subMenu="assets"
    >
      <H1>{t('Assets')}</H1>
      <SubMenuAssetsWrapper>
        {isLoading && <LoadingIndicator />}
        {!isLoading && (
          <SpacesTree
            loading={hierarchyData.loading}
            tree={hierarchyData.tree}
            error={hierarchyData.error}
            customerId={customerId}
            activeItem={activeTreeNodeId}
            withStatus
          />
        )}
      </SubMenuAssetsWrapper>
    </MenuItem>
  );
}

export default MenuAssets;

const SubMenuAssetsWrapper = styled.div`
  ${SpacesTreeStyles.TreeLayout} {
    padding: 0;
  }

  ${TreeNodeStyles.PrimaryNode} {
    background-color: transparent;
    padding-left: calc(var(--depth) * 12px);

    ${TreeNodeStyles.NodeName} {
      text-decoration: var(--active-text-decoration);
      color: var(--active-color);
    }
  }

  ${TreeNodeStyles.NodeName} {
    color: ${({ theme }) => theme.colors.default.white};
    padding-right: ${({ theme }) => theme.spacing.sp24};
  }

  ${TreeNodeStyles.ToggleButton} {
    position: absolute;
    right: 0;
    color: ${({ theme }) => theme.colors.default.white};
  }

  ${TreeNodeStyles.StatusIndicator} {
    min-width: ${pxToRem(8)};
    width: ${pxToRem(8)};
    min-height: ${pxToRem(8)};
    height: ${pxToRem(8)};
    border-radius: ${pxToRem(8)};
  }
`;
