import React from 'react';
import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@eriksdigital/atomic-ui/components';

import { selectUnreadCount } from 'app/containers/Notifications/selectors';
import { actions } from 'app/containers/Notifications/slice';
import { NotificationsIcon } from './icons';

export const NotificationsIndicator = () => {
  const dispatch = useDispatch();
  const notificationCount = useSelector(selectUnreadCount);
  const hasNotifications = notificationCount > 0;

  const toggleNotifications = () => {
    dispatch(actions.toggleOverlay());
  };

  return (
    <IndicatorButton
      id="notifications-toggle-btn"
      data-testid="notifications-toggle-btn"
      onClick={toggleNotifications}
      hasNotifications={hasNotifications}
      title={
        hasNotifications
          ? 'You have unread notifications'
          : 'No unread notifications'
      }
    >
      <Icon size="sz32" as={NotificationsIcon} />
      {hasNotifications ? notificationCount : null}
    </IndicatorButton>
  );
};

interface IndicatorButtonProps {
  hasNotifications: boolean;
}

const IndicatorButton = styled.button<IndicatorButtonProps>`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sp4};
  padding: 0 ${({ theme }) => theme.spacing.sp8};
  border: none;
  background-color: ${({ theme, hasNotifications }) =>
    hasNotifications ? theme.colors.default.red : theme.colors.default.blue1};
  color: ${({ theme }) => theme.colors.text.white};
  width: auto;
  min-width: ${({ theme }) => theme.sizes.sz32};
  height: ${({ theme }) => theme.sizes.sz32};
  border-radius: ${({ theme }) => theme.sizes.sz32};
  font-size: ${({ theme }) => theme.fonts.fontSize.ft12};
  transition: all 300ms;

  &:hover {
    background-color: ${({ theme }) => theme.colors.default.blue2};
    cursor: pointer;
  }
`;
