/**
 *
 * Pagination
 *
 */

import React, { useState, ReactNode, Fragment } from 'react';
import { Button } from '@eriksdigital/atomic-ui/components';

import t from 'locales/translation';
import { Div } from 'app/components/Div';
import { Text } from 'app/components/Typography';

interface Props {
  items: ReactNode[];
  numberItems?: number;
}

export const Pagination = ({ items, numberItems = 10 }: Props) => {
  const [showNItems, setShowNItems] = useState<number>(numberItems);
  const limitedItem = items.slice(0, showNItems);
  const showButton = limitedItem.length !== items.length;

  return (
    <>
      {limitedItem.map((item, index) => (
        <Fragment key={index}>{item}</Fragment>
      ))}

      {showButton && (
        <Div
          mt="16px"
          mb="26px"
          display="flex"
          justifyContent="center"
          align="center"
        >
          <Button
            id="show-more-button"
            type="button"
            stretch="auto"
            onClick={() => setShowNItems(n => n + numberItems)}
          >
            <Text fontWeight="medium">{t('ShowMore')}</Text>
          </Button>
        </Div>
      )}
    </>
  );
};
