import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

export const Link = styled(RouterLink)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sp8};
  color: ${({ theme }) => theme.colors.default.blue1};
  text-decoration: none;
  font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }

  svg {
    width: ${({ theme }) => theme.sizes.sz20};
    height: ${({ theme }) => theme.sizes.sz20};
  }
`;
