import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
  }

  body {
    word-break: initial;
    overflow-wrap: break-word;
    -webkit-font-smoothing: antialiased;
  }

  #root {
    height: 100%;
    min-height: 100%;
    min-width: 100%;
  }

  input, select, button, textarea, pre {
    font-family: inherit;
    font-size: inherit;
  }

  /* Hotjar feedback button fix */
  #_hj_feedback_container,
  ._hj_feedback_container [class$="_Feedback__container"]
  {
      z-index: 9998;
      position: relative;
  }
`;
