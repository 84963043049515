import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ItemCarousel, Button } from '@eriksdigital/atomic-ui/components';
import { SRLWrapper, useLightbox } from 'simple-react-lightbox';
import { UploadImageLink } from 'app/components/UploadImageLink';
import { Resource } from 'types';
import {
  selectMediaItemsLoading,
  selectMediaItems,
  selectLightboxMediaItems,
} from '../selectors';
import { translationString } from 'locales/translation';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import NO_IMAGE_FOUND from 'assets/images/NO_IMAGE_FOUND.jpg';
import { MediaGalleryProps } from '../types';

type Props = MediaGalleryProps & {
  onManageMediaClick: () => void;
};

function MediaCarousel({
  entityType,
  entityId,
  resourceTypes,
  single = false,
  canManage = false,
  hasLightbox = false,
  roundedBorders = false,
  onManageMediaClick,
}: Props) {
  const { customerId, locationId } = useParams<{
    customerId: string;
    locationId: string;
  }>();

  const { openLightbox } = useLightbox();

  const isLoading = useSelector(state =>
    selectMediaItemsLoading(state, entityId),
  );

  const resources: Resource[] = useSelector(state =>
    selectMediaItems(state, entityId),
  );

  const lbElements = useSelector(state =>
    selectLightboxMediaItems(state, entityId),
  );

  return (
    <>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          {resources.length > 0 ? (
            <>
              {single || resources.length === 1 ? (
                <SingleImageFrame>
                  <ImageTile
                    src={
                      resources[0]?.specificProperties?.large ||
                      resources[0].url
                    }
                    title={`${resources[0].description}_image`}
                    onClick={() => (hasLightbox ? openLightbox(0) : null)}
                  />
                </SingleImageFrame>
              ) : (
                <StyledItemCarousel
                  responsiveConfig={{
                    0: {
                      items: 1,
                    },
                  }}
                  items={resources.map((resource, idx) => (
                    <ImageTile
                      src={resource?.specificProperties?.large || resource.url}
                      title={`${resource.description}_image`}
                      onClick={() => (hasLightbox ? openLightbox(idx) : null)}
                    />
                  ))}
                  roundedBorders={roundedBorders}
                />
              )}
            </>
          ) : (
            <NoImagePlaceholder>
              <img src={NO_IMAGE_FOUND} alt="img not available" />
            </NoImagePlaceholder>
          )}
          {canManage && (
            <ManageItemsControls>
              {single ? (
                <UploadImageLink
                  data-stringid="ReplaceImage"
                  entityType={entityType}
                  accept="image/x-png,image/gif,image/jpeg"
                  customerId={customerId}
                  locationId={locationId}
                  acceptedTypes={resourceTypes}
                  entityId={entityId}
                  text={translationString('ReplaceImage')}
                  small
                />
              ) : (
                <Button
                  id={`btnManageMedia-${entityId}`}
                  data-testid={`btnManageMedia-${entityId}`}
                  variant="ghost"
                  onClick={onManageMediaClick}
                  text={translationString('Gallery.ManageMedia')}
                  data-stringid="Gallery.ManageMedia"
                />
              )}
            </ManageItemsControls>
          )}
          {hasLightbox && lbElements.length > 0 && (
            <SRLWrapper elements={lbElements} />
          )}
        </>
      )}
    </>
  );
}

export default MediaCarousel;

const StyledItemCarousel = styled(ItemCarousel)<{ roundedBorders?: boolean }>`
  ${({ theme, roundedBorders }) =>
    roundedBorders
      ? `
    padding: 0 0 ${theme.spacing.sp8};
    .alice-carousel__wrapper {
      border-radius: 10px;
    }
    `
      : `padding: ${theme.spacing.sp8};`}

  #roundExtraLarge {
    width: ${({ theme }) => theme.sizes.sz40};
    height: ${({ theme }) => theme.sizes.sz40};
  }
`;

const NoImagePlaceholder = styled.div<{ roundedBorders?: boolean }>`
  height: 300px;
  background-color: ${({ theme }) => theme.colors.background.lightGray};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing.sp8};
  ${({ roundedBorders }) => roundedBorders && `border-radius: 10px;`}
`;

const SingleImageFrame = styled.div<{ roundedBorders?: boolean }>`
  margin-bottom: ${({ theme }) => theme.spacing.sp8};
  ${({ roundedBorders }) =>
    roundedBorders &&
    `
    border-radius: 10px;
    overflow: hidden;
    `}
`;

const ImageTile = styled.div<{ src: string }>`
  height: 300px;
  background: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-position: center;
`;

const ManageItemsControls = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: ${({ theme }) => theme.spacing.sp8};
  button {
    font-size: ${({ theme }) => theme.fonts.fontSize.ft14};
  }
`;
