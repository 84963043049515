import React from 'react';

import { decodeHTML } from 'utils/decodeHtml';
import { intl } from './intl';
import translations from './en.json';

export function t(
  key?: string,
  values?: Record<string, string | number>,
  checkIfEmpty?: boolean,
  defaultMessage?: string,
) {
  if (!key || (checkIfEmpty && !translations[key])) return '';

  return (
    <span data-stringid={key}>
      {intl.formatMessage(
        {
          id: key,
          defaultMessage,
          description: translations[key],
        },
        values,
      )}
    </span>
  );
}

export function translationString(
  key?: string,
  values?: Record<string, string | number>,
  checkIfEmpty?: boolean,
  defaultMessage?: string,
): string {
  if (!key || (checkIfEmpty && !translations[key])) return '';

  return intl.formatMessage(
    {
      id: key,
      defaultMessage,
      description: translations[key],
    },
    values,
  );
}

export function renderTranslationHTML(
  key?: string,
  values?: Record<string, string | number>,
  checkIfEmpty?: boolean,
  defaultMessage?: string,
): React.ReactNode {
  if (!key || (checkIfEmpty && !translations[key])) return '';

  const formattedMessage = intl.formatMessage(
    {
      id: key,
      defaultMessage,
      description: translations[key],
    },
    values,
  );

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: decodeHTML(formattedMessage),
      }}
    />
  );
}

export function translateAttributeLabel(
  attrId: string,
  defaultMessage?: string,
): string {
  const attributeKey = `Attributes.${attrId}`;
  const detailsKey = `${attributeKey}.details`;
  const description = translations[detailsKey] || translations[attributeKey];

  return intl.formatMessage({
    id: detailsKey in translations ? detailsKey : attributeKey,
    description,
    defaultMessage,
  });
}

export default t;
