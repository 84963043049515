import { PayloadAction, Action } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import {
  ContainerState,
  NotificationEventCategory,
  NotificationPanelItem,
} from './types';

// The initial state of the Notifications container
export const initialState: ContainerState = {
  overlayVisible: false,
  unreadCount: 0,
  notifications: [],
  currentCategory: 'ALL',
  currentCustomerOnly: false,
  error: null,
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    toggleOverlay(state, action: Action) {
      return {
        ...state,
        overlayVisible: !state.overlayVisible,
        currentCategory: initialState.currentCategory,
      };
    },
    changeCurrentCategory(state, action: PayloadAction<string>) {
      return {
        ...state,
        currentCategory: action.payload as NotificationEventCategory,
      };
    },
    getNotifications(state, action: PayloadAction<string>) {
      return {
        ...state,
        error: initialState.error,
      };
    },
    getNotificationsSuccess(
      state,
      action: PayloadAction<NotificationPanelItem[]>,
    ) {
      return {
        ...state,
        notifications: action.payload,
        unreadCount: action.payload.reduce(
          (total, current) => total + (current.isRead ? 0 : 1),
          0,
        ),
      };
    },
    notificationsOperationFailure(state, action: PayloadAction<string>) {
      return {
        ...state,
        error: action.payload,
      };
    },
    markNotificationRead(
      state,
      action: PayloadAction<{ noteId: string; closePanel: boolean }>,
    ) {
      return {
        ...state,
        overlayVisible: !action.payload.closePanel,
      };
    },
    markAllNotificationsRead(state, action: Action) {
      return {
        ...state,
      };
    },
    markNotificationsReadComplete(state, action: PayloadAction<string[]>) {
      return {
        ...state,
        notifications: state.notifications.map(note => ({
          ...note,
          isRead: action.payload.includes(note.id) ? true : note.isRead,
        })),
      };
    },
    toggleNotificationCustomerFilter(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        currentCustomerOnly: action.payload,
      };
    },
  },
});

export const { actions, reducer, name: sliceKey } = notificationsSlice;
